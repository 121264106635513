import { Box, useDisclosure, VStack, HStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Button, Infobox } from 'Atoms';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  MappedDisclosureRequirements,
  AdditionalType,
  MaterialityState,
  MaterialMetric,
  ParentMaterialityState,
  MaterialityFields,
} from './MaterialityAssessment.d';
import {
  AttachmentBox,
  GetMaterialStandardDocument_,
  NoteHistory,
  ShortUser,
  GetParentMaterialityAssessmentQuery_,
  useUpdateAssessmentOnboardingMutation,
} from 'models';
import { ContentHeader, ContentLayout, Loader } from 'Molecules';
import {
  InputCardDocumentation,
  InputCardDocumentationProps,
} from 'Molecules/InputCard/InputCardDocumentation';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addMandatoryMetrics,
  useAddMaterialityAssessment,
  useGetStandardMaterialityData,
  useMapDisclosureRequirements,
} from './MaterialityAssessment.hooks';
import { MetricsMaterialityModal } from './MetricsMaterialityModal';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { MaterialityRadioGroup } from './MaterialityRadioGroup';
import { MaterialityStatusBox, MaterialityStatusTag, ParentInfoBox } from './MaterialityUtils';
import { DisclosureRequirementsTable } from './DisclosureRequirementsTable';
import { AdditionalTypesGroup } from 'Molecules/MetricTagsList';
import { Typography } from 'Tokens';
import { LockedIcon } from 'Tokens/Icons/Function';
import { Control, Controller, useForm } from 'react-hook-form';
import { DataCollectionLevel } from '../DataCollection';

const AssessMetrics = ({
  currentStep,
  isGroupOwner,
  hasParent,
  disclosureRequirements,
  parentData,
  handleOpenModal,
  isStandardMandatory,
  isCollectOnlyMandatory,
}: {
  currentStep: number;
  isGroupOwner: boolean;
  hasParent: boolean;
  disclosureRequirements: MappedDisclosureRequirements;
  parentData?: GetParentMaterialityAssessmentQuery_;
  handleOpenModal: (reference: string) => void;
  isStandardMandatory: boolean;
  isCollectOnlyMandatory: boolean;
}) => {
  return (
    <Box position="relative">
      <Box pl={isStandardMandatory || isCollectOnlyMandatory ? '0px' : '32px'} pb="24px">
        {isStandardMandatory ? (
          <>
            <Typography variant="h3" mb="2px">
              Review and assess disclosure requirements and metric
            </Typography>
            <Typography variant="body" color="text.muted" mb="16px">
              Review and assess what is material for your company
            </Typography>
          </>
        ) : isCollectOnlyMandatory ? (
          <Typography variant="h3" mb="16px">
            Review disclosure requirements and metric
          </Typography>
        ) : (
          <ActiveStepLabels
            title="Review and assess disclosure requirements and metrics"
            description="Review and assess what is material for your company"
            active={currentStep === 2}
            done={false}
            ml="33px"
          />
        )}
        {(currentStep === 2 || isStandardMandatory) && (
          <VStack spacing="8px" alignItems="start">
            <DisclosureRequirementsTable
              disclosureRequirements={disclosureRequirements}
              handleOpenModal={handleOpenModal}
              hasParent={hasParent}
              isStandardMandatory={isStandardMandatory}
              parentData={parentData}
              isGroupOwner={isGroupOwner}
            />
            <Button
              variant="primary"
              type="submit"
              form="add-materiality"
              alignSelf="start"
              mt="16px"
              rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
            >
              Done
            </Button>
          </VStack>
        )}
      </Box>
    </Box>
  );
};

const AddReasoning = ({
  currentStep,
  setCurrentStep,
  isAttachmentDrawerOpen,
  onAttachmentDrawerClose,
  attachmentBox,
  showDocumentation,
  allowReviewMetrics,
}: {
  currentStep: number;
  setCurrentStep: (val: number) => void;
  isAttachmentDrawerOpen: boolean;
  onAttachmentDrawerClose: () => void;
  attachmentBox: AttachmentBox | undefined;
  showDocumentation: InputCardDocumentationProps;
  allowReviewMetrics: boolean;
}) => {
  return (
    <Box position="relative">
      <Box
        borderLeft={allowReviewMetrics ? '1px dashed' : 'none'}
        pl="32px"
        borderColor="border.hover"
        pb="24px"
      >
        <ActiveStepLabels
          title="Reasoning for choice"
          description="Provide reasoning for why the the topic is material or not. If such reasoning is already documented elsewhere (e.g in an uploaded materiality assessment), please write a short note referring to this documentation."
          active={currentStep === 1}
          mb={currentStep === 1 ? '24px' : '0px'}
          done={currentStep > 1}
          ml="33px"
          goBack={() => setCurrentStep(1)}
          descriptionWidth="490px"
        />

        {currentStep === 1 && (
          <Box w="490px">
            <AttachmentDrawer
              isOpen={isAttachmentDrawerOpen}
              refetch={[GetMaterialStandardDocument_]}
              onClose={onAttachmentDrawerClose}
              attachmentBox={attachmentBox}
            >
              <InputCardDocumentation {...showDocumentation} flexDirection="column" width="490px" />
            </AttachmentDrawer>
            {allowReviewMetrics ? (
              <Button
                variant="primary"
                alignSelf="start"
                rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
                mb="24px"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Next step
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                form="add-materiality"
                alignSelf="start"
                rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
              >
                Done
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const AssessStandardMateriality = ({
  currentStep,
  control,
  defaultValue,
  saveMateriality,
  isMaterial,
  setCurrentStep,
  isCollectOnly,
}: {
  currentStep: number;
  control: Control<MaterialityFields, any>;
  saveMateriality: (value: MaterialityState | null) => void;
  defaultValue: MaterialityState | null;
  isMaterial?: boolean | null;
  setCurrentStep: (val: number) => void;
  isCollectOnly: boolean;
}) => {
  return (
    <Box>
      <Controller
        name="material"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px">
              <ActiveStepLabels
                title="Assess materiality for this topic"
                description="Based on your materiality assessment, is this topic material for your company?"
                mb={currentStep === 0 ? '24px' : '0px'}
                active={currentStep === 0}
                done={currentStep > 0}
                ml="33px"
                goBack={() => setCurrentStep(0)}
              />
              {currentStep === 0 && (
                <>
                  <MaterialityRadioGroup
                    value={value ?? defaultValue}
                    onChange={(val) => {
                      onChange(val);
                      saveMateriality(val);
                    }}
                    defaultValue={defaultValue}
                    isCollectOnly={isCollectOnly}
                  />
                  <Button
                    variant="primary"
                    alignSelf="start"
                    rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    isDisabled={isMaterial == null}
                    mb="24px"
                    mt="24px"
                  >
                    Next step
                  </Button>
                </>
              )}
            </Box>
          );
        }}
      />
    </Box>
  );
};

export const AddMaterialityAssessment = ({ isOnboarding = false }: { isOnboarding?: boolean }) => {
  const { esrsAssessmentId = '' } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { handleSubmit, control, watch } = useForm<MaterialityFields>();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const navigate = useNavigate();

  const [initialLoad, setInitialLoad] = useState(true);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [materialMetrics, setMaterialMetrics] = useState<MaterialMetric[]>([]);
  const [assessmentAdded, setAssessmentAdded] = useState(false);
  const [drRef, setDrRef] = useState<string>('');

  const user: ShortUser | null = useUserData();
  const addMateriality = useAddMaterialityAssessment();
  const mapDisclosureRequirements = useMapDisclosureRequirements();
  const [updateOnboardingStep] = useUpdateAssessmentOnboardingMutation();

  const {
    standard,
    standardMetrics,
    materialStandard,
    parentStandardMaterialityData,
    hasParent,
    isGroupOwner,
    standardDataLoading,
    materialStandardLoading,
    parentData,
    isCollectOnly,
    loading,
  } = useGetStandardMaterialityData();

  const isStandardMandatory = useMemo(() => !(standard?.isTopical ?? true), [standard]);

  const allowReviewMetrics = useMemo(() => {
    if (isStandardMandatory) return true;
    return materialStandard?.isMaterial;
  }, [materialStandard, isStandardMandatory]);

  const parentMaterialMetrics = useMemo(
    () =>
      parentStandardMaterialityData?.materialMetrics.filter(
        (m) => m.metric.disclosureRequirementRef === drRef && m.isMaterial
      ) ?? [],
    [parentStandardMaterialityData, drRef]
  );

  const allParentMaterialMetrics = useMemo(() => {
    return parentStandardMaterialityData?.materialMetrics ?? [];
  }, [parentStandardMaterialityData]);

  const disclosureRequirements = useMemo(
    () =>
      mapDisclosureRequirements({
        materialMetrics: materialStandard?.materialMetrics ?? [],
        parentMaterialMetrics: allParentMaterialMetrics,
        isCollectOnly: isCollectOnly ?? false,
        isGroupOwner: isGroupOwner,
        isStandardMaterial: isStandardMandatory,
        isParentStandardMaterial: parentStandardMaterialityData?.isMaterial,
        requirementGroups: standard?.disclosureRequirementGroups ?? [],
      }),
    [
      standard,
      materialMetrics,
      parentStandardMaterialityData,
      isStandardMandatory,
      isGroupOwner,
      isCollectOnly,
      materialStandard,
    ]
  );

  const standardAdditionalTypes: AdditionalType[] = useMemo(() => {
    return (
      standard?.additionalTypes
        .map((aType) => aType.additionalType)
        .map((type) => ({
          reference: type.reference,
          title: type.title ?? '',
          description: type.description ?? '',
          learnMore: type.learnMore ?? '',
        })) ?? []
    );
  }, [standard]);

  const isCollectOnlyMandatory = useMemo(
    () => materialStandard?.isMaterial && materialStandard.isDataGatheringOnly && isCollectOnly,
    [materialStandard, isCollectOnly]
  );

  const isStandardSpecial = useMemo(
    () => isCollectOnlyMandatory || isStandardMandatory,
    [isCollectOnlyMandatory, isStandardMandatory]
  );

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => materialStandard?.attachmentBox ?? undefined,
    [materialStandard]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => materialStandard?.noteHistory ?? undefined,
    [materialStandard]
  );

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      currentAuthor: user ?? undefined,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetMaterialStandardDocument_],
      isInputCard: false,
      flexDirection: 'column-reverse',
    };
  }, [attachmentBox, noteHistory, user, materialStandardLoading]);

  const defaultValue = useMemo(() => {
    if (materialStandard?.isDataGatheringOnly) return MaterialityState.gatherData;
    if (materialStandard?.isMaterial === null) return null;
    if (materialStandard?.isMaterial) return MaterialityState.material;
    if (materialStandard?.isMaterial === false) return MaterialityState.notMaterial;
    return null;
  }, [materialStandard]);

  const allMaterialMetrics = useMemo(
    () =>
      isCollectOnly
        ? materialMetrics
        : addMandatoryMetrics(
            standard,
            materialMetrics,
            isGroupOwner,
            watch('material', defaultValue)
          ),
    [isCollectOnly, standard, materialMetrics, isGroupOwner, watch('material', defaultValue)]
  );

  const handleAddMateriality = (values: MaterialityFields, isAutosave: boolean) => {
    const dataGathering = isCollectOnlyMandatory
      ? materialStandard?.isDataGatheringOnly
      : isCollectOnly
      ? false
      : undefined;
    addMateriality(
      values.material !== undefined ? values : { material: defaultValue },
      !!materialStandard,
      allMaterialMetrics,
      parentStandardMaterialityData?.materialMetrics ?? [],
      dataGathering
    );
    if (!isAutosave) {
      navigate(-1);
      if (isOnboarding) {
        updateOnboardingStep({
          variables: {
            id: esrsAssessmentId,
            onboardingStep: 3,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (materialMetrics.length > 0 && !initialLoad)
      handleSubmit((values) => handleAddMateriality(values, true))();
  }, [materialMetrics]);

  useEffect(() => {
    if (materialStandard) {
      if (materialMetrics.length === 0 || materialMetrics.some((met) => !met.metric))
        setMaterialMetrics(materialStandard?.materialMetrics ?? []);
    }
    if (materialStandard?.id) {
      setAssessmentAdded(true);
    }
    if (
      initialLoad &&
      materialStandard &&
      materialStandard?.isMaterial &&
      currentStep !== 1 // Prevent automatically moving to step 2 when still typing
    ) {
      setCurrentStep(2);
    }
  }, [materialStandard]);

  const saveMateriality = (value: MaterialityState | null) => {
    if (
      watch('material') === MaterialityState.notMaterial &&
      parentStandardMaterialityData?.isMaterial
    ) {
      const unAssessedMetrics = standardMetrics?.metrics.filter(
        (metric) =>
          !materialStandard?.materialMetrics.find((mm) => mm.metricRef === metric.reference)
      );
      setMaterialMetrics([
        ...(unAssessedMetrics?.map((metric) => ({
          metricRef: metric.reference,
          isDataGatheringOnly: false,
          isMaterial: null,
          dataCollection: DataCollectionLevel.company,
        })) ?? ([] as MaterialMetric[])),
      ]);
    }
    handleAddMateriality({ material: value }, true);
    setInitialLoad(false);
    setAssessmentAdded(true);
  };

  const handleOpenModal = (reference: string) => {
    setDrRef(reference);
    onOpen();
  };

  const submitMateriality = () => {
    handleSubmit((values) => handleAddMateriality(values, true))();
  };

  if (loading) return <Loader />;

  return (
    <VStack
      bg="bg.defaultToDecorative"
      h="fit-content"
      alignItems="center"
      justifyContent="start"
      flexGrow="1"
    >
      <VStack w="100%" alignItems="start" flexGrow="1">
        <ContentLayout isLoading={standardDataLoading} backButton={true} header={false}>
          <Box
            width="88%"
            bg="bg.default"
            border="1px solid"
            borderColor="border.decorative"
            borderRadius="8px"
            p="24px"
            margin="0 auto"
          >
            <ContentLayout
              isLoading={standardDataLoading}
              variant="inline.nopad"
              header={
                <HStack alignItems="flex-start" p="0px" justifyContent="space-between">
                  <ContentHeader
                    title={standard?.title ?? ''}
                    subtitle={standard?.reference}
                    props={{ padding: '0px 0px 8px', width: 'unset' }}
                    height="fit-content"
                  />
                  {isCollectOnly ? (
                    <MaterialityStatusBox
                      materiality={
                        isCollectOnlyMandatory
                          ? MaterialityState.collectDataMandatory
                          : materialStandard?.isMaterial
                          ? MaterialityState.collectData
                          : MaterialityState.doNotCollect
                      }
                    />
                  ) : isStandardMandatory ? (
                    <MaterialityStatusBox materiality={MaterialityState.mandatory} />
                  ) : (
                    <MaterialityStatusTag
                      isAssessed={assessmentAdded}
                      isMaterial={materialStandard?.isMaterial}
                      isDataGather={materialStandard?.isDataGatheringOnly ?? false}
                      isMandatory={isStandardMandatory}
                    />
                  )}
                </HStack>
              }
            >
              {!!standardAdditionalTypes.length && (
                <Box mt="12px">
                  <AdditionalTypesGroup
                    tagsWithHelp={standardAdditionalTypes.map((aType) => {
                      return {
                        reference: aType.reference,
                        title: aType.title ?? '',
                        helpText: aType.description ?? '',
                      };
                    })}
                  />
                </Box>
              )}

              {hasParent &&
                !isStandardMandatory &&
                (isCollectOnly ? (
                  materialStandard?.isMaterial ? (
                    <Box mt="8px">
                      <Infobox
                        status="info"
                        title="Collect data"
                        closable={false}
                        titleProps={{ variant: 'h3' }}
                        description="Disclosure requirements in this standard are material for a parent, so it is required to collect data"
                      />
                    </Box>
                  ) : (
                    <Box mt="8px">
                      <Infobox
                        status="neutral"
                        title="Not required to collect data"
                        closable={false}
                        titleProps={{ variant: 'h3' }}
                        description="This standard is not material for a parent, so you don't have to collect data. However, you can choose to collect data if you wish"
                      />
                    </Box>
                  )
                ) : (
                  <Box mt="20px">
                    <ParentInfoBox
                      status={
                        parentStandardMaterialityData
                          ? parentStandardMaterialityData?.isMaterial
                            ? ParentMaterialityState.material
                            : ParentMaterialityState.notMaterial
                          : ParentMaterialityState.toAssess
                      }
                    />
                  </Box>
                ))}
              {isStandardMandatory && (
                <Infobox
                  status="neutral"
                  title="Mandatory"
                  description="This topic is mandatory for all companies"
                  icon={<LockedIcon color="text.muted" mt="3px" />}
                  closable={false}
                />
              )}

              <VStack alignItems="flex-start" pt={isStandardSpecial ? '32px' : '28px'}>
                <VStack
                  width="100%"
                  alignItems="flex-start"
                  pl="0px"
                  pr={isStandardSpecial ? '0px' : '16px'}
                  ml={isStandardSpecial ? '0px' : '16px'}
                  mb="32px"
                  spacing="24px"
                >
                  <Box position="relative">
                    <form
                      onSubmit={handleSubmit((values) => handleAddMateriality(values, false))}
                      id="add-materiality"
                    >
                      <VStack alignItems="start" gap="0px">
                        {standard?.isTopical && !isCollectOnlyMandatory && (
                          <>
                            <AssessStandardMateriality
                              control={control}
                              currentStep={currentStep}
                              defaultValue={defaultValue}
                              isMaterial={materialStandard?.isMaterial}
                              setCurrentStep={setCurrentStep}
                              saveMateriality={saveMateriality}
                              isCollectOnly={isCollectOnly ?? false}
                            />
                            <AddReasoning
                              allowReviewMetrics={allowReviewMetrics ?? false}
                              attachmentBox={attachmentBox}
                              currentStep={currentStep}
                              isAttachmentDrawerOpen={isAttachmentDrawerOpen}
                              onAttachmentDrawerClose={onAttachmentDrawerClose}
                              setCurrentStep={setCurrentStep}
                              showDocumentation={showDocumentation}
                            />
                          </>
                        )}
                        {allowReviewMetrics && (
                          <AssessMetrics
                            disclosureRequirements={disclosureRequirements}
                            currentStep={currentStep}
                            isStandardMandatory={isStandardMandatory}
                            handleOpenModal={handleOpenModal}
                            hasParent={hasParent}
                            isGroupOwner={isGroupOwner}
                            parentData={parentData}
                            isCollectOnlyMandatory={isCollectOnlyMandatory ?? false}
                          />
                        )}
                      </VStack>
                    </form>
                  </Box>
                </VStack>
              </VStack>
              {isOpen && (
                <MetricsMaterialityModal
                  standardMetrics={standardMetrics}
                  materialMetrics={materialMetrics}
                  setMaterialMetrics={setMaterialMetrics}
                  isOpen={isOpen}
                  setInitialLoad={setInitialLoad}
                  disclosureRequirement={disclosureRequirements.find((dr) => dr.drRef === drRef)}
                  isGroupOwner={isGroupOwner}
                  submitMateriality={submitMateriality}
                  parentMetrics={parentMaterialMetrics}
                  hasParent={hasParent}
                  disableMaterial={defaultValue === MaterialityState.gatherData}
                  onClose={onClose}
                  isCollectOnly={isCollectOnly ?? false}
                  isStandardMandatory={isStandardMandatory}
                />
              )}
            </ContentLayout>
          </Box>
        </ContentLayout>
      </VStack>
    </VStack>
  );
};
