import { Box, HStack, MenuButton, useDisclosure, VStack } from '@chakra-ui/react';
import { AdditionalTypeTag, Button } from 'Atoms';
import { GetStandardMetricsQuery_ } from 'models';
import { LearnMoreDrawer, MetricLearnMoreHeader, Modal } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import {
  MaterialMetric,
  MaterialMetricMapping,
  MaterialityFields,
  MaterialityState,
  ParentMaterialMetrics,
  AdditionalType,
  MappedDisclosureRequirements,
  ParentMaterialityState,
  Metric,
} from './MaterialityAssessment.d';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { MaterialityRadioGroup, MaterialityStatus } from './MaterialityRadioGroup';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuSection } from 'Molecules/Menu';
import { Controller, useForm } from 'react-hook-form';
import { MetricsMaterialityTable } from './MetricsMaterialityTable';
import { DisclosureRequirementInfo, ParentInfoBox } from './MaterialityUtils';
import { DataCollectionLevel, DataCollectionType } from '../DataCollection';

export const AssessAllBtn = ({
  updateMaterialityForAll,
  isDisabled,
}: {
  updateMaterialityForAll: (value: { isMaterial: boolean | null; dataGathering: boolean }) => void;
  isDisabled?: boolean;
}) => {
  const menuBtn = (
    <MenuButton
      as={Button}
      variant="ghost"
      size="md"
      isDisabled={isDisabled}
      rightIcon={<ChevronDownIcon boxSize="16px" />}
    >
      Assess all
    </MenuButton>
  );

  const sections: MenuSection[] = [
    {
      actions: [
        {
          id: 'material',
          title: MaterialityStatus.material,
          onClick: () => updateMaterialityForAll({ isMaterial: true, dataGathering: false }),
        },
        {
          id: 'notMaterial',
          title: MaterialityStatus.notMaterial,
          onClick: () => updateMaterialityForAll({ isMaterial: false, dataGathering: false }),
        },
        {
          id: 'gatherData',
          title: MaterialityStatus.gatherData,
          onClick: () => updateMaterialityForAll({ isMaterial: true, dataGathering: true }),
        },
      ],
    },
  ];
  return <Menu customMenuButton={menuBtn} sections={sections} />;
};

const LearnMoreStep = ({
  onDrawerOpen,
  noTitle,
  additionalTypes,
  setSelectedMetric,
}: {
  onDrawerOpen: () => void;
  noTitle?: boolean;
  additionalTypes?: AdditionalType[];
  setSelectedMetric: (metric: any) => void;
}) => {
  return (
    <VStack w="100%" spacing="8px" alignItems="start">
      {!noTitle && <Typography variant="h3">Understand Disclosure Requirement</Typography>}
      <VStack alignItems="start" spacing="20px">
        {!!additionalTypes?.length && (
          <HStack spacing="4px">
            {additionalTypes?.map((aType) => {
              return (
                <AdditionalTypeTag
                  key={aType.reference}
                  label={aType.title ?? aType.reference}
                  helpText={aType.description ?? ''}
                />
              );
            })}
          </HStack>
        )}
        <Button
          w="111px"
          size="md"
          variant="secondary"
          onClick={() => {
            setSelectedMetric(undefined);
            onDrawerOpen();
          }}
        >
          Learn more
        </Button>
      </VStack>
    </VStack>
  );
};

export const MetricsMaterialityModal = ({
  isOpen,
  onClose,
  standardMetrics,
  materialMetrics,
  isGroupOwner,
  setMaterialMetrics,
  parentMetrics,
  disclosureRequirement,
  setInitialLoad,
  disableMaterial,
  hasParent,
  submitMateriality,
  isCollectOnly,
  isStandardMandatory,
}: {
  isOpen: boolean;
  onClose: () => void;
  standardMetrics: GetStandardMetricsQuery_ | undefined;
  materialMetrics: MaterialMetric[];
  isGroupOwner?: boolean;
  setInitialLoad: (val: boolean) => void;
  parentMetrics?: ParentMaterialMetrics[];
  disclosureRequirement: MappedDisclosureRequirements[number] | undefined;
  disableMaterial: boolean;
  setMaterialMetrics: (materialMetrics: MaterialMetric[]) => void;
  hasParent: boolean;
  submitMateriality: () => void;
  isCollectOnly: boolean;
  isStandardMandatory: boolean;
}) => {
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const [selectedMetric, setSelectedMetric] = useState<Metric>();
  const [isDRAssessed, setIsDRAssessed] = useState<boolean>(false);
  const [metricMapping, setMetricMapping] = useState<MaterialMetricMapping>();
  const { control, setValue } = useForm<MaterialityFields>();

  const defaultDataCollection = useMemo(
    () => (isGroupOwner ? DataCollectionLevel.subsidiaries : DataCollectionLevel.company),
    [isGroupOwner]
  );
  const hasMetrics = (disclosureRequirement?.metrics ?? []).length > 0;

  const status = useMemo(
    () =>
      isStandardMandatory
        ? MaterialityState.mandatory
        : (disclosureRequirement?.materialityStatus as MaterialityState),
    [disclosureRequirement, isStandardMandatory]
  );

  const canCollectDisclosure = useMemo(
    () => status === MaterialityState.collectData || status === MaterialityState.doNotCollect,
    [status]
  );

  const isMandatoryOrMaterialMandatory = useMemo(() => {
    if (status === MaterialityState.mandatory || status === MaterialityState.materialMandatory) {
      return true;
    }
    if (isCollectOnly) {
      return isStandardMandatory;
    }
    return false;
  }, [status, isCollectOnly, isStandardMandatory]);

  const metrics = useMemo(
    () =>
      standardMetrics?.metrics.filter((metric) =>
        disclosureRequirement?.metrics.find((drMetric) => metric.reference === drMetric.reference)
      ) ?? [],
    [standardMetrics]
  );

  const selectedMetricFromDR = useMemo(() => {
    return disclosureRequirement?.metrics.find(
      (metric) => metric.reference === selectedMetric?.reference
    );
  }, [selectedMetric]);

  const defaultMapping: MaterialMetricMapping = metrics.reduce(
    (acc: MaterialMetricMapping, curr) => {
      return {
        ...acc,
        [curr.reference]: {
          dataCollection: defaultDataCollection,
          isMaterial: true,
          isDataGatheringOnly: false,
        },
      };
    },
    {}
  );

  const transformData = (data: MaterialMetricMapping): MaterialMetric[] => {
    const transformedData: MaterialMetric[] = Object.entries(data).map((selection) => {
      return {
        metricRef: selection?.[0],
        dataCollection: selection?.[1]?.dataCollection,
        isMaterial: selection?.[1]?.isMaterial ?? null,
        isDataGatheringOnly: selection?.[1]?.isDataGatheringOnly,
        metric: selection?.[1]?.metric,
      };
    });
    return transformedData;
  };

  const mapMetrics = (data: MaterialMetric[]): MaterialMetricMapping => {
    return data.reduce((acc: MaterialMetricMapping, materialMetric: MaterialMetric) => {
      acc[materialMetric.metricRef] = {
        isMaterial: materialMetric.isMaterial ?? null,
        isDataGatheringOnly: materialMetric.isDataGatheringOnly,
        dataCollection:
          (materialMetric.dataCollection as DataCollectionType) ?? defaultDataCollection,
        metric: materialMetrics.find((met) => met.metricRef === materialMetric.metricRef)?.metric,
      };
      return acc;
    }, {});
  };

  const updateMaterialityForAll = (value: {
    isMaterial: boolean | null;
    dataGathering: boolean;
  }) => {
    setMetricMapping((prev) => {
      const updatedMapping: MaterialMetricMapping = {
        ...defaultMapping,
        ...mapMetrics(materialMetrics),
      };

      const newMetrics = transformData({ ...defaultMapping, ...metricMapping } ?? {});

      newMetrics
        .filter((metric) =>
          disclosureRequirement?.metrics.find((m) => m.reference === metric.metricRef)
        )
        .forEach((metric) => {
          updatedMapping[metric.metricRef] = {
            ...prev?.[metric.metricRef],
            isMaterial: value.isMaterial === null ? null : value.isMaterial,
            isDataGatheringOnly: value.dataGathering ?? false,
            dataCollection:
              (prev?.[metric.metricRef]?.dataCollection as DataCollectionLevel) ??
              defaultDataCollection,
            metric: metric.metric,
          };
        });

      return {
        ...prev,
        ...updatedMapping,
      };
    });
  };

  useEffect(() => {
    if (status !== null && status !== MaterialityState.toAssess) {
      const statusValue =
        status === MaterialityState.doNotCollect
          ? MaterialityState.notMaterial
          : status === MaterialityState.collectData ||
            status === MaterialityState.collectDataMandatory
          ? MaterialityState.material
          : status ?? null;
      setValue('material', statusValue);
      setIsDRAssessed(true);
    }
  }, [status]);

  useEffect(() => {
    setMetricMapping({ ...mapMetrics(materialMetrics) });
  }, [materialMetrics]);

  const showTable = useMemo(
    () =>
      control._formValues.material === MaterialityState.material ||
      control._formValues.material === MaterialityState.gatherData,
    [control._formValues.material]
  );

  const handleUpdateMateriality = (value: MaterialityState | null) => {
    const isMaterial =
      value === null
        ? null
        : value === MaterialityState.material || value === MaterialityState.gatherData;
    setIsDRAssessed(true);
    updateMaterialityForAll({ isMaterial, dataGathering: value === MaterialityState.gatherData });
  };

  const handleConfirm = () => {
    setInitialLoad(false);

    if (status !== MaterialityState.mandatory && hasMetrics) {
      setMaterialMetrics(transformData(metricMapping ?? {}));
    }

    if (
      hasMetrics &&
      status === MaterialityState.mandatory &&
      disclosureRequirement?.metrics.length
    ) {
      // map all metrics to be material
      const data: MaterialMetricMapping = disclosureRequirement?.metrics.reduce(
        (acc: MaterialMetricMapping, curr) => {
          return {
            ...acc,
            [curr.reference]: {
              isMaterial: true,
              isDataGatheringOnly: false,
              dataCollection: defaultDataCollection,
              metric: materialMetrics.find((met) => met.metricRef === curr.reference)?.metric,
            },
          };
        },
        {}
      );
      setMaterialMetrics(transformData(data));
      submitMateriality();
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={disclosureRequirement?.title}
      size="xl"
      subtitle={'Disclosure requirement ' + disclosureRequirement?.drRef}
      onConfirm={handleConfirm}
      onCancel={onClose}
      hasFooter={
        status !== MaterialityState.mandatory && status !== MaterialityState.materialMandatory
      }
    >
      <VStack spacing="16px" alignItems="strech" width="100%">
        <VStack alignItems="strech" gap="8px">
          {disclosureRequirement?.parentMateriality &&
            hasParent &&
            status !== MaterialityState.mandatory &&
            !isCollectOnly && (
              <ParentInfoBox
                status={disclosureRequirement?.parentMateriality as ParentMaterialityState}
              />
            )}
          <DisclosureRequirementInfo
            status={
              isCollectOnly
                ? status
                : control._formValues?.material
                ? control._formValues?.material
                : MaterialityState.toAssess ?? status
            }
          />
        </VStack>
        <VStack spacing="0px" alignItems="stretch">
          <Box borderLeft="1px dashed" pl="32px" ml="14px" borderColor="border.hover" pb="32px">
            <ActiveStepLabels
              bgColor="bg.muted"
              done={true}
              clickable={false}
              title={'Understand Disclosure Requirement'}
              hasSteps={false}
              ml="63px"
            />
            <LearnMoreStep
              onDrawerOpen={onDrawerOpen}
              noTitle={
                isMandatoryOrMaterialMandatory && !disclosureRequirement?.metrics?.length
                  ? false
                  : true
              }
              setSelectedMetric={setSelectedMetric}
              additionalTypes={
                isMandatoryOrMaterialMandatory && !disclosureRequirement?.metrics?.length
                  ? undefined
                  : disclosureRequirement?.additionalTypes
              }
            />
          </Box>
          {!isMandatoryOrMaterialMandatory && (isCollectOnly ? canCollectDisclosure : true) && (
            <Box
              pl="32px"
              ml="14px"
              pb="32px"
              borderLeft={showTable || !control._formValues.material ? '1px dashed' : ''}
              borderColor="border.hover"
            >
              <ActiveStepLabels
                bgColor="bg.muted"
                done={true}
                title={'Assess Disclosure Requirement'}
                hasSteps={false}
                clickable={false}
                ml="63px"
              />
              <Controller
                name="material"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MaterialityRadioGroup
                      value={value ?? MaterialityState.toAssess}
                      defaultValue={status}
                      isMaterialDisabled={disableMaterial}
                      onChange={(val) => {
                        onChange(val);
                        handleUpdateMateriality(val);
                      }}
                      isCollectOnly={isCollectOnly ? canCollectDisclosure : false}
                      isDr={true}
                      width="613px"
                    />
                  );
                }}
              />
            </Box>
          )}
          {isMandatoryOrMaterialMandatory
            ? !!disclosureRequirement?.metrics?.length && (
                <Box pl="32px" ml="14px">
                  <ActiveStepLabels
                    bgColor="bg.muted"
                    done={true}
                    title={'Understand Disclosure Requirement'}
                    hasSteps={false}
                    clickable={false}
                    ml="63px"
                  />
                  <Box
                    width="100%"
                    border="1px solid"
                    borderRadius="8px"
                    borderColor="border.decorative"
                  >
                    <MetricsMaterialityTable
                      parentMetrics={parentMetrics}
                      setSelectedMetric={setSelectedMetric}
                      onDrawerOpen={onDrawerOpen}
                      metricMapping={metricMapping}
                      isGroupOwner={isGroupOwner}
                      hasParent={hasParent}
                      setMetricMapping={setMetricMapping}
                      isDRAssessed={isDRAssessed}
                      disclosureRequirement={disclosureRequirement}
                      readOnly={true}
                      isCollectOnly={isCollectOnly}
                    />
                  </Box>
                </Box>
              )
            : (showTable || !isDRAssessed) && (
                <VStack
                  pl="32px"
                  ml="16px"
                  borderColor="border.hover"
                  pb="24px"
                  gap="8px"
                  alignItems="stretch"
                >
                  <ActiveStepLabels
                    title={'Assess metrics'}
                    bgColor="bg.muted"
                    done={true}
                    hasSteps={false}
                    ml="63px"
                    clickable={false}
                  />
                  <MetricsMaterialityTable
                    parentMetrics={parentMetrics}
                    setSelectedMetric={setSelectedMetric}
                    onDrawerOpen={onDrawerOpen}
                    metricMapping={metricMapping}
                    isGroupOwner={isGroupOwner}
                    hasParent={hasParent}
                    setMetricMapping={setMetricMapping}
                    isDRAssessed={isDRAssessed}
                    disclosureRequirement={disclosureRequirement}
                    isCollectOnly={isCollectOnly}
                  />
                </VStack>
              )}
        </VStack>
      </VStack>
      <LearnMoreDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        description={
          (selectedMetric ? selectedMetric?.description : disclosureRequirement?.description) ?? ''
        }
        header={!selectedMetric ? disclosureRequirement?.title : undefined}
        customHeader={
          selectedMetric ? (
            <MetricLearnMoreHeader
              metricRef={selectedMetric?.reference ?? ''}
              tags={selectedMetricFromDR?.tags ?? []}
            />
          ) : undefined
        }
      />
    </Modal>
  );
};
