import { TableData } from 'Molecules/NestedTable';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import { TeamMember, TeamMembersFilterOption, useTeamMembers } from 'containers/TeamMembers';
import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { ProjectLeaderSelect } from 'containers/Esrs/pieces/Assessment/EsrsConfig/ProjectLeaderSelect';
import { User } from 'models';
import { Avatar, AvatarSize } from 'Atoms';
import { AddUserIcon } from 'Tokens/Icons/Function';

export const MetricOwnerSelect = ({
  row,
  selectedQuarter,
  companyReportingUnit,
  assessmentProjectLeader,
  isBorderless = false,
  size,
}: {
  row: TableData<MetricsTableData>;
  selectedQuarter: string;
  companyReportingUnit?: string;
  assessmentProjectLeader?: Partial<User>;
  isBorderless?: boolean;
  size?: AvatarSize;
}) => {
  const { onDatapointChange, answer } = useGetDatapointValues(row, companyReportingUnit);

  const dataPoint = useMemo(
    () => answer?.datapoints.find((dp) => dp.timeframe === selectedQuarter),
    [answer]
  );

  const { members } = useTeamMembers(TeamMembersFilterOption.All);
  const dpOwner = useMemo(() => {
    return dataPoint?.owner ? dataPoint.owner : assessmentProjectLeader;
  }, [dataPoint, answer, members, selectedQuarter]);

  const updateDatapoint = (member: TeamMember) => {
    onDatapointChange(
      dataPoint?.value ?? null,
      answer?.hasOptedOut ?? false,
      answer?.optOutReason ?? '',
      dataPoint ?? { timeframe: selectedQuarter },
      row.tags,
      member.id
    );
  };

  return (
    <HStack spacing="4px" onClick={(e) => e.stopPropagation()}>
      <ProjectLeaderSelect
        onChange={updateDatapoint}
        defaultProjectLeader={dpOwner}
        avatarText={dpOwner?.displayName ?? dpOwner?.email}
        isBorderless={isBorderless}
        rounded
        small
        size={size}
      />
    </HStack>
  );
};

export const MetricOwnerAvatar = ({
  row,
  selectedQuarter,
  companyReportingUnit,
  assessmentProjectLeader,
}: {
  row: TableData<MetricsTableData>;
  selectedQuarter: string;
  companyReportingUnit?: string;
  assessmentProjectLeader?: Partial<User>;
}) => {
  const { answer } = useGetDatapointValues(row, companyReportingUnit);

  const dataPoint = useMemo(
    () => answer?.datapoints.find((dp) => dp.timeframe === selectedQuarter),
    [answer, selectedQuarter]
  );
  const dpOwner = useMemo(() => {
    return dataPoint?.owner ? dataPoint.owner : assessmentProjectLeader;
  }, [dataPoint, assessmentProjectLeader]);
  return dpOwner ? (
    <Avatar name={dpOwner?.displayName} size="xs" />
  ) : (
    <Box bg="bg.muted" boxSize="20px" borderRadius="50%" padding="2px">
      <AddUserIcon color="text.default" />
    </Box>
  );
};
