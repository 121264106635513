import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionPanelProps,
  Box,
  HStack,
  AccordionProps as ChakraAccordionProps,
} from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { IconButton } from 'Atoms/Buttons';
import { useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';

export const AccordionTheme = {
  baseStyle: {
    container: {
      borderRadius: '12px',
    },
    button: {
      minHeight: '60px',
      borderRadius: '12px',
      _hover: {
        bg: 'bg.hover',
      },
      _active: {
        bg: 'bg.pressed',
      },
      _expanded: {
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
      },
    },
    panel: {
      paddingBottom: '16px',
    },
    variant: 'solid',
  },
  variants: {
    solid: {
      container: {
        bg: 'bg.muted',
        border: 'none',
      },
    },
    outlined: {
      container: {
        bg: 'bg.default',
        border: '1px solid',
        borderColor: 'border.decorative !important',
      },
    },
  },
};

export type AccordionProps = {
  items?: {
    label?: string;
    reference?: string;
    icon?: JSX.Element;
    accordionNb?: number;
    tag?: JSX.Element[];
    content?: React.ReactNode | string;
    onItemClick?: () => void;
  }[];
  variant?: 'solid' | 'outlined';
  marginBottom?: string;
  width?: string;
  lazyLoadContent?: boolean;
} & Pick<AccordionPanelProps, 'children'> &
  ChakraAccordionProps;

export function Accordion({
  items,
  variant,
  marginBottom = '16px',
  width = '100%',
  lazyLoadContent = false,
  ...props
}: AccordionProps) {
  const [openItems, setOpenItems] = useState<number[]>([]);
  const handleChange = (value: number[]) => {
    setOpenItems(value);
  };
  return (
    <ChakraAccordion
      allowMultiple
      {...props}
      variant={variant}
      onChange={handleChange}
      width={width}
    >
      {items?.map((item, index) => (
        <AccordionItem key={item.reference} marginBottom={marginBottom}>
          {({ isExpanded }) => (
            <>
              <AccordionButton onClick={item.onItemClick}>
                <IconButton
                  size="xs"
                  variant="ghost"
                  icon={openItems.includes(index) ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  marginRight="8px"
                  aria-label="chevron icon"
                />
                {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                <HStack flex="2" textAlign="left">
                  {item.reference && (
                    <Typography variant="bodyLarge" color="text.hint">
                      {item.reference}
                    </Typography>
                  )}
                  {item.label && (
                    <Typography variant="h3" marginRight="8px">
                      {item.label}
                    </Typography>
                  )}
                  {item.accordionNb && (
                    <Typography variant="bodyStrong" marginLeft="0px !important" color="text.hint">
                      {item.accordionNb}
                    </Typography>
                  )}
                </HStack>
                {item.tag?.map((t, i) => (
                  <span key={i} style={{ marginLeft: '8px' }}>
                    {t}
                  </span>
                ))}
              </AccordionButton>
              <AccordionPanel>
                <Box minH="20px">{lazyLoadContent ? isExpanded && item.content : item.content}</Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </ChakraAccordion>
  );
}
