import { useMaterialStandardId } from 'containers/Esrs';
import {
  CompanyLevelMetricsPerDisclosureDocument_,
  EsrsAssessmentDocument_,
  GetMaterialStandardDocument_,
  MaterialMetricsPerDisclosureDocument_,
  MaterialMetricsPerDisclosureQuery_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useGetMaterialStandardIsCollectedQuery,
  useMaterialMetricsPerDisclosureQuery,
  useUpdateMaterialStandardDataCollectionMutation,
  useUpsertMaterialMetricsMutation,
} from 'models';
import { useEffect, useMemo } from 'react';
import { DataCollectionLevel, TableMetricData } from './DataCollection.d';

type Metric =
  MaterialMetricsPerDisclosureQuery_['assessableMetrics'][0]['childrenMetrics'][0]['childMetric'];
type ParentMetric = MaterialMetricsPerDisclosureQuery_['assessableMetrics'][0];

export const useMaterialStandardDataCollection = (
  standardRef: string,
  esrsAssessmentId: string
) => {
  const {
    companyAssessmentId: companyStandardId,
    parentAssessmentId: parentStandardId,
    loading: materialStandardIdLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const [updateMaterialStandardDataCollection] = useUpdateMaterialStandardDataCollectionMutation();

  const { data: materialStandardData, loading: materialStandardDataLoading } =
    useGetMaterialStandardIsCollectedQuery({
      variables: {
        assessmentId: esrsAssessmentId,
        standardRef: standardRef,
      },
      skip: !esrsAssessmentId || !standardRef,
    });

  const isDataCollected = useMemo(
    () => materialStandardData?.materialityAssessment[0]?.isDataCollected ?? false,
    [materialStandardData]
  );

  const parentEsrsAssessmentId = useMemo(
    () => materialStandardData?.materialityAssessment[0]?.esrsAssessment.parentAssessment?.id,
    [materialStandardData]
  );

  const updateDataCollectionStatus = async () => {
    await updateMaterialStandardDataCollection({
      variables: {
        materialStandardId: companyStandardId,
      },
      refetchQueries: [GetMaterialStandardDocument_],
    });
  };

  return {
    isDataCollected,
    updateDataCollectionStatus,
    companyStandardId,
    parentStandardId,
    parentEsrsAssessmentId,
    loading: materialStandardDataLoading || materialStandardIdLoading,
  };
};

export const useMaterialMetricsPerDR = ({
  disclosureRequirementRef,
  companyStandardId,
  parentStandardId,
  isGroup,
  isStandardMandatory,
}: {
  disclosureRequirementRef: string;
  companyStandardId: string;
  parentStandardId: string;
  isGroup: boolean;
  isStandardMandatory: boolean;
}) => {
  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const { data: disclosureMetricData, loading: metricDataLoading } =
    useMaterialMetricsPerDisclosureQuery({
      variables: {
        disclosureRequirementRef,
        companyAssessmentId: companyStandardId,
        parentAssessmentId: parentStandardId || companyStandardId,
      },
      skip: !companyStandardId || !disclosureRequirementRef,
    });

  const checkIfChildNeedsMaterialMetric = (metric: Metric, parent: ParentMetric) => {
    const hasMaterialMetric = !!metric?.materialMetrics?.length;
    const defaultDataCollection = isGroup
      ? isStandardMandatory
        ? DataCollectionLevel.group
        : DataCollectionLevel.subsidiaries
      : DataCollectionLevel.company;
    if (!hasMaterialMetric) {
      const parentMaterialMetric = parent.materialMetrics[0];

      // create a material metric for it
      upsertMaterialMetric({
        variables: {
          objects: [
            {
              frequency: parentMaterialMetric?.frequency ?? 'Yearly',
              dataCollection: parentMaterialMetric?.dataCollection ?? defaultDataCollection,
              materialStandardId: companyStandardId,
              metricRef: metric?.reference,
            },
          ],
        },
        refetchQueries: [
          CompanyLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
          MaterialMetricsPerDisclosureDocument_,
          EsrsAssessmentDocument_,
        ],
      }).catch((error) => {
        console.log('Failed to add material metric for missing child', error);
      });
    }
    if (!!metric?.childrenMetrics?.length) {
      metric?.childrenMetrics.forEach((childMetric) => {
        checkIfChildNeedsMaterialMetric(childMetric.childMetric as Metric, metric as ParentMetric);
      });
    }
  };

  useEffect(() => {
    disclosureMetricData?.assessableMetrics.forEach((metric) => {
      checkIfChildNeedsMaterialMetric(metric, metric);
    });
  }, [disclosureMetricData]);

  return {
    disclosureMetricData,
    metricDataLoading,
  };
};

export const getAllMaterialMetricChildren = (
  companyStandardId: string,
  metric?: TableMetricData
): any => {
  const childrenMetrics = metric?.childrenMetrics;

  if (!childrenMetrics) {
    return [];
  }

  const data = childrenMetrics.flatMap((childMetric) => {
    const childData = getAllMaterialMetricChildren(
      companyStandardId,
      childMetric?.childMetric as TableMetricData
    );
    const materialMetric = childMetric.childMetric?.materialMetrics.find(
      (mm) => mm.materialStandardId === companyStandardId
    );
    return [
      {
        id: materialMetric?.id,
        metricRef: childMetric.childMetric?.reference,
        materialStandardId: companyStandardId,
        metricType: childMetric.childMetric?.metricType,
        isMaterial: materialMetric?.isMaterial,
      },
      ...childData,
    ];
  });

  return data;
};

export const getFlattenedMaterialMetrics = (
  metrics: TableMetricData[],
  companyStandardId: string
): any => {
  return metrics
    .flatMap((metric) => {
      const materialMetric = metric.materialMetrics.find(
        (mm) => mm.materialStandardId === companyStandardId
      );

      return [
        {
          id: materialMetric?.id,
          metricRef: metric.reference,
          materialStandardId: companyStandardId,
          metricType: metric.metricType,
          isMaterial: materialMetric?.isMaterial,
        },
        getAllMaterialMetricChildren(companyStandardId, metric),
      ].flat();
    })
    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
};
