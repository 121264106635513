import { useCallback, useMemo } from 'react';
import {
  ShortUser,
  useGetMdrmAnswersQuery,
  useUpsertDatapointMutation,
  useUpsertMdrmAnswersMutation,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Esrs_Answer_Constraint_,
  Esrs_Answer_Update_Column_,
  Esrs_DatapointTag_Constraint_,
  GetEsrsMetricsDatapointsDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  GetSingleEsrsMetricAnswerDocument_,
  Esrs_EsrsMdrm_Constraint_,
  Esrs_EsrsMdrm_Update_Column_,
  GetMdrmAnswersDocument_,
  GetCompanyLevelMdrmMetricAnswersDocument_,
  GetMetricAnswersDocument_,
} from 'models';
import { MdrmAnswers, MdrmType } from './MetricMdrm';
import { useUserData } from '@nhost/react';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { TimePeriods } from '../../Requirement';
import { useParams } from 'react-router-dom';

export type OnMdrmAnswerChangeType = (value: string, mdrmId: MdrmType) => void;

export const useMdrmAnswers = ({
  datapointId,
  metricRef,
  row,
  companyReportingUnit,
  timeframe,
}: {
  datapointId: string;
  metricRef: string;
  row: MetricsTableData;
  companyReportingUnit?: string;
  timeframe?: TimePeriods;
}) => {
  const { esrsAssessmentId = '' } = useParams();

  const [upsertMdrmAnswers] = useUpsertMdrmAnswersMutation();

  const [upsertDatapoint] = useUpsertDatapointMutation();

  const user: ShortUser | null = useUserData();

  const { data: mdrmData, loading } = useGetMdrmAnswersQuery({
    variables: {
      datapointId,
      metricRef,
    },
    skip: !datapointId || !metricRef,
  });

  const answers: MdrmAnswers = useMemo(() => {
    const mdrm = mdrmData?.esrs_EsrsMDRM_by_pk;
    return {
      methodology: mdrm?.methodology ?? '',
      metricUsed: mdrm?.metricUsed ?? '',
      validation: mdrm?.validation ?? '',
    };
  }, [mdrmData]);

  const allMdrmAnswered = useMemo(() => {
    return Object.values(answers).every((value) => value !== null && value !== '');
  }, [answers]);

  const lastAnsweredBy = useMemo(() => mdrmData?.esrs_EsrsMDRM_by_pk?.user, [mdrmData]);

  const onMdrmAnswerChange: OnMdrmAnswerChangeType = useCallback(
    (value: string, mdrmId: MdrmType) => {
      if (!datapointId)
        return upsertDatapoint({
          variables: {
            objects: {
              value: null,
              timeframe: timeframe,
              authorId: user?.id,
              isAIGenerated: false,
              datapointTags: {
                data: row?.tags ?? [],
                on_conflict: {
                  constraint:
                    Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
                },
              },
              answer: {
                data: {
                  metricRef: row.metric.reference,
                  reportingUnitId: companyReportingUnit,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  noteHistory: {
                    data: {},
                    on_conflict: {
                      constraint: NoteHistory_Constraint_.NoteHistoryMetricAnswerIdKey_,
                      update_columns: [NoteHistory_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
              mdrmAnswers: {
                data: [
                  {
                    ...answers,
                    metricRef: metricRef,
                    answeredByUserId: user?.id,
                    [mdrmId]: value ?? '',
                  },
                ],
                on_conflict: {
                  constraint: Esrs_EsrsMdrm_Constraint_.EsrsMdrmPkey_,
                  update_columns: [
                    Esrs_EsrsMdrm_Update_Column_.MetricUsed_,
                    Esrs_EsrsMdrm_Update_Column_.Validation_,
                    Esrs_EsrsMdrm_Update_Column_.Methodology_,
                    Esrs_EsrsMdrm_Update_Column_.AnsweredByUserId_,
                  ],
                },
              },
            },
          },

          refetchQueries: [
            GetEsrsMetricsDatapointsDocument_,
            GetSingleEsrsMetricAnswerDocument_,
            GetMdrmAnswersDocument_,
            GetCompanyLevelMdrmMetricAnswersDocument_,
          ],
        });
      return upsertMdrmAnswers({
        variables: {
          object: {
            ...answers,
            datapointId: datapointId,
            metricRef: metricRef,
            answeredByUserId: user?.id,
            [mdrmId]: value ?? '',
          },
        },
        refetchQueries: [
          GetMdrmAnswersDocument_,
          GetCompanyLevelMdrmMetricAnswersDocument_,
          GetMetricAnswersDocument_,
        ],
      });
    },
    [upsertMdrmAnswers, answers, datapointId, metricRef, user?.id]
  );

  return { answers, loading, lastAnsweredBy, onMdrmAnswerChange, allMdrmAnswered };
};
