import { Button, Tooltip, TruncatableText } from 'Atoms';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { AIIcon, RefreshIcon } from 'Tokens/Icons/Function';
import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast as useChakraToast,
} from '@chakra-ui/react';
import { CheckIcon } from 'Tokens/Icons/Status';
import { AttachmentBox } from 'models';
import { Typography } from 'Tokens';
import { nhost } from 'utils/nhost';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { HelpTooltip, Modal } from 'Molecules';
import { getNameExtension } from 'utils/files';
import { AssessableMetrics } from '../Metrics/Metrics';
import { useFeatureFlags } from 'containers/Navigation';
import { useTranslation } from 'utils/translation';
import { GeneratedNarrativeAnswer } from '../Metrics/MetricAI';
import { extractNarrativeAnswer, extractTextFromPdf } from '../Metrics/MetricAI/AIRequestFunctions';

const AnswersRegenerationModal = ({
  isOpen,
  onClose,
  extractAndGenerate,
  selectedFileId,
}: {
  isOpen: boolean;
  onClose: () => void;
  extractAndGenerate: (fileId: string) => Promise<any>;
  selectedFileId: string;
}) => {
  return (
    <Modal
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      hasHeader={false}
      confirmText="Write new texts"
      onConfirm={() => {
        extractAndGenerate(selectedFileId);
        onClose();
      }}
    >
      <Typography variant="bodyStrong">
        Do you want AI to write new answers for all these data points? Current versions of answers
        will be erased and new texts will be generated
      </Typography>
    </Modal>
  );
};

export const PolicyAISummarizer = ({
  attachments,
  isSendingRequest,
  setIsSendingRequest,
  requestSignal,
  areAnswersGenerated,
  populateAnswersWithAI,
  metrics,
}: {
  attachments: AttachmentBox['attachments'];
  isSendingRequest: boolean;
  setIsSendingRequest: Dispatch<SetStateAction<boolean>>;
  requestSignal: AbortSignal;
  areAnswersGenerated: boolean;
  populateAnswersWithAI: (generatedAnswers: GeneratedNarrativeAnswer[]) => void;
  metrics: AssessableMetrics;
}) => {
  const [selectedFileId, setSelectedFileId] = useState<string>('');

  const {
    onOpen: onAnswersRegenerationModalOpen,
    onClose: onAnswersRegenerationModalClose,
    isOpen: isAnswersRegenerationModalOpen,
  } = useDisclosure();

  const toast = useToast();
  const chakraToast = useChakraToast();

  const extractAndGenerateAnswers = async (fileId: string) => {
    const file = attachments.find((attachment) => attachment.file.storageFile?.id === fileId)?.file;
    const fileType = getNameExtension(file?.storageFile?.name ?? '').extension;
    const fileName = file?.title ?? '';
    const preSignedUrl = await nhost.storage.getPresignedUrl({ fileId: fileId });
    const fileUrl = preSignedUrl.presignedUrl?.url ?? '';

    if (fileType !== 'pdf') {
      return toast({
        text: 'Please select a valid pdf document',
        variant: 'danger',
      });
    }

    const toastId = toast({
      text: 'AI is generating answers from policy...',
      closable: true,
      duration: null,
    });

    try {
      setIsSendingRequest(true);
      const result = await extractTextFromPdf(fileUrl, fileName, requestSignal);

      if (result) {
        const combinedContent = result.content.reduce((acc: string, curr: any) => {
          return acc + curr.text;
        }, '');

        const extractedAnswers = await Promise.all(
          metrics.map(async (metric) => {
            try {
              const populatedAnswer = await extractNarrativeAnswer(
                metric.title,
                combinedContent,
                requestSignal
              );

              const metricAnswerData = populatedAnswer.data;

              return { ...metricAnswerData, metricRef: metric.reference };
            } catch (error) {
              setIsSendingRequest(false);
              toast({
                variant: 'danger',
                text: 'Couldn’t generate answer. Click on the metric to learn more or write your answer manually',
              });
            }
          })
        );
        setIsSendingRequest(false);
        populateAnswersWithAI(extractedAnswers);
        chakraToast.close(toastId);
        return extractedAnswers;
      }
    } catch (error) {
      setIsSendingRequest(false);
      chakraToast.close(toastId);
      toast({
        variant: 'danger',
        text: 'Could not read file text, please try again',
      });
    }
  };
  const { company } = useCurrentCompany();
  const { hasAiAccess } = useFeatureFlags({ company });
  const { t } = useTranslation(['common']);

  const handleUseFile = () => {
    if (areAnswersGenerated) onAnswersRegenerationModalOpen();
    else extractAndGenerateAnswers(selectedFileId);
  };

  if (attachments.length === 1) {
    return (
      <>
        <HStack spacing="4px">
          <Tooltip label={t('common:aiConsent')} isDisabled={hasAiAccess}>
            <Button
              size="sm"
              variant="ghost"
              color={areAnswersGenerated ? 'text.muted' : 'text.info'}
              isDisabled={!hasAiAccess}
              leftIcon={
                areAnswersGenerated ? <RefreshIcon color="inherit" /> : <AIIcon color="inherit" />
              }
              isLoading={isSendingRequest}
              _hover={{ bg: areAnswersGenerated ? 'bg.hover' : 'bg.selected' }}
              _active={{
                bg: 'bg.pressed',
              }}
              onClick={() => {
                if (areAnswersGenerated) onAnswersRegenerationModalOpen();
                else extractAndGenerateAnswers(attachments[0].file.storageFile?.id);
              }}
            >
              {areAnswersGenerated ? 'Autofill again' : 'Autofill from policy'}
            </Button>
          </Tooltip>
          <HelpTooltip
            iconColor="text.muted"
            placement="bottom"
            label="Policy metrics' answers will be generated by AI from a policy you have uploaded"
          />
        </HStack>
        <AnswersRegenerationModal
          isOpen={isAnswersRegenerationModalOpen}
          onClose={onAnswersRegenerationModalClose}
          extractAndGenerate={extractAndGenerateAnswers}
          selectedFileId={attachments[0].file.storageFile?.id}
        />
      </>
    );
  }

  return (
    <>
      <HStack spacing="4px">
        <Menu gutter={2} closeOnSelect={false}>
          <Tooltip label={t('common:aiConsent')} isDisabled={hasAiAccess}>
            <MenuButton
              as={Button}
              size="sm"
              variant="ghost"
              border="none"
              color={areAnswersGenerated ? 'text.muted' : 'text.info'}
              isDisabled={!hasAiAccess}
              leftIcon={
                areAnswersGenerated ? <RefreshIcon color="inherit" /> : <AIIcon color="inherit" />
              }
              isLoading={isSendingRequest}
              _hover={{ bg: areAnswersGenerated ? 'bg.hover' : 'bg.selected' }}
              _active={{
                bg: 'bg.pressed',
              }}
            >
              {areAnswersGenerated ? 'Autofill again' : 'Autofill from policy'}
            </MenuButton>
          </Tooltip>
          <MenuList p="8px" w="280px">
            <MenuGroup p="8px">
              {attachments?.map((attachment) => {
                return (
                  <MenuItem
                    p="8px"
                    m="0px"
                    w="100%"
                    onClick={() => setSelectedFileId(attachment.file.storageFile?.id)}
                  >
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      color={
                        selectedFileId === attachment.file.storageFile?.id ? 'text.selected' : ''
                      }
                    >
                      <HStack>
                        <TruncatableText
                          color="inherit"
                          text={attachment.file.title}
                          variant="bodyStrong"
                        />
                      </HStack>
                      {attachment.file.storageFile?.id === selectedFileId && (
                        <CheckIcon color="inherit" />
                      )}
                    </HStack>
                  </MenuItem>
                );
              })}
            </MenuGroup>
            <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
            <Box w="100%">
              <Button
                isDisabled={!selectedFileId}
                variant="primary"
                w="100%"
                onClick={handleUseFile}
                isLoading={isSendingRequest}
              >
                Use this file
              </Button>
            </Box>
          </MenuList>
        </Menu>
        <HelpTooltip
          iconColor="text.muted"
          placement="bottom"
          label="Policies summary will be generated by AI from a policy you have uploaded. Generated text will follow the ESRS checklist and cover the needs for reporting. If AI fails to cover all points from the checklist, you will get a warning. "
        />
      </HStack>
      <AnswersRegenerationModal
        isOpen={isAnswersRegenerationModalOpen}
        onClose={onAnswersRegenerationModalClose}
        extractAndGenerate={extractAndGenerateAnswers}
        selectedFileId={selectedFileId ?? ''}
      />
    </>
  );
};
