import { isBoolean, isEmpty, sortBy } from 'lodash';
import {
  ActivityAssessmentQuery_,
  ActivityTagEnum,
  ActivityVersion_,
  AnswerSet,
  ObjectiveKeyEnum,
  ObjectiveWithQuestions,
  QuestionLevel_Enum_,
  QuestionType_Enum_,
  QuestionWithShortAnswer,
  ResolvedQuestion,
} from 'models';
import { getObjectiveIndex, sortObjectives } from 'utils/objectives/objectivesOrder';
import {
  getSubquestionProgress,
  isMultipleCriteria,
  removeSubquestions,
  sortedWithDependencies,
  transformMultipleCriteriaQuestion,
} from './multipleCriteriaQuestion';
import { ALL_QUESTIONS, NOT_EDITABLE, resolveCondition } from './resolveCondition';

export const DEFAULT_ACTIVITY_TAG = ActivityTagEnum.green;
export const AUTO_SC_PREFIX = 'auto-sc-';

// EMUMERATIONS
export const QuestionLevel = QuestionLevel_Enum_;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type QuestionLevel = QuestionLevel_Enum_;

export const QuestionType = QuestionType_Enum_;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type QuestionType = QuestionType_Enum_;

export const createId = (activityRef: string, objectiveKey?: string) =>
  `a${activityRef.replace('.', '-')}${objectiveKey ? `-${objectiveKey}` : ''}`;

export const isQuestionVisible = (q: ResolvedQuestion) => !!q.isVisible;

export const isQuestionApplicable = (q: ResolvedQuestion) => !!q.isVisible && !!q.isEditable;

export const isQuestionRequired = (q: ResolvedQuestion) =>
  isQuestionApplicable(q) && (q.isRequired || q.isAnswered);

export const isQuestionAnswered = (q: ResolvedQuestion) => q.isAnswered;

export const isQuestionAligned = (q: ResolvedQuestion) => !!q.isAligned;

export const isQuestionNotAligned = (q: ResolvedQuestion) => q.isAligned === false;

export const isQuestionSC = (q: Pick<ResolvedQuestion, 'level' | 'levelExpression'>) =>
  q.level == QuestionLevel.SubstantialContribution_ || !!q.levelExpression?.includes('SC');

export const isQuestionSCAndApplicable = (q: ResolvedQuestion) =>
  isQuestionSC(q) && isQuestionApplicable(q);

export const isQuestionDNSH = (q: Pick<ResolvedQuestion, 'level'>) =>
  q.level == QuestionLevel.DoNoSignificantHarm_;

export const isDNSHQuestionAligned = (q: ResolvedQuestion) =>
  !isQuestionRequired(q) || isQuestionAligned(q);

export const isSCQuestionAligned = (q: ResolvedQuestion) =>
  !isQuestionRequired(q) ||
  (isQuestionAligned(q) && q.level == QuestionLevel.SubstantialContribution_);

export const getQuestionsProgress = (questions: ResolvedQuestion[]) => {
  const required = questions.filter(isQuestionRequired);
  const subQuestions = getSubquestionProgress(required);
  const total = required.length + subQuestions.total;
  const answered = required.filter(isQuestionAnswered).length + subQuestions.answered;
  const notAligned =
    (required.filter(isQuestionNotAligned).length + subQuestions.notAligned) /
    (total > 0 ? total : 1);
  const progress = total > 0 ? answered / total : 1.0;

  return { total, answered, notAligned, progress };
};

export const isSCAutoAligned = (questions: ResolvedQuestion[]) =>
  questions.some((q) => q.uniqueId.startsWith(AUTO_SC_PREFIX));

export const isActivityAligned = (objectives: ObjectiveWithQuestions[]) => {
  // Objectives where this activity is actually doing substantial contribution
  const substantialContributionObjectives = objectives
    .filter((o) => o.questions.some(isQuestionSCAndApplicable) || isSCAutoAligned(o.questions))
    .filter((o) =>
      o.questions.filter(isQuestionSC).filter(isQuestionAnswered).every(isSCQuestionAligned)
    )
    .map((o) => o.key);

  // Objectives where this activity is actually doing substantial contribution
  const significantHarmObjectives = objectives
    .filter(
      (o) =>
        !o.questions.filter(isQuestionDNSH).filter(isQuestionAnswered).every(isDNSHQuestionAligned)
    )
    .map((o) => o.key);

  // Don't do harm, and at least one contribution
  const isAligned =
    substantialContributionObjectives.length >= 1 && significantHarmObjectives.length === 0;
  return isAligned;
};

function sortQuestions<
  T extends Pick<QuestionWithShortAnswer, 'objective' | 'questionSetRef' | 'group' | 'orderIndex'>
>(questions: T[]) {
  return sortBy(questions, [
    (q) => getObjectiveIndex(q.objective.key),
    (q) => q.group?.toLocaleLowerCase(),
    (q) => q.orderIndex.toLocaleLowerCase(),
  ]);
}

export const resolveActivity = (
  objectives: ActivityAssessmentQuery_['objectives'],
  activityTagExpression: ActivityVersion_['tagExpression'],
  preFilledAnswerSet: AnswerSet = {},
  isMSSAlignedAndCompleted?: boolean
): { resolvedQuestions: ResolvedQuestion[]; resolvedActivityTag: ActivityTagEnum } => {
  const resolvedQuestions: ResolvedQuestion[] = [];

  const questions = objectives.flatMap((o) => o.questions) ?? [];

  const answerSet: AnswerSet = questions.reduce(
    (set, q) => ({ ...set, [q.uniqueId]: q.answers[0] }),
    { ...preFilledAnswerSet }
  );

  const sortedQuestions = sortedWithDependencies(sortQuestions(questions));

  sortedQuestions.forEach((question) => {
    const conditionVariables = {
      [ALL_QUESTIONS]: resolvedQuestions,
    };

    const isVisible = resolveCondition(question.isVisible, answerSet, conditionVariables);
    const isRequired = resolveCondition(question.isRequired, answerSet, conditionVariables);
    const isEditable = isVisible
      ? resolveCondition(
          question.isEditable,
          answerSet,
          conditionVariables,
          false // Switch to true when you want to debug
        )
      : null;

    const answer = answerSet[question.uniqueId];
    let answerValue = answer?.data ?? '';
    if (isEmpty(answerValue)) {
      answerValue = '';
    }
    const isMultipleCriteriaQuestion = isMultipleCriteria(question);

    const isAligned =
      !isVisible || !isEditable || (answerValue === '' && !isMultipleCriteriaQuestion)
        ? null
        : resolveCondition(question.isAligned, answerSet, {
            this: answerValue,
            ...conditionVariables,
          });

    const isAnswered = isEditable === false || (isEditable === true && isAligned !== null);
    const choices = question.choices.slice().sort((a, b) => a.order.localeCompare(b.order));
    const level = question.levelExpression
      ? resolveCondition(question.levelExpression, answerSet, {
          this: answerValue,
          ...conditionVariables,
        })
      : question.level;

    // For activities that can do a subst. contribution to both adaptation and mitigation
    // Mitigation is the main objective, while contribution to only adaptation leads to a lower score.
    const isSecondarySubstantialContributionObjective =
      level === QuestionLevel.SubstantialContribution_ &&
      question.objective.key === ObjectiveKeyEnum.adaptation &&
      questions.some((qs) => qs.objective.key === ObjectiveKeyEnum.mitigation && isQuestionSC(qs));

    // TODO: Take another look at this, is it correct? And could it be made more intuitive?
    if (!isEditable)
      if (isEditable === false)
        // All dependant questions will not be editable
        answerSet[question.uniqueId] = NOT_EDITABLE;
      else if (answer)
        // This question become not editable, so other questions should not depend on its answer while resolving their expressions
        delete answerSet[question.uniqueId];

    let resolvedQuestion: ResolvedQuestion = {
      ...question,
      isVisible,
      isEditable,
      isRequired,
      isAligned,
      isAnswered,
      level,
      answer,
      choices,
      subQuestions: [],
      isSecondarySubstantialContributionObjective,
    };

    if (isMultipleCriteriaQuestion) {
      resolvedQuestion = transformMultipleCriteriaQuestion(
        question,
        resolvedQuestion,
        resolvedQuestions
      );
    }

    resolvedQuestions.push(resolvedQuestion);
  });

  let resolvedActivityTag: ActivityTagEnum = DEFAULT_ACTIVITY_TAG; // TODO: Check if we can avoid this, but prob not

  const isCompleted = resolvedQuestions.filter(isQuestionRequired).every(isQuestionAnswered);

  const questionsSortedByObjective =
    objectives.filter((o) => o.questions.length > 0).sort(sortObjectives) ?? [];

  const objectivesWithQuestions: ObjectiveWithQuestions[] = questionsSortedByObjective.map(
    (objective) => {
      return {
        ...objective,
        questions: resolvedQuestions
          .filter((rq) => rq.objective.key === objective.key)
          .map((rq) => ({
            ...rq,
            ...objective.questions.find((q) => q.id === rq.id)?.olderRevisions,
          })),
      };
    }
  ) as ObjectiveWithQuestions[];

  try {
    resolvedActivityTag = resolveCondition(activityTagExpression, answerSet, {
      [ALL_QUESTIONS]: resolvedQuestions,
    });
    if (
      isBoolean(resolvedActivityTag) ||
      !isMSSAlignedAndCompleted ||
      !(isActivityAligned(objectivesWithQuestions) && isCompleted)
    ) {
      resolvedActivityTag = DEFAULT_ACTIVITY_TAG;
    }
  } catch (error) {
    console.error('Failed to resolve activity tag', error);
  }

  // TODO: Make sure everything with subquestions works as it should
  return {
    resolvedQuestions: removeSubquestions(sortQuestions(resolvedQuestions)),
    resolvedActivityTag,
  };
};

export const getQuestionId = (q: ResolvedQuestion) => createId(q.objective.key, q.uniqueId); // TODO: Used to be activity ref + unique id in old repo
