import { Box, HStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyState, Tag, VisibleIf } from 'Atoms';
import { useCurrentCompanyId } from 'utils/hooks';
import { ContentLayout, AvatarGroup, Table } from 'Molecules';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaiIndicatorsIllustration, Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import {
  usePaiRequests,
  PaiCompanyRequest,
  useCompanyPaiReport,
  usePaiCategories,
} from '../CompanyPai.hooks';
import { uniq } from 'lodash';
import { ReportPeriods } from 'Features/PortfolioView';

const StatusTag = ({ year, period }: { year: number; period: ReportPeriods }) => {
  const { companyId } = useCurrentCompanyId();
  const { t } = useTranslation('pai');
  const { report } = useCompanyPaiReport(year, period, companyId ?? '');

  const categories = usePaiCategories(report);

  return (
    <>
      {categories.every((c) => c.isDone === true) ? (
        <Tag variant="success">{t('status.done')}</Tag>
      ) : (
        <Tag variant="info">{t('table.status.started')}</Tag>
      )}
    </>
  );
};

export const PaiReportTable = ({ requests }: { requests: PaiCompanyRequest[] }) => {
  const { t } = useTranslation('pai');
  const navigate = useNavigate();

  const columns: ColumnDef<PaiCompanyRequest>[] = useMemo(
    () => [
      {
        header: t('table.name.header'),
        id: 'name',
        cell: ({ row }) => (
          <HStack spacing="8px">
            <Typography variant="bodyStrong">{t('report')}</Typography>
            <Tag size="xs">
              {row?.original?.year}{' '}
              {row?.original?.period !== ReportPeriods.year &&
                `${row?.original?.period?.toLocaleUpperCase()}`}
            </Tag>
          </HStack>
        ),
      },
      {
        header: t('table.status.header'),
        id: 'status',
        cell: ({ row }) => {
          const reportExists =
            (row.original.report !== null &&
              row?.original?.report?.answers?.some((answer) => answer.data !== null)) ??
            0 > 0;
          if (reportExists) {
            return <StatusTag year={row.original.year} period={row?.original?.period} />;
          }
          return <Tag variant="undefined">{t('table.status.reported')}</Tag>;
        },
      },
      {
        header: t('table.investors.header'),
        id: 'investors',
        cell: ({ row }) => (
          <AvatarGroup
            size="sm"
            names={uniq(row?.original?.requests.map((r) => r.portfolio?.ownerCompany?.name))}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <Table<PaiCompanyRequest>
      data={requests}
      columns={columns}
      rowProps={{ _hover: { bg: 'bg.hover' } }}
      onRowClick={(row) => {
        navigate(`${row?.year}/${row?.period}`);
      }}
    />
  );
};

export const InvestorsEmptyState = () => {
  const { t } = useTranslation('pai');
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('table.empty.title')}
        description={t('table.empty.description')}
        icon={<PaiIndicatorsIllustration boxSize="120px" />}
      />
    </Box>
  );
};

export const PaiReportList = () => {
  const { companyId } = useCurrentCompanyId();
  const { requests } = usePaiRequests(companyId ?? undefined);
  return (
    <ContentLayout header={'PAI'}>
      <VisibleIf condition={requests.length >= 1}>
        <PaiReportTable requests={requests} />
      </VisibleIf>
      <VisibleIf condition={requests.length < 1}>
        <InvestorsEmptyState />
      </VisibleIf>
    </ContentLayout>
  );
};
