import { Box, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { useCurrentCompany } from 'utils/hooks';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetCompanyLevelMdrmMetricAnswersQuery_,
  GetEsrsReportStructureQuery_,
  GetMetricAnswersQuery_,
  GetReportMetricsPerDisclosureQuery_,
  useEsrsAssessmentQuery,
  useGetReportMetricsPerDisclosureQuery,
  useHideDrMutation,
  useHideMetricMutation,
} from 'models';
import {
  CompletionStatus,
  ReportProgressPieChart,
  ReportStatusBar,
  ReportStatusIcon,
  toRomanNumeral,
} from './ReportUtils';
import { Button, IconButton } from 'Atoms';
import { PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { QuestionType } from 'utils/scores/questions';
import { useEffect, useMemo } from 'react';
import { ReportMetricTable } from './ReportComponents/ReportMetricTable';
import { DataCollectionLevel } from '../DataCollection';
import { MetricViewEnums } from '../DisclosureRequirements';
import {
  getMetricData,
  useDisclosureProgress,
  useESRSReportStructure,
  useFilterDrMetrics,
  useIsDrHidden,
  useIsMetricHidden,
  useReportAnswers,
} from './Report.hooks';
import { ReportNarrativeMetricsCard } from './ReportComponents/ReportNarrativeMetricsCard';
import { Loader } from 'Molecules';
import { MDRM_QUESTIONS } from '../DisclosureRequirements/Metrics/MetricSidebar/MetricMdrm';
import { MdrmMetricAnswers } from './MetricAnswerCalculation';

const MdrmMetric = ({
  metricWithAnswers,
  standardRef,
  drRef,
  isPreview,
}: {
  metricWithAnswers: MdrmMetricAnswers;
  standardRef: string;
  drRef: string;
  isPreview: boolean;
}) => {
  const [metric, answers] = useMemo(
    () => [metricWithAnswers.metric, metricWithAnswers.answers],
    [metricWithAnswers]
  );

  const overallStatus = useMemo(() => {
    const answersArray = Object.values(answers);
    return answersArray.every((answer) => !!answer)
      ? CompletionStatus.complete
      : CompletionStatus.incomplete;
  }, [answers]);

  const { company } = useCurrentCompany();
  const { esrsAssessmentId = '' } = useParams();

  const goToMetric = () => {
    window.open(
      `/${company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}?openDrawer=open?metricRef=${metric.reference}`
    );
  };

  return (
    <VStack w="100%" alignItems="start" spacing="6px" pt="6px" pb="16px">
      {!isPreview && (
        <HStack>
          <ReportStatusIcon status={overallStatus} />
          <HStack gap="2px">
            <Typography variant="bodyStrong">{overallStatus}</Typography>
          </HStack>
        </HStack>
      )}
      <VStack w="100%" alignItems="start" spacing="4px">
        <Typography variant="bodyStrong">{`${metric.title} (MDR-M)`}</Typography>
        {!isPreview && (
          <Typography variant="body" color="text.hint">
            Answered in sub-metrics
          </Typography>
        )}
      </VStack>
      <VStack alignItems="start" w="100%" spacing="8px" pl={isPreview ? '' : '28px'} mt="14px">
        {MDRM_QUESTIONS.map((mdrmQ) => {
          const answer = answers?.[mdrmQ.id];
          const status: CompletionStatus = answers?.[mdrmQ.id]
            ? CompletionStatus.complete
            : CompletionStatus.incomplete;

          return (
            <VStack
              alignItems="start"
              borderTop={!isPreview ? '1px solid' : ''}
              w="100%"
              borderColor="border.decorative"
              pt="6px"
              spacing="6px"
              pb="8px"
              id={`${metric.reference}-${mdrmQ.id}`}
              key={`${metric.reference}-${mdrmQ.id}`}
            >
              {!isPreview && (
                <HStack p="4px">
                  <ReportStatusIcon status={status} />
                  <HStack gap="2px">
                    <Typography variant="bodyStrong">{status}</Typography>
                  </HStack>
                </HStack>
              )}
              <VStack w="100%" alignItems="start" spacing="4px">
                <Typography variant="bodyStrong">{mdrmQ.question}</Typography>
                {answer ? (
                  <Typography variant="body" whiteSpace="pre-wrap">
                    {answer}
                  </Typography>
                ) : (
                  <VStack gap="12px" alignItems="start">
                    <Typography variant="body" color="text.hint">
                      No answer has been provided yet
                    </Typography>
                    {!isPreview && (
                      <Button variant="secondary" size="sm" onClick={() => goToMetric()}>
                        Provide answer
                      </Button>
                    )}
                  </VStack>
                )}
              </VStack>
            </VStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

const MdrmSection = ({
  metricAndChildrenMdrm,
  standardRef,
  drRef,
  isPreview,
}: {
  metricAndChildrenMdrm: MdrmMetricAnswers[];
  standardRef: string;
  drRef: string;
  isPreview: boolean;
}) => {
  return (
    <VStack w="100%" alignItems="start">
      {metricAndChildrenMdrm.map((metricWithAnswers) => {
        return (
          <MdrmMetric
            key={metricWithAnswers.metric.reference}
            metricWithAnswers={metricWithAnswers}
            drRef={drRef}
            standardRef={standardRef}
            isPreview={isPreview}
          />
        );
      })}
    </VStack>
  );
};

const MetricCard = ({
  metric,
  standardRef,
  standardName,
  drRef,
  loadingReportAnswers,
  reportingUnitsAnswers,
  groupAnswers,
  companyAnswers,
  allMdrmAnswers,
  isPreview,
}: {
  metric: GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'][number];
  isDrHidden?: boolean;
  standardName: string;
  standardRef: string;
  drRef: string;
  loadingReportAnswers: boolean;
  reportingUnitsAnswers?: GetAnswersForMetricsOnCompanyLevelQuery_;
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_;
  companyAnswers?: GetMetricAnswersQuery_;
  allMdrmAnswers?: GetCompanyLevelMdrmMetricAnswersQuery_['esrs_EsrsMDRM'];
  isPreview: boolean;
}) => {
  const { esrsAssessmentId = '' } = useParams();
  const company = useCurrentCompany();
  const navigate = useNavigate();

  const { isHidden, loading: loadingMetricVisibility } = useIsMetricHidden(
    metric.metric.reference,
    esrsAssessmentId
  );

  const [onHideMetric, { loading: isHidingMetric }] = useHideMetricMutation();

  const metricData = useMemo(() => {
    return getMetricData(
      metric,
      metric.materialStandardId,
      standardRef,
      companyAnswers,
      reportingUnitsAnswers,
      groupAnswers,
      company.company?.isGroupOwner,
      '',
      '',
      allMdrmAnswers
    );
  }, [metric, reportingUnitsAnswers, groupAnswers, companyAnswers, company, allMdrmAnswers]);

  const provideAnswer = () => {
    navigate(
      metric.dataCollection === DataCollectionLevel.company
        ? `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric.metric.reference}`
        : `/${company?.company?.id}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${metricData?.reportingUnitId}/disclosure-requirement/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric.metric.reference}`
    );
  };
  const status = useMemo(() => {
    if (isHidden) {
      return CompletionStatus.hidden;
    }
    if (metricData?.completed) {
      return CompletionStatus.complete;
    }
    return CompletionStatus.incomplete;
  }, [metric, metricData, isHidden]);

  return (
    <VStack
      alignItems="start"
      borderTop={isPreview ? '' : '1px solid'}
      w="100%"
      borderColor="border.decorative"
      pt="6px"
      spacing="6px"
      pb="16px"
      id={`${metric.metric.reference}`}
    >
      {metric.metric.metricType !== QuestionType.LongText_ && (
        <MdrmSection
          metricAndChildrenMdrm={metricData.mdrmAnswers}
          standardRef={standardRef}
          drRef={drRef}
          isPreview={isPreview}
        />
      )}
      {!isPreview && (
        <ReportStatusBar
          metric={metric.metric}
          status={status}
          isMetricHidden={isHidden}
          standardName={standardName}
          standardRef={standardRef}
          drRef={drRef}
          dataCollection={metric.dataCollection ?? ''}
          completed={metricData?.completed ?? false}
          onHide={(x) =>
            onHideMetric({
              variables: {
                metricRef: metric.metric.reference,
                isHidden: x,
                assessmentId: esrsAssessmentId,
              },
            })
          }
          isLoading={isHidingMetric || loadingMetricVisibility}
        />
      )}
      <VStack alignItems="start" opacity={isHidden ? 0.3 : 1} w="100%" spacing="4px">
        <Typography variant="bodyStrong">{metric.metric.title}</Typography>
        <Skeleton isLoaded={!loadingReportAnswers}>
          {metricData?.completed ? (
            metric.metric.metricType === QuestionType.LongText_ ? (
              <Typography variant="body" whiteSpace="pre-wrap">
                {metricData?.textAnswer}
              </Typography>
            ) : (
              <ReportMetricTable
                metrics={[metricData?.tableData]}
                currency={company.company?.currency ?? ''}
                standardId={metric.materialStandardId}
              />
            )
          ) : (
            <VStack gap="12px" alignItems="start">
              <Typography variant="body" color="text.hint">
                No answer has been provided yet
              </Typography>
              {!isPreview && (
                <Button variant="secondary" size="sm" onClick={provideAnswer}>
                  Provide answer
                </Button>
              )}
            </VStack>
          )}
        </Skeleton>
      </VStack>
    </VStack>
  );
};

const DrCard = ({
  dr,
  standard,
  loadingReportAnswers,
  reportingUnitsAnswers,
  groupAnswers,
  companyAnswers,
  allMdrmAnswers,
  isPreview,
  urlMetricRef,
}: {
  dr: GetEsrsReportStructureQuery_['esrs_MaterialStandard'][number]['standard']['disclosureRequirementGroups'][number]['requirements'][number];
  standard: GetEsrsReportStructureQuery_['esrs_MaterialStandard'][number]['standard'];
  loadingReportAnswers: boolean;
  reportingUnitsAnswers?: GetAnswersForMetricsOnCompanyLevelQuery_;
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_;
  companyAnswers?: GetMetricAnswersQuery_;
  allMdrmAnswers?: GetCompanyLevelMdrmMetricAnswersQuery_['esrs_EsrsMDRM'];
  isPreview: boolean;
  urlMetricRef?: string;
}) => {
  const { esrsAssessmentId } = useParams();
  const company = useCurrentCompany();

  const { progress, loading: loadingProgress } = useDisclosureProgress(
    dr.reference,
    esrsAssessmentId
  );

  const [onHideDR, { loading: isHidingDr }] = useHideDrMutation();

  const isCompleted = useMemo(() => {
    return Number(progress) === 100;
  }, [progress]);

  const { data: drMetrics, loading: drMetricsLoading } = useGetReportMetricsPerDisclosureQuery({
    variables: {
      assessmentId: esrsAssessmentId,
      disclosureRequirementRef: dr.reference,
    },
    skip: !esrsAssessmentId,
  });

  const { filteredMetrics, childMetrics } = useFilterDrMetrics(isPreview, drMetrics);

  const editAnswer = () => {
    window.open(
      `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standard.reference}/disclosure-requirement/metric/${dr.reference}`
    );
  };
  const materialMetrics = useMemo(
    () =>
      drMetrics?.EsrsMetric.filter(
        (metric) =>
          !metric.materialMetrics.some(
            (met) => met.metric.metricType === QuestionType.Decimal_ && !met.metric.isAssessable
          )
      ).flatMap((metric) => metric.materialMetrics) ?? [],
    [drMetrics]
  );

  useEffect(() => {
    if (!drMetricsLoading && urlMetricRef) {
      const element = document.getElementById(urlMetricRef);
      if (element && urlMetricRef === element.id) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }
  }, [urlMetricRef, drMetricsLoading, filteredMetrics]);

  const { isHidden: isDrHidden, loading: loadingDRVisibility } = useIsDrHidden(
    materialMetrics.map((x) => x.metricRef),
    esrsAssessmentId
  );

  const status = useMemo(() => {
    if (isDrHidden) {
      return CompletionStatus.hidden;
    }
    if (isCompleted) {
      return CompletionStatus.complete;
    }
    return CompletionStatus.incomplete;
  }, [isDrHidden, isCompleted]);

  return (
    <VStack
      id={`${dr.reference}`}
      p={isPreview ? 'unset' : '20px'}
      border={isPreview ? 'unset' : '1px solid'}
      borderColor="border.decorative"
      borderRadius="8px"
      w="100%"
      alignItems="start"
      gap="4px"
    >
      {!isPreview && (
        <HStack justifyContent="space-between" w="100%">
          <Skeleton isLoaded={!loadingProgress}>
            <HStack>
              {status !== CompletionStatus.incomplete ? (
                <ReportStatusIcon status={status} />
              ) : (
                <ReportProgressPieChart collectedValue={Number(progress)} />
              )}
              <Typography variant="bodyStrong">{progress}%</Typography>
            </HStack>
          </Skeleton>
          <HStack gap="2px">
            <IconButton
              aria-label="hide"
              variant="ghost"
              size="sm"
              onClick={() =>
                onHideDR({
                  variables: {
                    assessmentId: esrsAssessmentId,
                    drRef: dr.reference,
                    isHidden: !isDrHidden,
                  },
                })
              }
              isLoading={isHidingDr || loadingDRVisibility || loadingProgress}
              icon={isDrHidden ? <PrivateIcon /> : <PublicIcon />}
            />
            <IconButton
              aria-label="edit"
              size="sm"
              variant="ghost"
              icon={<ArrowUpRightIcon />}
              onClick={editAnswer}
              tooltipLabel="Open the source"
            />
          </HStack>
        </HStack>
      )}
      <VStack alignItems="stretch" gap="20px" w="100%">
        <Typography variant="h3" color="text.default">
          {dr.reference} {dr.title}
        </Typography>
        <Skeleton isLoaded={!loadingDRVisibility}>
          <VStack alignItems="stretch" gap="20px" w="100%">
            {filteredMetrics.map((metric) =>
              metric.metric.metricType === QuestionType.LongText_ ? (
                <ReportNarrativeMetricsCard
                  key={`${metric.metric.reference}-${dr.reference}`}
                  metric={metric}
                  standardId={standard.reference}
                  isDrHidden={isDrHidden}
                  standardRef={standard.reference}
                  standardName={standard.title}
                  drRef={dr.reference}
                  childMetrics={childMetrics}
                  loadingReportAnswers={loadingReportAnswers}
                  reportingUnitsAnswers={reportingUnitsAnswers}
                  groupAnswers={groupAnswers}
                  companyAnswers={companyAnswers}
                  isPreview={isPreview}
                />
              ) : (
                <MetricCard
                  key={`${metric.metric.reference}-${dr.reference}`}
                  metric={metric}
                  isDrHidden={isDrHidden}
                  standardRef={standard.reference}
                  standardName={standard.title}
                  drRef={dr.reference}
                  loadingReportAnswers={loadingReportAnswers}
                  reportingUnitsAnswers={reportingUnitsAnswers}
                  groupAnswers={groupAnswers}
                  companyAnswers={companyAnswers}
                  allMdrmAnswers={allMdrmAnswers}
                  isPreview={isPreview}
                />
              )
            )}
          </VStack>
        </Skeleton>
      </VStack>
    </VStack>
  );
};

const StandardCard = ({
  standard,
  loadingReportAnswers,
  reportingUnitsAnswers,
  groupAnswers,
  companyAnswers,
  allMdrmAnswers,
  isPreview,
  urlMetricRef,
}: {
  standard: GetEsrsReportStructureQuery_['esrs_MaterialStandard'][number]['standard'];
  loadingReportAnswers: boolean;
  reportingUnitsAnswers?: GetAnswersForMetricsOnCompanyLevelQuery_;
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_;
  companyAnswers?: GetMetricAnswersQuery_;
  allMdrmAnswers?: GetCompanyLevelMdrmMetricAnswersQuery_['esrs_EsrsMDRM'];
  isPreview: boolean;
  urlMetricRef?: string;
}) => {
  const requirements = useMemo(() => {
    return standard.disclosureRequirementGroups
      .flatMap((group) => group.requirements)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [standard]);

  return (
    <VStack alignItems="start" w="100%" gap="16px" id={`${standard.reference}`}>
      <Typography variant="h2">{standard.title}</Typography>
      {requirements.map((dr) => (
        <VStack key={`${standard.reference}${dr.reference}`} alignItems="start" w="100%" gap="8px">
          <DrCard
            dr={dr}
            standard={standard}
            loadingReportAnswers={loadingReportAnswers}
            reportingUnitsAnswers={reportingUnitsAnswers}
            groupAnswers={groupAnswers}
            companyAnswers={companyAnswers}
            allMdrmAnswers={allMdrmAnswers}
            isPreview={isPreview}
            urlMetricRef={urlMetricRef}
          />
        </VStack>
      ))}
    </VStack>
  );
};

export const ReportContent = ({ isPreview }: { isPreview: boolean }) => {
  const { t } = useTranslation('esrs');
  const { esrsAssessmentId } = useParams();
  const { categories, loading: loadingStructure } = useESRSReportStructure(esrsAssessmentId);
  const [searchParams] = useSearchParams();
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);

  const {
    reportingUnitsAnswers,
    groupAnswers,
    companyAnswers,
    allMdrmAnswers,
    loading: loadingReportAnswers,
  } = useReportAnswers(esrsAssessmentId);

  const { hash } = useLocation();
  const { data: assessment, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  return (
    <Box>
      <VStack
        maxW="1088px"
        p="72px 48px"
        shadow="interactive.accent"
        background="bg.elevated.interactive"
        alignItems="start"
        borderRadius="12px"
      >
        <VStack maxW="800px" gap="32px" alignItems="start">
          <Skeleton isLoaded={!loadingAssessment}>
            <Typography variant="d3" whiteSpace="normal">
              {t('assessment.report.reportTitle') +
                ' for ' +
                assessment?.esrsAssessment?.company.name +
                ' ' +
                assessment?.esrsAssessment?.reportingYear}
            </Typography>
          </Skeleton>

          <VStack alignItems="start" w="100%" gap="32px">
            {loadingStructure ? (
              <Loader label="Building report..." />
            ) : (
              categories.map((cat, index) => {
                return (
                  <VStack
                    key={`${cat.reference}`}
                    alignItems="start"
                    w="100%"
                    gap="24px"
                    id={`${cat.reference}`}
                  >
                    <Typography variant="h1">
                      {toRomanNumeral(index + 1) + '. ' + cat.title}
                    </Typography>
                    {cat.standards
                      .sort((a, b) => a.standard.reference.localeCompare(b.standard.reference))
                      .map((std) => (
                        <StandardCard
                          key={std.standard.reference}
                          standard={std.standard}
                          loadingReportAnswers={loadingReportAnswers}
                          reportingUnitsAnswers={reportingUnitsAnswers}
                          groupAnswers={groupAnswers}
                          companyAnswers={companyAnswers}
                          allMdrmAnswers={allMdrmAnswers}
                          isPreview={isPreview}
                          urlMetricRef={urlMetricRef ?? ''}
                        />
                      ))}
                  </VStack>
                );
              })
            )}
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
