import { HStack } from '@chakra-ui/react';
import { Button } from 'Atoms/Buttons';
import { useKnowledgeBase } from 'Features/KnowledgeBase';
import { HelpTooltip } from 'Molecules/HelpTooltip';
import { ReportsCard, ReportItem } from 'Molecules/ReportsCard';
import { LockedIcon } from 'Tokens/Icons/Function';
import { GetReportsQuery_ } from 'models';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'utils/translation';

export const HomeTaxonomyReportCard = ({
  data,
  onToggleTaxonomyModal,
  isTaxonomyLearnMoreClosed,
  hasTaxonomyAccess,
  isGroup,
}: {
  data: GetReportsQuery_ | undefined;
  onToggleTaxonomyModal: (value: boolean) => void;
  isTaxonomyLearnMoreClosed: boolean;
  hasTaxonomyAccess: boolean;
  isGroup: boolean;
}) => {
  const { t } = useTranslation('home');
  const { onOpen } = useKnowledgeBase();
  const navigate = useNavigate();

  const taxonomyReports = useMemo(() => {
    const taxonomy: ReportItem[] =
      data?.taxonomyReports?.map((report) => ({
        id: report.id,
        status:
          (report.cachedResult?.progress?.financials === 100 &&
            report.cachedResult?.progress?.screening === 100) ||
          report.isLocked
            ? 'done'
            : 'todo',
        title: report.aggregate.title,
      })) ?? [];

    const groupTaxonomy: ReportItem[] =
      data?.groupTaxonomyReports?.map((report) => ({
        id: report.id,
        status: report.consolidatedCompanyAssessment?.isLocked ? 'done' : 'todo',
        title: report.name,
      })) ?? [];

    return isGroup ? groupTaxonomy : taxonomy;
  }, [data, isGroup]);

  return (
    <ReportsCard
      title={t('home:taxonomy.title')}
      subTitle={t('home:emptyState.taxonomy.subTitle')}
      description={t('home:taxonomy.description')}
      type="taxonomy"
      buttonText={t('home:emptyState.taxonomy.infoButton')}
      onLearnMoreClick={() => onOpen('Taxonomy assessment in 5 steps with Celsia')}
      onDismissClick={() => onToggleTaxonomyModal(true)}
      isContentVisible={!isTaxonomyLearnMoreClosed}
      requestedBy={[]}
      reportsList={
        hasTaxonomyAccess
          ? taxonomyReports.map((report) => ({
              ...report,
              onClick: () => navigate(`assessments/${report.id}`),
            }))
          : []
      }
      emptyState={{
        title: t('home:emptyState.taxonomy.title'),
        description: t('home:emptyState.taxonomy.description'),
        action: (
          <HStack spacing="15.33px">
            <Button
              leftIcon={!hasTaxonomyAccess ? <LockedIcon /> : undefined}
              isDisabled={!hasTaxonomyAccess}
              variant="secondary"
              onClick={() => (isGroup ? navigate('assessments') : navigate('assessments/new'))}
            >
              {t('home:emptyState.taxonomy.button')}
            </Button>
            {!hasTaxonomyAccess && <HelpTooltip label={t('home:emptyState.taxonomy.noAccess')} />}
          </HStack>
        ),
      }}
      onAllReportClick={() => navigate('assessments')}
    />
  );
};
