import { CheckIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Circle, Collapse, HStack, Skeleton, VStack, useColorMode } from '@chakra-ui/react';
import { IconButton, Tag } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { LinkIcon, PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { QuestionMarkIcon } from 'Tokens/Icons/Status';
import { useMemo, useState } from 'react';
import { MetricViewEnums } from '../DisclosureRequirements';
import { useParams } from 'react-router-dom';
import { useCurrentCompany } from 'utils/hooks';
import { DataCollectionLevel } from '../DataCollection';
import { PieChart } from 'react-minimal-pie-chart';

export enum CompletionStatus {
  complete = 'Completed',
  hidden = 'Hidden',
  incomplete = 'Incomplete',
}

export const COMPLETION_STATES = {
  [CompletionStatus.complete]: {
    bg: 'bg.success',
    icon: <CheckIcon color="text.success" boxSize="12px" />,
    text: 'Completed (all data points provided)',
  },
  [CompletionStatus.hidden]: {
    bg: 'bg.warning',
    icon: <PrivateIcon color="text.warning" />,
    text: 'Completed, but hidden from the report',
    textIncomplete: 'Incomplete and hidden from the report',
  },
  [CompletionStatus.incomplete]: {
    bg: 'bg.unknown',
    icon: <QuestionMarkIcon color="text.muted" />,
    text: 'Incomplete (not all data points provided)',
  },
};

export const MARKED_GENERAL = ['E1-GOV-3', 'G1-GOV-1'];

export const toRomanNumeral = (num: number) => {
  const numbers = ['0', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  return numbers[num];
};

export const ReportProgressPieChart = ({ collectedValue }: { collectedValue: number }) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
  return (
    <Circle size="16px" alignItems="center">
      <PieChart
        lineWidth={16}
        data={[
          {
            title: 'Collected',
            value: collectedValue,
            color: colors['bg.progress'][color],
          },
          {
            title: 'Missing',
            value: 100 - collectedValue,
            color: colors['bg.unknown'][color],
          },
        ]}
      />
    </Circle>
  );
};

export const ReportStatusIcon = ({ status }: { status: CompletionStatus }) => {
  return (
    <VStack
      bg={COMPLETION_STATES[status].bg}
      borderRadius="4px"
      boxSize="20px"
      textAlign="center"
      boxSizing="border-box"
      justifyContent="center"
    >
      {COMPLETION_STATES[status].icon}
    </VStack>
  );
};

export const ReportStatusBar = ({
  status,
  metric,
  standardRef,
  standardName,
  completed,
  drRef,
  dataCollection,
  isMetricHidden,
  isLoading,
  onHide,
  shouldHideCompletion = false,
}: {
  status: CompletionStatus;
  metric: {
    reference: string;
  };
  standardRef: string;
  completed: boolean;
  standardName: string;
  isMetricHidden: boolean;
  drRef: string;
  isLoading: boolean;
  onHide: (isHidden: boolean) => void;
  dataCollection: string | null;
  shouldHideCompletion?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { esrsAssessmentId = '' } = useParams();
  const company = useCurrentCompany();

  const editAnswer = () => {
    if (!!metric) {
      window.open(
        `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric.reference}`
      );
    }
  };

  return (
    <VStack w="100%" alignItems="start" gap="0px" userSelect="none">
      <HStack justifyContent="space-between" w="100%">
        <HStack cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
          {!shouldHideCompletion && (
            <Skeleton isLoaded={!isLoading}>
              <ReportStatusIcon status={status} />
            </Skeleton>
          )}
          <HStack gap="2px">
            <Typography variant="bodyStrong">{status}</Typography>
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </HStack>
        </HStack>
        <HStack gap="2px">
          <IconButton
            isLoading={isLoading}
            aria-label="hide"
            variant="ghost"
            size="sm"
            icon={isMetricHidden ? <PrivateIcon /> : <PublicIcon />}
            onClick={() => onHide(!isMetricHidden)}
          />
          <IconButton
            aria-label="edit"
            size="sm"
            variant="ghost"
            icon={<ArrowUpRightIcon />}
            onClick={editAnswer}
            tooltipMaxWidth="200px"
            tooltipLabel={
              dataCollection === DataCollectionLevel.company
                ? 'Open the source and edit'
                : 'Open the source'
            }
          />
        </HStack>
      </HStack>

      <Box p="0px">
        <Collapse in={isOpen} animateOpacity>
          <VStack alignItems="start" w="100%" p="8px 0px">
            <HStack gap="8px">
              <Typography variant="body" w="132px">
                Status:
              </Typography>
              <Skeleton isLoaded={!isLoading}>
                <Typography variant="body">
                  {status === CompletionStatus.hidden
                    ? completed
                      ? COMPLETION_STATES[status].text
                      : COMPLETION_STATES[status].textIncomplete
                    : COMPLETION_STATES[status].text}
                </Typography>
              </Skeleton>
            </HStack>
            <HStack gap="8px">
              <Typography variant="body" w="132px">
                Source:
              </Typography>
              <Tag
                variant="ghost"
                bgColor="bg.interactive"
                borderRadius="4px"
                size="sm"
                cursor="pointer"
                padding="0px 4px"
                onClick={editAnswer}
              >
                <HStack gap="4px">
                  <LinkIcon />
                  <Typography variant="body">
                    {standardRef} {standardName}
                  </Typography>
                </HStack>
              </Tag>
            </HStack>
          </VStack>
        </Collapse>
      </Box>
    </VStack>
  );
};
