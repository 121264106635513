import { Tag, Tooltip } from 'Atoms';
import { LockedIcon } from 'Tokens/Icons/Function';
import { ReactNode } from 'react';
import { useTranslation } from 'utils/translation';

export type AssessmentStatusTagProps = {
  isLocked: boolean;
  rightIcon?: ReactNode;
  size?: 'sm' | 'md';
};

export const AssessmentStatusTag = ({ isLocked, rightIcon, size = 'sm' }: AssessmentStatusTagProps) => {
  const { t } = useTranslation(['assessment']);

  return (
    <Tooltip
      label={isLocked ? t('common:assessment.isLocked.lockedTooltip') : null}
      aria-label={isLocked ? t('common:assessment.isLocked.locked') : ''}
    >
      <Tag
        size={size}
        variant={isLocked ? 'success' : 'info'}
        rightIcon={isLocked ? null : rightIcon}
        leftIcon={isLocked ? <LockedIcon color="inherit" /> : null}
        clickable={isLocked ? false : true}
      >
        {isLocked
          ? t('common:assessment.isLocked.completed')
          : t('common:assessment.isLocked.inProgress')}
      </Tag>
    </Tooltip>
  );
};
