import {
  Center,
  HStack,
  VStack,
  Table as ChakraTable,
  Tr,
  Td,
  useDisclosure,
} from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { ChartData, ScoreChart, ScoreChartWithLegend } from 'Molecules/ScoreChart';
import { useMemo } from 'react';
import { formatNum, isNullish } from 'utils/numbers';
import { expandTaxonomyScore } from 'utils/scores/taxonomyScore';
import { ScoreSectionsEnum, SubstantialContributionProportion, TaxonomyScore } from 'models';
import { useTranslation } from 'utils/translation';
import { Alert, VisibleIf } from 'Atoms';
import { Modal } from 'Molecules';
import { HelpIcon } from 'Tokens/Icons/Status';
import { nonClimateObjectives, sortObjectives } from 'utils/objectives/objectivesOrder';

interface TaxonomyScoreCompletionChartProps {
  size: 'xs' | 'sm' | 'lg';
  title: ScoreSectionsEnum;
  score: TaxonomyScore;
  scData?: SubstantialContributionProportion;
  progress?: { screening: number; financials: number };
  withDetails?: boolean;
  companyName?: string;
}

export const ZeroFinancialsMessage = ({
  isLarge = false,
  title,
}: {
  isLarge?: boolean;
  title: string;
}) => {
  const { t } = useTranslation('common');
  return (
    <Center>
      <Typography variant={isLarge ? 'h3' : 'detail'} color="text.muted">
        {t(`zero-${title}`)}
      </Typography>
    </Center>
  );
};

export const NoDataMessage = ({ isLarge = false }: { isLarge?: boolean }) => {
  const { t } = useTranslation('assessment');
  return (
    <Center>
      <Typography variant={isLarge ? 'h3' : 'detail'} color="text.muted">
        {t('assessment:noData')}
      </Typography>
    </Center>
  );
};

const DetailsModal = ({
  isOpen,
  onClose,
  data,
  section,
  companyName,
  scData,
}: {
  isOpen: boolean;
  onClose: () => void;
  data: TaxonomyScore;
  section: ScoreSectionsEnum;
  companyName?: string;
  scData?: SubstantialContributionProportion;
}) => {
  const { t } = useTranslation(['portfolio', 'common', 'assessment']);

  const sortedSCData = useMemo(() => {
    if (!scData) return [];
    return Object.entries(scData)
      .map(([key, value]) => ({ key, value }))
      .filter((sc) => !nonClimateObjectives.includes(sc.key))
      .sort(sortObjectives);
  }, [scData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        companyName
          ? `${companyName} ${t(`common:financials.${section}`)} ${t(
              'portfolio:financialsScore.score'
            )}`
          : `${t(`portfolio:financialsScore.total`)} ${t(`common:financials.${section}`)} ${t(
              'portfolio:financialsScore.score'
            )}`
      }
      size="sm"
      hasFooter={false}
    >
      <VStack width="100%" spacing="16px">
        <Alert
          status="neutral"
          title={t('portfolio:financialsScore.alert', {
            section: t(`common:financials.${section}`),
          })}
          closable={false}
        />
        <ChakraTable>
          <Tr borderTop="1px solid" borderColor="border.decorative">
            <Td p="14px 8px" colSpan={3}>
              <Typography variant="overline">
                {t('portfolio:financialsScore.eligibility')}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Td p="14px 8px">
              <Typography variant="detail">{t('portfolio:financialsScore.aligned')}</Typography>
            </Td>
            <Td p="14px 8px">
              <Typography align="right" variant="detail">
                {data.aligned?.toFixed(2)}%
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Td p="14px 8px">
              <Typography variant="detail">
                {t('portfolio:financialsScore.notAlignedOrInProgress')}
              </Typography>
            </Td>
            <Td p="14px 8px">
              <Typography align="right" variant="detail">
                {(data.eligible - data.aligned)?.toFixed(2)}%
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Td p="14px 8px">
              <Typography variant="detail">{t('portfolio:financialsScore.notEligible')}</Typography>
            </Td>
            <Td p="14px 8px">
              <Typography align="right" variant="detail">
                {(data.total - data.eligible)?.toFixed(2)}%
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Td p="14px 8px">
              <Typography variant="detail">{t('portfolio:financialsScore.inProgress')}</Typography>
            </Td>
            <Td p="14px 8px">
              <Typography align="right" variant="detail">
                {data.inProgress?.toFixed(2) ?? 0}%
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Td p="14px 8px" colSpan={3}>
              <Typography variant="overline">
                {t('portfolio:financialsScore.enablingTransitional')}
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Td p="14px 8px">
              <Typography variant="detail">{t('portfolio:financialsScore.enabling')}</Typography>
            </Td>
            <Td p="14px 8px">
              <Typography align="right" variant="detail">
                {data.enabling?.toFixed(2)}%
              </Typography>
            </Td>
          </Tr>
          <Tr>
            <Td p="14px 8px">
              <Typography variant="detail">
                {t('portfolio:financialsScore.transitional')}
              </Typography>
            </Td>
            <Td p="14px 8px">
              <Typography align="right" variant="detail">
                {data.transitional?.toFixed(2)}%
              </Typography>
            </Td>
          </Tr>
          <VisibleIf condition={!!sortedSCData.length}>
            <Tr>
              <Td p="14px 8px" colSpan={3}>
                <Typography variant="overline">{t('portfolio:financialsScore.sc')}</Typography>
              </Td>
            </Tr>
          </VisibleIf>
          {sortedSCData.map((sc) => (
            <Tr key={sc.key}>
              <Td p="14px 8px">
                <Typography variant="detail">{t(`assessment:objectives.${sc?.key}`)}:</Typography>
              </Td>
              <Td p="14px 8px">
                <Typography align="right" variant="detail">
                  {Number(sc?.value?.[section])?.toFixed(2)}%
                </Typography>
              </Td>
            </Tr>
          ))}
        </ChakraTable>
      </VStack>
    </Modal>
  );
};

export const SmallTaxonomyScoreChart = ({
  data,
  noData = false,
  isZero = false,
  title,
}: {
  data: ChartData;
  noData?: boolean;
  isZero?: boolean;
  title: string;
}) => {
  const { t } = useTranslation('assessment');
  const { aligned } = data;
  if (noData || isZero) {
    return <HStack>{isZero ? <ZeroFinancialsMessage title={title} /> : <NoDataMessage />}</HStack>;
  }
  return (
    <HStack>
      <VStack alignItems="flex-start">
        <HStack width="65px" justifyContent="flex-start">
          <Typography variant="h3">{formatNum(aligned)}%</Typography>
        </HStack>
        <HStack justifyContent="flex-start">
          <Typography variant="detail" color="text.muted">
            {t(`assessment:score.align`)}
          </Typography>
        </HStack>
      </VStack>
      <ScoreChart size="sm" data={data} />
    </HStack>
  );
};
export const TaxonomyScoreChart = ({
  size = 'sm',
  title,
  score,
  progress,
  scData,
  withDetails = false,
  companyName,
}: TaxonomyScoreCompletionChartProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { expandedScore, noData, isZero } = useMemo(() => {
    const { aligned, total, eligible, ...rest } = expandTaxonomyScore(score);
    const hasZero =
      (progress?.financials ?? 0) > 0 &&
      (isNullish(total) || isNullish(aligned) || isNullish(eligible));
    const isEmpty = progress?.financials === 0 || isNullish(eligible) || isNullish(aligned);
    return {
      noData: isEmpty,
      expandedScore: { aligned, total, eligible, ...rest },
      isZero: hasZero,
    };
  }, [score, progress]);

  if (size === 'xs') {
    return (
      <ScoreChart
        size="xs"
        data={{
          aligned: expandedScore.aligned,
          notAligned: expandedScore.notAligned,
          notEligible: expandedScore.notEligible,
        }}
      />
    );
  }

  if (size === 'sm')
    return (
      <>
        <SmallTaxonomyScoreChart
          title={title}
          noData={noData}
          isZero={isZero}
          data={{
            aligned: expandedScore.aligned,
            notAligned: expandedScore.notAligned,
            notEligible: expandedScore.notEligible,
          }}
        />
        {withDetails && (
          <>
            <HelpIcon
              boxSize="12px"
              onClick={() => {
                onOpen();
              }}
            />
            <DetailsModal
              isOpen={isOpen}
              onClose={onClose}
              data={score}
              section={
                title.toLowerCase() === 'turnover'
                  ? ScoreSectionsEnum.revenue
                  : (title.toLowerCase() as ScoreSectionsEnum)
              }
              scData={scData}
              companyName={companyName}
            />
          </>
        )}
      </>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <ScoreChartWithLegend
        noData={noData}
        isZero={isZero}
        size="lg"
        title={title}
        data={{
          aligned: expandedScore.aligned,
          notAligned: expandedScore.notAligned,
          notEligible: expandedScore.notEligible,
        }}
        onDetailsClick={withDetails ? onOpen : undefined}
      />
      {withDetails && (
        <DetailsModal
          isOpen={isOpen}
          onClose={onClose}
          data={score}
          section={title}
          scData={scData}
        />
      )}
    </div>
  );
};
