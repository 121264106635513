import { Box, CloseButton, HStack, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Button, IconButton, Tabs, Tag, TruncatableText } from 'Atoms';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { createGraphObject } from 'containers/Esrs/utils';
import { QuestionType_Enum_, User } from 'models';
import { LearnMoreContent, Loader, MetricLearnMoreHeader } from 'Molecules';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DesignIllustration, Typography } from 'Tokens';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { ArrowLeftIcon, ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { MaximizeIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import {
  MetricViewEnums,
  QuartersEnums,
  QuartersObject,
  TimePeriods,
  TimePeriodsEnums,
} from '../../Requirement';
import { AggregatedQualitativeAnswers } from '../AggregatedMetrics';
import { MetricMenu } from '../InputTable/MetricMenu';
import { useGetDatapointValues } from '../InputTable/QuarterInput';
import { isFrequencyYearly, MetricsTableData, useAggregatedRowValue } from '../MetricAnswers.hooks';
import { GraphCard } from '../MetricsGraphModal';
import { MetricComments } from './MetricComments';
import { MetricOverview } from './MetricOverview';
import { MetricSource } from './MetricSource';
import { MetricMdrm } from './MetricMdrm';
import { getMetricChartUrl } from '../Metrics.utils';

type PartialYearObject = QuartersObject & { Year?: number };

export const MetricSidebar = ({
  row,
  metric,
  materialStandardId,
  companyReportingUnit,
  hasGraph = true,
  selectedMetric,
  setSelectedMetric,
  onMenuOpen,
  selectedQuarter,
  esrsAssessmentProjectLeader,
  setRowData,
  isGroup,
  isMetricDr,
}: {
  row?: MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] };
  metric: MetricsTableData['metric'];
  materialStandardId: string;
  companyReportingUnit?: string;
  hasGraph?: boolean;
  selectedMetric: SelectedMetric | undefined;
  setSelectedMetric: (param: SelectedMetric) => void;
  onMenuOpen: () => void;
  selectedQuarter: TimePeriods;
  esrsAssessmentProjectLeader?: Partial<User>;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  isGroup: boolean;
  isMetricDr: boolean;
}) => {
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    view,
    disclosureRequirementRef = '',
  } = useParams();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const [selectedTab, setSelectedTab] = useState<string | undefined>('overview');
  const [viewLearnMore, setViewLearnMore] = useState(false);
  const user = useUserData();

  const [searchParams, setSearchParams] = useSearchParams();

  const { answer, dataPointsPerQuarter, dataPointPerYear, isAggregated, loading } =
    useGetDatapointValues(row ?? { metric: metric }, companyReportingUnit);

  const isYearly = useMemo(
    () => isFrequencyYearly(row ?? { metric: metric }, materialStandardId),
    [row, metric, materialStandardId]
  );
  const isParentMetric = useMemo(() => !!row?.subRows?.length, [row]);
  const isLongText = useMemo(() => metric.metricType === QuestionType_Enum_.LongText_, [metric]);
  const isOverview = useMemo(() => view === MetricViewEnums.overview, [view]);

  const { result: aggregatedValues } = useAggregatedRowValue(
    row ?? { metric: metric },
    isYearly,
    companyReportingUnit
  );

  const datapoint = useMemo(
    () =>
      isParentMetric || isLongText || isYearly
        ? dataPointPerYear
        : dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter)?.value,
    [isYearly, dataPointPerYear, dataPointsPerQuarter, selectedQuarter, isLongText]
  );

  useEffect(() => {
    if (openDrawer) {
      setSelectedTab('comment');
    } else if (searchParams.get('sidebar') === 'open') {
      setSelectedTab('mdr-m');
    }
  }, [openDrawer, searchParams]);

  const getValuePerQuarter = (quarter: string) =>
    Number(dataPointsPerQuarter.find((dp) => dp.field === quarter)?.value?.value ?? 0);

  const graphData: PartialYearObject = useMemo(
    () =>
      createGraphObject(
        isYearly,
        getValuePerQuarter(QuartersEnums.q1),
        getValuePerQuarter(QuartersEnums.q2),
        getValuePerQuarter(QuartersEnums.q3),
        getValuePerQuarter(QuartersEnums.q4),
        Number(dataPointPerYear?.value) ?? 0
      ),
    [dataPointsPerQuarter, isYearly]
  );

  const aggregatedGraphData: PartialYearObject = useMemo(
    () =>
      createGraphObject(
        isYearly,
        aggregatedValues[QuartersEnums.q1],
        aggregatedValues[QuartersEnums.q2],
        aggregatedValues[QuartersEnums.q3],
        aggregatedValues[QuartersEnums.q4],
        aggregatedValues.Year
      ),
    [aggregatedValues, isYearly]
  );

  const graph = useMemo(
    () => (isAggregated ? aggregatedGraphData : graphData),
    [aggregatedGraphData, graphData, isAggregated]
  );

  const subTitle = useMemo(() => {
    const isTag = !!row?.tagName;
    const isParent = !!row?.parentMetric;
    if (isTag) return row.metric.shortTitle ?? row.metric.title;
    if (isParent) return row?.parentMetric?.title;
  }, [row]);

  const mainTitle = useMemo(() => {
    if (!row) return metric.shortTitle ?? metric.title;
    return !!row?.tagName ? row?.tagName : row?.metric.shortTitle ?? row?.metric.title;
  }, [row]);

  const hideComments = useMemo(() => {
    const quarterlyMetricInYearView =
      !isYearly && selectedQuarter === TimePeriodsEnums.year && !isParentMetric && !isLongText;
    const groupedTagsRow = !!(row?.tagName && row?.tagType);
    if (isOverview || groupedTagsRow || quarterlyMetricInYearView) {
      return true;
    }
    return false;
  }, [isYearly, selectedQuarter, isParentMetric, isLongText, row]);

  const commentsCount = useMemo(
    () => datapoint?.thread?.comments_aggregate.aggregate?.count,
    [datapoint]
  );

  useEffect(() => {
    setSelectedMetric({
      description: metric.description ?? '',
      reference: metric.reference,
      tags: metric.materialMetrics
        .find((mm) => mm.materialStandardId === materialStandardId)
        ?.materialMetricTags?.map((tag) => ({
          type: tag.tagType,
        })),
    });
  }, [metric]);

  const tabs = useMemo(() => {
    return (
      [
        {
          title: 'Overview',
          id: 'overview',
          content: (
            <MetricOverview
              row={row}
              metric={metric}
              selectedQuarter={selectedQuarter}
              companyReportingUnit={companyReportingUnit}
              esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
              materialStandardId={materialStandardId}
            />
          ),
        },
        {
          title: 'MDR-M',
          id: 'mdr-m',
          content: (
            <MetricMdrm
              datapointId={datapoint?.id}
              metricRef={metric.reference}
              row={row ?? { metric: metric }}
              companyReportingUnit={companyReportingUnit}
              timeframe={isYearly ? TimePeriodsEnums.year : selectedQuarter}
            />
          ),
          isHidden: !isMetricDr || metric.metricType === QuestionType_Enum_.LongText_,
        },
        {
          title: 'Source',
          id: 'source',
          content: (
            <MetricSource
              answerId={answer?.id}
              metric={row?.metric ?? metric}
              hasOptedOut={answer?.hasOptedOut}
              companyReportingUnit={companyReportingUnit}
              answerData={row?.sourceData}
              isGroup={isGroup}
              username={user?.displayName ?? ''}
            />
          ),
        },
        {
          title: (
            <HStack spacing="6px">
              <Typography variant="bodyStrong" color="currentColor">
                Comments
              </Typography>
              {commentsCount && (
                <Tag variant="info" size="xs">
                  {commentsCount}
                </Tag>
              )}
            </HStack>
          ),
          id: 'comment',
          content: (
            <MetricComments
              datapoint={datapoint}
              companyReportingUnit={companyReportingUnit}
              selectedQuarter={selectedQuarter}
              isYearly={isYearly || isParentMetric || isLongText}
              row={row ?? { metric: metric, tags: [] }}
            />
          ),
        },
      ].filter((tab) => (hideComments ? tab.id !== 'comment' : true)) ?? []
    );
  }, [row, metric, selectedQuarter, companyReportingUnit, hideComments, isYearly, isParentMetric]);

  return (
    <VStack
      minWidth="352px"
      maxWidth="356px"
      height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
      pb="24px"
      mt="-16px"
      mr="-16px"
      mb="-16px"
      spacing="0px"
      alignItems="flex-start"
      borderLeft="1px solid"
      borderColor="border.decorative"
      overflow="auto"
      position="sticky"
      top="0px"
      right="0px"
      bg="bg.default"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <HStack padding="8px 12px 8px 20px" justifyContent="space-between" w="100%">
            {viewLearnMore ? (
              <HStack spacing="6px">
                <IconButton
                  variant="ghost"
                  icon={<ArrowLeftIcon />}
                  onClick={() => setViewLearnMore(false)}
                  aria-label={''}
                  size="sm"
                />
                <Typography color="text.muted" variant="bodyStrong" py="8px">
                  Help center
                </Typography>
              </HStack>
            ) : (
              <Typography color="text.hint" variant="bodyStrong" py="8px">
                Data point
              </Typography>
            )}
            <CloseButton
              onClick={() => {
                searchParams.delete('sidebar');
                setSearchParams(searchParams);
                if (openDrawer && !!hash) {
                  navigate(pathname.split('?')[0]);
                }
                setRowData(undefined);
              }}
            />
          </HStack>
          {!viewLearnMore ? (
            <>
              {hasGraph ? (
                <Box padding="16px 8px" w="100%" h="fit-content" minH="156px" position="relative">
                  <GraphCard
                    data={graph}
                    height={132}
                    margins={{
                      top: 5,
                      right: 8,
                      left: -20,
                      bottom: 0,
                    }}
                    showXAxis={false}
                  />
                  <IconButton
                    aria-label="maximize"
                    icon={<MaximizeIcon color="text.action" />}
                    size={'sm'}
                    onClick={() => {
                      const url = getMetricChartUrl({
                        row: row ?? { metric },
                        metricRef: metric.reference,
                        standardId: materialStandardId,
                        companyId,
                        esrsAssessmentId,
                        standardRef,
                        disclosureRequirementRef,
                      });
                      navigate(url);
                    }}
                    position="absolute"
                    right="12px"
                    top="4px"
                  />
                </Box>
              ) : (
                <Box h="100px">
                  <DesignIllustration
                    boxSize="244px 145px"
                    w="224px"
                    position="absolute"
                    top={0}
                    right={0}
                  />
                </Box>
              )}
              <VStack alignItems="start" width="100%" spacing="16px" p="0px 20px 12px">
                <VStack justifyContent="end" width="100%" spacing="4px" height="44px">
                  {subTitle && (
                    <TruncatableText variant="detail" color="text.detail" text={subTitle} />
                  )}
                  <TruncatableText variant="h3" text={mainTitle} />
                </VStack>
                <HStack width="100%" spacing="6px">
                  <Button
                    variant="secondary"
                    leftIcon={<DocumentIcon color="text.action" />}
                    rightIcon={<ArrowUpRightIcon color="text.action" opacity={0.4} />}
                    onClick={() =>
                      window.open(
                        `/${companyId}/esrs/${esrsAssessmentId}/report?metricRef=${standardRef}-${metric.reference}`
                      )
                    }
                  >
                    <Typography variant="bodyStrong" color="text.action" pr="44px">
                      Open in report
                    </Typography>
                  </Button>
                  <IconButton
                    aria-label="learn more"
                    icon={<HelpIcon color="text.action" />}
                    size="md"
                    onClick={() => {
                      setViewLearnMore(true);
                    }}
                  />
                  <MetricMenu
                    metric={metric}
                    onOpen={onMenuOpen}
                    setSelectedMetric={setSelectedMetric}
                    companyReportingUnit={companyReportingUnit}
                  />
                </HStack>
              </VStack>
              <Tabs
                currentTab={selectedTab}
                onChange={(tabId) => {
                  setSelectedTab(String(tabId));
                }}
                padList="20px"
                items={tabs.filter((tab) => !tab.isHidden)}
              />
            </>
          ) : (
            <VStack alignItems="first" p="16px">
              <LearnMoreContent
                header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
                description={selectedMetric?.description}
                customHeader={
                  !selectedMetric?.isDisclosure ? (
                    <MetricLearnMoreHeader
                      metricRef={selectedMetric?.reference ?? ''}
                      tags={selectedMetric?.tags ?? []}
                      titleVariant="h3"
                    />
                  ) : undefined
                }
              />
            </VStack>
          )}
        </>
      )}
    </VStack>
  );
};
