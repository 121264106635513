import { Accordion, Alert } from 'Atoms';
import { NewVersionIllustration, Typography } from 'Tokens';
import { Modal, ModalProps } from 'Molecules';
import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { AIIcon } from 'Tokens/Icons/Function';
import { ActivityMetaData } from '../VersioningOfQuestions.hooks';

export const NewVersionModal = ({
  isOpen,
  onClose,
  onConfirm,
  outdatedActivities,
  updatedActivities,
  ...rest
}: {
  outdatedActivities: ActivityMetaData[];
  updatedActivities: ActivityMetaData[];
} & Omit<ModalProps, 'children'>) => {
  const { t } = useTranslation(['assessment', 'common']);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      size="md"
      title="Update to a new version of questions"
      titleIcon={<AIIcon boxSize="24px" color="text.info" />}
      illustration={<NewVersionIllustration width="100%" height="fit-content" />}
      hasFooter={outdatedActivities.length ? true : false}
      confirmText="Update"
      cancelText="Skip for now"
      {...rest}
    >
      <VStack spacing="24px" alignItems="start">
        <VStack alignItems="start" spacing="8px">
          <Typography variant="h3">Always up to date</Typography>
          <Typography variant="body">
            For any assessments covering the financial year of 2023 or later, we highly recommend to
            update to the newest version of questions.
          </Typography>
        </VStack>
        <VStack alignItems="start" spacing="8px">
          <Typography variant="h3">Why were updates introduced?</Typography>
          <Typography variant="body">
            Celsia is closely following all regulatory updates from the EU to ensure that our
            software is always up to date. In addition, we pay close attention to any additional
            guidance coming from the EU (e.g FAQs) and also how different other stakeholders
            interpret the criteria.
            <br />
            <br />
            The goal is to make sure that our software is not only covering the regulation itself,
            but also promotes best-practice interpretation of all required criteria.
          </Typography>
        </VStack>
        <VStack alignItems="start" spacing="8px">
          <Typography variant="h3">What is required from you?</Typography>
          <Alert
            status="info"
            closable={false}
            title="Questions affected by this update will be changed and answers will be reset"
          />
          <Typography variant="body">
            You will need to go through updated questions and provide new answers. For updated
            questions an old version will be available together with previously provided answers and
            documentation to make this process as efficient as possible.
            <br />
            <br />
            If new criteria to an activity have been introduced by the amendments, new questions
            have been added under the activity to implement those. Newly added questions will be
            marked with a “New” label.
          </Typography>
        </VStack>
        <VStack alignItems="start" spacing="8px" width="100%">
          {outdatedActivities.length ? (
            <Typography variant="h3">What will change?</Typography>
          ) : (
            <Typography variant="h3">What has changed?</Typography>
          )}
          <Typography variant="body">
            Here&apos;s a list of activities that were subject to the updates:
          </Typography>
          <Accordion
            variant="solid"
            items={[...outdatedActivities, ...updatedActivities].map((a) => ({
              label: a.name,
              content: <Typography variant="body">{a.changeDescription}</Typography>,
            }))}
            marginBottom={'8px'}
          />
        </VStack>
        <VStack alignItems="start" spacing="8px">
          <Typography variant="h3">Do you need help?</Typography>
          <Typography variant="body">
            Contact us and we will provide additional information and guidance.
          </Typography>
        </VStack>
      </VStack>
    </Modal>
  );
};
