import { Text, HStack, Tooltip, Box, SkeletonCircle, useDisclosure } from '@chakra-ui/react';
import { Avatar, AvatarProps } from 'Atoms';
import { User } from 'models';
import React from 'react';
import { Menu } from 'Molecules/Menu';
import { LanguageEditor } from 'Organisms/LanguageEditor';
import { useSignOut } from '@nhost/react';
import { useTranslation } from 'react-i18next';
import { WorldIcon } from 'Tokens/Icons/Data';
import { SignOutIcon } from 'Tokens/Icons/Function';
import { DarkModeSwitch } from 'Atoms/DarkModeSwitch';
import { isCelsiaSupport } from 'utils/users';
import { useApolloClient } from '@apollo/client';

export type UserAvatarProps = {
  user: Pick<User, 'displayName' | 'avatarUrl'>;
} & AvatarProps;

export function UserAvatar({ user, ...props }: UserAvatarProps) {
  if (!user) {
    return <SkeletonCircle size="24px" />;
  }
  const { displayName, avatarUrl } = user;
  return (
    <Tooltip label={displayName} color="text.onAccent">
      <Avatar
        name={displayName}
        src={avatarUrl?.includes('default=blank') ? '' : avatarUrl}
        {...props}
      />
    </Tooltip>
  );
}

export function AvatarWithName({ user, ...props }: UserAvatarProps) {
  return (
    <HStack>
      <UserAvatar user={user} {...props} />
      <Text>{user?.displayName}</Text>
    </HStack>
  );
}
type AvatarMenuProps = {
  user: User;
};
export const AvatarMenu = ({ user }: AvatarMenuProps) => {
  const { t } = useTranslation('common');
  const { signOut } = useSignOut();
  const client = useApolloClient();
  const {
    isOpen: isLanguageEditorOpen,
    onOpen: onLanguageEditorOpen,
    onClose: onLanguageEditorClose,
  } = useDisclosure();

  const handleSignOut = () => {
    client.stop();
    signOut();
  };

  return (
    <Box>
      <Menu
        menuButton={
          <SkeletonCircle isLoaded={user != null} boxSize="32px">
            <UserAvatar size="sm" user={user ?? {}} />
          </SkeletonCircle>
        }
        sections={[
          {
            title: user?.displayName,
            actions: [
              {
                id: 'language',
                title: t('common:actions.language'),
                leftElement: <WorldIcon color="inherit" />,
                onClick: onLanguageEditorOpen,
              },
            ],
          },
          ...(isCelsiaSupport(user.email)
            ? [
                {
                  actions: [
                    {
                      id: 'dark-mode-switch',
                      reactNode: <DarkModeSwitch key={'dark-mode-switch'} />,
                    },
                  ],
                },
              ]
            : []),
          {
            actions: [
              {
                id: 'logout',
                title: t('common:actions.logOut'),
                leftElement: <SignOutIcon color="inherit" />,
                onClick: handleSignOut,
              },
            ],
          },
        ]}
        size={'md'}
      />
      {user && (
        <LanguageEditor isOpen={isLanguageEditorOpen} onClose={onLanguageEditorClose} user={user} />
      )}
    </Box>
  );
};
AvatarMenu.displayName = 'AvatarMenu';
