import { RefetchQueriesInclude } from '@apollo/client';
import { DrawerCloseButton, Heading, HStack, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useCompanyFiles } from 'containers/Drive/Drive.hooks';
import { FileEditorContextProvider, FileList } from 'containers/Drive/pieces';
import { AttachmentBox, DocumentationFile } from 'models';
import { AlertDialog } from 'Molecules';
import { useSaveAttachments } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { Drawer } from '../../Molecules/Drawer/Drawer';

export function AttachmentDrawer({
  children,
  isOpen,
  refetch,
  onClose,
  onAttachClicked,
  attachmentBox,
  singleFileSelect = false,
}: React.PropsWithChildren<{
  onAttachClicked?: (filesToAttach: DocumentationFile[]) => void;
  refetch?: RefetchQueriesInclude;
  isOpen: boolean;
  onClose: () => void;
  attachmentBox?: AttachmentBox;
  singleFileSelect?: boolean;
}>) {
  const saveAttachments = useSaveAttachments();
  const { files } = useCompanyFiles();
  const { t } = useTranslation(['files', 'common']);
  const preAttachedFiles = useMemo(() => {
    return attachmentBox?.attachments.map((attachment) => attachment.file) ?? [];
  }, [files, attachmentBox]);

  const [selectedFiles, setSelectedFiles] = useState<DocumentationFile[]>();
  const [filesExist, setFilesExist] = useState<boolean>(files.length > 0);

  const [showAlert, setShowAlert] = React.useState(false);
  const toast = useToast();

  useEffect(() => {
    if (!filesExist && files.length > 0 && isOpen) {
      setFilesExist(true);
      setSelectedFiles(files);
      return;
    }
    if (filesExist && files.length === 0 && isOpen) {
      setFilesExist(false);
      setSelectedFiles([]);
      return;
    }
    if (selectedFiles === undefined) {
      setSelectedFiles(preAttachedFiles);
      return;
    }
    const fileIds = files.map((file) => file.id);
    setSelectedFiles(selectedFiles?.filter((file) => fileIds.includes(file.id)));
  }, [files, preAttachedFiles]);

  const defaultOnAttachClicked = useCallback(
    (filesToAttach: DocumentationFile[]) => {
      if (attachmentBox) {
        return saveAttachments(filesToAttach, attachmentBox, refetch);
      }
    },
    [saveAttachments, attachmentBox, refetch]
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedFiles(preAttachedFiles);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedFiles && singleFileSelect)
      if (selectedFiles.length > 1) {
        setSelectedFiles([selectedFiles?.[0]]);
      }
  }, [selectedFiles, singleFileSelect]);

  const handleOnClose = useCallback(() => {
    onClose();
    setSelectedFiles(undefined);
  }, [onClose]);

  const showAlertIfAttachmentsChanged = useCallback(() => {
    if (
      selectedFiles?.every((file) => preAttachedFiles.includes(file)) &&
      selectedFiles.length === preAttachedFiles.length
    ) {
      handleOnClose();
    } else {
      setShowAlert(true);
    }
  }, [selectedFiles, preAttachedFiles]);

  return (
    <FileEditorContextProvider>
      {children}
      <Drawer
        headerProps={{ fontSize: 'normal', fontWeight: 'normal' }}
        header={
          <VStack alignItems="stretch" width="100%" spacing="md">
            <HStack>
              <Heading flexGrow={1}>{t('files:submitDocumentation')}</Heading>
              <DrawerCloseButton fontSize="h2" marginLeft="auto" />
            </HStack>
          </VStack>
        }
        footer={
          <HStack alignItems="start" width="100%">
            <Button
              variant="primary"
              onClick={() => {
                toast({
                  text: t('files:attachmentInProgress'),
                  destroyAll: true,
                });
                if (!!onAttachClicked) {
                  onAttachClicked(selectedFiles ?? []);
                } else {
                  defaultOnAttachClicked(selectedFiles ?? []);
                }
                handleOnClose();
              }}
            >
              {t('files:attachSelected', { noOfFiles: selectedFiles?.length })}
            </Button>
            <Button variant="ghost" onClick={() => showAlertIfAttachmentsChanged()}>
              {t('common:button:cancel')}
            </Button>
          </HStack>
        }
        size={'xl'}
        isOpen={isOpen}
        isClosable="no-button"
        onClose={() => showAlertIfAttachmentsChanged()}
        isEmpty={files.length ? false : true}
      >
        <AlertDialog
          title={<Typography>{t('files:cancelAttachmentModal:title')}</Typography>}
          onConfirm={() => {
            handleOnClose();
          }}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
          confirmLabel={t('files:cancelAttachmentModal:confirm')}
        >
          <Typography padding="16px">{t('files:cancelAttachmentModal:body')} </Typography>
        </AlertDialog>
        <FileList
          files={files}
          selectedFiles={selectedFiles ?? []}
          setSelectedFiles={setSelectedFiles}
          singleFileSelect={singleFileSelect}
        />
      </Drawer>
    </FileEditorContextProvider>
  );
}
