import { GetEsrsMetricQuery_, useGetEsrsMetricQuery } from 'models';
import { useMemo } from 'react';
import { AdditionalType } from '../..';
import { MaterialMetricPerDisclosure } from '../../DataCollection';

type MetricNumberData = {
  firstLevel?: string | null | undefined;
  secondLevel?: string | null | undefined;
  order: number | undefined;
  isAR: boolean | undefined;
  [key: string]: any;
};

export const getMetricRefNumber = (metric: MetricNumberData | undefined | null) => {
  const firstLevel = metric?.firstLevel ?? '';
  const secondLevel = metric?.secondLevel ?? '';
  const order = metric?.order;

  if (metric?.isAR) return `AR ${order} ${firstLevel} ${secondLevel}`.trim();

  return `${order} ${firstLevel} ${secondLevel}`.trim();
};

export const useEsrsMetricData = (
  metricRef: string,
  disclosureMetric?: MaterialMetricPerDisclosure
) => {
  const { data: esrsMetricData, loading: metricDataLoading } = useGetEsrsMetricQuery({
    variables: {
      metricRef,
    },
    skip: !metricRef || !!disclosureMetric,
  });

  const metric = useMemo(
    () => disclosureMetric ?? esrsMetricData?.metric,
    [esrsMetricData, disclosureMetric]
  );

  const metricAdditionalTypes: AdditionalType[] = useMemo(() => {
    return (
      metric?.additionalTypes
        .map((type) => type.additionalType)
        .map((aType) => ({
          reference: aType.reference,
          title: aType.title ?? '',
          description: aType.description ?? '',
          learnMore: aType.learnMore ?? '',
        })) ?? []
    );
  }, [metric]);

  const metricRefNumber = useMemo(() => {
    return getMetricRefNumber(metric) ?? '';
  }, [metric]);

  return { metric, metricRefNumber, metricAdditionalTypes, metricDataLoading };
};
