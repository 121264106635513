import { VStack, Stack, MenuButton } from '@chakra-ui/react';
import { Button, Card, Link, ButtonLink, FormField, Input, Infobox } from 'Atoms';
import { Typography } from 'Tokens';
import React from 'react';
import { Logo } from 'Molecules';
import { useForm, useFormState } from 'react-hook-form';

import { getLanguageMap, useTranslation } from 'utils/translation';
import { useSignOut } from '@nhost/react';
import { useNavigate } from 'react-router-dom';
import { useChangeLanguage } from 'containers/Navigation';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { isServiceAccount } from 'utils/users';
import { Menu } from 'Molecules/Menu';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { LockedIcon } from 'Tokens/Icons/Function';

const KNOWN_ERROR = 'already-signed-in';
const INVALID_EMAIL_PASSWORD = 'invalid-email-password';
const INVALID_REQUEST = 'invalid-request';
const SERVICE_ACCOUNT = 'service-account';

export const LanguageSwitcher = () => {
  const map = getLanguageMap();
  const { changeLanguage, language } = useChangeLanguage();
  const languages = Object.entries(map).map(([code, name]) => ({
    id: name,
    onClick: () => changeLanguage(code),
    title: name,
  }));
  return (
    <Menu
      size="lg"
      menuButton={
        <MenuButton as={Button} variant="link" rightIcon={<ChevronDownIcon color="inherit" />}>
          {map[language]}
        </MenuButton>
      }
      sections={[
        {
          actions: languages,
        },
      ]}
    />
  );
};

const LoginErrorBox = ({ error }: { error: string }) => {
  const { t } = useTranslation('login');
  if (error === INVALID_EMAIL_PASSWORD)
    return (
      <Infobox
        closable={false}
        w={'100%'}
        my={'8px'}
        status="warning"
        description={
          <>
            {t('login:incorrectEmailOrPassword')}
            <Link color={'text.action'} to="/changePassword">
              {' '}
              {t('login:resetPassword')}
            </Link>
            .
          </>
        }
      />
    );
  if (error === INVALID_REQUEST)
    return (
      <Infobox
        closable={false}
        minHeight="fit-content"
        w="100%"
        my="8px"
        status="warning"
        description={t('login:invalidEmail')}
      />
    );
  if (error === KNOWN_ERROR)
    return (
      <Infobox
        closable={false}
        w="100%"
        my="8px"
        status="warning"
        description={t('login:nhostDown')}
      />
    );
  if (error === SERVICE_ACCOUNT)
    return (
      <Infobox
        closable={false}
        w="100%"
        my="8px"
        status="warning"
        description={t('login:serviceAccount.cannotLogIn')}
      />
    );
  return <></>;
};

type LoginInput = {
  email: string;
  password: string;
};
export const LoginForm = ({ onSuccess, email }: { onSuccess: () => any; email?: string }) => {
  const toast = useToast();
  const { t } = useTranslation('login');
  const { signOut } = useSignOut();
  const [errorType, setErrorType] = React.useState<string>();

  const { register, handleSubmit, control, reset } = useForm<LoginInput>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      email: email || '',
    },
  });
  const { errors, isSubmitting } = useFormState({ control });

  const onLogin = async (values: { email: string; password: string }) => {
    const fields = { ...values, email: values.email ? values.email.trim() : '' };
    if (isServiceAccount(fields.email)) {
      setErrorType(SERVICE_ACCOUNT);
      toast({
        text: t('login:serviceAccount.invalidUser'),
        variant: 'danger',
      });
      return;
    }
    await signOut().finally(() => {
      nhost.auth
        .signIn({ email: fields.email, password: fields.password })
        .then((res) => {
          const { error } = res;
          if (error?.error === INVALID_REQUEST) {
            setErrorType(INVALID_REQUEST);
          } else if (error?.error === INVALID_EMAIL_PASSWORD) {
            setErrorType(INVALID_EMAIL_PASSWORD);
          } else if (error?.error === KNOWN_ERROR) {
            setErrorType(KNOWN_ERROR);
            toast({
              text: t('login:nhostDown'),
              variant: 'danger',
            });
          } else {
            onSuccess();
          }
          reset({
            password: '',
          });
        })
        .catch((err) => {
          toast({
            text: err.message,
            variant: 'danger',
          });
        });
    });
  };

  const validateEmail = (value: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;
    return emailRegex.test(value) || t('login:inputs.email.validateError');
  };

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <VStack spacing="16px">
        <FormField
          id="email"
          label={t('login:fields.email.label')}
          isInvalid={!!errors.email}
          error={errors.email?.message}
        >
          <Input
            {...register('email', {
              required: t('login:inputs.email.required'),
              validate: validateEmail,
            })}
            placeholder={t('login:fields.email.label')}
            autoComplete="username"
            size="md"
            width="100%"
          />
        </FormField>
        <FormField
          id="password"
          label={t('login:fields.password.label')}
          isInvalid={!!errors.password}
          error={errors.password?.message ?? ''}
        >
          <Input
            {...register('password', { required: t('login:inputs.password') })}
            type="password"
            placeholder={t('login:fields.password.label')}
            autoComplete="current-password"
            size="md"
            width="100%"
          />
        </FormField>
        {errorType && <LoginErrorBox error={errorType} />}
        <Button variant="primary" width="full" mt="24px" type="submit" isLoading={isSubmitting}>
          {t('login:logIn')}
        </Button>
      </VStack>
    </form>
  );
};

export const LoginPage = ({
  children,
  heading,
  header,
}: {
  heading: string;
  header?: React.ReactNode;
  children: React.ReactNode;
}) => (
  <Stack
    minHeight="100vh"
    alignItems="center"
    gap="0px"
    p="10%"
    background="bg.decorative"
  >
    <VStack spacing="0px" width="320px">
      <Logo size="110px" />
      {header
        ? header
        : heading.split('\n\n').map((h) => (
            <Typography variant="h4" mt="8px" key={h}>
              {h}
            </Typography>
          ))}
    </VStack>
    <VStack width="320px" mt="56px" alignItems="stretch">
      {children}
    </VStack>
    {/* <Box position="absolute" top="lg" right="lg">
      <LanguageSwitcher />
    </Box> */}
  </Stack>
);

export function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation('login');

  return (
    <LoginPage heading={t('login:loginHeader')}>
      <LoginForm onSuccess={() => navigate('/')} />
      <ButtonLink to="/sso-login" variant="secondary" width="100%">{t('login:sso')}</ButtonLink>
      <ButtonLink to="/changePassword" variant="ghost" width="100%" leftIcon={<LockedIcon/>}>{t('login:forgotPassword')}</ButtonLink>
    </LoginPage>
  );
}
