import {
  useCompanyAssessmentQuery,
  useEsrsAssessmentYearQuery,
  useGetMetricNameQuery,
  useGetPortfolioNameQuery,
  useGetTargetQuery,
  useGroupAssessmentQuery,
} from 'models';
import { useMemo } from 'react';
import {
  BreadcrumbsList,
  ESRS_PATHS_TO_IGNORE,
  ESRS_REF_REGEX,
  ID_REGEX,
  LinkType,
  PAI_PATHS_TO_IGNORE,
  PATHS_TO_IGNORE,
  PORTFOLIOS_PATHS_TO_IGNORE,
} from './Breadcrumbs.utils';
import { useParams } from 'react-router-dom';

const portfolioOwnerContext = {
  headers: {
    'x-hasura-role': 'portfolio-owner',
  },
};

export const useGetPathName = (
  index: number,
  path: string,
  linkType: string,
  isEditTarget: boolean
) => {
  const {
    metricRef = '',
    cAssessmentId = '',
    portfolioId = '',
    esrsAssessmentId = '',
    gAssessmentId = '',
    targetId = '',
  } = useParams();

  const isAssessmentId = useMemo(
    () => [cAssessmentId, gAssessmentId, portfolioId, esrsAssessmentId].includes(path),
    [cAssessmentId, portfolioId, esrsAssessmentId]
  );

  const isGroup = useMemo(() => gAssessmentId !== '', [gAssessmentId]);

  const { data: taxonomyGroupAssessment } = useGroupAssessmentQuery({
    variables: { assessmentId: gAssessmentId },
    skip: path !== gAssessmentId,
  });
  const { data: taxonomyAssessment } = useCompanyAssessmentQuery({
    variables: { cAssessmentId },
    skip: path !== cAssessmentId,
  });
  const { data: esrsAssessment } = useEsrsAssessmentYearQuery({
    variables: { esrsAssessmentId },
    skip: path !== esrsAssessmentId,
  });
  const { data: portfolioName } = useGetPortfolioNameQuery({
    variables: { id: path },
    context: portfolioOwnerContext,
    skip: path !== portfolioId,
  });
  const { data: esrsTarget } = useGetTargetQuery({
    variables: { targetId: path },
    skip: path !== targetId || !isEditTarget,
  });
  const { data: esrsMetricName } = useGetMetricNameQuery({
    variables: { ref: path },
    skip: path !== metricRef || !path,
  });

  const pageName = useMemo(() => {
    if (index === 0) return BreadcrumbsList[linkType].home;
    if (path === metricRef) {
      return esrsMetricName?.EsrsMetric[0].title;
    }
    if (isAssessmentId) {
      if (linkType === LinkType.Taxonomy && isGroup) {
        return taxonomyGroupAssessment?.assessment?.name;
      }
      if (linkType === LinkType.Taxonomy && !isGroup) {
        return taxonomyAssessment?.companyAssessment?.aggregate.title;
      }
      if (linkType === LinkType.Esrs) {
        return esrsAssessment?.esrsAssessment?.reportingYear;
      }
      if (linkType === LinkType.Pai) {
        return portfolioName?.portfolio?.name;
      }
      if (linkType === LinkType.Portfolios) {
        return portfolioName?.portfolio?.name;
      }
    }
    if (ID_REGEX.test(path) && isEditTarget) {
      return (
        `${esrsTarget?.target?.direction ?? 'Maintain'} ${
          esrsTarget?.target?.metric?.title ?? ''
        }` ?? 'Target'
      );
    }
    return BreadcrumbsList[linkType][path] ?? path.charAt(0).toUpperCase() + path.slice(1);
  }, [
    taxonomyGroupAssessment,
    taxonomyAssessment,
    esrsAssessment,
    portfolioName,
    isAssessmentId,
    isEditTarget,
    path,
  ]);

  return pageName ?? '';
};

export const checkCurrentPath = (path: string) => ({
  isTaxonomy: path.includes('/assessments'),
  isEsrs: path.includes('/esrs'),
  isPai: path.includes('/pai/'),
  isEsrsStandard: path.includes('/standard'),
  isChart: path.includes('/chart'),
  isOnboarding: path.includes('onboarding'),
  isEditTarget: path.includes('/target') && path.includes('/edit'),
});

export const isValidPath = (
  path: string,
  {
    isEsrsStandard,
    isOnboarding,
    isChart,
    isEditTarget,
  }: { isEsrsStandard: boolean; isOnboarding: boolean; isChart: boolean; isEditTarget: boolean },
  materialStandardId: string,
  linkType: string,
  currentPath: string
) => {
  const shouldHideRef =
    !currentPath.includes('/target-form') &&
    !currentPath.includes('/chart') &&
    ESRS_REF_REGEX.test(path);
  if (path === '' || shouldHideRef || PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (isEsrsStandard && ESRS_PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (linkType === LinkType.Portfolios && PORTFOLIOS_PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (linkType === LinkType.Pai && PAI_PATHS_TO_IGNORE.includes(path)) {
    return false;
  }
  if (isOnboarding && ID_REGEX.test(path)) {
    return false;
  }
  if (isEditTarget && path === 'target-form') {
    return false;
  }
  if (isChart && path === materialStandardId) {
    return false;
  }
  return true;
};
