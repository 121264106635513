import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { ContentHeader, Loader } from 'Molecules';
import { Breadcrumbs } from 'Molecules/Breadcrumbs';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

type ContentLayoutProps = {
  children?: React.ReactNode | React.ReactNode[];
  isLoading?: boolean;
  loadingText?: string;
  header: string | React.ReactNode;
  contentProps?: BoxProps;
  height?: string;
  contentHeight?: string;
  variant?: 'wide' | 'narrow' | 'inline' | 'inline.nopad';
  paddingTop?: string;
  paddingBottom?: string;
  backButton?: boolean | React.ReactNode;
  onBackButtonClick?: () => void;
  onBackNavigate?: () => void;
};

export const LG_PADDING_HEIGHT = '56px';
export const MD_PADDING_HEIGHT = '32px';
export const SM_PADDING_HEIGHT = '16px';

export type BackButtonProps = {
  onClick?: () => void;
};
export const BackButton = ({ onClick }: BackButtonProps) => {
  const navigate = useNavigate();
  const defaultOnClick = () => navigate(-1);
  const navigateOnClick = onClick || defaultOnClick;
  return <IconButton size="md" variant="ghost" onClick={navigateOnClick} aria-label={'Back'} />;
};

export const ContentLayout = ({
  children,
  isLoading = false,
  loadingText,
  header,
  contentProps = {},
  backButton,
  onBackButtonClick,
  onBackNavigate,
  variant = 'wide',
  paddingTop, // paddingTop & paddingBottom are added to make it possible to override default ->
  paddingBottom, // -> padding values locally. Add !important if override doesn't work on all screen sizes.
}: ContentLayoutProps) => {
  let head;
  if (typeof header === 'string') {
    head = <ContentHeader title={header} />;
  } else {
    head = header;
  }

  let variantStyles;
  switch (variant) {
    case 'wide':
      variantStyles = {
        maxWidth: '1560px',
        minWidth: '800px',
        padding: ['16px', '16px', '16px', '16px', '40px'], // Side paddings
        paddingTop: [
          SM_PADDING_HEIGHT,
          SM_PADDING_HEIGHT,
          MD_PADDING_HEIGHT,
          MD_PADDING_HEIGHT,
          MD_PADDING_HEIGHT,
        ],
        paddingBottom: [
          SM_PADDING_HEIGHT,
          SM_PADDING_HEIGHT,
          SM_PADDING_HEIGHT,
          LG_PADDING_HEIGHT,
          LG_PADDING_HEIGHT,
        ],
      };
      break;
    case 'narrow':
      variantStyles = {
        maxWidth: '840px',
        minWidth: '800px',
        padding: ['16px', '16px', '16px', '16px', '16px'], // Side paddings
        paddingTop: [
          SM_PADDING_HEIGHT,
          SM_PADDING_HEIGHT,
          MD_PADDING_HEIGHT,
          LG_PADDING_HEIGHT,
          LG_PADDING_HEIGHT,
        ],
        paddingBottom: [
          SM_PADDING_HEIGHT,
          SM_PADDING_HEIGHT,
          SM_PADDING_HEIGHT,
          LG_PADDING_HEIGHT,
          LG_PADDING_HEIGHT,
        ],
      };
      break;
    case 'inline':
      variantStyles = {
        width: '100%',
        maxWidth: '1560px',
        minWidth: '800px',
        padding: '16px', // Side paddings
        paddingTop: SM_PADDING_HEIGHT,
        paddingBottom: SM_PADDING_HEIGHT,
      };
      break;
    case 'inline.nopad':
      variantStyles = {
        width: '100%',
        maxWidth: '100%',
        padding: '0',
        paddingTop: '0',
        paddingBottom: '0',
      };
  }

  const mergedStyles = {
    ...variantStyles,
    paddingBottom: paddingBottom || variantStyles.paddingBottom,
    paddingTop: paddingTop || variantStyles.paddingTop,
  };

  return (
    <Loader isLoaded={!isLoading} label={loadingText}>
      <Stack
        width="100%"
        gap="0px"
        alignItems={{ base: 'flex-start', lg: 'center' }}
        display="flex"
        flexGrow="1"
      >
        {backButton && (
          <Breadcrumbs onBackButtonClick={onBackButtonClick} onBackNavigate={onBackNavigate} />
        )}
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          flexGrow="1"
          alignSelf={{ base: 'flex-start', lg: 'center' }}
          {...variantStyles}
          {...mergedStyles}
          {...contentProps}
        >
          {head}
          <Suspense>{children}</Suspense>
        </Box>
      </Stack>
    </Loader>
  );
};
