import { useKnowledgeBase } from 'Features/KnowledgeBase';
import { ReportsCard, ReportItem } from 'Molecules/ReportsCard/ReportsCard';
import { usePaiRequests } from 'containers/CompanyPai/CompanyPai.hooks';
import { uniq, uniqBy } from 'lodash';
import { GetPaiRequestersQuery_, GetReportsQuery_ } from 'models';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'utils/translation';

const isPaiReportDone = (
  data: GetReportsQuery_,
  report: GetReportsQuery_['paiCompanyReports'][0]
) => {
  const reportingYear = report?.year;
  const requestsOfSameYear = data?.paiCompanyRequests.filter((request) => {
    const requestYear = request.year;
    return requestYear === reportingYear;
  });
  const allInvesteeMetrics = requestsOfSameYear
    ?.map((request) => {
      const allInvestorMetrics = request.selectedIndicators
        .map((indicator) => indicator.indicator.investorMetrics.map((m) => m.investorMetric))
        .flat();
      const investeeMetrics = allInvestorMetrics
        .map((metric) => metric.investeeMetrics.map((m) => m.investeeMetricReference))
        .flat();
      return investeeMetrics;
    })
    .flat();
  return allInvesteeMetrics.every((metric) =>
    report.answers.some((answer) => answer.investeeMetricReference === metric && !!answer.data)
  );
};

export const HomePaiReportCard = ({
  data,
  companyId,
  onTogglePaiModal,
  isPaiLearnMoreClosed,
  hasPaiAccess,
  paiRequesters,
}: {
  data: GetReportsQuery_ | undefined;
  companyId: string;
  onTogglePaiModal: (value: boolean) => void;
  isPaiLearnMoreClosed: boolean;
  hasPaiAccess: boolean;
  paiRequesters: GetPaiRequestersQuery_ | undefined;
}) => {
  const { t } = useTranslation('home');
  const { onOpen } = useKnowledgeBase();
  const navigate = useNavigate();
  const { requests, loading: isLoadingReports } = usePaiRequests(companyId ?? undefined);

  const paiReports = useMemo(() => {
    const pai: Array<ReportItem & { period?: string }> =
      (data &&
        requests?.map((r) => ({
          id: r.report?.id,
          title: String(r.year),
          period: r.period,
          status: r.report && isPaiReportDone(data, r.report) ? 'done' : 'todo',
          count: uniq(
            r.requests
              .map((request) => request.selectedIndicators)
              .flat()
              .filter(
                (s, _, array) =>
                  s.isNew === true &&
                  !array.some(
                    (a) => a.indicator.reference === s.indicator.reference && a.isNew === false
                  )
              )
              .map((selected) =>
                selected.indicator.investorMetrics
                  .map((metric) =>
                    metric.investorMetric.investeeMetrics.map(
                      (investee) => investee.metric.reference
                    )
                  )
                  .flat()
              )
              .flat()
          ).filter(
            (m) => r.report && r.report?.answers.every((a) => a.investeeMetricReference !== m)
          ).length,
        }))) ??
      [];

    return pai;
  }, [data, requests]);

  return (
    <ReportsCard
      title={t('home:pai.title')}
      subTitle={t('home:emptyState.pai.subTitle')}
      description={t('home:pai.description')}
      type="pai"
      buttonText={t('home:emptyState.pai.infoButton')}
      onLearnMoreClick={() => onOpen('What are PAI indicators')}
      onDismissClick={() => onTogglePaiModal(true)}
      isContentVisible={!isPaiLearnMoreClosed}
      requestedBy={
        uniqBy(
          paiRequesters?.requesters.map((r) => ({
            id: r.portfolio?.ownerCompany?.id,
            name: r.portfolio?.ownerCompany?.name,
            logoUrl: r.portfolio?.ownerCompany?.logoUrl ?? undefined,
          })),
          'id'
        ) ?? []
      }
      reportsList={
        hasPaiAccess
          ? paiReports.map((report) => ({
              ...report,
              onClick: () => navigate(`pai/${report.title}/${report?.period}`),
            }))
          : []
      }
      emptyState={{
        title: t('home:emptyState.pai.title'),
        description: t('home:emptyState.pai.description'),
      }}
      onAllReportClick={() => navigate('pai')}
      isLoaded={!isLoadingReports}
    />
  );
};
