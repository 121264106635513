import {
  Table as ChakraTable,
  Thead,
  Th,
  VStack,
  Tbody,
  Tr,
  Td,
  HStack,
  Circle,
  useColorMode,
} from '@chakra-ui/react';
import { PaiPortfolioReport } from 'models';
import { PieChart } from 'react-minimal-pie-chart';
import { colors, Typography } from 'Tokens';
import { formatNum } from 'utils/numbers';
import { calculateMetricResults, getMetricProgress } from 'utils/scores/pai';
import { PaiResults, mapUnitToCompanyCurrencyPAI } from './PortfolioPai.hooks';
import { ReportPeriods } from 'Features/PortfolioView';
import { useCurrentCompany } from 'utils/hooks';
import { useMemo } from 'react';

export const AnswersCollectedProgress = ({ progress }: { progress: number }) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const pieChartData = useMemo(() => {
    const collectedColor = colors['bg.notEligible.accent'][color];
    const missingColor = colors['bg.unknown'][color];
    return [
      {
        title: 'Collected',
        value: progress,
        color: collectedColor,
      },
      {
        title: 'Missing',
        value: 100 - progress,
        color: missingColor,
      },
    ];
  }, [color, progress]);

  return (
    <HStack spacing="8px">
      <Circle size="16px" alignItems="center">
        <PieChart data={pieChartData} lineWidth={26} />
      </Circle>
      <Typography variant="body" color="text.muted">
        {formatNum(progress)}%
      </Typography>
    </HStack>
  );
};

export const IndicatorRow = ({
  indicator,
  results,
  reportPeriod,
  companyCount,
}: {
  results: PaiResults;
  indicator: PaiPortfolioReport['selectedIndicators'][number]['indicator'];
  reportPeriod: ReportPeriods;
  companyCount: number;
}) => {
  const { company } = useCurrentCompany();
  const isSingleMetric = indicator.investorMetrics?.length === 1;

  const indicatorTitle = (
    <VStack spacing="2px" alignItems="flex-start">
      <Typography variant="bodyStrong">{indicator?.title}</Typography>
      <Typography variant="detail" color="text.muted">
        {indicator?.category.table.isCustom ? <>Other indicators &#8212; </> : <>PAI &#8212; </>}
        {indicator?.category.table.title}: {indicator?.category.title}
      </Typography>
    </VStack>
  );
  const reportingPeriodDataCollection = (
    <Typography color="text.muted" variant="body" alignContent="flex-end">
      {reportPeriod === ReportPeriods.year ? 'Yearly' : 'Quarterly'}
    </Typography>
  );

  const renderSingleMetric = () => {
    const investorMetric = indicator.investorMetrics?.[0]?.investorMetric;
    const { result: metricResult, hasUnits } = calculateMetricResults(investorMetric, results);
    const unitCurrency = mapUnitToCompanyCurrencyPAI(investorMetric?.unit, company?.currency);
    const showUnitCurrency = hasUnits ? unitCurrency : '';

    return (
      <Tr>
        <Td>{indicatorTitle}</Td>
        <Td>
          <Typography color="text.muted" variant="body">
            {metricResult} {showUnitCurrency}
          </Typography>
        </Td>
        <Td>
          <VStack spacing="24px" alignItems="flex-start">
            {indicator.investorMetrics.map((metric) => (
              <AnswersCollectedProgress
                progress={getMetricProgress(metric.investorMetric, results, companyCount)}
              />
            ))}
          </VStack>
        </Td>
        <Td>{reportingPeriodDataCollection}</Td>
      </Tr>
    );
  };

  const renderMultipleMetrics = () => {
    return (
      <>
        {indicator.investorMetrics.map((metric, index) => {
          const investorMetric = metric?.investorMetric;
          const { result: metricResult, hasUnits } = calculateMetricResults(
            investorMetric,
            results
          );
          const unitCurrency = hasUnits
            ? mapUnitToCompanyCurrencyPAI(investorMetric?.unit, company?.currency)
            : '';
          return (
            <Tr>
              <Td
                textOverflow="clip"
                border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}
              >
                {index === 0 && indicatorTitle}
                <HStack paddingLeft="24px" paddingTop="12px">
                  <Typography height="100%" whiteSpace="initial" variant="bodyStrong">
                    {investorMetric?.title}
                  </Typography>
                </HStack>
              </Td>
              <Td border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}>
                <HStack paddingTop={index === 0 ? '68px' : '12px'}>
                  <Typography color="text.muted" variant="body">
                    {metricResult} {unitCurrency}
                  </Typography>
                </HStack>
              </Td>
              <Td border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}>
                <HStack paddingTop={index === 0 ? '68px' : '12px'}>
                  <AnswersCollectedProgress
                    progress={getMetricProgress(investorMetric, results, companyCount)}
                  />
                </HStack>
              </Td>
              <Td border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}>
                <HStack paddingTop={index === 0 ? '68px' : '12px'}>
                  {reportingPeriodDataCollection}
                </HStack>
              </Td>
            </Tr>
          );
        })}
      </>
    );
  };

  return isSingleMetric ? renderSingleMetric() : renderMultipleMetrics();
};

export const IndicatorsResultsTable = ({
  selectedIndicators,
  results,
  reportPeriod,
}: {
  selectedIndicators: PaiPortfolioReport['selectedIndicators'];
  results: PaiResults | undefined;
  reportPeriod: ReportPeriods;
}) => {
  return (
    <VStack width="100%" alignItems="stretch">
      <ChakraTable size="sm">
        <Thead letterSpacing="normal">
          <Th>Indicator</Th>
          <Th>
            {reportPeriod === ReportPeriods.year ? 'Impact this year' : 'Impact this quarter'}
          </Th>
          <Th>Answers collected</Th>
          <Th>Data Collected</Th>
        </Thead>

        <Tbody>
          {selectedIndicators.map(
            ({ indicator, isForAllCompanies, paiReportIndicatorPortfolioCompanies }) => {
              const filteredPaiReportIndicatorPortfolioCompanies =
                paiReportIndicatorPortfolioCompanies.filter((pc) =>
                  results
                    ?.map((result) => result.company.id)
                    .includes(pc.portfolioCompany?.company?.id)
                );
              const companyCount: number =
                (isForAllCompanies
                  ? results?.length
                  : filteredPaiReportIndicatorPortfolioCompanies?.length) ?? 1;
              const filteredResults = isForAllCompanies
                ? results
                : results?.filter((result) =>
                    paiReportIndicatorPortfolioCompanies
                      .map((c) => c.portfolioCompany?.company?.id)
                      .includes(result.company.id)
                  );
              return (
                <IndicatorRow
                  indicator={indicator}
                  results={filteredResults ?? []}
                  reportPeriod={reportPeriod}
                  companyCount={companyCount}
                />
              );
            }
          )}
        </Tbody>
      </ChakraTable>
    </VStack>
  );
};
