import { Box, HStack, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Avatar, EmptyState, Infobox, Input, Tag, VisibleIf } from 'Atoms';
import { NothingFoundIllustration, Typography } from 'Tokens';
import { Modal, Table } from 'Molecules';
import { TFunction, useTranslation } from 'utils/translation';
import { useEffect, useMemo, useState } from 'react';
import { useUserData } from '@nhost/react';
import {
  CELSIA_SUPPORT_TAG,
  isCelsiaSupport,
  isServiceAccount,
  SERVICE_ACCCOUNT_TAG,
  PENDING_USER_TAG,
} from 'utils/users';
import { DeleteIcon, EditIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { TeamMember, TeamMembersFilterOption, UserStatus, useUserRole } from '..';
import { Menu } from 'Molecules/Menu';
import {
  UserRole_Enum_,
  useDeleteCompanyUserMutation,
  useUpdateCompanyInvitationMutation,
  useUpdateCompanyUserMutation,
} from 'models';
import { RoleSelector } from './RoleSelector';
import { useCurrentCompany, useToast } from 'utils/hooks';

const getUserRole = (member: TeamMember, t: TFunction) => {
  const celsiaSupport = isCelsiaSupport(member.email);
  const serviceAccount = isServiceAccount(member.email);

  if (celsiaSupport) {
    return CELSIA_SUPPORT_TAG;
  }
  if (serviceAccount) {
    return SERVICE_ACCCOUNT_TAG;
  }

  if (member.role === UserRole_Enum_.Admin_) {
    return t('common:words.admin');
  }
  if (member.role === UserRole_Enum_.Auditor_) {
    return t('common:words.auditor');
  }
  if (member.role === UserRole_Enum_.Editor_) {
    return t('common:words.editor');
  }

  return PENDING_USER_TAG;
};

const getUserStatus = (member: TeamMember): UserStatus => {
  const isUserDisabled = !!member.disabled;
  const isUserPending = member.type === 'invitation';
  if (isUserDisabled) {
    return UserStatus.Deactivated;
  }
  if (isUserPending) {
    return UserStatus.Pending;
  }
  return UserStatus.Active;
};

export const TeamMembersTable = ({
  members,
  setFilterBy,
}: {
  members: TeamMember[];
  setFilterBy: (s: TeamMembersFilterOption) => void;
}) => {
  const user = useUserData();
  const { t } = useTranslation('common');
  const toast = useToast();
  const { company } = useCurrentCompany();
  const { role: currentUserRole } = useUserRole();

  const [memberToEdit, setMemberToEdit] = useState<TeamMember>();
  const [roleToSet, setRoleToSet] = useState<UserRole_Enum_>();

  const [updateCompanyInvitation] = useUpdateCompanyInvitationMutation();
  const [updateCompanyUser] = useUpdateCompanyUserMutation();
  const [deleteCompanyUser] = useDeleteCompanyUserMutation();

  const handleRemoveMember = (member: TeamMember) => {
    if (member.type === 'invitation') {
      return updateCompanyInvitation({
        variables: {
          id: member.id,
          companyInvitation: {
            status: 'disabled',
          },
        },
        refetchQueries: ['CompanyInvitations'],
      })
        .then(() => toast({ text: 'Invitation removed' }))
        .catch(() => {
          toast({ text: 'Failed to remove invitation', variant: 'danger' });
        });
    }

    return deleteCompanyUser({
      variables: {
        companyId: company?.id,
        userId: member.id,
      },
      refetchQueries: ['CompanyUsers'],
    })
      .then(() => toast({ text: 'User removed' }))
      .catch(() => {
        toast({ text: 'Failed to remove user', variant: 'danger' });
      });
  };

  const handleEditMemberRole = (
    member: TeamMember | undefined,
    role: UserRole_Enum_ | undefined
  ) => {
    if (!member || !role) return;
    if (member.type === 'invitation') {
      return updateCompanyInvitation({
        variables: {
          id: member.id,
          companyInvitation: {
            role,
          },
        },
        refetchQueries: ['CompanyInvitations'],
      })
        .then(() => toast({ text: 'Role updated' }))
        .catch(() => {
          toast({ text: 'Failed to update role', variant: 'danger' });
        });
    }

    return updateCompanyUser({
      variables: {
        companyId: company?.id,
        userId: member.id,
        role,
      },
      refetchQueries: ['CompanyUsers'],
    })
      .then(() => toast({ text: 'Role updated' }))
      .catch(() => {
        toast({ text: 'Failed to update role', variant: 'danger' });
      });
  };

  useEffect(() => {
    if (memberToEdit) {
      setRoleToSet(memberToEdit.role);
    }
  }, [memberToEdit, setRoleToSet]);

  const columns = useMemo(() => {
    const cols: ColumnDef<TeamMember>[] = [
      {
        header: t('common:fields.userName.label'),
        accessorKey: 'name',
        cell: ({ row }) => {
          const active = row.original.type === 'user' && !row.original.disabled;
          return (
            <HStack spacing="16px">
              <Avatar
                size="sm"
                name={row.original?.name ?? row.original?.email}
                color={active ? 'text.onAccent' : 'text.default'}
                backgroundColor={active ? 'bg.accent' : 'bg.disabled.accent'}
              />
              {active ? (
                <VStack width="100%" alignItems="flex-start">
                  <Typography variant="bodyStrong">
                    {row.original?.name} {user?.email === row.original.email ? '(You)' : ''}
                  </Typography>
                  <Typography variant="micro">{row.original?.email}</Typography>
                </VStack>
              ) : (
                <VStack width="100%" alignItems="flex-start" justifyContent="center">
                  <Typography variant="bodyStrong" color="text.hint">
                    {row.original?.email}
                  </Typography>
                </VStack>
              )}
            </HStack>
          );
        },
      },
      {
        header: t('common:fields.userRole.label'),
        id: 'role',
        cell: ({ row }) => (
          <Typography variant="bodyStrong" color="hint">
            {getUserRole(row.original, t)}
          </Typography>
        ),
      },
      {
        header: t('common:status'),
        id: 'status',
        cell: ({ row }) => <Tag>{t(`common:memberStatus.${getUserStatus(row.original)}`)}</Tag>,
      },
      {
        header: ' ',
        id: 'actions',
        cell: ({ row }) => (
          <VisibleIf condition={!row.original.disabled}>
            <Menu
              key={row.original.id}
              sections={[
                {
                  actions: [
                    {
                      id: 'edit-role',
                      title: 'Edit role',
                      onClick: () => setMemberToEdit(row.original),
                      leftElement: <EditIcon color="inherit" />,
                      isDisabled: currentUserRole !== UserRole_Enum_.Admin_,
                    },
                    {
                      id: 'remove',
                      title: 'Remove',
                      variant: 'destructive',
                      onClick: () => handleRemoveMember(row.original),
                      leftElement: <DeleteIcon color="inherit" />,
                      isDisabled:
                        currentUserRole !== UserRole_Enum_.Admin_ &&
                        user?.email !== row.original.email,
                    },
                  ],
                },
              ]}
            />
          </VisibleIf>
        ),
      },
    ];
    return cols;
  }, [t, currentUserRole]);

  if (!members.length) {
    return (
      <Box w="100%" flexGrow="1" mt="16px">
        <EmptyState
          title={t('common:search.filter.emptyTitle')}
          description={t('common:search.filter.emptyDescription')}
          callToAction={{
            text: t('common:search.filter.emptyBtn'),
            variant: 'secondary',
            onClick: () => {
              setFilterBy(TeamMembersFilterOption.All);
            },
            leftIcon: <RefreshIcon color="inherit" />,
          }}
          icon={<NothingFoundIllustration boxSize="120px" />}
        />
      </Box>
    );
  }

  return (
    <>
      <Table<TeamMember> columns={columns} data={members} />
      <Modal
        isOpen={!!memberToEdit}
        onClose={() => setMemberToEdit(undefined)}
        onConfirm={() =>
          handleEditMemberRole(memberToEdit, roleToSet)?.then(() => setMemberToEdit(undefined))
        }
        title="Edit user role"
        confirmButtonProps={{
          isDisabled: memberToEdit?.role === roleToSet,
        }}
      >
        <Infobox
          status="neutral"
          withIcon={false}
          closable={false}
          minHeight="unset"
          mb="16px"
          description={
            <Typography variant="body">
              The role change will only be effective in {company?.name ?? 'current company'}
            </Typography>
          }
        />
        <HStack width="100%" spacing="4px">
          <Input value={memberToEdit?.email} isDisabled width="100%" />
          <RoleSelector value={roleToSet} onChange={setRoleToSet} />
        </HStack>
      </Modal>
    </>
  );
};
