import { VStack } from '@chakra-ui/react';
import { AutoResizeTextarea } from 'Atoms';
import { Loader } from 'Molecules';
import { Typography } from 'Tokens';
import { UpsertMdrmAnswersMutation_ } from 'models';
import { useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks';
import { OnMdrmAnswerChangeType, useMdrmAnswers } from './MetricMdrm.hooks';
import { FetchResult } from '@apollo/client';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { TimePeriods } from '../../Requirement';

export const MDRM_QUESTIONS: MdrmQuestionType[] = [
  {
    id: 'metricUsed',
    question:
      'Description of metric used to evaluate performance and effectiveness, in relation to material impact, risk or opportunity',
  },
  {
    id: 'methodology',
    question: 'Disclosure of methodologies and significant assumptions behind metric',
  },
  {
    id: 'validation',
    question: 'Type of external body other than assurance provider that provides validation',
  },
];

type MdrmQuestionType = {
  id: MdrmType;
  question: string;
};

export type MdrmAnswers = {
  metricUsed: string | null;
  methodology: string | null;
  validation: string | null;
};

export type MdrmType = 'metricUsed' | 'methodology' | 'validation';

const MdrmQuestion = ({
  question,
  mdrmId,
  onMdrmAnswerChange,
  answer,
}: {
  question: string;
  mdrmId: MdrmType;
  answer: string;
  onMdrmAnswerChange: OnMdrmAnswerChangeType;
}) => {
  const [localValue, setLocalValue] = useState<string>(answer);

  const debouncedValue = useDebounce(localValue, 500);

  useEffect(() => {
    setLocalValue(answer);
  }, [answer]);

  useEffect(() => {
    if (debouncedValue !== answer) onMdrmAnswerChange(debouncedValue ?? '', mdrmId);
  }, [debouncedValue]);

  return (
    <VStack spacing="8px" alignItems="start">
      <Typography variant="bodyStrong">{question}</Typography>
      <AutoResizeTextarea
        minH="80px"
        onChange={({ target }) => setLocalValue(target.value)}
        placeholder="Add your answer"
        value={localValue ?? answer ?? ''}
      />
    </VStack>
  );
};

export const MetricMdrm = ({
  metricRef,
  datapointId,
  row,
  companyReportingUnit,
  timeframe,
}: {
  metricRef: string;
  datapointId: string;
  row: MetricsTableData;
  companyReportingUnit?: string;
  timeframe?: TimePeriods;
}) => {
  const { answers, loading, onMdrmAnswerChange } = useMdrmAnswers({
    datapointId,
    metricRef,
    row,
    companyReportingUnit,
    timeframe,
  });

  if (loading) return <Loader height="300px" />;

  return (
    <VStack px="20px" py="24px" spacing="24px" alignItems="start">
      {MDRM_QUESTIONS.map((q) => {
        const answer = answers?.[q.id] ?? '';
        return (
          <MdrmQuestion
            key={q.id}
            question={q.question}
            mdrmId={q.id}
            onMdrmAnswerChange={onMdrmAnswerChange}
            answer={answer}
          />
        );
      })}
    </VStack>
  );
};
