import {
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  VerticalAlign,
  TextRun,
  LevelFormat,
  AlignmentType,
  convertInchesToTwip,
  Footer,
  PageNumber,
  NumberFormat,
} from 'docx';
import {
  AggregatedMetricsExtraData,
  AggregatedMetricsTableData,
} from '../../DisclosureRequirements';
import {
  DocumentInput,
  EsrsReportCategory,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
  SimplifiedReportNarrativeMetric,
} from '../Report.types';
import { styles, tableBorders, TextStyle } from './reportThemes';
import { MdrmMetricAnswers } from '../MetricAnswerCalculation';
import { MDRM_QUESTIONS } from '../../DisclosureRequirements/Metrics/MetricSidebar/MetricMdrm';
import { ORDER_OF_CATEGORIES } from 'utils/categoriesOrder';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { TableData } from 'Molecules/NestedTable';
import { QuestionType_Enum_ } from 'models';
import { simplifyNarrativeMetrics } from '../Report.hooks';

const getRowTitle = (row: AggregatedMetricsExtraData) => {
  const isTag = !!row.tags?.length;
  const metricTitle = row.metric?.shortTitle ?? row.metric?.title;
  if (row.tagName) {
    return row.tagName;
  } else if (isTag) {
    return row.tags?.[0]?.tagValue;
  } else {
    return `${metricTitle}${
      row.tagType && row.tagType !== 'undefined' ? ` by ${row.tagType} ` : ''
    }`;
  }
};

const getTableRowText = (row: TableData<AggregatedMetricsExtraData>) => {
  const percentage = row.percentage;
  const result = row.result?.Year ?? 0;
  const isResultNaN = isNaN(result);
  const parsedResult = isResultNaN ? 0 : parseFloat(result.toFixed(2));
  const stringifiedResult = String(parsedResult) ?? '--';

  return percentage ? stringifiedResult + ` (${percentage}%)` : stringifiedResult;
};

const addEmptySpace = (spacing: number): Paragraph => {
  return new Paragraph({
    text: '',
    spacing: {
      after: spacing,
    },
  });
};

export class EsrsDocumentCreator {
  public async create(input: DocumentInput, companyCurrency: string): Promise<Document> {
    const document = new Document({
      creator: 'Celsia.io',
      revision: 1,
      subject: 'Esrs report',
      title: `${input.data.companyName} - ESRS report ${input.data.reportingYear}`,
      styles: styles,
      numbering: {
        config: [
          {
            reference: 'number-system',
            levels: [
              {
                level: 0,
                format: LevelFormat.UPPER_ROMAN,
                text: '%1.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.25), hanging: convertInchesToTwip(0.25) },
                  },
                },
              },
            ],
          },
        ],
      },
      sections: [
        {
          properties: {
            page: {
              margin: {
                left: convertInchesToTwip(1.25),
                right: convertInchesToTwip(1.25),
              },
              pageNumbers: {
                start: 1,
                formatType: NumberFormat.DECIMAL,
              },
            },
          },
          children: this.createSections(
            {
              categories: input.data.categories,
              companyName: input.data.companyName,
              reportingYear: input.data.reportingYear,
            },
            companyCurrency
          ),
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      children: [PageNumber.CURRENT],
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          },
        },
      ],
    });

    return document;
  }

  public createSections(
    {
      companyName,
      reportingYear,
      categories,
    }: {
      companyName: string;
      reportingYear: number;
      categories: EsrsReportCategory[];
    },
    companyCurrency: string
  ) {
    return [
      new Paragraph({
        text: `ESRS sustainability statement for ${companyName} ${reportingYear}`,
        style: TextStyle.title,
        spacing: {
          after: 400,
        },
      }),
      this.createCategories(categories ?? [], companyCurrency),
    ].flat();
  }

  public createCategories(categories: EsrsReportCategory[], companyCurrency: string) {
    const visibleCategories = categories
      .filter((category) =>
        category.standards.some((standard) =>
          standard.disclosureRequirements.some(
            (dr) => !dr.isHidden && dr.metrics.some((metric) => metric.completed)
          )
        )
      )
      .sort((a, b) => ORDER_OF_CATEGORIES[a.title] - ORDER_OF_CATEGORIES[b.title]);
    const displayedCategories = visibleCategories.flatMap((category) => {
      return [
        new Paragraph({
          text: category.title,
          style: TextStyle.h1,
          spacing: {
            after: 300,
          },
          numbering: {
            level: 0,
            reference: 'number-system',
          },
        }),
        this.createStandards(category.standards, companyCurrency),
      ];
    });
    return displayedCategories.flat();
  }

  public createStandards(standards: EsrsReportStandard[], companyCurrency: string) {
    const visibleStandards = standards
      .filter(
        (standard) =>
          !standard.disclosureRequirements.every(
            (dr) => dr.isHidden || dr.metrics.every((metric) => metric.metric.isHidden)
          )
      )
      .sort((a, b) => a.reference.localeCompare(b.reference));
    const displayedStandards = visibleStandards.flatMap((standard) => {
      return [
        new Paragraph({
          text: `${standard.reference} ${standard.title}`,
          style: TextStyle.h2,
          spacing: {
            after: 200,
          },
        }),
        this.createDisclosures(standard.disclosureRequirements, companyCurrency),
      ];
    });
    return displayedStandards.flat();
  }

  public createDisclosures(
    disclosureRequirements: EsrsReportDisclosureRequirement[],
    companyCurrency: string
  ) {
    const visibleDisclosureRequirements = disclosureRequirements.filter((dr) =>
      dr.metrics.some((metric) => !metric.metric.isHidden)
    );
    const displayedDisclosureRequirements = visibleDisclosureRequirements
      .sort((a, b) => a.order - b.order)
      .flatMap((dr) => {
        return [
          new Paragraph({
            text: `${dr.reference} – ${dr.title}`,
            style: TextStyle.h3,
            spacing: {
              after: 150,
            },
          }),
          this.createMetrics(dr.metrics, companyCurrency),
        ];
      });
    return displayedDisclosureRequirements.flat();
  }

  public createMetrics(metrics: EsrsReportMetric[], companyCurrency: string) {
    const visibleMetrics = metrics.filter((metric) => !metric.metric.isHidden);

    const narrativeMetrics = visibleMetrics.filter(
      (m) => m.metric.metric.metricType === QuestionType_Enum_.LongText_
    );

    const quantitativeMetrics = visibleMetrics.filter(
      (m) => m.metric.metric.metricType !== QuestionType_Enum_.LongText_
    );

    const quantitativeMetricsSection = this.createQuantitativeMetrics(
      quantitativeMetrics,
      companyCurrency
    );
    const narrativeMetricsSection = this.createNarrativeMetrics(narrativeMetrics);

    return [quantitativeMetricsSection, narrativeMetricsSection].flat();
  }

  public createQuantitativeMetrics(
    quantitativeMetrics: EsrsReportMetric[],
    companyCurrency: string
  ) {
    const displayedMetrics = quantitativeMetrics.flatMap((metric) => {
      const { shortTitle, title } = metric.metric.metric;

      const hasAnswer = metric.tableData?.result?.Year;

      const metricHeader = hasAnswer
        ? []
        : new Paragraph({
            text: shortTitle ?? title,
            style: TextStyle.h4,
            spacing: {
              after: 100,
            },
          });

      const mdrmSection = this.createMdrm(metric);
      const metricTableSection = this.createMetricTable(metric, companyCurrency);

      return [mdrmSection, metricHeader, metricTableSection, addEmptySpace(250)];
    });

    return displayedMetrics.flat();
  }

  public createMetricTable(metric: EsrsReportMetric, companyCurrency: string) {
    const quantitativeMetricTable = new Table({
      columnWidths: [4000, 2000, 2000],
      margins: {
        top: 60,
        bottom: 60,
        right: 60,
        left: 60,
      },
      width: {
        size: '100%',
        type: WidthType.PERCENTAGE,
      },
      borders: tableBorders,
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '60%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Metric',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '20%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Unit',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '20%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Total',
                  style: TextStyle.tableTitle,
                }),
              ],
            }),
          ],
        }),
        this.createDataPointRows(metric, companyCurrency),
      ].flat(),
    });

    if (metric.metric.metric.metricType === QuestionType_Enum_.LongText_) return [];

    return quantitativeMetricTable;
  }

  public createDataPointRows(metric: EsrsReportMetric, companyCurrency: string) {
    const metricResults = metric.tableData;
    const metricInfo = metric.metric.metric;

    const displayedRows = [
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: metricInfo.shortTitle ?? metricInfo.title,
                style: TextStyle.body,
                children: [
                  new TextRun({
                    text: metricResults.tagType ? ` by ${metricResults.tagType}` : '',
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: metricInfo.unitOfMeasurement
                  ? mapUnitToCompanyCurrency(metricInfo.unitOfMeasurement, companyCurrency)
                  : 'NA',
                style: TextStyle.body,
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: getTableRowText(metric.tableData),
                style: TextStyle.body,
              }),
            ],
          }),
        ],
      }),
      this.createNestedDataPointsRows(metricResults, companyCurrency),
    ];
    return displayedRows.flat();
  }

  public createNestedDataPointsRows(
    metricResults: AggregatedMetricsTableData,
    companyCurrency: string,
    depth = 1
  ) {
    const subRows = metricResults.subRows;

    if (!subRows?.length) return [];

    const nestedRows: TableRow[] =
      subRows?.flatMap((row) => {
        return [
          new TableRow({
            children: [
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    indent: {
                      start: depth * 220,
                    },
                    text: getRowTitle(row),
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: row.metric.unitOfMeasurement
                      ? mapUnitToCompanyCurrency(row.metric.unitOfMeasurement, companyCurrency)
                      : 'NA',
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: getTableRowText(row),
                    style: TextStyle.body,
                  }),
                ],
              }),
            ],
          }),
          ...(this.createNestedDataPointsRows(row, companyCurrency, depth + 1) ?? []),
        ];
      }) ?? [];

    return nestedRows.flat();
  }

  public createMdrm(metric: EsrsReportMetric) {
    const isNarrativeMetric = metric.metric.metric.metricType === QuestionType_Enum_.LongText_;

    if (isNarrativeMetric) return [];

    const metricMdrmDataPoints = metric.mdrmAnswers;

    const displayedMdrm = metricMdrmDataPoints.flatMap((mdrm) => {
      const { shortTitle, title } = mdrm.metric;

      const mdrmAnswersArray = Object.values(mdrm.answers);
      const noMdrmAnswered = mdrmAnswersArray.every((answer) => !answer || answer === '');
      if (noMdrmAnswered) return [];

      return [
        new Paragraph({
          text: shortTitle ?? title,
          style: TextStyle.h4,
          spacing: {
            after: 100,
          },
        }),
        this.createMetricMdrmAnswers(mdrm),
        addEmptySpace(100),
      ];
    });

    return displayedMdrm.flat();
  }

  public createMetricMdrmAnswers(mdrmMetricAnswers: MdrmMetricAnswers) {
    const displayedAnswers = MDRM_QUESTIONS.flatMap((q) => {
      const answer = mdrmMetricAnswers.answers?.[q.id];

      if (!!answer) {
        return [
          new Paragraph({
            text: answer,
            style: TextStyle.body,
            spacing: {
              after: 100,
            },
          }),
        ];
      }

      return [];
    });

    return displayedAnswers.flat();
  }

  public createNarrativeMetrics(narrativeMetrics: EsrsReportMetric[]) {
    const filteredMetrics = simplifyNarrativeMetrics(narrativeMetrics);

    const displayedMetrics = filteredMetrics.flatMap((metric) => {
      const narrativeMetricSection = this.createNarrativeMetric(metric, true);

      return [narrativeMetricSection, addEmptySpace(200)];
    });

    return displayedMetrics.flat();
  }

  public createNarrativeMetric(metric: SimplifiedReportNarrativeMetric, isTopLevel: boolean) {
    const { shortTitle, title } = metric.materialMetric.metric;

    const titleParagraph = new Paragraph({
      text: shortTitle ?? title,
      style: TextStyle.h4,
      spacing: {
        after: 100,
      },
    });

    const hasAnswer = !!metric?.textAnswer && metric?.textAnswer !== '';

    // Split answers with multiple lines into paragraphs
    const answerParagraphs = this.createNarrativeAnswer(metric, hasAnswer);

    if (metric.children.length > 0) {
      const nestedChildren: Paragraph[] = (
        metric.children.flatMap((child) => {
          const narrativeMetricSection = this.createNarrativeMetric(child, false);

          return [narrativeMetricSection, addEmptySpace(100)];
        }) ?? []
      ).flat();

      return [titleParagraph, nestedChildren].flat();
    }

    return isTopLevel || !hasAnswer ? [titleParagraph, ...answerParagraphs] : [...answerParagraphs];
  }

  public createNarrativeAnswer(metric: SimplifiedReportNarrativeMetric, hasAnswer: boolean) {
    const textAnswer = metric.textAnswer ?? '';
    const answerLines = (hasAnswer ? textAnswer.split('\n') : ['No answer provided.']) ?? [];

    const answerParagraphs = answerLines.map(
      (line) =>
        new Paragraph({
          text: line,
          style: hasAnswer ? TextStyle.body : TextStyle.bodyItalic,
          spacing: {
            afterAutoSpacing: true,
          },
        })
    );

    return answerParagraphs;
  }
}
