import { useState, useEffect, useMemo, useCallback } from 'react';
import { DatapointFieldsFragment_ } from 'models';
import {
  areArraysOfObjectsEqual,
  isFrequencyYearly,
  MetricsTableData,
  useAggregatedRowValue,
  useEsrsAnswer,
  usePercentageValues,
} from '../MetricAnswers.hooks';
import { QUARTERS_FIELDS, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { HStack, NumberInput, NumberInputField, Skeleton } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { showResult } from '../AggregatedMetrics/calculations';
import { PercentageValueView } from './MetricYear';
import { QuestionType } from 'utils/scores/questions';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

export const useGetDatapointValues = (row: MetricsTableData, companyReportingUnit?: string) => {
  const { answer, datapoints, onDatapointChange, loading } = useEsrsAnswer(
    row.metric?.reference,
    companyReportingUnit
  );

  const dataPointsPerQuarter = useMemo(() => {
    return QUARTERS_FIELDS.map((field) => {
      const correspondingDatapoint = datapoints?.find((datapoint) =>
        row?.tags?.length
          ? areArraysOfObjectsEqual(row?.tags, datapoint.datapointTags) &&
            datapoint.timeframe === field
          : datapoint.timeframe === field && !datapoint.datapointTags.length
      );
      return { field: field, value: correspondingDatapoint };
    });
  }, [datapoints]);

  const dataPointPerYear = useMemo(() => {
    if (row.metric?.metricType === QuestionType.LongText_) {
      return datapoints?.find((datapoint) => datapoint.timeframe === TimePeriodsEnums.year);
    }
    const correspondingDatapoint = datapoints?.find((datapoint) =>
      row?.tags?.length
        ? areArraysOfObjectsEqual(row?.tags, datapoint.datapointTags) &&
          datapoint.timeframe === TimePeriodsEnums.year
        : datapoint.timeframe === TimePeriodsEnums.year && !datapoint.datapointTags.length
    );
    return correspondingDatapoint;
  }, [datapoints]);

  const isAggregated = useMemo(() => {
    return !!row?.subRows?.length;
  }, [row]);

  const datapointText = useMemo(
    () => (row.metric?.metricType === QuestionType.LongText_ ? datapoints[0] : undefined),
    [datapoints]
  );

  return {
    answer,
    datapoints,
    onDatapointChange,
    loading,
    dataPointsPerQuarter,
    dataPointPerYear,
    datapointText,
    isAggregated,
  };
};

const DatapointInput = ({
  hasOptedOut,
  updateDatapoint,
  value,
  datapoint,
}: {
  value?: number;
  hasOptedOut: boolean;
  datapoint?: DatapointFieldsFragment_;
  updateDatapoint: (value: number | null, datapoint?: DatapointFieldsFragment_) => void;
}) => {
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  // Use a local state for the input value to ensure it's controlled.
  const [inputValue, setInputValue] = useState<number | undefined>(value ?? undefined);

  // Update the local input value whenever the external value changes.
  useEffect(() => {
    if (!hasStartedTyping) setInputValue(value ?? undefined);
  }, [value]);

  const debounceSave = useCallback(
    debounce((newValue) => {
      updateDatapoint(newValue, datapoint);
      setHasStartedTyping(false);
    }, 500),
    [updateDatapoint, datapoint]
  );

  const handleChange = (val: string) => {
    setHasStartedTyping(true);
    const newValue = Number.isNaN(Number(val)) ? undefined : Number(val);
    setInputValue(newValue);
    debounceSave(newValue);
  };

  return (
    <NumberInput
      variant="ghost"
      width="100%"
      value={inputValue}
      clampValueOnBlur
      onChange={(val) => handleChange(val)}
      onKeyPress={(event) => {
        if (event.key === '-') {
          event.preventDefault();
        }
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <NumberInputField
        width="100%"
        textAlign="left"
        opacity={hasOptedOut ? 0.4 : 1}
        disabled={hasOptedOut}
        placeholder="0"
      />
    </NumberInput>
  );
};

export const QuarterInput = ({
  row,
  selectedQuarter,
  isOverview,
  companyStandardId,
  companyReportingUnit,
  assessmentProjectLeaderId,
  setRowData,
}: {
  row: MetricsTableData;
  selectedQuarter: TimePeriods;
  companyStandardId: string;
  isOverview?: boolean;
  companyReportingUnit?: string;
  assessmentProjectLeaderId?: string;
  setRowData?: (param: MetricsTableData | undefined) => void;
}) => {
  const [searchParams] = useSearchParams();
  const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const {
    answer,
    onDatapointChange,
    loading,
    dataPointsPerQuarter,
    dataPointPerYear,
    isAggregated,
  } = useGetDatapointValues(row, companyReportingUnit);

  const isYearly = useMemo(
    () => isFrequencyYearly(row, companyStandardId),
    [row, companyStandardId]
  );

  const dataPointPerQuarter = useMemo(
    () => dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter),
    [selectedQuarter, dataPointsPerQuarter]
  );
  const { result: aggregatedValues } = useAggregatedRowValue(row, isYearly, companyReportingUnit);

  const showAsPercentage = useMemo(() => {
    return row.metric.showAsPercentage ?? false;
  }, [row]);

  const percentageValue = usePercentageValues(
    row,
    selectedQuarter,
    isYearly,
    isAggregated ? aggregatedValues[selectedQuarter] : dataPointPerYear?.value,
    companyReportingUnit
  );

  const updateDatapoints = (value: number | null, dp: Partial<DatapointFieldsFragment_>) => {
    const data = value;
    onDatapointChange(
      data === null ? data : String(data),
      answer?.hasOptedOut ?? false,
      answer?.optOutReason ?? '',
      dp,
      row.tags,
      dp.ownerId ?? assessmentProjectLeaderId
    );
  };

  const drawerDatapoint = useMemo(
    () => (!!row?.subRows?.length || isYearly ? dataPointPerYear : dataPointPerQuarter?.value),
    [isYearly, dataPointPerYear, dataPointPerQuarter]
  );

  useEffect(() => {
    if (drawerDatapoint?.id === urlDatapointId && openDrawer) {
      setRowData?.(row);
    }
  }, [urlDatapointId, drawerDatapoint, openDrawer]);

  if (
    (isYearly && selectedQuarter !== TimePeriodsEnums.year) ||
    (!isYearly && selectedQuarter === TimePeriodsEnums.year)
  )
    return (
      <Typography variant="body" color="text.hint" w={'100%'}>
        N/A
      </Typography>
    );

  if (isAggregated) {
    return (
      <Typography variant="body" w={'100%'}>
        {showResult(aggregatedValues[selectedQuarter])}{' '}
        {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
      </Typography>
    );
  }

  if (isYearly) {
    return (
      <Skeleton isLoaded={!loading} w="100%">
        {isOverview ? (
          <Typography variant="body" w="100%">
            {dataPointPerQuarter?.value?.value ?? 0}{' '}
            {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
          </Typography>
        ) : (
          <HStack mx="-4px" spacing="4px">
            <DatapointInput
              hasOptedOut={answer?.hasOptedOut ?? false}
              value={dataPointPerYear?.value ? Number(dataPointPerYear?.value) : undefined}
              datapoint={dataPointPerYear}
              updateDatapoint={(value, datapoint) =>
                updateDatapoints(value, datapoint ?? { timeframe: TimePeriodsEnums.year })
              }
            />

            {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
          </HStack>
        )}
      </Skeleton>
    );
  }
  if (!isYearly) {
    return (
      <Skeleton isLoaded={!loading}>
        {isOverview ? (
          <Typography variant="body" w="100%">
            {dataPointPerQuarter?.value?.value ?? 0}{' '}
            {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
          </Typography>
        ) : (
          <HStack mx="-4px" spacing="4px">
            <DatapointInput
              hasOptedOut={answer?.hasOptedOut ?? false}
              value={
                dataPointPerQuarter?.value?.value
                  ? Number(dataPointPerQuarter?.value?.value)
                  : undefined
              }
              datapoint={dataPointPerQuarter?.value}
              updateDatapoint={(value, datapoint) =>
                updateDatapoints(value, datapoint ?? { timeframe: dataPointPerQuarter?.field })
              }
            />
            {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
          </HStack>
        )}
      </Skeleton>
    );
  }
  return (
    <Typography variant="body" color="text.hint">
      N/A
    </Typography>
  );
};
