import { HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { CalendarIcon } from 'Tokens/Icons/Data';
import { ArrowDownRightIcon, ArrowUpRightIcon } from 'Tokens/Icons/Direction';

export const MilestoneCard = ({
  year,
  target,
  onClick,
}: {
  year: number;
  target: number;
  onClick: () => void;
}) => {
  return (
    <VStack
      p="16px"
      w="240px"
      spacing="16px"
      borderRadius="8px"
      alignItems="start"
      shadow="interactive"
      background="bg.elevated.interactive"
      onClick={onClick}
      _hover={{ cursor: 'pointer', shadow: 'interactive.hover', bg: 'bg.elevated.interactive.hover', }}
    >
      <HStack spacing="4px">
        <CalendarIcon boxSize="18px" color="text.default" />
        <Typography variant="h3">{year}</Typography>
      </HStack>
      <VStack p="8px 10px" borderRadius="8px" bg="bg.muted" w="100%" alignItems="start">
        <HStack spacing="4px">
          <VStack spacing="0px" alignItems="start" w="160px">
            <Typography variant="h3">{target ?? 0}%</Typography>
            <Typography variant="detail">Company target</Typography>
          </VStack>
          {target > 0 ? (
            <ArrowUpRightIcon color="text.hint" boxSize="20px" />
          ) : (
            <ArrowDownRightIcon color="text.hint" boxSize="20px" />
          )}
        </HStack>
      </VStack>
    </VStack>
  );
};
