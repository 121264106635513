import { Box, Button, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { AssessableMetrics } from '../Metrics';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import { Typography } from 'Tokens';
import {
  AttachmentBox,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Esrs_Answer_Constraint_,
  Esrs_Answer_Update_Column_,
  Esrs_DatapointTag_Constraint_,
  GetDisclosureRequirementAttachmentDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetEsrsMetricAnswerDocument_,
  GetEsrsMetricsDatapointsDocument_,
  GetSingleEsrsMetricAnswerDocument_,
  ShortUser,
  User,
  useGetDisclosureRequirementAttachmentQuery,
  useGetEsrsMetricsDatapointsQuery,
  useUpsertDatapointMutation,
  useUpsertDisclosureRequirementAttachmentMutation,
} from 'models';
import { RefetchQueriesInclude } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRemoveAttachment } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { getNameExtension } from 'utils/files';
import { AttachIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { MetricsAnswersGenerator } from './AnswersGenerator';
import { useUserData } from '@nhost/react';
import { useParams } from 'react-router-dom';
import { TimePeriodsEnums } from '../../Requirement';
import { IconButton } from 'Atoms';
import { MetricsTableData, areArraysOfObjectsEqual } from '..';
import { GeneratedNarrativeAnswer, GeneratedNumericAnswer } from '.';
import { AIProgressModal, InteractionBlocker } from './AIUtils';

const omitArrayTypename = (arr: any[]) => arr.map(({ __typename, ...rest }) => rest);

const DisclosureRequirementAttachment = ({
  attachments,
  onAttachmentDrawerOpen,
  refetchQueries,
}: {
  attachments: AttachmentBox['attachments'];
  onAttachmentDrawerOpen: () => void;
  refetchQueries: RefetchQueriesInclude;
}) => {
  const unassignAttachment = useRemoveAttachment();

  const removeAttachment = useCallback(
    (attachmentId: string) => {
      return unassignAttachment(attachmentId, refetchQueries);
    },
    [unassignAttachment]
  );

  // Take only the first attachment since we only allow selecting one file through the drawer
  const attachment = useMemo(() => attachments?.[0], [attachments]);
  const file = useMemo(() => attachment?.file, [attachment]);
  const { extension } = useMemo(() => {
    return getNameExtension(file?.storageFile?.name ?? '');
  }, [file]);

  return (
    <VStack w="100%" spacing="16px" alignItems="stretch">
      {!attachment ? (
        <HStack w="100%">
          <Button
            leftIcon={<AttachIcon />}
            variant="ghost"
            size="sm"
            onClick={onAttachmentDrawerOpen}
          >
            Add document
          </Button>
        </HStack>
      ) : (
        <VStack spacing="0px" alignItems="start">
          <HStack
            h="28px"
            justifyContent="center"
            alignItems="center"
            spacing="6px"
            p="4px"
            border="1px solid"
            borderColor="border.decorative"
            borderRadius="5px"
          >
            <Box w="16.67px">
              <FileIcon
                extension={extension}
                {...defaultStyles[extension as keyof typeof defaultStyles]}
                labelUppercase
              />
            </Box>

            <Typography variant="bodyStrong">{file.title}</Typography>

            <IconButton
              variant="ghost"
              aria-label="CloseButton"
              size="xs"
              icon={<RemoveIcon color="inherit" />}
              onClick={() => removeAttachment(attachment.id)}
            />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

export const MetricsAI = ({
  quantitativeMetrics,
  narrativeMetrics,
  materialStandardId,
  reportingUnitId,
  disclosureRequirementRef,
  esrsAssessmentProjectLeader,
}: {
  quantitativeMetrics: MetricsTableData[];
  narrativeMetrics: AssessableMetrics;
  materialStandardId: string;
  disclosureRequirementRef: string;
  reportingUnitId?: string;
  esrsAssessmentProjectLeader?: Partial<User>;
}) => {
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isAIProgressModalOpen,
    onClose: onAIProgressModalClose,
    onOpen: onAIProgressModalOpen,
  } = useDisclosure();

  const [upsertDatapoint] = useUpsertDatapointMutation();
  const user: ShortUser | null = useUserData();
  const { esrsAssessmentId = '' } = useParams();

  const [upserDRtAttachment] = useUpsertDisclosureRequirementAttachmentMutation();

  const { data: documentationData, loading: documentationDataLoading } =
    useGetDisclosureRequirementAttachmentQuery({
      variables: {
        disclosureRequirementRef: disclosureRequirementRef,
        materialStandardId: materialStandardId,
        reportingUnitId: reportingUnitId,
      },
      skip: !disclosureRequirementRef || !materialStandardId || !reportingUnitId,
    });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_DisclosureRequirementAttachment[0]?.attachmentBox ?? undefined,
    [documentationData]
  );

  const abortController = new AbortController();

  const getNestedDataPoints = (metric: MetricsTableData): MetricsTableData[] => {
    const childrenDataPoints: MetricsTableData[] = [];

    const recurseChildren = (row: MetricsTableData) => {
      if (!row.subRows?.length) {
        childrenDataPoints.push(row);
      } else {
        row.subRows.forEach((subRow) => {
          recurseChildren(subRow);
        });
      }
    };

    recurseChildren(metric);

    return childrenDataPoints;
  };

  const flatNestedMetrics = useMemo(
    () => quantitativeMetrics.flatMap((metric) => getNestedDataPoints(metric)),
    [quantitativeMetrics]
  );

  const filterUniqueMetricsByNameAndTags = (metrics: MetricsTableData[]): MetricsTableData[] => {
    const uniqueMetrics: MetricsTableData[] = [];
    const uniqueSet = new Set<string>();

    metrics.forEach((metric) => {
      const metricRef = metric.metric.reference;
      const tagsString = metric.tags?.map((tag) => `${tag.tagType} - ${tag.tagValue}`).join(', ');

      const key = `${metricRef}${metric.tags ? ' | ' + tagsString : ''}`;

      if (!uniqueSet.has(key)) {
        uniqueSet.add(key);
        uniqueMetrics.push(metric);
      }
    });

    return uniqueMetrics;
  };

  const uniqueNumericDataPoints = useMemo(
    () => filterUniqueMetricsByNameAndTags(flatNestedMetrics),
    [flatNestedMetrics]
  );

  const metricRefs = useMemo(() => {
    const quantitativeMetricRefs = quantitativeMetrics.map((metric) => metric.metric.reference);
    const narrativeMetricsRefs = narrativeMetrics.map((metric) => metric.reference);
    return [...quantitativeMetricRefs, ...narrativeMetricsRefs];
  }, [quantitativeMetrics, narrativeMetrics]);

  const { data: metricDataPointsData } = useGetEsrsMetricsDatapointsQuery({
    variables: {
      assessmentId: esrsAssessmentId,
      metricRefs: metricRefs,
      reportingUnitId: reportingUnitId,
    },
  });

  const metricDataPoints = useMemo(() => {
    return metricDataPointsData?.answers ?? [];
  }, [metricDataPointsData]);

  const areAnswersGenerated = useMemo(() => {
    return metricDataPoints?.some((mdp) => mdp.datapoints?.some((dp) => dp.isAIGenerated));
  }, [metricDataPoints]);

  const [isGeneratingAnswers, setIsGeneratingAnswers] = useState<boolean>(false);

  const populateNarrativeAnswers = (generatedAnswers: GeneratedNarrativeAnswer[]) => {
    upsertDatapoint({
      variables: {
        objects:
          narrativeMetrics?.map((metric) => {
            const currentAnswer = metricDataPoints?.find((dp) => dp.metricRef === metric.reference);
            const dataPoint = currentAnswer?.datapoints[0];
            return {
              id: dataPoint?.id ?? undefined,
              value:
                generatedAnswers.find(
                  (generatedAnswer) => generatedAnswer.metricRef === metric.reference
                )?.answer ?? '',
              timeframe: TimePeriodsEnums.year,
              authorId: user?.id,
              ownerId: dataPoint?.ownerId ?? esrsAssessmentProjectLeader?.id,
              isAIGenerated: true,
              answer: {
                data: {
                  metricRef: currentAnswer?.metricRef ?? metric.reference,
                  hasOptedOut: currentAnswer?.hasOptedOut ?? false,
                  optOutReason: currentAnswer?.optOutReason ?? '',
                  reportingUnitId: reportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },

      refetchQueries: [
        GetEsrsMetricAnswerDocument_,
        GetEsrsMetricsDatapointsDocument_,
        GetSingleEsrsMetricAnswerDocument_,
      ],
    });
  };

  const populateQuantitativeAnswers = (generatedAnswers: GeneratedNumericAnswer[]) => {
    upsertDatapoint({
      variables: {
        objects:
          uniqueNumericDataPoints?.map((metric) => {
            const currentAnswer = metricDataPoints?.find(
              (dp) => dp.metricRef === metric.metric.reference
            );
            const dataPoint = currentAnswer?.datapoints.find((dp) =>
              areArraysOfObjectsEqual(omitArrayTypename(dp.datapointTags), metric.tags)
            );
            const generatedAnswer = generatedAnswers.find(
              (answer) =>
                answer.metricRef === metric.metric.reference &&
                areArraysOfObjectsEqual(answer.tags, metric.tags)
            );

            return {
              id: dataPoint?.id ?? undefined,
              value: generatedAnswer?.answer ?? '0',
              timeframe: dataPoint?.timeframe ?? TimePeriodsEnums.year,
              authorId: user?.id,
              ownerId: dataPoint?.ownerId ?? esrsAssessmentProjectLeader?.id,
              isAIGenerated: true,
              datapointTags: {
                data: generatedAnswer?.tags ?? [],
                on_conflict: {
                  constraint:
                    Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
                },
              },
              answer: {
                data: {
                  metricRef: currentAnswer?.metricRef ?? metric.metric.reference,
                  hasOptedOut: currentAnswer?.hasOptedOut ?? false,
                  optOutReason: currentAnswer?.optOutReason ?? '',
                  reportingUnitId: reportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },

      refetchQueries: [
        GetEsrsMetricAnswerDocument_,
        GetEsrsMetricsDatapointsDocument_,
        GetSingleEsrsMetricAnswerDocument_,
      ],
    });
  };

  useEffect(() => {
    if (!documentationDataLoading && !attachmentBox) {
      upserDRtAttachment({
        variables: {
          objects: {
            disclosureRequirementRef,
            materialStandardId: materialStandardId,
            reportingUnitId: reportingUnitId,
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint:
                  AttachmentBox_Constraint_.AttachmentBoxDisclosureRequirementAttachmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.DisclosureRequirementAttachmentId_],
              },
            },
          },
        },
        refetchQueries: [GetDisclosureRequirementAttachmentDocument_],
      });
    }
  }, [attachmentBox, documentationDataLoading]);

  useEffect(() => {
    const handleClick = () => {
      if (isGeneratingAnswers) {
        onAIProgressModalOpen();
      }
    };
    window.addEventListener('click', handleClick);
    if (!isGeneratingAnswers) {
      onAIProgressModalClose();
    }

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [isGeneratingAnswers]);

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
      refetch={[GetDisclosureRequirementAttachmentDocument_]}
      singleFileSelect={true}
    >
      <HStack
        w="100%"
        p="16px"
        border="1px solid"
        borderColor="border.selected.accent"
        borderRadius="6px"
      >
        <HStack spacing="16px" w="100%">
          <VStack spacing="16px" w="100%" flex={1} alignItems="start">
            <VStack spacing="4px" w="100%" alignItems="start">
              <Typography variant="h3">Generate answers with AI</Typography>
              <Typography variant="body">
                Use your documents to generate answers in this disclosure requirement. Select a
                document that contains relevant information to generate answers.
              </Typography>
            </VStack>
            <DisclosureRequirementAttachment
              attachments={attachmentBox?.attachments ?? []}
              onAttachmentDrawerOpen={onAttachmentDrawerOpen}
              refetchQueries={[
                GetDisclosureRequirementAttachmentDocument_,
                GetDisclosureRequirementGroupsDocument_,
              ]}
            />
          </VStack>

          <MetricsAnswersGenerator
            quantitativeMetrics={uniqueNumericDataPoints}
            narrativeMetrics={narrativeMetrics}
            attachments={attachmentBox?.attachments ?? []}
            populateNarrativeAnswers={populateNarrativeAnswers}
            populateQuantitativeAnswers={populateQuantitativeAnswers}
            isDisabled={attachmentBox?.attachments.length === 0}
            requestSignal={abortController.signal}
            isGeneratingAnswers={isGeneratingAnswers}
            setIsGeneratingAnswers={setIsGeneratingAnswers}
            areAnswersGenerated={areAnswersGenerated}
          />
        </HStack>

        <AIProgressModal
          isOpen={isAIProgressModalOpen}
          onClose={onAIProgressModalClose}
          setIsGenerating={setIsGeneratingAnswers}
          cancelRequest={() => {
            abortController.abort();
          }}
        />

        {isGeneratingAnswers && <InteractionBlocker />}
      </HStack>
    </AttachmentDrawer>
  );
};
