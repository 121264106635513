import {
  StackProps,
  VStack,
  SkeletonCircle,
  Avatar,
  Skeleton,
  Circle,
  Image,
  Heading,
  HStack,
} from '@chakra-ui/react';
import { Header, LabelText } from 'Tokens';
import { CompanyDetails } from 'models';

export const CompanyLogo = ({ url, size }: { url: string; size: string; shadow?: string }) => (
  <Circle overflow="hidden" bg="bg.unknown" size={size}>
    <Image src={url} width={size} height={size} />
  </Circle>
);

export const CompanyPresentation = ({
  company,
}: {
  company: Pick<CompanyDetails, 'logoUrl' | 'name'>;
}) => {
  return (
    <HStack alignItems="center" width="100%">
      {company.logoUrl && <CompanyLogo url={company.logoUrl} size="24px" />}
      <VStack alignItems="center">
        <Heading fontSize="md" fontWeight="medium" color="text.default">
          {company.name}
        </Heading>
      </VStack>
    </HStack>
  );
};

export const CompanyAvatar = ({
  company,
  ...rest
}: StackProps & { company?: Pick<CompanyDetails, 'name' | 'logoUrl'> }) => (
  <VStack {...rest}>
    <SkeletonCircle isLoaded={!!company} size="64px">
      {company?.logoUrl ? (
        <CompanyLogo url={company.logoUrl} size="64px" />
      ) : (
        <Avatar size="lg" name={company?.name} src={company?.logoUrl ?? undefined} />
      )}
    </SkeletonCircle>
    <Skeleton isLoaded={!!company}>
      <Header size="lg">{company?.name}</Header>
    </Skeleton>
  </VStack>
);

export const CompanyAvatarWithName = ({
  company,
  hideName = false,
}: {
  company?: Pick<CompanyDetails, 'name' | 'logoUrl'>;
  hideName?: boolean;
}) => (
  <HStack>
    {company?.logoUrl ? (
      <CompanyLogo url={company.logoUrl} size="32px" />
    ) : (
      <Avatar size="sm" name={company?.name} src={company?.logoUrl ?? undefined} />
    )}
    {!hideName && <LabelText fontSize="16px">{company?.name}</LabelText>}
  </HStack>
);
