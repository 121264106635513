import { HStack, VStack, Box, FormLabel } from '@chakra-ui/react';
import { FormField, IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { Modal, Select } from 'Molecules';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { BusinessUnitsIcon } from 'Tokens/Icons/Data';
import { Typography } from 'Tokens';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IconHelp, IconTrash } from '@tabler/icons-react';
import {
  EsrsAssessmentDocument_,
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Insert_Input_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  GroupLevelMetricsPerDisclosureDocument_,
  MaterialMetricsPerDisclosureDocument_,
  QuestionType_Enum_,
  useDeleteMaterialMetricTagMutation,
  useDeleteMaterialMetricTagValueMutation,
  useUpsertMaterialMetricsMutation,
} from 'models';
import { useToast } from 'utils/hooks';
import { TagSelectionMenu, TagValuesSelector } from './TagSelectMenus';
import { groupBy, uniq, uniqBy } from 'lodash';
import { MetricTagsList } from 'Molecules/MetricTagsList';
import {
  DataCollectionLevel,
  FREQUENCY_OPTIONS,
  GROUP_DATA_GATHERING_OPTIONS,
  TableMetricData,
} from '../DataCollection.d';
import { getAllMaterialMetricChildren } from '../DataCollection.hooks';

export enum TagStatus {
  required = 'Required by ESRS',
  added = 'Added',
  requested = 'Requested by parent company',
}

export type MetricConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedMetricData?: TableMetricData;
  isMetricDisclosure: boolean;
  companyStandardId: string;
  parentStandardId: string;
};

export type MaterialMetricFields = {
  frequency: string;
  dataCollection?: string;
};

export type TagBreakdown = {
  type: string;
  values: string[];
  isFromESRS: boolean;
  isFromParent: boolean;
};

export const NoSelectedTags = ({
  checkedTags,
  setCheckedTags,
  tagOptions,
}: {
  checkedTags: TagBreakdown[];
  setCheckedTags: Dispatch<SetStateAction<TagBreakdown[]>>;
  tagOptions: TagBreakdown[];
}) => {
  return (
    <VStack
      width="100%"
      justifyContent="center"
      alignItems="center"
      border="1px dashed"
      borderRadius="8px"
      borderColor="border.default"
      pt="24px"
      pb="16px"
    >
      <BusinessUnitsIcon color="text.hint" />
      <Typography variant="body" color="text.muted" w="275px" align="center">
        You can break down this metric into more granular sub-metrics.
        <br />
        Select how you want to break it down:
      </Typography>
      <TagSelectionMenu
        options={tagOptions ?? []}
        checked={checkedTags}
        setChecked={setCheckedTags}
      />
    </VStack>
  );
};

export const MetricConfigModalParent = ({
  isOpen,
  onClose,
  selectedMetricData,
  isMetricDisclosure,
  companyStandardId,
}: MetricConfigModalProps) => {
  const materialMetric = useMemo(
    () =>
      selectedMetricData?.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
    [selectedMetricData, companyStandardId]
  );

  const { control, reset, handleSubmit, watch } = useForm<MaterialMetricFields>({
    defaultValues: {
      dataCollection: materialMetric?.dataCollection ?? '',
      frequency: materialMetric?.frequency ?? 'Yearly',
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const selectedMaterialMetricId = useMemo(() => materialMetric?.id, [materialMetric]);

  const isEditingDisabled = useMemo(
    () =>
      (selectedMetricData?.isChild &&
        selectedMetricData.parentMetric?.materialMetrics.find(
          (mm) => mm.materialStandardId === companyStandardId
        )?.isMaterial) ??
      undefined,
    [selectedMetricData]
  );

  const getDataCollectionText = (dataCollectionLevel: string): string => {
    if (dataCollectionLevel === DataCollectionLevel.subsidiaries) return 'subsidiaries level';
    if (dataCollectionLevel === DataCollectionLevel.group) return 'group level';
    return '';
  };

  const adminPanelTags = useMemo(() => {
    return selectedMetricData?.adminPanelTags;
  }, [selectedMetricData]);

  const tagsFromCompany = useMemo(() => {
    return materialMetric?.materialMetricTags;
  }, [materialMetric]);

  const { existingTags, optionsToAdd } = useMemo(() => {
    const esrsMandatory =
      adminPanelTags
        ?.filter((x) => !x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: true,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    const esrsOptional =
      adminPanelTags
        ?.filter((x) => !!x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: false,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    const fromCompany =
      tagsFromCompany?.map((x) => ({
        type: x.tagType,
        isFromESRS: !!esrsMandatory.find((y) => y.type === x.tagType),
        isFromParent: false,
        values:
          adminPanelTags?.find((t) => t.type === x.tagType)?.values.tagValues.map((v) => v.value) ??
          [],
      })) ?? [];

    const existing: TagBreakdown[] = uniqBy([...esrsMandatory, ...fromCompany], 'type');
    const options: TagBreakdown[] = esrsOptional.filter(
      (x) => !existing.find((y) => y.type === x.type)
    );
    return { existingTags: existing, optionsToAdd: options };
  }, [adminPanelTags, tagsFromCompany]);

  const getStatus = (tag: TagBreakdown): TagStatus => {
    if (tag.isFromESRS) return TagStatus.required;
    return TagStatus.added;
  };

  const { frequency, dataCollection } = useMemo(() => {
    return {
      frequency: materialMetric?.frequency ?? 'Yearly',
      dataCollection: materialMetric?.dataCollection ?? '',
    };
  }, [materialMetric]);

  const toast = useToast();

  const { isValid, errors } = useFormState({ control });
  const [availableTags, setAvailableTags] = useState<TagBreakdown[]>(existingTags);
  const [options, setOptions] = useState<TagBreakdown[]>(optionsToAdd);
  const [prevTags, setPrevTags] = useState<TagBreakdown[]>(availableTags);
  const [configuredTags, setConfiguredTags] = useState<{ tagType: string; tagValue: string }[]>([]);

  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const [deleteMaterialMetricTag] = useDeleteMaterialMetricTagMutation();
  const [deleteMaterialMetricTagValue] = useDeleteMaterialMetricTagValueMutation();

  const isGroupLevel = useMemo(
    () => watch('dataCollection') === DataCollectionLevel.group,
    [watch('dataCollection')]
  );

  const childrenMetrics = useMemo(
    () => getAllMaterialMetricChildren(companyStandardId, selectedMetricData),
    [selectedMetricData, companyStandardId]
  );

  const handleConfirm = async (data: MaterialMetricFields) => {
    const metricAndChildren: { id: string; metricRef: string; isMaterial: boolean | null }[] = [
      {
        id: selectedMaterialMetricId,
        metricRef: selectedMetricData?.reference,
        isMaterial: materialMetric?.isMaterial,
      },
      ...childrenMetrics,
    ].filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    const deleteAllTags =
      data.dataCollection === DataCollectionLevel.subsidiaries &&
      materialMetric?.dataCollection === DataCollectionLevel.group;

    const groupedConfiguredTags = groupBy(configuredTags, 'tagType');

    const tagsAvailable: Esrs_MaterialMetricTag_Insert_Input_[] = availableTags.map((tag) => {
      return {
        tagType: tag.type,
      };
    });

    upsertMaterialMetric({
      variables: {
        objects: metricAndChildren.map((mm) => {
          return {
            id: mm.id,
            frequency: data.frequency,
            dataCollection: data.dataCollection ?? DataCollectionLevel.group,
            isMaterial: mm.isMaterial,
            materialStandardId: companyStandardId,
            metricRef: mm?.metricRef,
            materialMetricTags: {
              data: isGroupLevel
                ? Object.keys(groupedConfiguredTags).map((key) => ({
                    tagType: key,
                    materialTagValues: {
                      data: groupedConfiguredTags[key],
                      on_conflict: {
                        constraint:
                          Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                        update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                      },
                    },
                  }))
                : tagsAvailable,
              on_conflict: {
                constraint:
                  Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
                update_columns: [Esrs_MaterialMetricTag_Update_Column_.IsOptional_],
              },
            },
          };
        }),
      },
      refetchQueries: [
        GroupLevelMetricsPerDisclosureDocument_,
        MaterialMetricsPerDisclosureDocument_,
        EsrsAssessmentDocument_,
      ],
    })
      .then(() =>
        toast({
          text: 'Configured metric data collection settings',
        })
      )
      .catch((error) => {
        console.log(error);
        toast({
          text: 'Unable to configure metric data collection settings',
          variant: 'danger',
        });
      });

    const existingMaterialTags = materialMetric?.materialMetricTags.flatMap((materialTag) =>
      materialTag.materialTagValues.map((t) => ({
        tagType: t.tagType,
        tagValue: t.tagValue,
      }))
    );

    const tagValuesToDelete = deleteAllTags
      ? existingMaterialTags
      : existingMaterialTags?.filter((existingTag) => {
          return !configuredTags.some((configuredTag) => {
            return (
              configuredTag.tagType === existingTag.tagType &&
              configuredTag.tagValue === existingTag.tagValue
            );
          });
        });

    const existingMaterialTagTypes = materialMetric?.materialMetricTags.map((t) => t.tagType);
    const configuredMaterialTagTypes = uniq(availableTags.map((t) => t.type));
    const tagTypesToDelete = existingMaterialTagTypes?.filter(
      (tag) => !configuredMaterialTagTypes?.includes(tag)
    );

    const presentTags = materialMetric?.materialMetricTags.map((tag) => tag.tagType);

    const tagsToDelete = presentTags?.filter(
      (tag) => !availableTags?.map((x) => x.type).includes(tag)
    );

    try {
      if (tagTypesToDelete?.length)
        metricAndChildren.forEach((mm) => {
          deleteMaterialMetricTag({
            variables: {
              materialMetricId: mm.id,
              tagTypes: tagTypesToDelete ?? [],
            },
            refetchQueries: [
              GroupLevelMetricsPerDisclosureDocument_,
              MaterialMetricsPerDisclosureDocument_,
              EsrsAssessmentDocument_,
            ],
          });
        });

      if (tagValuesToDelete?.length)
        metricAndChildren.forEach((mm) => {
          tagValuesToDelete?.forEach((tag) => {
            deleteMaterialMetricTagValue({
              variables: {
                materialMetricId: mm.id,
                tagType: tag.tagType,
                tagValue: tag.tagValue,
              },
              refetchQueries: [
                GroupLevelMetricsPerDisclosureDocument_,
                MaterialMetricsPerDisclosureDocument_,
                EsrsAssessmentDocument_,
              ],
            });
          });
        });

      if (tagsToDelete?.length && !isGroupLevel)
        metricAndChildren.forEach((mm) => {
          return deleteMaterialMetricTag({
            variables: {
              materialMetricId: mm.id,
              tagTypes: tagsToDelete ?? [],
            },
          });
        });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric breakdown settings',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (materialMetric) {
      reset({
        frequency: frequency,
        dataCollection: dataCollection,
      });
    }

    if (materialMetric?.materialMetricTags.length === 0) {
      setConfiguredTags(
        selectedMetricData?.adminPanelTags.flatMap((tag) => {
          if (tag.values.alwaysAll) {
            return tag.values.tagValues.map((t) => ({
              tagType: t.type,
              tagValue: t.value,
            }));
          }
          return [];
        }) ?? []
      );
    } else {
      setConfiguredTags(
        materialMetric?.materialMetricTags.flatMap((materialTag) =>
          materialTag.materialTagValues.map((t) => ({
            tagType: t.tagType,
            tagValue: t.tagValue,
          }))
        ) ?? []
      );
    }
  }, [materialMetric]);

  const updatedOptions = useMemo(() => {
    if (!prevTags) return options;

    const removedTags = prevTags.filter(
      (tag) => !availableTags.some((newTag) => newTag.type === tag.type)
    );
    const addedTags = availableTags.filter(
      (newTag) => !prevTags.some((tag) => tag.type === newTag.type)
    );

    return options
      .filter((option) => !addedTags.some((tag) => tag.type === option.type))
      .concat(removedTags);
  }, [options, availableTags, prevTags]);

  useEffect(() => {
    setAvailableTags(existingTags);
    setOptions(optionsToAdd);
    setPrevTags(existingTags);
  }, [existingTags, optionsToAdd]);

  const hasBreakdown = useMemo(() => {
    return !!availableTags?.length || !!updatedOptions.length;
  }, [availableTags, updatedOptions]);

  const { title, shortTitle, isQualitative } = useMemo(() => {
    return {
      title: selectedMetricData?.title,
      shortTitle: selectedMetricData?.shortTitle,
      isQualitative: selectedMetricData?.metricType === QuestionType_Enum_.LongText_,
    };
  }, [selectedMetricData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <Typography variant="h2" mr="40px" justifyContent="center">
          {shortTitle ?? title ?? ''}
          {!!adminPanelTags?.length && (
            <>
              {' by '}
              <MetricTagsList tags={adminPanelTags.map((tag) => tag.type)} isHStack={false} />
            </>
          )}
        </Typography>
      }
      subtitle="Metric settings"
      size={hasBreakdown ? 'lg' : 'md'}
      confirmButtonProps={{
        type: 'submit',
        form: 'metric-config-form',
        isDisabled: !isValid,
      }}
      onConfirm={onClose}
    >
      <VStack width="100%" alignItems="stretch" spacing="16px">
        <form id="metric-config-form" onSubmit={handleSubmit(handleConfirm)}>
          <VStack alignItems="start" spacing="16px">
            {!isQualitative && isMetricDisclosure && (
              <Controller
                name="frequency"
                control={control}
                render={({ field: { onChange, value: fieldValue } }) => (
                  <FormField
                    label="Frequency"
                    id="frequency"
                    isRequired
                    isInvalid={!!errors.frequency}
                    error="Required"
                  >
                    <VStack width="324px" spacing="6px" alignItems="start">
                      <Select<{ value: string; label: string }>
                        width="100%"
                        placeholder="Select"
                        isDisabled={isEditingDisabled}
                        value={{ label: fieldValue, value: fieldValue }}
                        options={FREQUENCY_OPTIONS}
                        onChange={(freq) => {
                          onChange(freq?.value ?? undefined);
                        }}
                      />
                      {isEditingDisabled && (
                        <Typography variant="detail">
                          {`It is not possible to change frequency because the parent metric is collected ${frequency.toLocaleLowerCase()}`}
                        </Typography>
                      )}
                    </VStack>
                  </FormField>
                )}
              />
            )}
            <Controller
              name="dataCollection"
              control={control}
              render={({ field: { onChange, value: fieldValue } }) => (
                <FormField
                  label="Data gathering level"
                  id="dataLevel"
                  isInvalid={!!errors.dataCollection}
                  error="Required"
                >
                  <VStack width="324px" spacing="6px" alignItems="start">
                    <Select<{ value: string; label: string }>
                      width="100%"
                      placeholder="Select"
                      isDisabled={isEditingDisabled}
                      value={{
                        label:
                          GROUP_DATA_GATHERING_OPTIONS.find((option) => option.value === fieldValue)
                            ?.label ?? '',
                        value: fieldValue ?? '',
                      }}
                      options={GROUP_DATA_GATHERING_OPTIONS}
                      onChange={(dc) => {
                        onChange(dc?.value ?? undefined);
                      }}
                    />
                    {isEditingDisabled && (
                      <Typography variant="detail">
                        {`It is not possible to change data gathering level because the parent metric is collected on ${getDataCollectionText(
                          dataCollection
                        )}`}
                      </Typography>
                    )}
                  </VStack>
                </FormField>
              )}
            />
          </VStack>
        </form>

        {hasBreakdown && (
          <VStack alignItems="start" width="100%" spacing="6px">
            <FormLabel m="0px">Breakdown</FormLabel>
            {availableTags.length > 0 ? (
              <VStack spacing="6px" w="100%">
                {availableTags.map((tag, index) => {
                  return (
                    <HStack
                      key={`${tag.type}_${index}`}
                      bgColor="bg.interactive"
                      h="48px"
                      borderRadius="6px"
                      w="100%"
                      justifyContent="space-between"
                    >
                      <HStack paddingInline="8px" spacing="16px" w="50%">
                        {index > 0 && (
                          <Tag w="47px" variant="info" borderRadius="6px" title="AND" />
                        )}
                        <TruncatableText variant="bodyStrong" text={`by ${tag.type}`} />
                      </HStack>
                      <HStack w="50%" justifyContent="space-between">
                        <Box>
                          <TruncatableText
                            text={getStatus(tag)}
                            variant="body"
                            paddingInline="8px"
                          />
                        </Box>
                        {isGroupLevel && (
                          <TagValuesSelector
                            options={tag.values.map((v) => ({ tagType: tag.type, tagValue: v }))}
                            configured={configuredTags}
                            type={tag.type}
                            setConfigured={setConfiguredTags}
                          />
                        )}
                        {!tag.isFromESRS ? (
                          <IconButton
                            aria-label="delete"
                            variant="ghost"
                            icon={<IconTrash size="16px" />}
                            onClick={() => {
                              setAvailableTags((prev) => prev.filter((i) => i.type !== tag.type));
                            }}
                          />
                        ) : (
                          <Tooltip
                            label="'This breakdown is required by the ESRS. You can not delete this breakdown'"
                            placement="bottom"
                          >
                            <IconButton
                              aria-label="help"
                              variant="ghost"
                              icon={<IconHelp size="16px" />}
                            />
                          </Tooltip>
                        )}
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              <NoSelectedTags
                checkedTags={availableTags}
                setCheckedTags={setAvailableTags}
                tagOptions={updatedOptions ?? []}
              />
            )}

            {!!updatedOptions.length && !!availableTags.length && (
              <TagSelectionMenu
                options={updatedOptions}
                checked={availableTags}
                setChecked={setAvailableTags}
              />
            )}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
