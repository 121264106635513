import React, { useEffect, useRef, useState } from 'react';
import { TimePeriods } from '../../Requirement';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { User } from 'models';
import { Box, Table as ChakraTable, HStack, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { LongTextMetricInput } from '../LongTextMetricInput';
import { MetricOwnerAvatar } from './MetricOwnerSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { isEqual } from 'lodash';
import { IconButton } from 'Atoms';
import { AggregatedQualitativeAnswers } from '../AggregatedMetrics';

export const NarrativeMetricsTable = ({
  metrics,
  selectedQuarter,
  companyReportingUnit,
  esrsAssessmentProjectLeader,
  isGeneratingAnswers = false,
  rowData,
  setRowData,
  answersData,
  isReadOnly = false,
}: {
  metrics: MetricsTableData['metric'][];
  selectedQuarter: TimePeriods;
  companyReportingUnit?: string;
  esrsAssessmentProjectLeader?: Partial<User>;
  isGeneratingAnswers?: boolean;
  rowData?: MetricsTableData;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  answersData?: AggregatedQualitativeAnswers;
  isReadOnly?: boolean;
}) => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const [selectedMetricBox, setSelectedMetricBox] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const theadStyle = {
    letterSpacing: 'normal',
    borderColor: 'border.decorative',
    px: '8px',
    height: '48px',
  };
  const bodyRef = useRef(null);

  useEffect(() => {
    const handleDeselect = (event: MouseEvent) => {
      if (bodyRef.current && !(bodyRef.current as HTMLElement).contains(event.target as Node)) {
        setSelectedRow('');
        setSelectedMetricBox('');
      }
    };
    document.addEventListener('click', handleDeselect);
    return () => {
      document.addEventListener('click', handleDeselect);
    };
  }, []);

  const mapMetrics = (
    metric:
      | MetricsTableData['metric']
      | MetricsTableData['metric']['childrenMetrics'][number]['childMetric'],
    padding: number,
    metricId: string,
    borderTop: boolean,
    answers?: AggregatedQualitativeAnswers
  ): React.ReactNode => {
    const answerData = answers?.find((data) => data.metricRef === metric?.reference);

    if (metric) {
      const handleRowClick = () => {
        setSelectedMetricBox(metricId);
        setSelectedRow(metric.reference);
        if (openDrawer && !!hash) navigate(pathname.split('?')[0]);
        if (isEqual(rowData, { metric: metric })) {
          setRowData(undefined);
        } else
          setRowData(
            !!answerData?.reportingUnits?.length || !!answerData?.subsidiaries?.length
              ? { metric: metric, sourceData: answerData }
              : { metric: metric }
          );
      };
      return (
        <>
          <Tr
            borderTop={borderTop ? '1px solid' : 'none'}
            borderColor="border.decorative"
            _hover={{
              bg: isReadOnly ? '' : 'bg.hover',
              '&:hover .metricTitle': {
                textDecoration: 'underline',
                textDecorationColor: 'text.hint',
                textUnderlineOffset: '2px',
                transition: '0.15s',
              },
              '&:hover .metricArrow': {
                bg: 'bg.hover',
              },
            }}
            backgroundColor={
              selectedRow === metric.reference
                ? 'bg.hover'
                : selectedMetricBox === metricId
                ? 'bg.selected.muted'
                : 'none'
            }
            transition="0.1s"
          >
            <Td
              p="0px"
              border="none"
              colSpan={metric?.childrenMetrics.length ? 2 : 1}
              pl={`${padding}px`}
              verticalAlign="top"
            >
              <HStack
                p="14px 8px"
                spacing="8px"
                alignItems="start"
                height="100%"
                id={metric?.reference}
              >
                <MetricTypeIcon type={MetricTypes.text} />
                <Typography
                  id={metric?.reference}
                  variant="body"
                  className="metricTitle"
                  textDecoration="underline"
                  textDecorationColor={
                    isEqual(rowData, { metric: metric }) ? 'text.hint' : 'transparent'
                  }
                  textUnderlineOffset={'2px'}
                  transition="0.15s"
                  cursor="pointer"
                  onClick={handleRowClick}
                >
                  {metric?.shortTitle ?? metric?.title}
                </Typography>
              </HStack>
            </Td>
            {!metric.childrenMetrics.length &&
              (isReadOnly ? (
                <Typography variant="body" p="16px">
                  {answerData?.answer?.datapoints[0]?.value ?? 'N/A'}
                </Typography>
              ) : (
                <Td p="0px" border="none" verticalAlign="top" px="8px" py="6px">
                  <LongTextMetricInput
                    metric={metric}
                    companyReportingUnit={companyReportingUnit}
                    setRowData={setRowData}
                    isGeneratingAnswers={isGeneratingAnswers}
                  />
                </Td>
              ))}
            {!isReadOnly && (
              <Td p="0px 8px" border="none" verticalAlign="top">
                <Box my="10px">
                  <MetricOwnerAvatar
                    row={{ metric: metric, tags: [] }}
                    selectedQuarter={selectedQuarter}
                    companyReportingUnit={companyReportingUnit}
                    assessmentProjectLeader={esrsAssessmentProjectLeader}
                  />
                </Box>
              </Td>
            )}
            <Td p="0px 8px" border="none" textAlign="right" verticalAlign="top">
              <Box my="10px">
                <IconButton
                  aria-label="side-bar"
                  variant="ghost"
                  icon={<ArrowNarrowRightIcon />}
                  onClick={handleRowClick}
                  className="metricArrow"
                />
              </Box>
            </Td>
          </Tr>
          {metric?.childrenMetrics.length > 0 &&
            metric.childrenMetrics.map((child) =>
              mapMetrics(
                child.childMetric as MetricsTableData['metric'],
                padding + 24,
                metricId,
                false,
                answers
              )
            )}
        </>
      );
    } else return <></>;
  };

  return (
    <ChakraTable>
      <Thead w="100%" borderY="1px solid" borderColor="border.decorative">
        <Tr w="100%">
          <Th {...theadStyle} textTransform="none" w="40%">
            <Typography variant="bodyStrong">Narrative data point</Typography>
          </Th>
          <Th {...theadStyle} textTransform="none" w="fit-content">
            <Typography variant="bodyStrong">Answer</Typography>
          </Th>
          {!isReadOnly && (
            <Th {...theadStyle} textTransform="none" w="10%">
              <Typography variant="bodyStrong">Owner</Typography>
            </Th>
          )}
          <Th {...theadStyle} w="5%"></Th>
        </Tr>
      </Thead>
      <Tbody ref={bodyRef}>
        {metrics.map((metric) => (
          <React.Fragment key={metric.reference}>
            {mapMetrics(metric, 0, metric.reference, true, answersData)}
          </React.Fragment>
        ))}
      </Tbody>
    </ChakraTable>
  );
};
