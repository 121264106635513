import { MutableRefObject, useMemo, useRef } from 'react';
import { MetricsTableData, isFrequencyYearly, useAggregatedRowValue } from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import {
  DatapointFieldsFragment_,
  QuestionType_Enum_,
  useGetChildrenMetricsMetadataQuery,
} from 'models';
import { TableData } from 'Molecules/NestedTable';
import { HStack, VStack } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { MetricNameWithTag } from 'containers/Esrs/pieces/DataCollection/DataCollectionUtils';
import { getRowName } from '../Metrics.utils';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { FrequencyEnums } from '../../Requirement';

const getLastEditedData = (date?: Date, name?: string) => {
  if (!date) return { date: '', name: '' };
  const formattedDate = new Date(date)
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '.');
  return { date: formattedDate, name: name || '' };
};

export const useLastEdited = (
  esrsAssessmentId: string,
  row: MetricsTableData,
  companyStandardId: string,
  selectedQuarter: string,
  metricLevel?: boolean,
  reportingUnitId?: string
) => {
  const { data: childrenMetadata } = useGetChildrenMetricsMetadataQuery({
    variables: {
      reportingUnitId,
      assessmentId: esrsAssessmentId,
      parentMetricRef: row.metric?.reference,
    },
    skip: !reportingUnitId || !esrsAssessmentId || !row.metric?.reference,
  });
  const { dataPointsPerQuarter, dataPointPerYear, datapointText, isAggregated } =
    useGetDatapointValues(row, reportingUnitId);
  const isYearly = useMemo(
    () => isFrequencyYearly(row, companyStandardId),
    [row, companyStandardId]
  );
  const { result: aggregatedValues } = useAggregatedRowValue(row, isYearly, reportingUnitId);

  if (row.metric?.metricType === QuestionType_Enum_.LongText_) {
    if (isAggregated && !!childrenMetadata?.esrs_Answer[0]?.datapoints?.length) {
      const latestChild = childrenMetadata?.esrs_Answer[0]?.datapoints[0];
      return getLastEditedData(new Date(latestChild.updatedAt), latestChild.author?.displayName);
    }

    return getLastEditedData(datapointText?.updatedAt, datapointText?.author?.displayName);
  }

  if ((!isAggregated && aggregatedValues.latestAuthor) || isAggregated) {
    return getLastEditedData(aggregatedValues.latestDate, aggregatedValues.latestAuthor);
  }

  const getQuarterlyData = () => {
    if (metricLevel) {
      return dataPointsPerQuarter.reduce((a, b) =>
        a?.value?.updatedAt > b?.value?.updatedAt ? a : b
      );
    }
    return dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter);
  };

  const date = isAggregated
    ? aggregatedValues.latestDate
    : isYearly
    ? dataPointPerYear?.updatedAt
    : getQuarterlyData()?.value?.updatedAt;

  return getLastEditedData(date, getQuarterlyData()?.value?.author?.displayName);
};

const getLastEditedDataForQuarter = (
  quarter: string,
  dataPointsPerQuarter: {
    field: 'Q1' | 'Q2' | 'Q3' | 'Q4';
    value: DatapointFieldsFragment_ | undefined;
  }[]
) => {
  const dp = dataPointsPerQuarter.find((d) => d.field === quarter)?.value;
  return getLastEditedData(dp?.updatedAt, dp?.author?.displayName);
};

export const useLastEditedPerQuarter = (row: MetricsTableData, reportingUnitId?: string) => {
  const { dataPointsPerQuarter } = useGetDatapointValues(row, reportingUnitId);

  return {
    Q1: getLastEditedDataForQuarter('Q1', dataPointsPerQuarter),
    Q2: getLastEditedDataForQuarter('Q2', dataPointsPerQuarter),
    Q3: getLastEditedDataForQuarter('Q3', dataPointsPerQuarter),
    Q4: getLastEditedDataForQuarter('Q4', dataPointsPerQuarter),
  };
};

export const searchForParentMetric = (metrics: MetricsTableData[], ref: string) => {
  const searchMetric = (metric: MetricsTableData): boolean => {
    if (metric.metric.reference === ref && !metric.subRows?.some((row) => row.referenceToSource)) {
      return true;
    }

    if (metric.subRows) {
      return metric.subRows.some((row) => searchMetric(row));
    }

    return false;
  };

  return metrics?.find((metric) => searchMetric(metric));
};

export const MetricRowTitle = ({
  row,
  rowData,
  companyStandardId,
  onClick,
  isAggregated,
}: {
  row: Row<TableData<MetricsTableData>>;
  rowData?: MetricsTableData;
  companyStandardId: string;
  onClick?: () => void;
  isAggregated?: boolean;
}) => {
  const materialMetric = useMemo(
    () =>
      row.original.metric.materialMetrics?.find(
        (mm) => mm.materialStandardId === companyStandardId
      ),
    [row, companyStandardId]
  );

  const isCalculated = useMemo(
    () => row.getCanExpand() && !!row.original.metric.calculation,
    [row]
  );

  const rowType = useMemo(() => {
    if (isAggregated) return MetricTypes.aggregated;
    if (isCalculated) return MetricTypes.calculated;
    else if (!!row.subRows.length) return MetricTypes.aggregated;
    return MetricTypes.number;
  }, [row, isCalculated]);

  const subMetrics = useMemo(() => {
    if (isAggregated) {
      return row.original.metric.childrenMetrics.map((subRow) => ({
        name: subRow.childMetric?.shortTitle ?? subRow.childMetric?.title ?? '',
        reference: subRow.childMetric?.reference ?? '',
      }));
    }
    return row.subRows.map((subRow) => ({
      name: getRowName(subRow.original, true) ?? '',
      reference: subRow.original.metric.reference,
    }));
  }, [row]);

  const rowTitle = useMemo(() => {
    if (row.getParentRow()?.original.tagName && !row.getParentRow()?.original.tags?.length)
      return row.original.metric.shortTitle ?? row.original.metric.title;
    if (row.original.tagName) return row.original.tagName ?? '';
    return row.original.metric.shortTitle ?? row.original.metric.title;
  }, [row]);

  const parentRowTitle = useMemo(() => {
    if (row.original.tagName) {
      return (
        row.getParentRow()?.original.tagName ??
        row.getParentRow()?.original.metric.shortTitle ??
        row.getParentRow()?.original.metric.title
      );
    }
  }, [row]);

  const showTags = useMemo(
    () => (row.original.tagType || !row.original.isChild) && !row.original.tagName,
    [row]
  );

  const materialMetricTags = useMemo(
    () => (showTags ? materialMetric?.materialMetricTags?.map((tag) => tag.tagType) ?? [] : []),
    [materialMetric, showTags]
  );

  const metricNameRef = useRef<HTMLDivElement>(null);

  return (
    <HStack
      className="metricTitle"
      textDecoration="underline"
      textDecorationColor={row.original === rowData ? 'text.hint' : 'transparent'}
      textUnderlineOffset={row.original === rowData ? '2px' : '4px'}
      transition="0.15s"
      pl={`${row.depth * 24}px`}
      spacing="8px"
      cursor="pointer"
      width="100%"
    >
      <MetricTypeIcon
        type={rowType}
        isBusinessUnits={materialMetric?.dataCollection === DataCollectionLevel.reportingUnits}
        isSubsidiaries={materialMetric?.dataCollection === DataCollectionLevel.subsidiaries}
        isQuarterly={materialMetric?.frequency === FrequencyEnums.quarterly}
        calculation={row.original.metric.calculation ?? undefined}
        subMetrics={subMetrics}
      />
      <VStack
        ref={metricNameRef}
        width="100%"
        onClick={onClick}
        height="40px"
        justifyContent="center"
        alignItems="start"
      >
        <MetricNameWithTag
          name={rowTitle}
          parentRowName={parentRowTitle}
          tags={materialMetricTags}
          rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
        />
      </VStack>
    </HStack>
  );
};
