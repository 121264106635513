import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Routes,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const sentryTracingConfig = {
  routingInstrumentation: Sentry.reactRouterV6Instrumentation(
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
  ),
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
