import {
  HStack,
  Circle,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import { PieChart } from 'react-minimal-pie-chart';
import { colors, Typography } from 'Tokens';
import { MetricsTableData, isFrequencyYearly } from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import { useLastEditedPerQuarter } from './MetricsUtils';
import { QUARTERS_FIELDS } from '../../Requirement';
import { IconCheck, IconQuestionMark } from '@tabler/icons-react';
import { CheckIcon } from '@chakra-ui/icons';
import { QuestionMarkIcon } from 'Tokens/Icons/Status';
import { IconButton } from 'Atoms';
import { useMemo } from 'react';

interface Result {
  [quarter: string]: {
    date: string;
    name: string | undefined;
  };
}

const useCheckProgress = (
  row: MetricsTableData,
  companyStandardId: string,
  companyReportingUnit?: string
) => {
  const { dataPointsPerQuarter, dataPointPerYear, isAggregated } = useGetDatapointValues(
    row,
    companyReportingUnit
  );
  let total = 0;
  if (isAggregated) {
    if (row.subRows) {
      // This should be fixed asap!!
      row.subRows.forEach((subRow) => {
        total += useCheckProgress(subRow, companyStandardId, companyReportingUnit);
      });
      return Math.floor(total / row.subRows.length);
    }
  }
  const isYearly = isFrequencyYearly(row, companyStandardId);
  const isAssessedYearly = dataPointPerYear?.value ? true : false;
  const assessedQuarters = isAggregated
    ? []
    : dataPointsPerQuarter.filter((item) => item.value !== undefined && item.value?.value !== null);
  return isYearly ? (isAssessedYearly ? 100 : 0) : (assessedQuarters.length * 100) / 4;
};

const CollectedPopover = ({ row }: { row: MetricsTableData }) => {
  const popoverData = useLastEditedPerQuarter(row);

  return (
    <VStack alignItems="start" justifyContent="start" p={0} spacing={0}>
      {QUARTERS_FIELDS.map((quarter) => (
        <HStack p="10px 8px" w="100%">
          <HStack
            bgColor={popoverData[quarter].name ? 'bg.success' : 'bg.unknown'}
            h="28px"
            w="28px"
            borderRadius="6px"
            justifyContent="center"
            alignItems="center"
          >
            {popoverData[quarter].name ? (
              <IconCheck size="16px" color="#027A48" />
            ) : (
              <IconQuestionMark size="16px" color="#4D4D60" />
            )}
          </HStack>
          <VStack alignItems="start" spacing="2px">
            <Typography variant="bodyStrong" color="text.muted">
              {quarter}
            </Typography>
            <Typography variant="detail" color="text.muted">
              {popoverData[quarter].date
                ? `Last edited by ${popoverData[quarter].name} ${popoverData[quarter].date}`
                : 'Not added yet'}
            </Typography>
          </VStack>
        </HStack>
      ))}
    </VStack>
  );
};

export const MetricProgress = ({
  row,
  companyStandardId,
  companyReportingUnit,
}: {
  row: MetricsTableData;
  companyStandardId: string;
  companyReportingUnit?: string;
}) => {
  const progress = useCheckProgress(row, companyStandardId, companyReportingUnit);
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack>
          <Circle size="16px" alignItems="center">
            <PieChart
              lineWidth={16}
              data={[
                {
                  title: 'Collected',
                  value: progress,
                  color: colors['bg.notEligible.accent'][color],
                },
                {
                  title: 'Missing',
                  value: 100 - progress,
                  color: colors['bg.unknown'][color],
                },
              ]}
            />
          </Circle>
          <Typography variant="body">{Number.isNaN(progress) ? 0 : progress}%</Typography>
        </HStack>
      </PopoverTrigger>
      <PopoverContent w="316px">
        <PopoverBody p="8px">
          <CollectedPopover row={row} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
