import { useCompanyAssessmentResults } from 'Features/Results/Results.hooks';
import { useAllActivities } from 'Features/Screening/Screening.hooks';
import { groupBy, padStart, sortBy } from 'lodash';
import { ActivityResults, CompanyAssessmentResults, ObjectiveKeyEnum } from 'models';
import { useMemo } from 'react';
import { useCurrentCompany } from 'utils/hooks';
import { RowFormat, SheetFormat, TaxonomyTableData } from './table-generator';
import {
  calculateSmallTableData,
  getAlignedEnabling,
  getAlignedTransitional,
  getNonEligible,
  getTotalEligible,
  getTotalEligibleAligned,
  getTotalEligibleNotAligned,
} from './TaxonomyTableTotals';
import {
  getEligibleAlignedActivities,
  getEligibleNotAlignedActivities,
} from './TaxonomyTableActivities';
import { stringToYear } from 'utils/date';

export type ResultsByActivity = { [key: string]: ActivityResults[] };
export type ResultsBySC = { [key: string]: ActivityResults[] };
export type ResultsByActivityAndSC = { [key: string]: ResultsBySC };
export type FinancialsAttribute =
  | 'aligned'
  | 'eligible'
  | 'inProgress'
  | 'notAligned'
  | 'notEligible'
  | 'total';

export type Financials = {
  [attribute in FinancialsAttribute]: number;
};

export type FinancialsType = 'capex' | 'opex' | 'revenue';

export type FinancialsPerType = {
  [type in FinancialsType]: Financials;
};

const defaultFinancialsPerType: Financials = {
  aligned: 0,
  eligible: 0,
  inProgress: 0,
  notAligned: 0,
  notEligible: 0,
  total: 0,
};

const defaultFinancials: FinancialsPerType = {
  capex: defaultFinancialsPerType,
  opex: defaultFinancialsPerType,
  revenue: defaultFinancialsPerType,
};

export const getProportion = (absolute: number, total?: number): number => {
  if (total && total > 0) {
    return absolute / total;
  } else {
    return 0;
  }
};

const getRefNumbers = (input?: string): number[] | null => {
  if (!input) return null;
  const re = /(\d+)\.(\d+)(?:\.(\d+))?/;
  const matches = input.match(re);
  if (matches) {
    return [parseInt(matches[1] ?? '0'), parseInt(matches[2] ?? '0'), parseInt(matches[3] ?? '0')];
  } else {
    return null;
  }
};

const sortByRef = (row: RowFormat): string => {
  const refNumbers = getRefNumbers(row.activityName);
  if (!refNumbers) return row.activityName ?? '';
  const firstNumber = padStart(`${refNumbers[0]}`, 3, '0');
  const secondNumber = padStart(`${refNumbers[1]}`, 3, '0');
  const thirdNumber = padStart(`${refNumbers[2]}`, 3, '0');
  return `${firstNumber}.${secondNumber}.${thirdNumber}`;
};

const calculateSheet = (
  resultsByActivityAndTag: ResultsByActivityAndSC,
  activities: any,
  totalFinancials: FinancialsPerType,
  type: FinancialsType
): SheetFormat => {
  const eligibleAlignedActivities = getEligibleAlignedActivities(
    resultsByActivityAndTag,
    type,
    activities,
    totalFinancials[type].total
  );

  const sortedEligibleAlignedActivities = sortBy(eligibleAlignedActivities, sortByRef);

  const eligibleNotAlignedActivities = getEligibleNotAlignedActivities(
    resultsByActivityAndTag,
    type,
    activities,
    totalFinancials[type].total
  );

  const sortedEligibleNotAlignedActivities = sortBy(eligibleNotAlignedActivities, sortByRef);

  const totalEligibleAligned = getTotalEligibleAligned(
    eligibleAlignedActivities,
    totalFinancials[type].total
  );

  const alignedEnabling = getAlignedEnabling(eligibleAlignedActivities);

  const alignedTransitional = getAlignedTransitional(eligibleAlignedActivities);

  const totalEligibleNotAligned = getTotalEligibleNotAligned(
    eligibleNotAlignedActivities,
    totalFinancials[type].total
  );

  const totalEligible: RowFormat = getTotalEligible(totalEligibleNotAligned, totalEligibleAligned);

  const nonEligible = getNonEligible(totalEligible, totalFinancials, type);

  const total: RowFormat = {
    absolute: totalFinancials[type].total,
    proportion: 1,
  };

  return {
    eligibleAlignedActivities: sortedEligibleAlignedActivities,
    eligibleNotAlignedActivities: sortedEligibleNotAlignedActivities,
    alignedEnabling,
    alignedTransitional,
    totalEligibleAligned,
    totalEligibleNotAligned,
    totalEligible,
    nonEligible,
    total,
  };
};

export const getResultsByActivity = (
  cachedResults: CompanyAssessmentResults | undefined
): ResultsByActivity => {
  if (!cachedResults) return {};

  const allActivityResults: ActivityResults[] = [];

  cachedResults?.businessUnitResults.forEach((businessUnitResult) => {
    const generalAssessmentResult = businessUnitResult.generalAssessmentResult?.cachedResult;
    const activityResultsWithMSSG = businessUnitResult.activityResults.map((ar) => ({
      ...ar,
      isMSSGAligned: generalAssessmentResult?.isAligned && generalAssessmentResult.isCompleted,
    }));
    allActivityResults.push(...activityResultsWithMSSG);
  });

  return groupBy(allActivityResults, 'activityRef');
};

const hasAdaptationFinancials = (result: ActivityResults): boolean => {
  const financials = result?.cachedResult?.financials;
  if (financials?.capex?.adaptation === 0 || financials?.opex?.adaptation === 0) {
    return false;
  } else {
    return true;
  }
};

export const enablingTagGoesToAdaptation = ['1.1', '1.2', '1.3', '1.4', '2.1'];

const splitResultsBySC = (results: ActivityResults[]): ResultsBySC => {
  return results.reduce((acc, result) => {
    if (!result.cachedResult?.isAligned || !result.isMSSGAligned) {
      acc.notAligned = acc.notAligned ?? [];
      acc.notAligned.push(result);
      return acc;
    }
    const objectives =
      result?.cachedResult?.objectivesState?.substantialContributionObjectives?.toSorted();
    if (objectives.length === 1 && objectives.includes(ObjectiveKeyEnum.adaptation)) {
      if (result?.cachedResult?.activityTag === 'ENABLING') {
        acc.adaptationOnlyEnabling = acc.adaptationOnlyEnabling ?? [];
        acc.adaptationOnlyEnabling.push(result);
      } else {
        acc.adaptationOnly = acc.adaptationOnly ?? [];
        acc.adaptationOnly.push(result);
      }
    } else {
      if (objectives.includes(ObjectiveKeyEnum.adaptation) && hasAdaptationFinancials(result)) {
        if (result?.cachedResult?.activityTag === 'ENABLING') {
          if (enablingTagGoesToAdaptation.includes(result.activityRef)) {
            acc.adaptationEnabling = acc.adaptationEnabling ?? [];
            acc.adaptationEnabling.push(result);
          } else {
            acc[ObjectiveKeyEnum.adaptation] = acc[ObjectiveKeyEnum.adaptation] ?? [];
            acc[ObjectiveKeyEnum.adaptation].push(result);
          }
        } else {
          acc[ObjectiveKeyEnum.adaptation] = acc[ObjectiveKeyEnum.adaptation] ?? [];
          acc[ObjectiveKeyEnum.adaptation].push(result);
        }
      }
      let key = objectives
        .filter((o: ObjectiveKeyEnum) => o !== ObjectiveKeyEnum.adaptation)
        .join('-');
      if (
        result?.cachedResult?.activityTag === 'ENABLING' &&
        (!enablingTagGoesToAdaptation.includes(result.activityRef) ||
          !objectives.includes(ObjectiveKeyEnum.adaptation))
      ) {
        key = key + '-enabling';
      } else if (result?.cachedResult?.activityTag === 'TRANSITIONAL') {
        key = key + '-transitional';
      }
      acc[key] = acc[key] ?? [];
      acc[key].push(result);
    }
    return acc;
  }, {} as ResultsBySC);
};

export const getResultsByActivityAndSC = (
  results?: CompanyAssessmentResults
): ResultsByActivityAndSC => {
  const resultsByActivity = getResultsByActivity(results);
  const resultsByActivityAndTag: ResultsByActivityAndSC = {};

  Object.entries(resultsByActivity).forEach(([activityRef, activityResult]) => {
    resultsByActivityAndTag[activityRef] = splitResultsBySC(activityResult);
  });

  return resultsByActivityAndTag;
};

export const useTaxonomyTableData = (
  cAssessmentId: string,
  isGroup = false
): { data: TaxonomyTableData } => {
  const { company } = useCurrentCompany();
  const { data: results } = useCompanyAssessmentResults(cAssessmentId, false, isGroup);

  const resultsByActivityAndSC = useMemo(() => getResultsByActivityAndSC(results), [results]);

  const activities = useAllActivities();
  const activityMap = useMemo(
    () =>
      activities.reduce(
        (nameMap, activity) => ({
          ...nameMap,
          [activity.reference]: {
            name: activity.name,
            referenceNumber: activity.referenceNumber,
          },
        }),
        {}
      ),
    [activities]
  );

  const reportingYear = stringToYear(results?.startDate ?? '');

  const data = useMemo(() => {
    const totalFinancials: FinancialsPerType =
      results?.cachedResult?.financials ?? defaultFinancials;
    return {
      companyName: company?.name ?? '',
      reportingYear,
      currency: company?.currency ?? '',
      turnover: calculateSheet(resultsByActivityAndSC, activityMap, totalFinancials, 'revenue'),
      capex: calculateSheet(resultsByActivityAndSC, activityMap, totalFinancials, 'capex'),
      opex: calculateSheet(resultsByActivityAndSC, activityMap, totalFinancials, 'opex'),
      smallTableDataTurnover: calculateSmallTableData(
        resultsByActivityAndSC,
        results,
        activityMap,
        totalFinancials,
        'revenue'
      ),
      smallTableDataCapex: calculateSmallTableData(
        resultsByActivityAndSC,
        results,
        activityMap,
        totalFinancials,
        'capex'
      ),
      smallTableDataOpex: calculateSmallTableData(
        resultsByActivityAndSC,
        results,
        activityMap,
        totalFinancials,
        'opex'
      ),
    };
  }, [company, results, resultsByActivityAndSC, activityMap]);

  return { data };
};
