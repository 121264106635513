import { Button } from 'Atoms';
import { AssessableMetrics } from '../Metrics';
import { AttachmentBox } from 'models';
import { Dispatch, SetStateAction } from 'react';
import { getNameExtension } from 'utils/files';
import { nhost } from 'utils/nhost';
import { useToast } from 'utils/hooks';
import { useToast as useChakraToast, useDisclosure } from '@chakra-ui/react';
import { MetricsTableData } from '..';
import { GeneratedNarrativeAnswer, GeneratedNumericAnswer } from '.';
import { AnswersRegenerationModal } from './AIUtils';
import {
  extractNarrativeAnswer,
  extractNumericAnswer,
  extractTextFromPdf,
} from './AIRequestFunctions';

export const MetricsAnswersGenerator = ({
  quantitativeMetrics,
  narrativeMetrics,
  populateNarrativeAnswers,
  populateQuantitativeAnswers,
  attachments,
  isGeneratingAnswers,
  setIsGeneratingAnswers,
  requestSignal,
  isDisabled,
  areAnswersGenerated,
}: {
  quantitativeMetrics: MetricsTableData[];
  narrativeMetrics: AssessableMetrics;
  populateNarrativeAnswers: (generatedAnswers: GeneratedNarrativeAnswer[]) => void;
  populateQuantitativeAnswers: (generatedAnswers: GeneratedNumericAnswer[]) => void;
  attachments: AttachmentBox['attachments'];
  isGeneratingAnswers: boolean;
  setIsGeneratingAnswers: Dispatch<SetStateAction<boolean>>;
  requestSignal: AbortSignal;
  isDisabled: boolean;
  areAnswersGenerated: boolean;
}) => {
  const toast = useToast();
  const chakraToast = useChakraToast();
  const {
    onOpen: onAnswersRegenerationModalOpen,
    onClose: onAnswersRegenerationModalClose,
    isOpen: isAnswersRegenerationModalOpen,
  } = useDisclosure();

  const generateNumericAnswers = async (text: string) => {
    const availableDataPoints = quantitativeMetrics.map((metric) => {
      const resolveTagsString = () => {
        const metricStandaloneTitle = metric.metric.title;
        const tags = metric.tags;
        const tagsString = tags?.map((tag) => `by ${tag.tagType} for ${tag.tagValue}`).join(', ');
        if (metric.tags?.length) {
          return `${metricStandaloneTitle} ${tagsString}`;
        }
        return metricStandaloneTitle;
      };

      return {
        reference: metric.metric.reference,
        metricTitleWithTags: resolveTagsString(),
        unit: metric.metric.unitOfMeasurement,
        title: metric.metric.title,
        tags: metric.tags,
      };
    });

    const extractedAnswers = await Promise.all(
      availableDataPoints.map(async (dp) => {
        try {
          const populatedAnswer = await extractNumericAnswer(
            dp.metricTitleWithTags,
            dp.unit ?? 'NA',
            text,
            requestSignal
          );

          const metricAnswerData = populatedAnswer.data;

          return {
            ...metricAnswerData,
            metricRef: dp.reference,
            metricTitle: dp.title,
            tags: dp.tags,
          };
        } catch (error) {
          setIsGeneratingAnswers(false);
          toast({
            variant: 'danger',
            text: 'Couldn’t generate numeric answer. Click on the metric to learn more or write your answer manually',
          });
        }
      })
    );

    return extractedAnswers;
  };

  const generateNarrativeAnswers = async (text: string) => {
    const extractedAnswers = await Promise.all(
      narrativeMetrics.map(async (metric) => {
        try {
          const populatedAnswer = await extractNarrativeAnswer(metric.title, text, requestSignal);

          const metricAnswerData = populatedAnswer.data;

          return { ...metricAnswerData, metricRef: metric.reference };
        } catch (error) {
          setIsGeneratingAnswers(false);
          toast({
            variant: 'danger',
            text: 'Couldn’t generate answer. Click on the metric to learn more or write your answer manually',
          });
        }
      })
    );

    return extractedAnswers;
  };

  const extractAndGenerateAnswers = async (fileId: string) => {
    const file = attachments.find((attachment) => attachment.file.storageFile?.id === fileId)?.file;
    const fileType = getNameExtension(file?.storageFile?.name ?? '').extension;
    const fileName = file?.title ?? '';
    const preSignedUrl = await nhost.storage.getPresignedUrl({ fileId: fileId });
    const fileUrl = preSignedUrl.presignedUrl?.url ?? '';

    if (fileType !== 'pdf') {
      return toast({
        text: 'Please select a valid pdf document',
        variant: 'danger',
      });
    }

    const toastId = toast({
      text: 'AI is generating answers...',
      closable: true,
      duration: null,
    });

    try {
      setIsGeneratingAnswers(true);

      const result = await extractTextFromPdf(fileUrl, fileName, requestSignal);

      if (result) {
        const combinedContent = result.content.reduce((acc: string, curr: any) => {
          return acc + curr.text;
        }, '');

        const narrativeAnswers = await generateNarrativeAnswers(combinedContent);
        populateNarrativeAnswers(narrativeAnswers);

        const quantitativeAnswers = await generateNumericAnswers(combinedContent);
        populateQuantitativeAnswers(quantitativeAnswers);

        setIsGeneratingAnswers(false);
        chakraToast.close(toastId);
      }
    } catch (error) {
      setIsGeneratingAnswers(false);
      chakraToast.close(toastId);
      toast({
        variant: 'danger',
        text: 'Could not read file text, please try again',
      });
    }
  };

  return (
    <>
      <Button
        isLoading={isGeneratingAnswers}
        variant="primary"
        isDisabled={isDisabled}
        onClick={() => {
          if (areAnswersGenerated) onAnswersRegenerationModalOpen();
          else extractAndGenerateAnswers(attachments[0].file.storageFile?.id);
        }}
      >
        Generate
      </Button>

      <AnswersRegenerationModal
        isOpen={isAnswersRegenerationModalOpen}
        onClose={onAnswersRegenerationModalClose}
        extractAndGenerate={extractAndGenerateAnswers}
        selectedFileId={attachments[0]?.file?.storageFile?.id}
      />
    </>
  );
};
