import {
  EsrsAssessmentFieldsFragment_,
  GetEsrsCategoriesQuery_,
  GetEsrsStandardQuery_,
  GetMaterialStandardQuery_,
  GetParentMaterialityAssessmentQuery_,
} from 'models';
import { MaterialityStatus } from './MaterialityRadioGroup';
import { AlertStatus } from 'Atoms';
import { LockedIcon } from 'Tokens/Icons/Function';
import { CheckIcon, InfoIcon, WarningIcon } from 'Tokens/Icons/Status';
import { DataCollectionType } from '../DataCollection/DataCollection.d';

export enum MaterialityState {
  materialMandatory = 'materialMandatory',
  mandatory = 'mandatory',
  toAssess = 'toAssess',
  material = 'material',
  notMaterial = 'notMaterial',
  gatherData = 'gatherData',
  collectDataMandatory = 'collectDataMandatory',
  collectData = 'collectData',
  doNotCollect = 'doNotCollect',
}

export enum ParentMaterialityState {
  materialMandatory = 'materialMandatory',
  mandatory = 'mandatory',
  toAssess = 'toAssess',
  material = 'material',
  notMaterial = 'notMaterial',
  gatherData = 'gatherData',
}

export const DR_STATES = {
  [MaterialityState.mandatory]: {
    status: 'neutral' as AlertStatus,
    title: 'Mandatory',
    description: 'This disclosure requirement is mandatory for all companies',
    icon: <LockedIcon color="text.muted" />,
  },
  [MaterialityState.materialMandatory]: {
    status: 'info' as AlertStatus,
    title: 'Material',
    description: 'This disclosure requirement is material because topic was assessed as material',
    icon: <LockedIcon color="text.info" />,
  },
  [MaterialityState.material]: {
    status: 'info' as AlertStatus,
    title: MaterialityStatus.material,
    description: 'You have assessed this disclosure requirement as material',
    icon: <CheckIcon color="text.info" />,
  },
  [MaterialityState.notMaterial]: {
    status: 'neutral' as AlertStatus,
    title: MaterialityStatus.notMaterial,
    description: 'You have assessed this disclosure requirement as not material',
    icon: <CheckIcon color="inherit" />,
  },
  [MaterialityState.toAssess]: {
    status: 'warning' as AlertStatus,
    title: 'To assess',
    description: 'Learn more about the DR and assess if it is material for your company or not',
    icon: <WarningIcon color="text.warning" />,
  },
  [MaterialityState.gatherData]: {
    status: 'neutral' as AlertStatus,
    title: MaterialityStatus.gatherData,
    description:
      'You have assessed this disclosure requirement as not material; however, you will still be able to gather data for it.',
    icon: <CheckIcon color="inherit" />,
  },
  [MaterialityState.collectDataMandatory]: {
    status: 'info' as AlertStatus,
    title: 'Collect data',
    description:
      'This disclosure requirement is material for a parent, so it is required to collect data',
    icon: <LockedIcon color="text.info" />,
  },
  [MaterialityState.doNotCollect]: {
    status: 'info' as AlertStatus,
    title: 'Not required to collect data',
    description:
      'This DR is not material for a parent, so you don’t have to collect data. However, you can choose to collect data if you wish',
    icon: <InfoIcon mt="3px" />,
  },
  [MaterialityState.collectData]: {
    status: 'info' as AlertStatus,
    title: 'Collect data',
    description: 'You have assessed this disclosure requirement as material',
    icon: <CheckIcon color="text.info" />,
  },
};

export type MaterialStandard = {
  id: string;
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterial?: boolean | null;
  isTopical?: boolean;
  isParentMaterial?: boolean | null;
  isDataGatheringOnly?: boolean;
  isCopiedFromParent?: boolean;
  metrics: EsrsAssessmentFieldsFragment_['materialStandards'][number]['materialMetrics'];
  parentCompanyMetrics: MaterialMetric[];
  isDataCollected: boolean;
  disclosureRequirementGroups: GetEsrsCategoriesQuery_['EsrsCategory'][number]['standards'][number]['disclosureRequirementGroups'];
};

export type MaterialMap = {
  categoryName: string;
  categoryRef: string;
  hasParentMaterialityAssessment: boolean;
  materialStandards: MaterialStandard[];
};

export type BusinessUnitMaterialStandard = {
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterialForBu?: boolean;
  isMaterialForSubsidiary?: boolean;
  isMaterialForGroupParent?: boolean;
};

export type BusinessUnitMaterialMap = {
  categoryName: string;
  categoryRef: string;
  materialStandards: BusinessUnitMaterialStandard[];
};

export type Materiality =
  | 'material'
  | 'notMaterial'
  | 'materialMandatory'
  | 'mandatory'
  | 'toAssess'
  | 'gatherData'
  | 'collectDataMandatory'
  | 'collectData'
  | 'doNotCollect';

export type MaterialMetric = {
  id?: string;
  metricRef: string;
  dataCollection?: string | null;
  isDataGatheringOnly: boolean;
  isMaterial?: boolean | null;
  isParentMaterial?: boolean;
  isCopiedFromParent?: boolean;
  materialMetricTags?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['materialMetricTags'];
  metric?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['metric'];
};

export type MaterialDr = {
  drRef: string;
  isMaterial: boolean | null;
  isDataGatheringOnly: boolean;
};

export type MaterialityFields = {
  material: MaterialityState | null;
};

export type ParentMaterialMetrics = {
  metricRef: string;
  isMaterial?: boolean | null;
  dataCollection?: string | null | undefined;
  metric: {
    disclosureRequirementRef: string;
  };
};

export type Metric = Requirement['metrics'][number] & {
  tags: {
    type: string;
  }[];
};

export type AdditionalType = Requirement['additionalTypes'][number]['additionalType'];

export type DisclosureRequirementGroups = NonNullable<
  GetEsrsStandardQuery_['esrsStandard']
>['disclosureRequirementGroups'];

export type Requirement = DisclosureRequirementGroups[number]['requirements'][number];

export type MaterialMetricMapping = {
  [metricRef: string]: {
    isMaterial?: boolean | null;
    isDataGatheringOnly: boolean;
    dataCollection: DataCollectionType;
    metric?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['metric'];
  };
};

export type DisclosureRequirementMateriality = {
  drRef: string;
  materialityStatus: Materiality;
  parentMaterialityStatus?: Materiality;
};

export enum MetricDataCollection {
  companyLevel = 'company',
  reportingUnitLevel = 'reportingUnits',
}

export type MappedDisclosureRequirements = {
  title: string;
  drRef: string;
  materialityStatus: Materiality;
  order: number;
  parentMateriality: Materiality | MaterialityState;
  metrics: (Requirement['metrics'][number] & {
    tags: {
      type: string;
    }[];
  })[];
  description: string | null | undefined;
  additionalTypes: AdditionalType[];
  isMandatory: boolean;
}[];

export type ParentMaterialMetricsType = NonNullable<
  NonNullable<GetParentMaterialityAssessmentQuery_['EsrsAssessment_by_pk']>['parentAssessment']
>['materialStandards'][number]['materialMetrics'];
