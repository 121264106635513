import { Box, Grid, HStack, Skeleton, TagLeftIcon, VStack, useDisclosure } from '@chakra-ui/react';
import { Accordion, Avatar, Button, EmptyState, IconButton, Infobox, Tag } from 'Atoms';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { groupBy, keyBy, mapValues, omit, uniq, uniqBy } from 'lodash';
import {
  PaiCompanyReportsDocument_,
  PaiReportRequestsDocument_,
  useToggleSharePaiWithPortfolioMutation,
  AttachmentBox,
  GetPaiAnswerDocumentationByIdDocument_,
  usePortfoliosWithAccessToReportQuery,
  PortfoliosWithAccessToReportDocument_,
} from 'models';
import { AvatarGroup, ContentHeader, Select } from 'Molecules';
import { ContentLayout } from 'Molecules/ContentLayout';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import {
  PaiCompanyReportWithMetrics,
  useCompanyPaiReport,
  usePaiCategories,
} from '../CompanyPai.hooks';
import { PaiCategoryContent } from './PaiCategoryContent';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import { PrivateIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { useUserSetting } from 'containers/Navigation';
import { ReportHeaderBox, ReportPeriods } from 'Features/PortfolioView';
import { InsightExtractorButton } from './InsightExtractor';
import { formatDate, formatDateToDDMMYYYY } from 'utils/date';
import { PaiAccessType, PaiReportKey, SharePaiReportModal } from './SharePaiReportModal';

export enum PaiIndicatorStatusFilter {
  all = 'all',
  completed = 'completed',
  todo = 'todo',
}

const StatusFilterSelect = ({
  currentFilter,
  onChange,
}: {
  currentFilter: PaiIndicatorStatusFilter;
  onChange: (val: PaiIndicatorStatusFilter) => void;
}) => {
  const { t } = useTranslation('pai');

  const STATUS_TYPES = [
    { label: t('status.all'), value: PaiIndicatorStatusFilter.all },
    { label: t('status.completed'), value: PaiIndicatorStatusFilter.completed },
    { label: t('status.todo'), value: PaiIndicatorStatusFilter.todo },
  ];

  return (
    <HStack width="200px">
      <Select<{ label: string; value: PaiIndicatorStatusFilter }>
        options={STATUS_TYPES}
        value={STATUS_TYPES.find((s) => s.value === currentFilter) ?? STATUS_TYPES[0]}
        onChange={(value) => onChange(value?.value ?? PaiIndicatorStatusFilter.all)}
        placeholder={t('pai:allInvestors')}
      />
    </HStack>
  );
};

const PortfoliosSelector = ({
  currentFilter,
  onChange,
  options,
}: {
  currentFilter?: string;
  onChange: (val?: string) => void;
  options: { label: string; value: string }[];
}) => {
  const { t } = useTranslation('pai');

  return (
    <HStack width="200px">
      <Select<{ label: string; value: string }>
        options={options}
        value={options.find((s) => s.value === currentFilter)}
        onChange={(value) => onChange(value?.value)}
        placeholder={t('pai:allInvestors')}
      />
    </HStack>
  );
};

const KEY_FOR_PAI_FIELD = {
  [ReportPeriods.q1]: PaiReportKey.q1,
  [ReportPeriods.q2]: PaiReportKey.q2,
  [ReportPeriods.q3]: PaiReportKey.q3,
  [ReportPeriods.q4]: PaiReportKey.q4,
  [ReportPeriods.year]: PaiReportKey.year,
};
export const CompanyPaiReport = () => {
  const { companyId } = useCurrentCompanyId();
  const [statusFilter, setStatusFilter] = useState<PaiIndicatorStatusFilter>(
    PaiIndicatorStatusFilter.all
  );
  const [portfolioFilter, setPortfolioFilter] = useState<string | undefined>(undefined);
  const { t } = useTranslation(['pai', 'question', 'files']);
  const [toggleShare] = useToggleSharePaiWithPortfolioMutation();
  const toast = useToast();
  const { year, period } = useParams();
  const { report, isLoading } = useCompanyPaiReport(
    Number(year),
    period as ReportPeriods,
    companyId ?? ''
  );
  const { data: portfoliosWithAccessData, loading: isLoadingPortfoliosWithAccess } =
    usePortfoliosWithAccessToReportQuery({
      variables: {
        reportId: report?.id ?? '',
        year: Number(year),
        companyId: companyId ?? '',
      },
      skip: !report?.id,
    });

  const portfoliosWithAccessToThisReport = useMemo(() => {
    return (
      portfoliosWithAccessData?.Portfolio.filter(
        (p) => p.companies[0]?.[KEY_FOR_PAI_FIELD[period as ReportPeriods]]
      ) ?? []
    );
  }, [period, portfoliosWithAccessData]);

  const [shouldCloseNew, setShouldCloseNew] = useUserSetting('close-new-questions', new Date(1999));
  const [attachmentBox, setAttachmentBox] = useState<AttachmentBox>();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const categories = usePaiCategories(report);

  const filterByPortfolio = (indicators: PaiCompanyReportWithMetrics['indicators']) => {
    if (!portfolioFilter) {
      return true;
    }
    return indicators.some((i) => i.portfoliosRequesting.some((p) => p.id === portfolioFilter));
  };

  const filterCategories = (isDone?: boolean) => {
    if (statusFilter === PaiIndicatorStatusFilter.all) {
      return true;
    }
    if (statusFilter === PaiIndicatorStatusFilter.completed) {
      return isDone;
    }
    if (statusFilter === PaiIndicatorStatusFilter.todo) {
      return !isDone;
    }
  };

  const onShareResults = (updatedSharedList: PaiAccessType) => {
    const investorsMap = keyBy(report?.portfoliosRequesting, 'id');
    toggleShare({
      variables: {
        objects: Object.values(updatedSharedList).map((portfolioToChange) => ({
          ...portfolioToChange,
          ...(omit(
            investorsMap[portfolioToChange.portfolioId]?.companyMemberships?.filter(
              (c) => c.year === Number(year)
            )[0],
            ['__typename', 'valueOfInvestments']
          ) ?? {}),
          companyId: companyId,
          portfolioId: portfolioToChange.portfolioId,
          [KEY_FOR_PAI_FIELD[period as ReportPeriods]]: portfolioToChange?.[
            KEY_FOR_PAI_FIELD[period as ReportPeriods]
          ]
            ? report?.id
            : null,
          year: Number(year),
        })),
      },
      refetchQueries: [
        PaiReportRequestsDocument_,
        PaiCompanyReportsDocument_,
        PortfoliosWithAccessToReportDocument_,
      ],
    })
      .then(() => {
        onClose();
      })
      .catch(() => {
        toast({
          text: t('companyPaiReport.shareError'),
          variant: 'danger',
        });
      });
  };

  const newMetrics = useMemo(
    () =>
      categories
        ?.map((currentCat) =>
          currentCat?.indicators
            .filter((i) => i.isNew === true)
            .map((indicator) =>
              indicator.investorMetrics
                .map((metric) =>
                  metric.investorMetric.investeeMetrics.map((investee) => {
                    return {
                      investee: investee.metric.reference,
                      requestedBy: indicator.portfoliosRequesting.map((p) => p.ownerCompany.name),
                      category: currentCat.category.reference,
                    };
                  })
                )
                .flat()
            )
            .flat()
            .filter((m) =>
              currentCat?.answers.every((answer) => answer.investeeMetricReference !== m.investee)
            )
            .flat()
        )
        .flat(),
    [categories]
  );

  const newMetricsOwners = useMemo(
    () => uniq(newMetrics.map((nm) => nm.requestedBy).flat()),
    [newMetrics]
  );

  const showInfoBox =
    shouldCloseNew === new Date(1999)
      ? true
      : report?.updatedAt
      ? Number(report?.updatedAt) > Number(shouldCloseNew)
      : true;
  const openAttachmentDrawer = useCallback(
    (box?: AttachmentBox) => {
      setAttachmentBox(box);
      onAttachmentDrawerOpen();
    },
    [attachmentBox]
  );

  const filteredCategories = useMemo(() => {
    return categories
      .filter((c) => filterByPortfolio(c.indicators))
      .filter((cat) => filterCategories(cat?.isDone));
  }, [categories]);

  const newMetricsPerCategory = useMemo(() => {
    return groupBy(newMetrics, 'category');
  }, [newMetrics]);

  const filterPerCategory = useMemo(() => {
    return mapValues(keyBy(categories, 'category.reference'), (cat) =>
      cat.indicators.filter((i: any) => filterByPortfolio([i]))
    );
  }, [categories, filterByPortfolio]);

  const accordionItems = useMemo(() => {
    return filteredCategories.map((currentCat) => ({
      label: currentCat.category?.title,
      tag: [
        <>
          {!!newMetricsPerCategory[currentCat.category.reference] && (
            <Tag minWidth="fit-content" variant="info">
              <Typography variant="bodyStrong" color="text.info">
                {uniqBy(newMetricsPerCategory[currentCat.category.reference], 'investee').length}{' '}
                {t('pai:status.new')}
              </Typography>
            </Tag>
          )}
        </>,
        <Tag variant={currentCat.isDone ? 'success' : 'undefined'}>
          {currentCat.isDone ? t('pai:status.done') : t('pai:status.todo')}
        </Tag>,
      ],
      accordionNb: currentCat?.investeeMetrics?.length,
      content: (
        <PaiCategoryContent
          key={currentCat.category.reference}
          indicators={filterPerCategory[currentCat.category.reference]}
          statusFilter={statusFilter}
          paiReportId={report?.id}
          openAttachmentDrawer={openAttachmentDrawer}
          newMetrics={newMetricsPerCategory[currentCat.category.reference] ?? []}
        />
      ),
    }));
  }, [
    filteredCategories,
    report,
    statusFilter,
    newMetricsPerCategory,
    openAttachmentDrawer,
    filterByPortfolio,
  ]);

  return (
    <ContentLayout
      isLoading={isLoading}
      backButton
      header={
        <ContentHeader
          title={t('companyReport.title')}
          titleExtra={
            <Tag size="xs">
              {year} {`${period !== ReportPeriods.year ? ` - ${period?.toLocaleUpperCase()}` : ''}`}
            </Tag>
          }
          actions={
            <Skeleton height="40px" isLoaded={!isLoadingPortfoliosWithAccess}>
              <HStack spacing="8px">
                {portfoliosWithAccessToThisReport?.length ? (
                  <AvatarGroup
                    names={uniq(
                      portfoliosWithAccessToThisReport.map(
                        (portfolio) => portfolio.ownerCompany?.name
                      )
                    )}
                  />
                ) : (
                  <HStack>
                    <IconButton
                      size="md"
                      variant="ghost"
                      icon={<PrivateIcon />}
                      aria-label="Shared"
                      backgroundColor="bg.unknown"
                      ml="4px"
                      tooltipLabel={t('pai:share.tooltip')}
                      tooltipPlacement={'top-start'}
                    />
                  </HStack>
                )}
                <Button variant="primary" onClick={onOpen}>
                  {t('pai:companyReport.shareResults')}
                </Button>
                <SharePaiReportModal
                  isOpen={isOpen}
                  onClose={onClose}
                  report={report}
                  onConfirm={onShareResults}
                  portfoliosWithAccess={portfoliosWithAccessData}
                  idToCheck={KEY_FOR_PAI_FIELD[period as ReportPeriods]}
                />
              </HStack>
            </Skeleton>
          }
        />
      }
    >
      <Grid templateColumns="repeat(4, 1fr)" gap="12px">
        {report?.portfoliosRequesting?.map((p) => (
          <ReportHeaderBox
            key={p.id}
            header="Request"
            content={[
              {
                label: 'Investor',
                element: <Avatar boxSize="26px" name={p.ownerCompany.name}></Avatar>,
              },
              {
                label: 'Requested',
                element: (
                  <Typography variant="h2">
                    {formatDateToDDMMYYYY(
                      formatDate(
                        (period === ReportPeriods.year
                          ? p.sendOutDates?.year
                            ? new Date(p.sendOutDates?.year)
                            : new Date()
                          : p.sendOutDates?.[period?.toLocaleUpperCase() ?? 'Q1']
                          ? new Date(p.sendOutDates?.[period?.toLocaleUpperCase() ?? 'Q1'])
                          : new Date()) ?? new Date()
                      )
                    )}
                  </Typography>
                ),
              },
              {
                label: 'Deadline',
                element: (
                  <Typography variant="h2">
                    {formatDateToDDMMYYYY(
                      formatDate(
                        (period === ReportPeriods.year
                          ? new Date(p.dueDates?.year)
                          : new Date(p.dueDates?.[period?.toLocaleUpperCase() ?? 'Q1'])) ??
                          new Date()
                      )
                    )}
                  </Typography>
                ),
              },
            ]}
          />
        ))}
      </Grid>
      <HStack paddingY="16px" justifyContent="space-between" width="100%">
        <HStack spacing="10px">
          <StatusFilterSelect currentFilter={statusFilter} onChange={setStatusFilter} />
          <PortfoliosSelector
            currentFilter={portfolioFilter ?? undefined}
            onChange={setPortfolioFilter}
            options={[
              ...(report?.portfoliosRequesting.map((p) => ({
                label: `${p?.name} (${p?.ownerCompany?.name})`,
                value: p.id,
              })) ?? []),
              {
                label: t('pai:allInvestors'),
                value: undefined,
              },
            ]}
          />
          <InsightExtractorButton reportId={report?.id} />
        </HStack>
        <HStack spacing="8px">
          <Typography variant="body">{t('pai:label.progress')}:</Typography>
          {categories.every((c) => c.isDone === true) ? (
            <Tag variant="success">{t('pai:status.done')}</Tag>
          ) : (
            <Tag variant="info">
              {categories.some((c) => c.answers.length)
                ? t('pai:table.status.started')
                : t('pai:table.status.reported')}
            </Tag>
          )}
          ;
        </HStack>
      </HStack>
      {newMetrics.length > 0 && showInfoBox && (
        <Infobox
          title={newMetrics.length + t('pai:infoBox.title')}
          description={
            newMetricsOwners.map((metric, index) => {
              if (newMetricsOwners.length > 1 && index === newMetricsOwners.length - 1)
                return ' and ' + metric;
              else return ' ' + metric;
            }) + t('pai:infoBox.description')
          }
          status="info"
          titleProps={{ variant: 'h3' }}
          marginBottom="16px"
          bg="bg.info"
          color="text.info"
          isVisible={showInfoBox}
          onClose={() => {
            setShouldCloseNew(new Date());
          }}
        />
      )}
      {filteredCategories.length ? (
        <AttachmentDrawer
          isOpen={isAttachmentDrawerOpen}
          refetch={[GetPaiAnswerDocumentationByIdDocument_]}
          onClose={onAttachmentDrawerClose}
          attachmentBox={attachmentBox}
        >
          <VStack
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
            spacing="12px"
            margin="0 auto"
          >
            <Accordion lazyLoadContent variant="solid" items={accordionItems} />
          </VStack>
        </AttachmentDrawer>
      ) : (
        <Box w="100%" flexGrow="1" mt="16px">
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            callToAction={{
              text: t('common:search.filter.emptyBtn'),
              variant: 'secondary',
              onClick: () => {
                setStatusFilter(PaiIndicatorStatusFilter.all);
                setPortfolioFilter(undefined);
              },
              leftIcon: <RefreshIcon color="inherit" />,
            }}
            component={true}
          />
        </Box>
      )}
    </ContentLayout>
  );
};
