import { Controller, useForm, useFormState } from 'react-hook-form';
import { PortfolioCompany, CompanyDetails } from '../../models/types';
import { VStack } from '@chakra-ui/react';
import { FormField, Input, NumberInput } from 'Atoms';
import { CreatableSelect, Modal, RadioCardGroup, RadioCardProps } from 'Molecules';
import { useUserCompaniesQuery } from 'models';
import { useUserData } from '@nhost/react';
import { InputActionMeta } from 'react-select';
import { useState, useEffect, useMemo } from 'react';
import { useCurrentCompany, useDebounce } from 'utils/hooks';
import { useEstimateCompaniesLazyQuery_ } from '../../models/__generated__/graphql';
import { useTranslation } from 'utils/translation';
import { useUpsertPortfolioCompany } from 'containers/Portfolios';
import { useToast } from '../../utils/hooks/useToast';
import { useParams } from 'react-router-dom';

export enum EligibilityStatus {
  notSure = 'NOT_SURE',
  notEligible = 'NOT_ELIGIBLE',
  eligible = 'ELIGIBLE',
}

type EligibilityOption = Omit<RadioCardProps, 'isSelected'>;

type PortfolioFields = {
  amount: number;
  company: {
    id?: string;
    name: string;
    isEstimate?: boolean;
  };
  contactPersonName: string;
  contactPersonEmail: string;
  marketValue?: number;
  eligibilityStatus: EligibilityStatus;
};

export const UserCompanySelect = ({
  value,
  setValue,
  userCompanies,
}: {
  value: PortfolioFields['company'] | undefined;
  setValue: (company: PortfolioFields['company'] | undefined) => void;
  userCompanies: CompanyDetails[];
}) => {
  const [searchEstimateCompanies, { data, loading }] = useEstimateCompaniesLazyQuery_();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const debouncedQuery = useDebounce(searchQuery, 500);

  const { t } = useTranslation('portfolio');

  const estimateCompanies = (data?.companies ?? []).slice(0, 500);

  useEffect(() => {
    if (debouncedQuery === '') {
      searchEstimateCompanies({
        variables: {
          query: '%%',
        },
      });
    } else {
      searchEstimateCompanies({
        variables: {
          query: `%${debouncedQuery}%`,
        },
      });
    }
  }, [debouncedQuery]);

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setSearchQuery(newValue);
    }
  };

  return (
    <CreatableSelect
      value={value}
      isClearable={true}
      isLoading={loading}
      onInputChange={onInputChange}
      options={[
        {
          label: t('portfolio:search.yourCompanies'),
          options: userCompanies.map((c) => ({ ...c, isEstimate: false })),
        },
        {
          label: t('portfolio:search.celsiaEstimates'),
          options: estimateCompanies.map((eCompany) => ({
            id: eCompany.id,
            name: eCompany.name,
            industries: eCompany.industries ?? [],
            isEstimate: true,
          })),
        },
      ]}
      createOptionPosition="first"
      placeholder={t('portfolio:fields.company.placeholder')}
      getOptionValue={(c) => c.id ?? ''}
      getOptionLabel={(c) => c.name}
      getNewOptionData={(_input, label) => ({
        id: undefined,
        name: label as string,
        industries: [],
        isEstimate: false,
      })}
      onChange={(company) => {
        setValue(company ?? undefined);
        setSearchQuery('');
      }}
      formatCreateLabel={(input) => t('portfolio:createNew', { name: input })}
    />
  );
};

export const PortfolioCompanyEditorModal = ({
  portfolioCompany,
  isOpen,
  onClose,
  reportYear,
  currentPortfolioCompanyIds,
}: {
  portfolioCompany?: PortfolioCompany;
  isOpen: boolean;
  onClose: () => void;
  reportYear?: number;
  currentPortfolioCompanyIds: string[];
}) => {
  const { t } = useTranslation(['portfolio', 'common']);
  const { portfolioId, companyId } = useParams();
  const toast = useToast();
  const user = useUserData();
  const upsertPortfolioCompany = useUpsertPortfolioCompany();
  const { company: currentCompany } = useCurrentCompany();
  const initialValueOfInvestment = useMemo(() => {
    if (!portfolioCompany?.valueOfInvestments) return 0;
    const { q1 = 0, q2 = 0, q3 = 0, q4 = 0 } = portfolioCompany?.valueOfInvestments;
    return (q1 + q2 + q3 + q4) / 4;
  }, [portfolioCompany]);

  const { data } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user?.id,
  });

  const eligibilityOptions: EligibilityOption[] = [
    {
      title: t('portfolio:eligibilityOptions.notSure.title'),
      value: EligibilityStatus.notSure,
      subtitle: t('portfolio:eligibilityOptions.notSure.subTitle'),
    },
    {
      title: t('portfolio:eligibilityOptions.eligible.title'),
      value: EligibilityStatus.eligible,
      subtitle: t('portfolio:eligibilityOptions.eligible.subTitle'),
    },
    {
      title: t('portfolio:eligibilityOptions.nonEligible.title'),
      value: EligibilityStatus.notEligible,
      subtitle: t('portfolio:eligibilityOptions.nonEligible.subTitle'),
    },
  ];

  const { register, control, handleSubmit, reset, getValues } = useForm<PortfolioFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      amount: initialValueOfInvestment ?? undefined,
      contactPersonName: portfolioCompany?.contactPersonName ?? undefined,
      contactPersonEmail: portfolioCompany?.contactPersonEmail ?? undefined,
      marketValue: portfolioCompany?.marketValue ?? undefined,
      eligibilityStatus:
        (portfolioCompany?.eligibilityStatus as EligibilityStatus) ?? EligibilityStatus.notSure,
    },
  });

  useEffect(() => {
    if (portfolioCompany) {
      reset({
        amount: initialValueOfInvestment ?? undefined,
        company: {
          id: portfolioCompany?.company?.id,
          name: portfolioCompany?.company?.name,
          isEstimate: !!portfolioCompany?.estimateCompany,
        },
        contactPersonName: portfolioCompany?.contactPersonName ?? undefined,
        contactPersonEmail: portfolioCompany?.contactPersonEmail ?? undefined,
        marketValue: portfolioCompany?.marketValue ?? undefined,
        eligibilityStatus: portfolioCompany?.eligibilityStatus as EligibilityStatus,
      });
    }
  }, [portfolioCompany]);
  const { isValid, isDirty, errors } = useFormState({ control });

  const handleSave = async (editedPortfolioCompany: PortfolioFields) => {
    const { company } = editedPortfolioCompany;
    const c = company?.isEstimate
      ? { estimateCompany: { id: company.id } }
      : {
          company: {
            id: company?.id,
            name: company?.name,
            currency: currentCompany?.currency ?? 'NOK',
            industries: [],
          },
        };

    const newPc = await upsertPortfolioCompany({
      ...c,
      amount: editedPortfolioCompany.amount,
      marketValue: editedPortfolioCompany.marketValue,
      contactPersonName: editedPortfolioCompany.contactPersonName,
      contactPersonEmail: editedPortfolioCompany.contactPersonEmail,
      portfolioId: portfolioId ?? '',
      eligibilityStatus: editedPortfolioCompany.eligibilityStatus,
      year: reportYear,
    });

    if (newPc)
      toast({
        text: currentCompany
          ? t('portfolio:toast.companyUpdated', {
              companyName: newPc.company?.name ?? newPc.estimateCompany?.name,
            })
          : t('portfolio:toast.companyCreated', {
              companyName: newPc.company?.name ?? newPc.estimateCompany?.name,
            }),
      });
    reset({
      amount: undefined,
      company: undefined,
      contactPersonName: undefined,
      contactPersonEmail: undefined,
      marketValue: undefined,
      eligibilityStatus: EligibilityStatus.notSure,
    });
    onClose();
  };
  return (
    <Modal
      title={
        !portfolioCompany && !reportYear
          ? `${t('portfolio:addAsset')}`
          : !portfolioCompany
          ? `${t('portfolio:addAsset')} for ${reportYear}`
          : `${t('portfolio:editAsset')} for ${reportYear}`
      }
      isOpen={isOpen}
      onClose={onClose}
      confirmText={!portfolioCompany ? t('portfolio:addAsset') : t('portfolio:updateAsset')}
      confirmButtonProps={{
        type: 'submit',
        form: 'portfolio-company-editor-form',
        isDisabled: !isValid || !isDirty,
      }}
    >
      <form onSubmit={handleSubmit(handleSave)} id="portfolio-company-editor-form">
        <VStack spacing="md">
          {!portfolioCompany && (
            <Controller
              name="company"
              control={control}
              rules={{ required: t('portfolio:required.name') }}
              render={({ field: { onChange, value } }) => (
                <FormField
                  label={t('common:fields.companyName.label')}
                  id="name"
                  isRequired
                  isInvalid={!!errors.company}
                  error={t('portfolio:required.name')}
                >
                  <UserCompanySelect
                    value={value}
                    setValue={(company) => company && onChange(company)}
                    userCompanies={
                      data?.data?.companies
                        ?.map(({ company }) => company)
                        ?.filter(
                          (company) =>
                            !currentPortfolioCompanyIds.includes(company.id) &&
                            company.id !== companyId
                        ) ?? []
                    }
                  />
                </FormField>
              )}
            />
          )}

          {!portfolioCompany && (
            <Controller
              name="marketValue"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormField
                  label={t('portfolio:fields.marketValue.label')}
                  id="amount"
                  isInvalid={!!errors.marketValue}
                  error={errors.marketValue?.message}
                  helpText={t('portfolio:fields.marketValue.tooltip')}
                >
                  <NumberInput
                    placeholder="999999"
                    unit={currentCompany?.currency}
                    value={value}
                    onChange={(v) => onChange(Number(v))}
                    min={0}
                    max={Number.MAX_VALUE}
                    keepWithinRange
                    clampValueOnBlur
                    replaceNanWithZero
                  />
                </FormField>
              )}
            />
          )}
          {!portfolioCompany && (
            <Controller
              name="amount"
              control={control}
              rules={{
                required: t('portfolio:required.allocation'),
                validate: {
                  allocationValidation: (value) =>
                    value <= (getValues('marketValue') ?? 0) || t('portfolio:allocationValidation'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormField
                  label={t('portfolio:fields.amount.label')}
                  id="amount"
                  isRequired
                  isInvalid={!!errors.amount}
                  error={errors.amount?.message}
                  helpText={
                    'This value will be set for all quarters. You can later edit each quarter individually.'
                  }
                >
                  <NumberInput
                    placeholder="999999"
                    unit={currentCompany?.currency}
                    value={value}
                    onChange={(v) => onChange(Number(v))}
                    min={0}
                    max={Number.MAX_VALUE}
                    keepWithinRange
                    clampValueOnBlur
                    replaceNanWithZero
                  />
                </FormField>
              )}
            />
          )}
          <Controller
            name="contactPersonName"
            control={control}
            rules={{ required: t('portfolio:required.contactName') }}
            render={({ field: { onChange, value } }) => (
              <FormField
                label={t('portfolio:fields.contactPerson.name.label')}
                id="contactPersonName"
                isRequired
                isInvalid={!!errors.contactPersonName}
                error={errors.contactPersonName?.message}
              >
                <Input
                  width="100%"
                  placeholder="John Doe"
                  {...register('contactPersonName')}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              </FormField>
            )}
          />

          <Controller
            name="contactPersonEmail"
            control={control}
            rules={{ required: t('portfolio:required.contactEmail') }}
            render={({ field: { onChange, value } }) => (
              <FormField
                label={t('portfolio:fields.contactPerson.email.label')}
                id="contactPersonEmail"
                isRequired
                isInvalid={!!errors.contactPersonEmail}
                error={errors.contactPersonEmail?.message}
              >
                <Input
                  width="100%"
                  placeholder="John.doe@celsia.io"
                  {...register('contactPersonEmail')}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              </FormField>
            )}
          />

          <Controller
            name="eligibilityStatus"
            control={control}
            rules={{ required: t('portfolio:required.eligibilityStatus') }}
            render={({ field: { value, onChange } }) => (
              <FormField
                label={t('portfolio:fields.eligibilityStatus.label')}
                id="eligibilityStatus"
                isRequired
                isInvalid={!!errors.eligibilityStatus}
                error={errors.eligibilityStatus?.message}
                helpText={t('portfolio:fields.eligibilityStatus.tooltip')}
              >
                <VStack width="100%">
                  <RadioCardGroup
                    options={eligibilityOptions}
                    value={value}
                    onChange={(newStatus) => onChange(newStatus as EligibilityStatus)}
                    cardWidth="455px"
                  />
                </VStack>
              </FormField>
            )}
          />
        </VStack>
      </form>
    </Modal>
  );
};
