import { useMemo } from 'react';
import { Typography } from 'Tokens';
import {
  isFrequencyYearly,
  MetricsTableData,
  useAggregatedRowValue,
  usePercentageValues,
} from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import { TimePeriodsEnums } from '../../Requirement';
import { Tooltip } from 'Atoms';
import { Skeleton } from '@chakra-ui/react';

export const PercentageValueView = ({ value, row }: { value: string; row: MetricsTableData }) => {
  const tags = useMemo(() => {
    return row?.tags?.map((x) => x) ?? [];
  }, [row]);

  if (value)
    return (
      <Tooltip
        label={`${row.metric.title} ${tags.length ? ' by ' : ''} ${tags.map(
          (x) => x.tagValue
        )}: ${value.replace('(', '').replace(')', '')}`}
        cursor="pointer"
      >
        <span style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '4px' }}> {value}</span>
      </Tooltip>
    );

  return null;
};

export const MetricYear = ({
  row,
  companyStandardId,
  companyReportingUnit,
}: {
  row: MetricsTableData;
  companyStandardId: string;
  companyReportingUnit?: string;
}) => {
  const { dataPointsPerQuarter, dataPointPerYear, isAggregated } = useGetDatapointValues(
    row,
    companyReportingUnit
  );

  const isYearly = isFrequencyYearly(row, companyStandardId);

  const { result: aggregatedValues, isLoading } = useAggregatedRowValue(
    row,
    isYearly,
    companyReportingUnit
  );

  const showAsPercentage = useMemo(() => {
    return row.metric.showAsPercentage ?? false;
  }, [row]);

  const quartersTotal = useMemo(() => {
    const quartersValues = dataPointsPerQuarter.map((dp) => dp.value?.value);
    return quartersValues.reduce((a, b) => Number(a ?? 0) + Number(b ?? 0), 0);
  }, [dataPointsPerQuarter]);

  const percentageValue = usePercentageValues(
    row,
    TimePeriodsEnums.year,
    isYearly,
    isAggregated ? aggregatedValues.Year : isYearly ? dataPointPerYear?.value ?? 0 : quartersTotal
  );

  if (isLoading) return <Skeleton height="20px" width="100px" />;
  if (isAggregated)
    return (
      <Typography variant="body" width="100px">
        {aggregatedValues.Year}{' '}
        {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
      </Typography>
    );
  return (
    <Typography variant="body" width="100px">
      {isYearly ? dataPointPerYear?.value ?? 0 : quartersTotal}{' '}
      {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
    </Typography>
  );
};
