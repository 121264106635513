import React, { useContext, useEffect } from 'react';
import { User } from 'models';
import { useUserData } from '@nhost/react';
import { HStack, SkeletonCircle } from '@chakra-ui/react';
import { UserAvatar } from 'Organisms/UserAvatar';
import { Typography } from 'Tokens/Typography';
import { getFirstName } from 'utils/userFirstName';
import { useTranslation } from 'utils/translation';
import { useCurrentCompany } from 'utils/hooks';
import { GlobalContext, useFeatureFlags, useUserSetting } from 'containers/Navigation';
import { HomeWelcomeBox } from './HomeWelcomeBox';

export const CELSIA_WELCOME_MODAL = 'celsia_welcome_modal_is_closed';

export const HomeWelcomeSection = () => {
  const { t } = useTranslation('home');
  const user: User | null = useUserData();
  const firstName = getFirstName(user);
  const { company } = useCurrentCompany();
  const { showInfoBoxes, setShowInfoBoxes } = useContext(GlobalContext);
  const { community: hasCommunityAccess } = useFeatureFlags({ company });

  const [isWelcomeModalClosed, onToggleWelcomeModal] = useUserSetting(CELSIA_WELCOME_MODAL, false);
  useEffect(() => {
    if (showInfoBoxes) {
      onToggleWelcomeModal(false);
    }
  }, [showInfoBoxes]);

  useEffect(() => {
    if (isWelcomeModalClosed) setShowInfoBoxes(false);
  }, [isWelcomeModalClosed]);
  return (
    <>
      <HStack mb="24px" spacing="6px">
        {user != null ? (
          <>
            <Typography variant="h1" color="text.default">
              {t('home:welcomeModal.welcomeCelsia,')}
            </Typography>
            <SkeletonCircle isLoaded={user != null} boxSize="20px">
              <UserAvatar size="xs" user={user} />
            </SkeletonCircle>
            <Typography variant="h1" color="text.default">
              {firstName}
            </Typography>
          </>
        ) : (
          <Typography variant="h2" color="text.default">
            {t('home:welcomeModal.welcomeCelsia')}
          </Typography>
        )}
      </HStack>
      <HomeWelcomeBox
        isWelcomeModalClosed={isWelcomeModalClosed}
        onToggleWelcomeModal={onToggleWelcomeModal}
        hasCommunityAccess={hasCommunityAccess}
      />
    </>
  );
};
