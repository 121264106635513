import { Skeleton, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentCompany } from 'utils/hooks';
import { QuestionType } from 'utils/scores/questions';
import { MetricViewEnums } from '../../DisclosureRequirements';
import { CompletionStatus, ReportStatusBar } from '../ReportUtils';
import { ReportMetricTable } from './ReportMetricTable';
import { Button } from 'Atoms';
import { DataCollectionLevel } from '../../DataCollection';
import { getMetricData, useIsMetricHidden } from '../Report.hooks';
import {
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetMetricAnswersQuery_,
  GetReportMetricsPerDisclosureQuery_,
  useHideMetricMutation,
} from 'models';
import { useAssessmentReportingUnits } from 'containers/Esrs';

type ReportChildrenMetrics =
  GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'][number]['childrenMetrics'];

const NarrativeMetricCard = ({
  metric,
  standardRef,
  drRef,
  standardName,
  isDrHidden,
  isParentHidden,
  loadingReportAnswers,
  reportingUnitsAnswers,
  groupAnswers,
  companyAnswers,
  firstBUReportingUnitId,
  companyReportingUnitId,
  isPreview,
}: {
  metric?: GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'][number];
  childMetrics: GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'];
  isDrHidden?: boolean;
  standardRef: string;
  drRef: string;
  standardId: string;
  standardName: string;
  isParentHidden: boolean;
  loadingReportAnswers: boolean;
  reportingUnitsAnswers?: GetAnswersForMetricsOnCompanyLevelQuery_;
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_;
  companyAnswers?: GetMetricAnswersQuery_;
  firstBUReportingUnitId: string;
  companyReportingUnitId: string;
  isPreview: boolean;
}) => {
  const { esrsAssessmentId = '' } = useParams();

  const company = useCurrentCompany();

  const metricData = useMemo(() => {
    if (!metric) {
      return null;
    }
    return getMetricData(
      metric,
      metric?.materialStandardId,
      standardRef,
      companyAnswers,
      reportingUnitsAnswers,
      groupAnswers,
      company.company?.isGroupOwner,
      companyReportingUnitId,
      firstBUReportingUnitId,
      []
    );
  }, [metric, reportingUnitsAnswers, groupAnswers, companyAnswers, company]);

  const answer = useMemo(() => metricData?.textAnswer, [metric]);

  const { isHidden, loading: isLoadingMetricVisibility } = useIsMetricHidden(
    metric?.metricRef ?? '',
    esrsAssessmentId
  );
  const [onHideMetric, { loading: isHidingMetric }] = useHideMetricMutation();

  const provideAnswer = () => {
    if (!!metric) {
      window.open(
        metric?.dataCollection === DataCollectionLevel.company
          ? `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric.metricRef}`
          : `/${company?.company?.id}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${metricData?.reportingUnitId}/disclosure-requirement/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric?.metricRef}`
      );
    }
  };
  const status = useMemo(() => {
    if (isHidden) {
      return CompletionStatus.hidden;
    }
    if (metricData?.completed) {
      return CompletionStatus.complete;
    }
    return CompletionStatus.incomplete;
  }, [metricData, isHidden]);

  const shouldShowTitle = useMemo(() => {
    if (!isPreview) {
      return true;
    }
    if (metric?.childrenMetrics.length) return true;
    return !answer;
  }, [isPreview, metric, answer]);

  return (
    <VStack alignItems="start" gap="6px" w="100%">
      {!isPreview && metric && (
        <ReportStatusBar
          metric={metric?.metric}
          status={status}
          isMetricHidden={isParentHidden || isDrHidden || isHidden}
          standardName={standardName}
          standardRef={standardRef}
          drRef={drRef}
          dataCollection={metric?.dataCollection ?? ''}
          completed={metricData?.completed ?? false}
          onHide={(newVal) => {
            onHideMetric({
              variables: {
                metricRef: metric.metricRef,
                assessmentId: esrsAssessmentId,
                isHidden: newVal,
              },
            });
          }}
          isLoading={isLoadingMetricVisibility || isHidingMetric}
        />
      )}
      <Skeleton isLoaded={!loadingReportAnswers}>
        <VStack alignItems="start" opacity={isHidden ? 0.3 : 1} spacing="4px">
          {shouldShowTitle && <Typography variant="bodyStrong">{metric?.metric?.title}</Typography>}
          {metric?.childrenMetrics?.length ? (
            <Typography variant="body" opacity={0.46} pb="8px">
              {isPreview ? '' : 'Answered in sub-metrics'}
            </Typography>
          ) : answer ? (
            <Typography variant="body" whiteSpace="pre-wrap">
              {answer}
            </Typography>
          ) : (
            <VStack gap="12px" alignItems="start">
              <Typography variant="body" color="text.hint">
                No answer has been provided yet
              </Typography>
              {!isPreview && (
                <Button variant="secondary" size="sm" onClick={provideAnswer}>
                  Provide answer
                </Button>
              )}
            </VStack>
          )}
        </VStack>
      </Skeleton>
    </VStack>
  );
};

export const ReportNarrativeMetricsCard = ({
  metric,
  standardRef,
  drRef,
  standardId,
  standardName,
  childMetrics,
  isDrHidden,
  loadingReportAnswers,
  reportingUnitsAnswers,
  groupAnswers,
  companyAnswers,
  isPreview,
}: {
  metric: GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'][number];
  standardRef: string;
  drRef: string;
  standardId: string;
  standardName: string;
  childMetrics: GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'];
  isDrHidden: boolean;
  loadingReportAnswers: boolean;
  reportingUnitsAnswers?: GetAnswersForMetricsOnCompanyLevelQuery_;
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_;
  companyAnswers?: GetMetricAnswersQuery_;
  isPreview: boolean;
}) => {
  const company = useCurrentCompany();
  const { esrsAssessmentId = '' } = useParams();
  const { isHidden, loading: isLoadingMetricVisibility } = useIsMetricHidden(
    metric.metricRef,
    esrsAssessmentId
  );

  const { reportingUnitAssessments } = useAssessmentReportingUnits(esrsAssessmentId);

  const [companyReportingUnitId, firstBUReportingUnitId] = useMemo(
    () => [
      reportingUnitAssessments.find((ru) => ru.isCompanyLevel)?.id ?? '',
      reportingUnitAssessments.filter((ru) => !ru.isCompanyLevel)?.[0]?.id ?? '',
    ],
    [reportingUnitAssessments]
  );

  const [onHideMetric, { loading: isHidingMetric }] = useHideMetricMutation();

  const metricData = useMemo(() => {
    return getMetricData(
      metric,
      metric.materialStandardId,
      standardRef,
      companyAnswers,
      reportingUnitsAnswers,
      groupAnswers,
      company.company?.isGroupOwner,
      companyReportingUnitId,
      firstBUReportingUnitId,
      []
    );
  }, [metric, reportingUnitsAnswers, groupAnswers, companyAnswers, company]);

  const provideAnswer = () => {
    window.open(
      metric?.dataCollection === DataCollectionLevel.company
        ? `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric.metric.reference}`
        : `/${company?.company?.id}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${metricData.reportingUnitId}/disclosure-requirement/${drRef}/${MetricViewEnums.dataInput}?metricRef=${metric.metric.reference}`
    );
  };
  const status = useMemo(() => {
    if (isHidden) {
      return CompletionStatus.hidden;
    }
    if (metricData?.completed) {
      return CompletionStatus.complete;
    }
    return CompletionStatus.incomplete;
  }, [metric, isHidden]);

  const mapNarrativeChildMetrics = (metrics: ReportChildrenMetrics) => {
    return metrics.map((m) => {
      return (
        <VStack
          key={`${m.childMetric?.reference}-Prepare`}
          alignItems="start"
          ml={isPreview ? '0px' : '28px'}
          borderTop={isPreview ? '0px' : '1px solid'}
          borderColor="border.decorative"
          pt="6px"
          spacing="8px"
          minW={`calc(100% - 28px)`}
          pb="8px"
        >
          <NarrativeMetricCard
            metric={childMetrics.find((met) => met.metricRef === m.childMetric?.reference)}
            childMetrics={childMetrics}
            isDrHidden={isDrHidden}
            standardRef={standardRef}
            drRef={drRef}
            standardId={standardId}
            standardName={standardName}
            isParentHidden={isHidden}
            loadingReportAnswers={loadingReportAnswers}
            reportingUnitsAnswers={reportingUnitsAnswers}
            groupAnswers={groupAnswers}
            companyAnswers={companyAnswers}
            firstBUReportingUnitId={firstBUReportingUnitId}
            companyReportingUnitId={companyReportingUnitId}
            isPreview={isPreview}
          />
          {m.childMetric?.childrenMetrics.length &&
            mapNarrativeChildMetrics(m.childMetric.childrenMetrics as ReportChildrenMetrics)}
        </VStack>
      );
    });
  };
  return (
    <VStack
      key={`${standardRef}-${metric?.metricRef}`}
      alignItems="start"
      borderTop={isPreview ? '0px' : '1px solid'}
      w="100%"
      borderColor="border.decorative"
      pt="6px"
      spacing="6px"
      pb="16px"
      id={`${standardRef}-${metric?.metricRef}`}
    >
      {!isPreview && (
        <ReportStatusBar
          metric={metric?.metric}
          status={status}
          isMetricHidden={isHidden}
          standardName={standardName}
          standardRef={standardRef}
          drRef={drRef}
          dataCollection={metric?.dataCollection ?? ''}
          completed={metricData.completed ?? false}
          onHide={(newVal) =>
            onHideMetric({
              variables: {
                metricRef: metric?.metricRef,
                assessmentId: esrsAssessmentId,
                isHidden: newVal,
              },
            })
          }
          shouldHideCompletion={metric?.childrenMetrics?.length > 0}
          isLoading={isLoadingMetricVisibility || isHidingMetric}
        />
      )}
      <Skeleton isLoaded={!loadingReportAnswers} w="100%">
        <VStack alignItems="start" w="100%" spacing="4px">
          {metric?.childrenMetrics?.length ? (
            <VStack alignItems="start" gap="20px" w="100%">
              <VStack alignItems="start" gap="4px" opacity={isHidden ? 0.3 : 1}>
                <Typography variant="bodyStrong"> {metric.metric.title} </Typography>
                {!isPreview && (
                  <Typography variant="body" opacity={0.46}>
                    {metricData.completed
                      ? 'Answered in sub-metrics'
                      : 'Should be answered in sub-metrics'}
                  </Typography>
                )}
              </VStack>
              <VStack spacing="8px" w="100%" alignItems="start">
                {mapNarrativeChildMetrics(metric?.childrenMetrics)}
              </VStack>
            </VStack>
          ) : metricData.completed ? (
            metric?.metric.metricType === QuestionType.LongText_ ? (
              <VStack gap="4px" alignItems="start">
                <Typography variant="bodyStrong">{metric?.metric.title}</Typography>
                <Typography variant="body" whiteSpace="pre-wrap">
                  {metricData?.textAnswer}
                </Typography>
              </VStack>
            ) : (
              <VStack gap="4px" alignItems="start">
                <Typography variant="bodyStrong">{metric?.metric.title}</Typography>
                <ReportMetricTable
                  metrics={[metricData.tableData]}
                  currency={company.company?.currency ?? ''}
                  standardId={standardId}
                />
              </VStack>
            )
          ) : (
            <VStack gap="4px" alignItems="start">
              <Typography variant="bodyStrong">{metric?.metric.title}</Typography>
              <VStack alignItems="start" gap="12px">
                <Typography variant="body" color="text.hint">
                  No answer has been provided yet
                </Typography>
                {!isPreview && (
                  <Button variant="secondary" size="sm" onClick={provideAnswer}>
                    Provide answer
                  </Button>
                )}
              </VStack>
            </VStack>
          )}
        </VStack>
      </Skeleton>
    </VStack>
  );
};
