import { Box, HStack } from '@chakra-ui/react';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Button, IconButton, TruncatableText } from 'Atoms';
import { HelpTooltip } from 'Molecules';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import {
  ArrowCornerDownRight,
  ArrowNarrowRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from 'Tokens/Icons/Direction';
import { SelectedMetric } from 'containers/Esrs';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { isEqual } from 'lodash';
import { User } from 'models';
import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { QuartersEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { MetricsTableData, isFrequencyYearly } from '../MetricAnswers.hooks';
import { MetricRowTitle, searchForParentMetric } from './MetricsUtils';
import { MetricMdrmStatus } from './MetricMDRMStatus';
import { MetricOwnerAvatar } from './MetricOwnerSelect';
import { MetricProgress } from './MetricProgress';
import { MetricYear } from './MetricYear';
import { QuarterInput } from './QuarterInput';

export const MetricsInputTable = ({
  metrics,
  selectedQuarter,
  onDrawerOpen,
  onOpen,
  setSelectedMetric,
  companyStandardId,
  isOverview,
  areMetricsYearly,
  currency,
  companyReportingUnit,
  isGroup,
  esrsAssessmentProjectLeader,
  rowData,
  setRowData,
  pageSize = 5,
  filteredMetrics,
  setNumberOfRows,
  isMetricDr,
}: {
  metrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  onDrawerOpen: () => void;
  onOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  companyStandardId: string;
  isOverview: boolean;
  areMetricsYearly: boolean;
  currency: string;
  companyReportingUnit?: string;
  isCompanyLevel?: boolean;
  isGroup?: boolean;
  esrsAssessmentProjectLeader?: Partial<User>;
  rowData?: MetricsTableData;
  setRowData: (param: MetricsTableData | undefined) => void;
  pageSize?: number;
  filteredMetrics?: MetricsTableData[];
  setNumberOfRows: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isMetricDr: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const isYearly = useMemo(
    () => isFrequencyYearly(metrics[0], companyStandardId),
    [metrics, companyStandardId]
  );

  const nestedColumns: ColumnDefResolved<TableData<MetricsTableData>, any>[] | undefined =
    useMemo(() => {
      const goToSource = (ref: string) => {
        const element = document.getElementById(ref);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'smooth',
              inline: 'center',
            });
          });
        } else {
          const mainParent = searchForParentMetric(filteredMetrics ?? [], ref);
          const element = document.getElementById(mainParent?.metric.reference ?? '');
          if (element) {
            setTimeout(() => {
              element.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
              });
            });
          }
          // expand main parent
          setNumberOfRows((prevStates) => ({
            ...prevStates,
            [mainParent?.metric.reference ?? '']:
              (prevStates[mainParent?.metric.reference ?? ''] || 5) + 100,
          }));
        }
      };

      const handleRowClick = (row: MetricsTableData) => {
        if (!row.referenceToSource) {
          if (openDrawer && !!urlDatapointId) navigate(pathname.split('?')[0]);
          if (isEqual(rowData, row)) {
            setRowData(undefined);
          } else setRowData(row);
        }
      };

      const columns: ColumnDefResolved<TableData<MetricsTableData>, any>[] = [
        {
          id: 'expander',
          meta: {
            width: '36px',
            padding: '0px',
          },
          cell: ({ row }) =>
            row.getCanExpand() &&
            !row.original.referenceToSource && (
              <Box onClick={(e) => e.stopPropagation()}>
                <IconButton
                  variant={'ghost'}
                  size="md"
                  onClick={row.getToggleExpandedHandler()}
                  aria-label="expand"
                  icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                />
              </Box>
            ),
        },
        {
          header: 'Metric',
          meta: {
            width: '40%',
          },
          accessorKey: 'metric',
          cell: ({ row }) => {
            return row.original.referenceToSource ? (
              <HStack pl={`${row.depth * 24}px`} spacing="4px">
                <ArrowCornerDownRight color="text.default" />
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => goToSource(row.original.referenceToSource ?? '')}
                >
                  Go to sub-metrics
                </Button>
                <HelpTooltip
                  placement="bottom"
                  label={
                    'This metric is calculated using several sub-metrics that have been previously reported. For details, go to sub-metrics'
                  }
                />
              </HStack>
            ) : (
              <HStack
                spacing="8px"
                id={
                  !row.subRows.some((subrow) => subrow.original.referenceToSource)
                    ? row.original.metric.reference
                    : undefined
                }
              >
                <MetricRowTitle
                  companyStandardId={companyStandardId}
                  rowData={rowData}
                  row={row}
                  onClick={() => handleRowClick(row.original)}
                />
              </HStack>
            );
          },
        },
        {
          header: 'Unit',
          accessorKey: 'unit',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <TruncatableText
                variant="body"
                text={mapUnitToCompanyCurrency(
                  row.original.metric?.unitOfMeasurement ?? 'NA',
                  currency
                )}
              />
            ),
        },
        {
          header: QuartersEnums.q1,
          accessorKey: 'quarter1',
          meta: {
            width: '18%',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q1}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
              />
            ),
        },
        {
          header: QuartersEnums.q2,
          accessorKey: 'quarter2',
          meta: {
            width: '18%',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q2}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
              />
            ),
        },
        {
          header: QuartersEnums.q3,
          accessorKey: 'quarter3',
          meta: {
            width: '18%',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q3}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
              />
            ),
        },
        {
          header: QuartersEnums.q4,
          accessorKey: 'quarter4',
          meta: {
            width: '18%',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q4}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
              />
            ),
        },
        {
          header: selectedQuarter !== TimePeriodsEnums.year ? selectedQuarter : 'Total',
          accessorKey: 'quarter', // Used as "YEAR" when selectedQuarter is year on input view!
          meta: {
            width: '18%',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={selectedQuarter}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                setRowData={setRowData}
              />
            ),
        },
      ];

      if (selectedQuarter === TimePeriodsEnums.year || isOverview || isGroup) {
        columns.push({
          header: 'Total',
          accessorKey: 'yearly',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <MetricYear
                row={row.original}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
              />
            ),
        });
      }

      if (isOverview && !isYearly) {
        columns.push({
          header: 'Collected',
          meta: {
            width: '8%',
          },
          accessorKey: 'collected',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <MetricProgress
                row={row.original}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
              />
            ),
        });
      }

      if (isMetricDr) {
        columns.push({
          header: 'MDR-M',
          meta: {
            width: '10%',
          },
          accessorKey: 'mdr-m',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <MetricMdrmStatus
                row={row.original}
                companyReportingUnit={companyReportingUnit}
                selectedQuarter={selectedQuarter}
                materialStandardId={companyStandardId}
              />
            ),
        });
      }

      columns.push(
        {
          header: 'Owner',
          meta: {
            width: '10%',
          },
          accessorKey: 'ownerId',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <MetricOwnerAvatar
                row={row.original}
                selectedQuarter={selectedQuarter}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeader={esrsAssessmentProjectLeader}
              />
            ),
        },
        {
          header: '',
          meta: {
            width: '5%',
          },
          accessorKey: 'actions',
          cell: ({ row }) => {
            return (
              !row.original.referenceToSource && (
                <IconButton
                  className="metricArrow"
                  aria-label="side-bar"
                  variant="ghost"
                  icon={<ArrowNarrowRightIcon />}
                  onClick={() => handleRowClick(row.original)}
                />
              )
            );
          },
        }
      );

      return columns;
    }, [
      selectedQuarter,
      onDrawerOpen,
      onOpen,
      setSelectedMetric,
      companyStandardId,
      isOverview,
      areMetricsYearly,
      isMetricDr,
      pageSize,
    ]);

  const filteredColumns = useMemo(() => {
    const quarterFields = ['quarter1', 'quarter2', 'quarter3', 'quarter4'];
    if (isOverview) {
      if (areMetricsYearly) {
        return nestedColumns.filter(
          (col) => col.accessorKey !== 'quarter' && !quarterFields.includes(col.accessorKey ?? '')
        );
      }
      return nestedColumns.filter((col) => col.accessorKey !== 'quarter');
    }
    if (selectedQuarter === TimePeriodsEnums.year)
      return nestedColumns.filter(
        (column) =>
          !quarterFields.includes(column.accessorKey ?? '') && column.accessorKey !== 'yearly'
      );
    return nestedColumns.filter((col) => !quarterFields.includes(col.accessorKey ?? ''));
  }, [nestedColumns, selectedQuarter]);

  return (
    <NestedTable<MetricsTableData>
      columns={filteredColumns}
      data={metrics}
      expanded={true}
      pageSize={pageSize}
      rowProps={(row) => ({
        bg: isEqual(row, rowData) ? 'bg.hover' : '',
        transition: '0.1s',
        _hover: {
          transition: '0.15s ease-in-out',
          bg: 'bg.hover',
          '&:hover .metricTitle': {
            textDecoration: 'underline',
            textDecorationColor: 'text.hint',
            textUnderlineOffset: '2px',
          },
          '&:hover .metricArrow': {
            bg: 'bg.hover',
          },
        },
      })}
    />
  );
};
