import { Box, HStack, Image, TagProps } from '@chakra-ui/react';
import { Table } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { Typography } from 'Tokens';
import {
  EsrsAssessmentDocument_,
  EsrsAssessmentFieldsFragment_,
  EsrsAssessmentsDocument_,
  useDeleteEsrsAssessmentMutation,
} from 'models';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'Atoms/Tag';
import { Tooltip } from 'Atoms';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { Menu } from 'Molecules/Menu';
import { useMemo } from 'react';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { ColumnDef } from '@tanstack/react-table';

export const AccessTag = ({
  logo,
  name,
  ...props
}: Omit<TagProps, 'variant' | 'size'> & { logo?: string; name: string }) => {
  return (
    <Tag {...props}>
      <HStack spacing="6px" alignItems="center">
        <Typography variant="bodyStrong">Shared with:</Typography>
        {logo ? (
          <Tooltip label={name}>
            <Image src={logo} w="20px" h="20px" borderRadius="4px" />
          </Tooltip>
        ) : (
          <Tooltip label={name}>
            <Box h="20px">
              <CompanyIcon
                color="text.onAccent"
                bgColor="bg.accent"
                padding="6px"
                w="20px"
                h="20px"
                borderRadius="4px"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
    </Tag>
  );
};

export const EsrsGroupAssessmentTable = ({
  esrsGroupAssessments,
  isGroup,
}: {
  esrsGroupAssessments: EsrsAssessmentFieldsFragment_[];
  isGroup: boolean;
}) => {
  const { t } = useTranslation(['esrs']);
  const navigate = useNavigate();

  const [deleteAssessment, { loading }] = useDeleteEsrsAssessmentMutation();
  const hasParent = useMemo(() => {
    return esrsGroupAssessments.some((assessment) => assessment.parentAssessment !== null);
  }, [esrsGroupAssessments]);

  const columns: ColumnDef<EsrsAssessmentFieldsFragment_>[] = [
    {
      header: t('esrs:assessment.form.reportingDate'),
      id: 'reportingDate',
      cell: ({ row }) => (
        <HStack p="8px">
          <Typography variant="body">{row?.original?.reportingYear}</Typography>
        </HStack>
      ),
    },
    {
      header: t('esrs:assessment.materialityAssessment.name'),
      id: 'materialityAssessmentStatus',
      cell: ({ row }) => (
        <Tag variant="undefined">
          {!!row.original.materialStandards.length ? 'Started' : 'Not started'}
        </Tag>
      ),
    },
    {
      header: t('esrs:assessment.dataCollection.name'),
      id: 'dataCollectionStatus',
      cell: () => (
        <Tag variant="undefined">
          {false // TODO add real check
            ? 'Started'
            : 'Not started'}
        </Tag>
      ),
    },
    {
      header: t('esrs:assessment.access.name'),
      id: 'accessStatus',
      cell: ({ row }) => (
        <>
          {row.original.parentAssessment?.company && (
            <AccessTag
              logo={row.original.parentAssessment?.company.logoUrl ?? ''}
              name={row.original.parentAssessment?.company.name}
            />
          )}
        </>
      ),
    },
    {
      header: '',
      id: 'actions',
      meta: {
        width: '2%',
      },
      cell: ({ row }) => (
        <Box textAlign="right" paddingRight="6px">
          <Menu
            sections={[
              {
                actions: [
                  {
                    id: 'delete',
                    title: 'Delete',
                    variant: 'destructive',
                    isDisabled: loading,
                    leftElement: <DeleteIcon color="inherit" />,
                    onClick: () =>
                      deleteAssessment({
                        variables: {
                          assessmentId: row.original.id,
                        },
                        refetchQueries: [EsrsAssessmentsDocument_, EsrsAssessmentDocument_],
                      }),
                  },
                ],
              },
            ]}
          />
        </Box>
      ),
    },
  ];

  const filteredColumns = useMemo(
    () => columns.filter((col) => (isGroup || !hasParent ? col.id !== 'accessStatus' : true)),
    [columns, isGroup, hasParent]
  );

  return (
    <Table<EsrsAssessmentFieldsFragment_>
      onRowClick={(original: any) =>
        original.onboardingStep !== 0
          ? navigate(`${original.id}/onboarding`)
          : navigate(`${original.id}`)
      }
      rowProps={{
        _hover: {
          cursor: 'pointer',
          bg: 'bg.hover',
        },
      }}
      headerPadding="16px 0px"
      headerTextVariant="detailStrong"
      headerBorderColor="border.decorative"
      bottomRowBorder={false}
      cellPadding="0px"
      columns={filteredColumns}
      data={esrsGroupAssessments}
    />
  );
};
