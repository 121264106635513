import { HStack, VStack, Box, FormLabel } from '@chakra-ui/react';
import { FormField, IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { Modal, Select } from 'Molecules';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Typography } from 'Tokens';
import { useEffect, useMemo, useState } from 'react';
import { IconHelp, IconTrash } from '@tabler/icons-react';
import {
  CompanyLevelMetricsPerDisclosureDocument_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  MaterialMetricsPerDisclosureDocument_,
  QuestionType_Enum_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useDeleteMaterialMetricTagMutation,
  useDeleteMaterialMetricTagValueMutation,
  useUpsertMaterialMetricsMutation,
} from 'models';
import { useToast } from 'utils/hooks';
import { groupBy, uniq } from 'lodash';
import { TagSelectionMenu, TagValuesSelector } from './TagSelectMenus';
import {
  MaterialMetricFields,
  MetricConfigModalProps,
  NoSelectedTags,
  TagBreakdown,
  TagStatus,
} from './MetricConfigModalParent';
import { MetricTagsList } from 'Molecules/MetricTagsList';
import { useParams } from 'react-router-dom';
import { useAssessmentReportingUnits } from 'containers/Esrs';
import {
  DataCollectionLevel,
  SUBSIDIARY_DATA_GATHERING_OPTIONS,
  FREQUENCY_OPTIONS,
} from '../DataCollection.d';
import { getAllMaterialMetricChildren } from '../DataCollection.hooks';

export const MetricConfigModal = ({
  isOpen,
  onClose,
  selectedMetricData,
  hasParentCompany,
  isMetricDisclosure,
  companyStandardId,
  parentStandardId,
}: MetricConfigModalProps & { hasParentCompany: boolean }) => {
  const { esrsAssessmentId = '' } = useParams();
  const toast = useToast();

  const materialMetric = useMemo(
    () =>
      selectedMetricData?.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
    [selectedMetricData, companyStandardId]
  );

  const selectedMaterialMetricId = useMemo(() => materialMetric?.id, [materialMetric]);

  const { reportingUnitAssessments: reportingUnits } =
    useAssessmentReportingUnits(esrsAssessmentId);
  const hasReportingUnits = useMemo(
    () => reportingUnits?.filter((ru) => ru.isCompanyLevel === false)?.length,
    [reportingUnits]
  );

  const { control, reset, handleSubmit } = useForm<MaterialMetricFields>({
    defaultValues: {
      dataCollection: materialMetric?.dataCollection ?? '',
      frequency: materialMetric?.frequency ?? 'Yearly',
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const isEditingDisabled = useMemo(
    () =>
      (selectedMetricData?.isChild &&
        selectedMetricData.parentMetric?.materialMetrics.find(
          (mm) => mm.materialStandardId === companyStandardId
        )?.isMaterial) ??
      undefined,
    [selectedMetricData]
  );

  const adminPanelTags = useMemo(() => {
    return selectedMetricData?.adminPanelTags;
  }, [selectedMetricData]);

  const tagsFromParent = useMemo(() => {
    if (!hasParentCompany) return [];
    return selectedMetricData?.materialMetrics.find(
      (mm) => mm.materialStandardId === parentStandardId
    )?.materialMetricTags;
  }, [selectedMetricData, hasParentCompany]);

  const tagsFromCompany = useMemo(() => {
    return materialMetric?.materialMetricTags;
  }, [materialMetric]);

  const { existingTags, optionsToAdd } = useMemo(() => {
    const justFromESRS =
      adminPanelTags
        ?.filter((x) => !x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: true,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
          alwaysAll: x.values.alwaysAll,
        })) ?? [];
    const justFromParent =
      tagsFromParent
        ?.filter((x) => !justFromESRS?.find((y) => y.type === x.tagType))
        .map((x) => ({
          type: x.tagType,
          isFromESRS: false,
          isFromParent: true,
          values:
            (adminPanelTags ?? [])
              .find((y) => y.type === x.tagType)
              ?.values.tagValues.map((z) => z.value) ?? [],
          alwaysAll: x.metricTagType.alwaysAll,
        })) ?? [];
    const justFromCompany =
      tagsFromCompany
        ?.filter((x) => ![...justFromESRS, ...justFromParent].find((y) => y.type === x.tagType))
        .map((x) => ({
          type: x.tagType,
          isFromESRS: false,
          isFromParent: false,
          values:
            (adminPanelTags ?? [])
              .find((y) => y.type === x.tagType)
              ?.values.tagValues.map((z) => z.value) ?? [],
          alwaysAll: x.metricTagType.alwaysAll,
        })) ?? [];

    const existing: TagBreakdown[] = [...justFromESRS, ...justFromParent, ...justFromCompany];
    const options: TagBreakdown[] =
      (adminPanelTags?.filter((x) => !existing.find((y) => y.type === x.type)) ?? [])
        .filter((t) => !!t.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: !x.isOptional,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    return { existingTags: existing, optionsToAdd: options };
  }, [adminPanelTags, tagsFromParent, tagsFromCompany]);

  const getStatus = (tag: TagBreakdown): TagStatus => {
    if (tag.isFromESRS) return TagStatus.required;
    if (tag.isFromParent) return TagStatus.requested;
    return TagStatus.added;
  };

  const { frequency, dataCollection } = useMemo(() => {
    return {
      frequency: materialMetric?.frequency ?? 'Yearly',
      dataCollection: materialMetric?.dataCollection ?? '',
    };
  }, [materialMetric]);

  const { isValid, errors } = useFormState({ control });
  const [availableTags, setAvailableTags] = useState<TagBreakdown[]>(existingTags);
  const [prevTags, setPrevTags] = useState<TagBreakdown[]>(availableTags);
  const [options, setOptions] = useState<TagBreakdown[]>(optionsToAdd);
  const [configuredTags, setConfiguredTags] = useState<{ tagType: string; tagValue: string }[]>([]);

  const getDataCollectionText = (dataCollectionLevel: string): string => {
    if (dataCollectionLevel === DataCollectionLevel.reportingUnits) return 'business units level';
    if (dataCollectionLevel === DataCollectionLevel.company) return 'company level';
    return '';
  };

  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const [deleteMaterialMetricTagValue] = useDeleteMaterialMetricTagValueMutation();
  const [deleteMaterialMetricTag] = useDeleteMaterialMetricTagMutation();

  const childrenMetrics = useMemo(
    () => getAllMaterialMetricChildren(companyStandardId, selectedMetricData),
    [selectedMetricData, companyStandardId]
  );

  const handleConfirm = async (data: MaterialMetricFields) => {
    const metricAndChildren: { id: string; metricRef: string; isMaterial: boolean | null }[] = [
      {
        id: selectedMaterialMetricId,
        metricRef: selectedMetricData?.reference,
        isMaterial: materialMetric?.isMaterial,
      },
      ...childrenMetrics,
    ].filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    const groupedConfiguredTags = groupBy(configuredTags, 'tagType');

    upsertMaterialMetric({
      variables: {
        objects: metricAndChildren.map((mm) => ({
          id: mm.id,
          frequency: data.frequency,
          isMaterial: mm.isMaterial,
          dataCollection: data.dataCollection ?? DataCollectionLevel.company,
          materialStandardId: companyStandardId,
          metricRef: mm?.metricRef,
          materialMetricTags: {
            data: Object.keys(groupedConfiguredTags).map((key) => ({
              tagType: key,
              materialTagValues: {
                data: groupedConfiguredTags[key],
                on_conflict: {
                  constraint:
                    Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                  update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                },
              },
            })),
            on_conflict: {
              constraint:
                Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
              update_columns: [Esrs_MaterialMetricTag_Update_Column_.IsOptional_],
            },
          },
        })),
      },
      refetchQueries: [
        CompanyLevelMetricsPerDisclosureDocument_,
        ReportingUnitsMetricsPerDisclosureDocument_,
        MaterialMetricsPerDisclosureDocument_,
        EsrsAssessmentDocument_,
      ],
    })
      .then(() =>
        toast({
          text: 'Configured metric data collection settings',
        })
      )
      .catch((error) => {
        console.log(error);
        toast({
          text: 'Unable to configure metric data collection settings',
          variant: 'danger',
        });
      });

    const existingMaterialTags = materialMetric?.materialMetricTags.flatMap((materialTag) =>
      materialTag.materialTagValues.map((t) => ({
        tagType: t.tagType,
        tagValue: t.tagValue,
      }))
    );
    const tagValuesToDelete = existingMaterialTags?.filter((existingTag) => {
      return !configuredTags.some((configuredTag) => {
        return (
          configuredTag.tagType === existingTag.tagType &&
          configuredTag.tagValue === existingTag.tagValue
        );
      });
    });

    const existingMaterialTagTypes = materialMetric?.materialMetricTags.map((t) => t.tagType);
    const configuredMaterialTagTypes = uniq(availableTags.map((t) => t.type));
    const tagTypesToDelete = existingMaterialTagTypes?.filter(
      (tag) => !configuredMaterialTagTypes?.includes(tag)
    );

    try {
      if (tagTypesToDelete?.length)
        metricAndChildren.forEach((mm) => {
          deleteMaterialMetricTag({
            variables: {
              materialMetricId: mm.id,
              tagTypes: tagTypesToDelete ?? [],
            },
            refetchQueries: [
              CompanyLevelMetricsPerDisclosureDocument_,
              ReportingUnitsMetricsPerDisclosureDocument_,
              MaterialMetricsPerDisclosureDocument_,
            ],
          });
        });

      if (tagValuesToDelete?.length)
        metricAndChildren.forEach((mm) => {
          tagValuesToDelete?.forEach((tag) => {
            deleteMaterialMetricTagValue({
              variables: {
                materialMetricId: mm.id,
                tagType: tag.tagType,
                tagValue: tag.tagValue,
              },
              refetchQueries: [
                CompanyLevelMetricsPerDisclosureDocument_,
                ReportingUnitsMetricsPerDisclosureDocument_,
                MaterialMetricsPerDisclosureDocument_,
              ],
            });
          });
        });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric breakdown settings',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (materialMetric) {
      reset({
        dataCollection: dataCollection,
        frequency: frequency,
      });
    }

    if (materialMetric?.materialMetricTags.length === 0) {
      setConfiguredTags(
        selectedMetricData?.adminPanelTags.flatMap((tag) => {
          if (tag.values.alwaysAll) {
            return tag.values.tagValues.map((t) => ({
              tagType: t.type,
              tagValue: t.value,
            }));
          }
          return [];
        }) ?? []
      );
    } else {
      setConfiguredTags(
        materialMetric?.materialMetricTags.flatMap((materialTag) =>
          materialTag.materialTagValues.map((t) => ({
            tagType: t.tagType,
            tagValue: t.tagValue,
          }))
        ) ?? []
      );
    }
  }, [selectedMetricData, materialMetric]);

  const updatedOptions = useMemo(() => {
    if (!prevTags) return options;

    const removedTags = prevTags.filter(
      (tag) => !availableTags.some((newTag) => newTag.type === tag.type)
    );
    const addedTags = availableTags.filter(
      (newTag) => !prevTags.some((tag) => tag.type === newTag.type)
    );

    return options
      .filter((option) => !addedTags.some((tag) => tag.type === option.type))
      .concat(removedTags);
  }, [options, availableTags, prevTags]);

  useEffect(() => {
    setAvailableTags(existingTags);
    setOptions(optionsToAdd);
    setPrevTags(existingTags);
  }, [existingTags, optionsToAdd]);

  const hasBreakdown = useMemo(() => {
    return !!availableTags?.length || !!updatedOptions.length;
  }, [availableTags, updatedOptions]);

  const { title, shortTitle, isQualitative } = useMemo(() => {
    return {
      title: selectedMetricData?.title,
      shortTitle: selectedMetricData?.shortTitle,
      isQualitative: selectedMetricData?.metricType === QuestionType_Enum_.LongText_,
    };
  }, [selectedMetricData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <Typography variant="h2" mr="40px">
          {shortTitle ?? title ?? ''}
          {!!adminPanelTags?.length && (
            <>
              {' by '}
              <MetricTagsList
                tags={adminPanelTags.map((tag) => tag.type)}
                isHStack={false}
                maxWidthInPixels={196}
              />
            </>
          )}
        </Typography>
      }
      subtitle="Metric settings"
      size={hasBreakdown ? 'lg' : 'md'}
      confirmButtonProps={{
        type: 'submit',
        form: 'metric-config-form',
        isDisabled: !isValid,
      }}
      onConfirm={onClose}
    >
      <VStack width="100%" alignItems="stretch" spacing="16px">
        <form id="metric-config-form" onSubmit={handleSubmit(handleConfirm)}>
          <VStack alignItems="start" spacing="16px">
            {!isQualitative && isMetricDisclosure && (
              <Controller
                name="frequency"
                control={control}
                render={({ field: { onChange, value: fieldValue } }) => (
                  <FormField
                    label="Frequency"
                    id="frequency"
                    isInvalid={!!errors.frequency}
                    error="Required"
                  >
                    <VStack width="324px" spacing="6px" alignItems="start">
                      <Select<{ value: string; label: string }>
                        width="287px"
                        placeholder="Select"
                        isDisabled={isEditingDisabled}
                        value={{ label: fieldValue, value: fieldValue }}
                        options={FREQUENCY_OPTIONS}
                        onChange={(freq) => {
                          onChange(freq?.value ?? undefined);
                        }}
                      />
                      {isEditingDisabled && (
                        <Typography variant="detail">
                          {`It is not possible to change frequency because the parent metric is collected ${frequency.toLocaleLowerCase()}`}
                        </Typography>
                      )}
                    </VStack>
                  </FormField>
                )}
              />
            )}
            <Controller
              name="dataCollection"
              control={control}
              render={({ field: { onChange, value: fieldValue } }) => (
                <FormField
                  label="Data gathering level"
                  id="dataLevel"
                  isInvalid={!!errors.dataCollection}
                  error="Required"
                >
                  <VStack width="324px" spacing="6px" alignItems="start">
                    <Select<{ value: string; label: string }>
                      width="287px"
                      placeholder="Select"
                      isDisabled={isEditingDisabled}
                      value={{
                        label:
                          SUBSIDIARY_DATA_GATHERING_OPTIONS.find(
                            (option) => option.value === fieldValue
                          )?.label ?? '',
                        value: fieldValue ?? '',
                      }}
                      options={SUBSIDIARY_DATA_GATHERING_OPTIONS}
                      onChange={(dc) => {
                        onChange(dc?.value ?? undefined);
                      }}
                      isOptionDisabled={(option) =>
                        !hasReportingUnits && option.value === 'reportingUnits'
                      }
                    />
                    {isEditingDisabled && (
                      <Typography variant="detail">
                        {`It is not possible to change data gathering level because the parent metric is collected on ${getDataCollectionText(
                          dataCollection
                        )}`}
                      </Typography>
                    )}
                  </VStack>
                </FormField>
              )}
            />
          </VStack>
        </form>

        {hasBreakdown && (
          <VStack alignItems="start" width="100%" spacing="6px">
            <FormLabel m="0px">Breakdown</FormLabel>
            {availableTags.length > 0 ? (
              <VStack spacing="6px" w="100%">
                {availableTags.map((tag, index) => {
                  return (
                    <HStack
                      key={`${tag.type}_${index}`}
                      bgColor="bg.interactive"
                      h="48px"
                      borderRadius="6px"
                      w="100%"
                      justifyContent="space-between"
                    >
                      <HStack paddingInline="8px" spacing="16px" w="50%">
                        {index > 0 && (
                          <Tag minW="fit-content" variant="info" size="xs" title="AND" />
                        )}
                        <TruncatableText variant="bodyStrong" text={`by ${tag.type}`} />
                      </HStack>
                      <HStack w="50%" justifyContent="space-between">
                        <Box w="150px">
                          <TruncatableText
                            text={getStatus(tag)}
                            variant="body"
                            paddingInline="8px"
                          />
                        </Box>

                        <TagValuesSelector
                          options={tag.values.map((v) => ({ tagType: tag.type, tagValue: v }))}
                          configured={configuredTags}
                          type={tag.type}
                          setConfigured={setConfiguredTags}
                        />
                        {!tag.isFromESRS && !tag.isFromParent ? (
                          <IconButton
                            aria-label="delete"
                            variant="ghost"
                            icon={<IconTrash size="16px" />}
                            onClick={() => {
                              setAvailableTags((prev) => prev.filter((i) => i.type !== tag.type));
                            }}
                          />
                        ) : (
                          <Tooltip
                            label={
                              tag.isFromESRS
                                ? 'This breakdown is required by the ESRS. You can not delete this breakdown'
                                : 'This breakdown is requested by the parent company. You can not delete this breakdown'
                            }
                            placement="bottom"
                          >
                            <IconButton
                              aria-label="help"
                              variant="ghost"
                              icon={<IconHelp size="16px" />}
                            />
                          </Tooltip>
                        )}
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              <NoSelectedTags
                checkedTags={availableTags}
                setCheckedTags={setAvailableTags}
                tagOptions={updatedOptions ?? []}
              />
            )}
            {!!updatedOptions.length && !!availableTags.length && (
              <TagSelectionMenu
                options={updatedOptions}
                checked={availableTags}
                setChecked={setAvailableTags}
              />
            )}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
