import { Box, Table as ChakraTable, Td, Tr, VStack } from '@chakra-ui/react';
import { Button, DateInput, Infobox, TruncatableText } from 'Atoms';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { RadioCardGroup } from 'Molecules';
import { Typography } from 'Tokens';
import { CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { GetActionsQuery_, GetReportingUnitsQuery_, GetTargetsQuery_ } from 'models';
import { getBaselineTotal, SubsidiariesType } from '../Targets.hooks';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { KeyResultEnums, LocalMilestoneFields } from '../../Requirement';
import { getCalculatedCompanyValues, getCalculatedSubsidiaryValues } from './Milestones.hooks';
import { TargetActionsSection } from '../TargetActionsSection';
import { MilestoneNumberInput, TableHeader } from './MilestonesUtils';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';

export const Milestones = ({
  isGroup,
  subsidiaries,
  reportingUnits,
  localAnswers,
  setLocalAnswers,
  milestoneId,
  setMilestoneId,
  actions,
  parentActions,
  actionRef,
  targetId,
  isReadOnly,
  parentTargetId,
  setMilestoneYear = () => {},
  setIsMilestoneView,
  allSubsidiariesIds,
  unit,
  setSelectedMilestoneId,
}: {
  isGroup: boolean;
  subsidiaries: SubsidiariesType;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  localAnswers: any;
  setLocalAnswers: any;
  milestoneId?: string;
  setMilestoneId: (param: string) => void;
  actions: GetActionsQuery_['actions'];
  parentActions: GetActionsQuery_['actions'];
  actionRef: string;
  targetId?: string;
  isReadOnly: boolean;
  parentTargetId: string;
  setMilestoneYear: Dispatch<SetStateAction<number | undefined>>;
  setIsMilestoneView: (param: boolean) => void;
  allSubsidiariesIds: string[];
  unit: string;
  setSelectedMilestoneId: any;
}) => {
  const [newYear, setNewYear] = useState<number | null>();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const milestoneObject = useMemo(
    () => localAnswers.milestones?.find((m: LocalMilestoneFields) => m.id === milestoneId),
    [milestoneId, localAnswers]
  );

  const companyBaseline = useMemo(
    () =>
      isGroup
        ? getBaselineTotal(subsidiaries ?? [], localAnswers)
        : getBaselineTotal(reportingUnits ?? [], localAnswers),
    [localAnswers, isGroup]
  );

  const otherMilestones = useMemo(
    () => localAnswers.milestones?.filter((m: LocalMilestoneFields) => m.id !== milestoneId) ?? [],
    [localAnswers, milestoneId]
  );

  useEffect(() => {
    setMilestoneYear(milestoneObject?.year ?? new Date().getFullYear());
    if (!!newYear && milestoneId?.length !== 4) {
      setLocalAnswers({
        ...localAnswers,
        milestones: [
          ...localAnswers.milestones?.filter((m: LocalMilestoneFields) => m.year !== newYear),
          {
            ...localAnswers.milestones?.find((m: LocalMilestoneFields) => m.year === newYear),
            id: milestoneId,
          },
        ],
      });
      setNewYear(null);
    }
  }, [milestoneId, newYear]);

  useEffect(() => {
    if (isReadOnly) setCurrentStep(1);
  }, []);

  const handleDateInputChange = (val: Date | null) => {
    setMilestoneYear(val?.getFullYear());
    if (!!milestoneId) {
      setLocalAnswers({
        ...localAnswers,
        milestones: [
          ...otherMilestones,
          {
            ...milestoneObject,
            year: val?.getFullYear(),
            prevYear: milestoneObject?.year,
          },
        ],
      });
    } else {
      setMilestoneId(String(val?.getFullYear()));
      setNewYear(val?.getFullYear());
      setLocalAnswers({
        ...localAnswers,
        milestones: [
          ...(localAnswers.milestones ?? []),
          {
            year: val?.getFullYear(),
            isBottomUp: false,
            value: null,
            subsidiaries: [],
            reportingUnits: [],
          },
        ],
      });
    }
  };

  const calculatedCompanyValues = getCalculatedCompanyValues(
    isGroup ? true : milestoneObject?.isBottomUp,
    companyBaseline,
    milestoneObject?.value,
    isGroup ? milestoneObject?.subsidiaries : milestoneObject?.reportingUnits,
    localAnswers
  );

  return (
    <VStack alignItems="flex-start" p="24px">
      <VStack
        width="100%"
        alignItems="flex-start"
        pl="0px"
        pr="16px"
        ml="16px"
        mb="32px"
        spacing="0px"
      >
        <Box
          position="relative"
          borderLeft="1px dashed"
          pl="32px"
          borderColor="border.hover"
          pb="24px"
        >
          <ActiveStepLabels
            title="Select year"
            description="Milestone year when targets should be reached"
            descriptionColor="text.default"
            active={currentStep === 0}
            done={currentStep > 0}
            goBack={() => setCurrentStep(0)}
            ml="33px"
            mb={currentStep === 0 ? '16px' : '0px'}
          />
          {currentStep === 0 && (
            <VStack spacing="16px" alignItems="start">
              <DateInput
                width="220px"
                value={milestoneObject?.year ? new Date(milestoneObject?.year, 0, 1) : new Date()}
                setValue={(val) => {
                  handleDateInputChange(val);
                }}
                showYearPicker
                dateFormat="yyyy"
                excludeDates={localAnswers.milestones?.map(
                  (m: LocalMilestoneFields) => new Date(m.year, 0, 1)
                )}
                disabled={isReadOnly}
              />
              <Button
                variant="primary"
                alignSelf="start"
                rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
                onClick={() => setCurrentStep(currentStep + 1)}
                mb="24px"
                isDisabled={!milestoneObject?.id}
                isLoading={milestoneId?.length === 4}
              >
                Next step
              </Button>
            </VStack>
          )}
        </Box>
        <Box
          position="relative"
          borderLeft="1px dashed"
          pl="32px"
          borderColor="border.hover"
          pb="24px"
        >
          <ActiveStepLabels
            title={`Company's and ${isGroup ? 'subsidiaries' : "business units'"} targets`}
            active={currentStep === 1}
            done={currentStep > 1}
            ml="33px"
            goBack={() => setCurrentStep(1)}
            mb={currentStep === 1 ? '16px' : '0px'}
          />
          {currentStep === 1 && (
            <VStack width="100%" alignItems="start" spacing="24px">
              {isGroup && (
                <Infobox
                  title="Calculated targets"
                  description=" Subsidiaries will see targets created for them by you, and they will be able to input their own targets. On this page, you will see targets automatically calculated for the parent company."
                  status="neutral"
                  closable={false}
                />
              )}
              {!isGroup && (
                <VStack width="100%" alignItems="start">
                  <Typography variant="h4">Select approach</Typography>
                  <RadioCardGroup
                    value={milestoneObject?.isBottomUp ? 'bottomUp' : 'topDown'}
                    defaultValue={'topDown'}
                    direction="horizontal"
                    cardWidth="360px"
                    onChange={(val) => {
                      setLocalAnswers({
                        ...localAnswers,
                        milestones: [
                          ...otherMilestones,
                          { ...milestoneObject, isBottomUp: val === 'bottomUp' ? true : false },
                        ],
                      });
                    }}
                    options={[
                      {
                        title: 'Top-down',
                        subtitle: `Set a target for a parent company and ${
                          isGroup ? 'subsidiaries' : 'business units'
                        } targets will be calculated evenly`,
                        value: 'topDown',
                        icon: CompanyBottomUpIcon,
                      },
                      {
                        title: 'Bottom-up',
                        subtitle: `Set targets for ${
                          isGroup ? 'subsidiaries' : 'business units'
                        } and get total for the parent company`,
                        value: 'bottomUp',
                        icon: CompanyTopDownIcon,
                        disabled: isGroup ? !subsidiaries.length : !reportingUnits?.length,
                      },
                    ]}
                  />
                </VStack>
              )}
              <VStack width="100%" alignItems="start">
                <Typography variant="h4">Company target</Typography>
                <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
                  <ChakraTable sx={{ tableLayout: 'fixed' }}>
                    <TableHeader unit={unit} />
                    <Tr>
                      <Td padding="8px" textAlign="left" border="none">
                        <Typography variant="bodyStrong" as="span">
                          Company
                        </Typography>
                      </Td>
                      <Td padding="8px" textAlign="left" border="none">
                        <Typography variant="body" as="span">
                          {unit} {companyBaseline}
                        </Typography>
                      </Td>
                      <Td padding="8px" textAlign="left" border="none">
                        <MilestoneNumberInput
                          defaultValue={milestoneObject?.value}
                          onChange={(val) => {
                            if (!isGroup) {
                              if (milestoneObject?.isBottomUp) {
                                setLocalAnswers({
                                  ...localAnswers,
                                  milestones: [
                                    ...otherMilestones,
                                    {
                                      ...milestoneObject,
                                      value: val,
                                    },
                                  ],
                                });
                              } else {
                                setLocalAnswers({
                                  ...localAnswers,
                                  milestones: [
                                    ...otherMilestones,
                                    {
                                      ...milestoneObject,
                                      value: val,
                                      subsidiaries: Object.assign(
                                        {},
                                        ...(subsidiaries?.map((s) => ({ [s.id]: val })) ?? [])
                                      ),
                                      reportingUnits: Object.assign(
                                        {},
                                        ...(reportingUnits?.map((ru) => ({ [ru.id]: val })) ?? [])
                                      ),
                                    },
                                  ],
                                });
                              }
                            }
                          }}
                          isDisabled={milestoneObject?.isBottomUp || isGroup}
                          isCompanyLevel={true}
                          calculatedValue={
                            isNaN(calculatedCompanyValues.percentage)
                              ? 0
                              : calculatedCompanyValues.percentage
                          }
                        />
                      </Td>
                      <Td padding="8px" textAlign="left" border="none">
                        <Typography variant="body" as="span">
                          {isNaN(calculatedCompanyValues.target)
                            ? 0
                            : calculatedCompanyValues.target}
                        </Typography>
                      </Td>
                    </Tr>
                  </ChakraTable>
                </Box>
              </VStack>
              {(isGroup ? subsidiaries.length : !!reportingUnits?.length) && (
                <VStack width="100%" alignItems="start">
                  <Typography variant="h4">
                    {isGroup ? 'Subsidiaries' : "Business units'"} targets
                  </Typography>
                  <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
                    <ChakraTable sx={{ tableLayout: 'fixed' }}>
                      <TableHeader unit={unit} />
                      {isGroup
                        ? subsidiaries?.map((s, index) => (
                            <Tr key={index}>
                              <Td
                                padding="8px"
                                textAlign="right"
                                border={index === subsidiaries.length - 1 ? 'none' : ''}
                              >
                                <TruncatableText text={s.company.name} variant="body" />
                              </Td>
                              <Td
                                padding="8px"
                                textAlign="left"
                                key={`milestone_${s.id}`}
                                border={index === subsidiaries.length - 1 ? 'none' : ''}
                              >
                                <Typography variant="body" as="span">
                                  {unit} {localAnswers[`${KeyResultEnums.baseline}_${s.id}`]}
                                </Typography>
                              </Td>
                              <Td
                                padding="8px"
                                textAlign="left"
                                border={index === subsidiaries.length - 1 ? 'none' : ''}
                              >
                                <MilestoneNumberInput
                                  defaultValue={milestoneObject?.value}
                                  onChange={(val) => {
                                    if (!isGroup) {
                                      setLocalAnswers({
                                        ...localAnswers,
                                        milestones: [
                                          ...otherMilestones,
                                          {
                                            ...milestoneObject,
                                            subsidiaries: {
                                              ...milestoneObject?.subsidiaries,
                                              [s.id]: val,
                                            },
                                          },
                                        ],
                                      });
                                    }
                                  }}
                                  isDisabled={!milestoneObject?.isBottomUp || isGroup}
                                  calculatedValue={milestoneObject?.subsidiaries[s.id]}
                                />
                              </Td>
                              <Td
                                padding="8px"
                                textAlign="left"
                                border={index === subsidiaries.length - 1 ? 'none' : ''}
                              >
                                <Typography variant="body" as="span">
                                  {isNaN(
                                    getCalculatedSubsidiaryValues(
                                      true,
                                      localAnswers[`${KeyResultEnums.baseline}_${s.id}`],
                                      milestoneObject?.subsidiaries[s.id],
                                      milestoneObject?.value
                                    )?.target
                                  )
                                    ? 0
                                    : getCalculatedSubsidiaryValues(
                                        true,
                                        localAnswers[`${KeyResultEnums.baseline}_${s.id}`],
                                        milestoneObject?.subsidiaries[s.id],
                                        milestoneObject?.value
                                      )?.target ?? 0}
                                </Typography>
                              </Td>
                            </Tr>
                          ))
                        : reportingUnits?.map((ru, index) => (
                            <Tr key={index}>
                              <Td
                                padding="8px"
                                textAlign="right"
                                border={index === reportingUnits?.length - 1 ? 'none' : ''}
                              >
                                <TruncatableText text={ru.name ?? ''} variant="body" />
                              </Td>
                              <Td
                                padding="8px"
                                textAlign="left"
                                key={`milestone_${ru.id}`}
                                border={index === reportingUnits?.length - 1 ? 'none' : ''}
                              >
                                <Typography variant="body" as="span">
                                  {unit} {localAnswers[`${KeyResultEnums.baseline}_${ru.id}`]}
                                </Typography>
                              </Td>
                              <Td
                                padding="8px"
                                textAlign="left"
                                border={index === reportingUnits.length - 1 ? 'none' : ''}
                              >
                                <MilestoneNumberInput
                                  defaultValue={milestoneObject?.value}
                                  onChange={(val) => {
                                    setLocalAnswers({
                                      ...localAnswers,
                                      milestones: [
                                        ...otherMilestones,
                                        {
                                          ...milestoneObject,
                                          reportingUnits: {
                                            ...milestoneObject?.reportingUnits,
                                            [ru.id]: val,
                                          },
                                        },
                                      ],
                                    });
                                  }}
                                  isDisabled={!milestoneObject?.isBottomUp}
                                  calculatedValue={
                                    getCalculatedSubsidiaryValues(
                                      milestoneObject?.isBottomUp,
                                      localAnswers[`${KeyResultEnums.baseline}_${ru.id}`],
                                      milestoneObject?.reportingUnits[ru.id],
                                      milestoneObject?.value
                                    ).percentage
                                  }
                                />
                              </Td>
                              <Td
                                padding="8px"
                                textAlign="left"
                                border={index === reportingUnits.length - 1 ? 'none' : ''}
                              >
                                <Typography variant="body" as="span">
                                  {isNaN(
                                    getCalculatedSubsidiaryValues(
                                      milestoneObject?.isBottomUp,
                                      localAnswers[`${KeyResultEnums.baseline}_${ru.id}`],
                                      milestoneObject?.reportingUnits[ru.id],
                                      milestoneObject?.value
                                    ).target
                                  )
                                    ? 0
                                    : getCalculatedSubsidiaryValues(
                                        milestoneObject?.isBottomUp,
                                        localAnswers[`${KeyResultEnums.baseline}_${ru.id}`],
                                        milestoneObject?.reportingUnits[ru.id],
                                        milestoneObject?.value
                                      ).target ?? 0}
                                </Typography>
                              </Td>
                            </Tr>
                          ))}
                    </ChakraTable>
                  </Box>
                </VStack>
              )}
              <Button
                variant="primary"
                alignSelf="start"
                rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
                mb="24px"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Next step
              </Button>
            </VStack>
          )}
        </Box>
        <Box position="relative" pl="32px" pb="24px">
          <ActiveStepLabels title="Add actions" active={currentStep === 2} done={false} ml="33px" />
          {currentStep === 2 && (
            <VStack alignItems="start" spacing="16px">
              <TargetActionsSection
                actions={actions}
                parentActions={parentTargetId ? parentActions : []}
                onChange={(val) => {
                  setLocalAnswers({ ...localAnswers, actions: val });
                }}
                watchedActions={localAnswers.actions}
                actionRef={actionRef}
                reportingUnits={reportingUnits ?? []}
                targetId={targetId}
                parentTargetId={parentTargetId}
                isGroup={isGroup}
                isReadOnly={isReadOnly}
                allSubsidiariesIds={allSubsidiariesIds}
                unit={unit}
              />
              <Button
                variant="primary"
                alignSelf="start"
                onClick={() => {
                  setIsMilestoneView(false);
                  setSelectedMilestoneId(null);
                }}
              >
                Done
              </Button>
            </VStack>
          )}
        </Box>
      </VStack>
    </VStack>
  );
};
