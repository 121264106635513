import { useTranslation } from 'utils/translation';
import { AlertDialog, ContentLayout, SearchInput, Table } from 'Molecules';
import { Tag, Button, Checkbox, EmptyState } from 'Atoms';
import { EUTaxonomyIllustration, LabelText, NothingFoundIllustration, Typography } from 'Tokens';
import { useCompanyAssessmentsQuery, CompanyAssessmentDetails } from 'models';
import { useCurrentCompany, useCurrentCompanyId, useToast } from 'utils/hooks';
import { useMemo, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { useDeleteCompanyAssessment } from '../Assessments.hooks';
import { CompanyAvatarWithName, UserAvatar } from 'Organisms';
import { useNavigate } from 'react-router-dom';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import mixpanel from 'mixpanel-browser';
import { noop } from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { stringToYear } from 'utils/date';
import { DuplicateAssessmentModal } from './DuplicateAssessmentModal';
import { Menu } from 'Molecules/Menu';
import { CopyIcon, DeleteIcon, EditIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { AssessmentStatusTag } from 'Organisms/AssessmentStatusTag';

type AssessmentsTableProps = {
  withSelection?: {
    selected: string[];
    setSelected: (val: string[]) => void;
  };
  hideActions?: boolean;
  assessments: CompanyAssessmentDetails[];
};

export const AssessmentsTable = ({
  assessments,
  hideActions = false,
  withSelection = undefined,
}: AssessmentsTableProps) => {
  const { t } = useTranslation(['assessment', 'common']);
  const { company } = useCurrentCompany();
  const [toDelete, setToDelete] = useState<string>();
  const showSelectors = withSelection !== undefined;
  const deleteCompanyAssessment = useDeleteCompanyAssessment();
  const [assessmentToDuplicate, setAssessmentToDuplicate] = useState<{
    id: string;
    startDate: string;
  }>();

  const navigate = useNavigate();
  const toast = useToast();

  const columns = useMemo(() => {
    const { selected = [], setSelected } = withSelection ?? {};
    const checkboxColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: '',
      id: 'checkbox',
      cell: ({ row }) => (
        <Checkbox
          isChecked={selected?.includes(row.original.id)}
          onChange={() => {
            if (selected?.includes(row.original.id)) {
              setSelected?.(selected?.filter((id) => id !== row.original.id));
            } else {
              setSelected?.([...(selected ?? []), row.original.id]);
            }
          }}
        />
      ),
    };
    const actionsColumn: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: '',
        id: 'actions',
        meta: {
          width: '2%',
        },
        cell: ({ row }) => {
          return (
            <Menu
              key={row.id}
              sections={[
                {
                  actions: [
                    {
                      id: `edit-${row.id}`,
                      title: t('common:actions.edit'),
                      onClick: () => {
                        navigate(`${row.original.id}/edit`);
                      },
                      isDisabled: row.original.isLocked,
                      leftElement: <EditIcon color="inherit" />,
                    },
                    {
                      id: `duplicate-${row.id}`,
                      title: t('common:actions.duplicate'),
                      onClick: () =>
                        setAssessmentToDuplicate({
                          id: row.original.id,
                          startDate: row.original.startDate,
                        }),
                      leftElement: <CopyIcon color="inherit" />,
                    },
                  ],
                },
                {
                  actions: [
                    {
                      id: `delete-${row.id}`,
                      title: t('common:actions.delete'),
                      variant: 'destructive',
                      onClick: () => {
                        mixpanel.track(TRACKING_EVENTS.ASSESSMENTS.DELETE, {
                          companyId: company?.id,
                        });
                        setToDelete(row.original.id);
                      },
                      isDisabled: row.original.isLocked,
                      leftElement: <DeleteIcon color="inherit" />,
                    },
                  ],
                },
              ]}
            />
          );
        },
      },
    ];

    const nameAndPeriodColumns: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: t('common:assessmentTable.name'),
        accessorKey: 'aggregate.title',
      },
      {
        header: t('common:assessmentTable.fiscalPeriod'),
        id: 'fiscalPeriod',
        cell: ({ row }) => <Tag size="xs">{stringToYear(row.original.startDate)}</Tag>,
      },
    ];

    const statusColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: t('common:assessmentTable.status'),
      id: 'status',
      cell: ({ row }) => <AssessmentStatusTag isLocked={row.original.isLocked} />,
    };

    const contactColumn: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: t('common:assessmentTable.assignedTo'),
        id: 'contactPerson',
        cell: ({ row }) => {
          const { contactPerson } = row.original.aggregate;
          if (contactPerson)
            return (
              <HStack>
                <UserAvatar user={contactPerson} size="sm" />
                <LabelText fontSize="md">{contactPerson.displayName}</LabelText>
              </HStack>
            );
          return <CompanyAvatarWithName company={company} />;
        },
      },
    ];

    return [
      ...(showSelectors ? [checkboxColumn] : []),
      ...nameAndPeriodColumns,
      statusColumn,
      ...contactColumn,
      ...(hideActions ? [] : actionsColumn),
    ];
  }, [showSelectors, hideActions, withSelection]);
  return (
    <>
      <Table<CompanyAssessmentDetails>
        data={assessments}
        columns={columns}
        onRowClick={(original: any) =>
          withSelection ? noop() : navigate(`${(original as CompanyAssessmentDetails).id}`)
        }
        rowProps={{
          _hover: {
            cursor: 'pointer',
            bg: 'bg.hover',
          },
        }}
      />
      <AlertDialog
        isOpen={!!toDelete}
        onClose={() => setToDelete(undefined)}
        onConfirm={() => {
          if (toDelete)
            deleteCompanyAssessment(
              toDelete,
              assessments.find((a) => a.id === toDelete)?.aggregate.id
            ).then(() => {
              toast({
                text: t('assessment:list.delete.success'),
              });
              setToDelete(undefined);
            });
        }}
        title={t('assessment:list.title')}
        confirmLabel={t('assessment:list.confirm')}
      >
        <Typography variant="body">{t('assessment:list.alert')}</Typography>
      </AlertDialog>
      {assessmentToDuplicate && (
        <DuplicateAssessmentModal
          isOpen={!!assessmentToDuplicate}
          onClose={() => setAssessmentToDuplicate(undefined)}
          id={assessmentToDuplicate.id}
          startDate={assessmentToDuplicate.startDate}
        />
      )}
    </>
  );
};

export const AssessmentsEmptyState = () => {
  const { t } = useTranslation(['assessment', 'common']);

  const navigate = useNavigate();

  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('common:report.noReport')}
        description={t('common:report.noReportDescription')}
        callToAction={{
          text: t('common:assessment.createAssessment'),
          variant: 'primary',
          onClick: () => navigate('new'),
        }}
        icon={<EUTaxonomyIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};

export const AssessmentsSearchEmptyState = ({ setSearch }: { setSearch: (s: string) => void }) => {
  const { t } = useTranslation('common');
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('common:search.filter.emptyTitle')}
        description={t('common:search.filter.emptyDescription')}
        icon={<NothingFoundIllustration boxSize="120px" />}
        callToAction={{
          text: t('common:search.filter.emptyBtn'),
          variant: 'secondary',
          onClick: () => {
            setSearch('');
          },
          leftIcon: <RefreshIcon color="inherit" />,
        }}
      />
    </Box>
  );
};

export const AssessmentsList = () => {
  const { companyId } = useCurrentCompanyId();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { data, loading } = useCompanyAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });
  const assessments = useMemo(() => {
    return data?.assessments ?? [];
  }, [data]);

  const filteredAssessmets = useMemo(() => {
    if (!search) return assessments;
    return assessments.filter((assessment) =>
      assessment.aggregate.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, assessments]);

  return (
    <ContentLayout isLoading={loading} header={t('common:menu.assessments')}>
      {assessments.length ? (
        <>
          <HStack width="100%" justifyContent="space-between" paddingY="16px">
            <SearchInput
              placeholder={t('common:words.filter')}
              search={search}
              setSearch={setSearch}
            />
            <Button
              onClick={() => navigate('new')}
              minWidth="none"
              paddingX="16px"
              variant="primary"
              size="md"
            >
              {t('common:assessment.createAssessment')}
            </Button>
          </HStack>
          {filteredAssessmets.length ? (
            <AssessmentsTable assessments={filteredAssessmets} />
          ) : (
            <AssessmentsSearchEmptyState setSearch={setSearch} />
          )}
        </>
      ) : (
        <AssessmentsEmptyState />
      )}
    </ContentLayout>
  );
};
