import { Box, HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { ContentHeader, ContentLayout } from 'Molecules';
import { Menu } from 'Molecules/Menu';
import { ViewSwitcher } from 'Molecules/ViewSwitcher';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { useState } from 'react';
import { ReportSideMenu } from './ReportSideMenu';
import { ReportContent } from './ReportContent';
import { EsrsReportDocsGenerator } from './DocGenerator';

enum ReportViews {
  prepare = 'Prepare',
  preview = 'Preview',
}

export const CreateReport = () => {
  const [selectedView, setSelectedView] = useState<string>(ReportViews.prepare);
  return (
    <ContentLayout
      backButton
      header={
        <ContentHeader
          title="Create report"
          style={{
            position: 'sticky',
            top: '0',
            right: '0',
            zIndex: '1',
            background: 'var(--chakra-colors-bg-defaultToTransparent)',
            backdropFilter: 'blur(10px)',
            paddingTop: '12px',
          }}
          actions={
            <HStack>
              <ViewSwitcher
                views={Object.values(ReportViews)}
                selected={selectedView}
                setSelected={setSelectedView}
              />
              <Menu
                size="lg"
                menuButton={
                  <Button rightIcon={<ChevronDownIcon color="inherit" />} variant="primary">
                    Download
                  </Button>
                }
                sections={[
                  {
                    actions: [
                      {
                        id: 'esrs-report-word-doc',
                        render: () => <EsrsReportDocsGenerator />,
                      },
                    ],
                  },
                ]}
              />
            </HStack>
          }
        />
      }
    >
      <HStack alignItems="start" gap="16px" justifyContent="center">
        <ReportSideMenu />

        <ReportContent isPreview={selectedView !== ReportViews.prepare} />
        <Box w="20%" h="100%"></Box>
      </HStack>
    </ContentLayout>
  );
};
