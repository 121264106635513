import React, { Fragment, useMemo, useRef, useState } from 'react';
import {
  BreakdownStatus,
  DataCollectionLevel,
  TableMetricData,
  TagStatus,
} from './DataCollection.d';
import { Box, HStack, useColorMode } from '@chakra-ui/react';
import { Tag, Tooltip } from 'Atoms';
import { CornerDownRightIcon } from 'Tokens/Icons/Direction';
import { WarningIcon } from '@chakra-ui/icons';
import { Typography, colors } from 'Tokens';

const getBreakdownStatus = (
  row: TableMetricData | undefined,
  companyStandardId: string,
  parentStandardId: string,
  isGroupCompany: boolean
): TagStatus => {
  const parentMetric = row?.parentMetric;
  const allTags = row?.adminPanelTags;
  const requiredTags = allTags?.filter((tag) => !tag.isOptional);
  const materialMetric = row?.materialMetrics?.find(
    (mm) => mm.materialStandardId === companyStandardId
  );
  const addedTags =
    isGroupCompany && materialMetric?.dataCollection !== DataCollectionLevel.group
      ? materialMetric?.materialMetricTags?.filter(
          (tag) => !requiredTags?.find((reqTag) => reqTag.type === tag.tagType)
        )
      : materialMetric?.materialMetricTags?.filter((tag) => tag.materialTagValues.length > 0);

  const required = requiredTags?.length;
  const added = addedTags?.length;
  const available = allTags?.filter(
    (tag) => tag.isOptional && !addedTags?.find((y) => y.tagType === tag.type)
  ).length;

  const parentHasTags =
    !!parentMetric?.adminPanelTags.length ||
    !!parentMetric?.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
      ?.materialMetricTags.length ||
    !!parentMetric?.materialMetrics.find((mm) => mm.materialStandardId === parentStandardId)
      ?.materialMetricTags.length;

  if (row?.isChild && parentHasTags) {
    return getBreakdownStatus(
      parentMetric as TableMetricData,
      companyStandardId,
      parentStandardId,
      isGroupCompany
    );
  } else if (added) {
    return { state: BreakdownStatus.added, number: added };
  } else if (required) {
    return { state: BreakdownStatus.required, number: required };
  } else if (available) {
    return { state: BreakdownStatus.available, number: 0 };
  }
  return { state: BreakdownStatus.notApplicable, number: 0 };
};

export const BreakDownTag = React.memo(
  ({
    metric,
    isGroupCompany,
    companyStandardId,
    parentStandardId,
  }: {
    metric: TableMetricData;
    isGroupCompany: boolean;
    companyStandardId: string;
    parentStandardId: string;
  }) => {
    const BREAKDOWN_STATES = useMemo(() => {
      return {
        required: {
          text: 'Required',
          variant: isGroupCompany ? 'default' : 'warning',
        },
        added: {
          text: 'Added',
          variant: 'selected',
        },
        requested: {
          text: 'Requested',
          variant: 'selected',
        },
        available: {
          text: 'Available',
          variant: 'default',
        },
        notApplicable: {
          text: 'N/A',
          variant: 'default',
        },
      };
    }, [isGroupCompany]);

    const materialMetric = useMemo(
      () => metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
      [metric, companyStandardId]
    );

    const addedBreakdowns = useMemo(
      () =>
        materialMetric?.materialMetricTags.filter((tag) => tag.materialTagValues.length > 0) ?? [],
      [materialMetric, companyStandardId]
    );
    const requiredBreakdowns = useMemo(
      () => metric.adminPanelTags.filter((tag) => !tag.isOptional) ?? [],
      [metric]
    );
    const remainingBreakdowns = useMemo(
      () =>
        requiredBreakdowns.filter(
          (required) => !addedBreakdowns.find((added) => added.tagType === required.type)
        ),
      [addedBreakdowns, requiredBreakdowns]
    );

    const parentMetricHasTags = useMemo(
      () =>
        !!metric?.parentMetric?.adminPanelTags.length ||
        !!metric?.parentMetric?.materialMetrics.find(
          (mm) => mm.materialStandardId === companyStandardId
        )?.materialMetricTags.length ||
        !!metric?.parentMetric?.materialMetrics.find(
          (mm) => mm.materialStandardId === parentStandardId
        )?.materialMetricTags.length,
      [metric]
    );

    const status = useMemo(
      () => getBreakdownStatus(metric, companyStandardId, parentStandardId, isGroupCompany),
      [metric, companyStandardId, parentStandardId, isGroupCompany]
    );

    const parentMetricStatus = useMemo(
      () =>
        getBreakdownStatus(
          metric.parentMetric as TableMetricData,
          companyStandardId,
          parentStandardId,
          isGroupCompany
        ),
      [metric.parentMetric, companyStandardId, parentStandardId, isGroupCompany]
    );

    const addedTagsRatio = useMemo(() => {
      const required = requiredBreakdowns.length ?? 0;
      const added = requiredBreakdowns.length - remainingBreakdowns.length ?? 0;
      return `${added}/${required}`;
    }, [requiredBreakdowns, remainingBreakdowns]);

    const showRemainingBreakdowns = useMemo(() => {
      return (
        !isGroupCompany &&
        !parentMetricHasTags &&
        !!remainingBreakdowns.length &&
        !!addedBreakdowns.length
      );
    }, [
      isGroupCompany,
      parentMetricHasTags,
      remainingBreakdowns,
      addedBreakdowns,
      companyStandardId,
    ]);

    return (
      <HStack spacing="4px">
        <Tag
          opacity={
            metric.isChild && parentMetricStatus.state !== BreakdownStatus.notApplicable ? 0.3 : 1
          }
          variant={BREAKDOWN_STATES[status.state].variant}
        >
          <HStack>
            {metric.isChild && parentMetricHasTags && (
              <>
                {status.state !== BreakdownStatus.available && (
                  <CornerDownRightIcon color="inherit" />
                )}
              </>
            )}
            <>
              {BREAKDOWN_STATES[status.state].text}
              {status.state === BreakdownStatus.available ||
              status.state === BreakdownStatus.notApplicable
                ? ''
                : ` (${status.number})`}
            </>
          </HStack>
        </Tag>
        {showRemainingBreakdowns && (
          <Tooltip
            label={`You have added values to ${addedTagsRatio} required breakdowns. Go to the metrics' settings to add the rest`}
          >
            <Tag opacity={metric.isChild ? 0.3 : 1} variant="warning">
              <HStack spacing="2px">
                <WarningIcon m={0} color="inherit" />
                <Typography variant="detailStrong" color="inherit">
                  {remainingBreakdowns.length}
                </Typography>
              </HStack>
            </Tag>
          </Tooltip>
        )}
      </HStack>
    );
  }
);

export const MetricNameWithTag = React.memo(
  ({
    name,
    parentRowName,
    tags,
    rowRef,
  }: {
    name: string;
    parentRowName?: string;
    tags: string[];
    rowRef: React.MutableRefObject<HTMLDivElement>;
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const { colorMode } = useColorMode();
    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
    const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
    const componentRef = useRef<HTMLDivElement>(null);
    const handleShowTooltip = () => {
      if (componentRef.current && rowRef.current) {
        if (componentRef.current.scrollWidth >= rowRef.current.scrollWidth) {
          setShowTooltip(true);
        } else setShowTooltip(false);
      }
    };

    const tagStyle = {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: colors['border.decorative'][color],
      padding: '2px 6px 2px 4px',
    };

    return (
      <Box ref={componentRef} w="fit-content">
        {showTooltip ? (
          <Tooltip
            isDisabled={!showTooltip}
            maxW="400px"
            width="100%"
            placement="bottom-start"
            label={`${name} ${tags.length ? 'by ' + tags.join(', ') : ''}${
              parentRowName ? ` (${parentRowName})` : ''
            }`}
          >
            <HStack onMouseEnter={handleShowTooltip}>
              <Typography noOfLines={1} variant="body" lineHeight="25px">
                {name} {tags.length ? 'by ' : ''}
                {tags.map((tag, index) => (
                  <Fragment key={index}>
                    {index > 0 && ', '}
                    <Typography display="inline" variant="detailStrong" style={tagStyle}>
                      {tag}
                    </Typography>
                  </Fragment>
                ))}
                {parentRowName ? (
                  <Typography display="inline" variant="body" color="text.hint">
                    ({parentRowName})
                  </Typography>
                ) : (
                  ''
                )}
              </Typography>
            </HStack>
          </Tooltip>
        ) : (
          <HStack onMouseEnter={handleShowTooltip}>
            <Typography noOfLines={1} variant="body" lineHeight="25px">
              {`${name} ${tags.length ? 'by ' : ''}`}{' '}
              {tags.map((tag, index) => (
                <Fragment key={index}>
                  {index > 0 && ', '}
                  <Typography display="inline" variant="detailStrong" style={tagStyle}>
                    {tag}
                  </Typography>
                </Fragment>
              ))}
              {parentRowName ? (
                <Typography display="inline" variant="body" color="text.hint">
                  ({parentRowName})
                </Typography>
              ) : (
                ''
              )}
            </Typography>
          </HStack>
        )}
      </Box>
    );
  }
);
