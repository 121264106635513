import { Box, FormControl, FormHelperText, FormLabel, HStack } from '@chakra-ui/react';
import { HelpTooltip } from 'Molecules';
import { Typography } from 'Tokens';
import { DangerIcon } from 'Tokens/Icons/Status';

export const FormField = ({
  id,
  label,
  children,
  helpText,
  helpTextAsTooltip = true,
  error,
  isInvalid,
  isRequired,
}: {
  id: string;
  label?: string;
  children: React.ReactNode;
  helpText?: string;
  helpTextAsTooltip?: boolean;
  error?: string;
  isInvalid?: boolean;
  isRequired?: boolean;
}) => {
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired} height="min-content">
      {label && (
        <Box mb="xs" className="label-container">
          <FormLabel htmlFor={id} display="inline" color="text.default">
            {label}
          </FormLabel>
          {helpText && helpTextAsTooltip && <HelpTooltip label={helpText} placement="right" />}
        </Box>
      )}
      <Box>{children}</Box>
      {helpText && !helpTextAsTooltip && (
        <Box marginTop="3px">
          <FormHelperText color="text.muted">{helpText}</FormHelperText>
        </Box>
      )}
      {isInvalid && error && (
        <HStack alignItems={'flex-start'} height={error ? '21px' : '0'} marginTop="4px">
          <DangerIcon color="text.critical" />
          <Typography variant="detail" color={'text.critical'}>
            {error}
          </Typography>
        </HStack>
      )}
    </FormControl>
  );
};
