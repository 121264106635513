import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableProps as ChakraTableProps,
  TableRowProps,
  Tooltip,
  chakra,
  HStack,
  VStack,
  TableCellProps,
} from '@chakra-ui/react';
import {
  useReactTable,
  ColumnDef,
  getPaginationRowModel,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  SortingState,
  PaginationState,
} from '@tanstack/react-table';
import { IconButton } from 'Atoms';
import React, { useEffect } from 'react';
import { Typography, TypographyVariant } from 'Tokens';
import {
  ArrowBarToLeftIcon,
  ArrowBarToRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'Tokens/Icons/Direction';
import { SortAscendingIcon, SortIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';

type TableProps<T> = {
  columns: Array<ColumnDef<T> & { width?: string }>;
  data: T[];
  extra?: React.ReactNode;
  onRowClick?: (row: T) => void;
  rowProps?: TableRowProps;
  cellProps?: TableCellProps;
  conditionalRowProps?: (row: T) => TableRowProps;
  pageSize?: number;
  headerPadding?: string;
  cellPadding?: string;
  allowSorting?: boolean;
  headerAlignment?: string;
  rowIcon?: React.ReactNode;
  headerTextVariant?: TypographyVariant;
  headerBorderColor?: string;
  bottomRowBorder?: boolean;
  withBorder?: boolean;
  emptyStateText?: string;
} & ChakraTableProps;

export function Table<T>({
  columns,
  data,
  extra,
  onRowClick,
  rowProps = {},
  cellProps = {},
  conditionalRowProps,
  pageSize = 10,
  headerPadding,
  cellPadding,
  allowSorting = true,
  headerAlignment,
  rowIcon,
  headerTextVariant,
  headerBorderColor,
  withBorder,
  bottomRowBorder = true,
  emptyStateText,
  ...rest
}: TableProps<T>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });

  useEffect(() => {
    if (pageSize) setPagination((prev) => ({ ...prev, pageSize }));
  }, [pageSize]);

  const table = useReactTable({
    columns,
    data,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    state: {
      pagination: pagination,
      sorting,
    },
  });
  const { t } = useTranslation('common');

  return (
    <VStack
      width="100%"
      alignItems="stretch"
      border={withBorder ? '1px solid' : 'none'}
      display={withBorder ? 'inline-block' : ''}
      borderColor={withBorder ? 'border.decorative' : ''}
      borderRadius="8px"
    >
      <ChakraTable wdith="100%" {...rest}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} width="100%">
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    width={meta?.width}
                    colSpan={meta?.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    fontSize="md"
                    fontWeight="semibold"
                    textTransform="none"
                    borderColor={headerBorderColor}
                    verticalAlign={headerAlignment}
                    padding={headerPadding}
                    letterSpacing="normal"
                    style={{
                      width: header.column.getSize(),
                    }}
                  >
                    <HStack spacing="0px">
                      <Typography variant={headerTextVariant ? headerTextVariant : 'bodyStrong'}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                      {allowSorting && header.column.getCanSort() && (
                        <chakra.span pl="4">
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <SortIcon aria-label="sorted descending" color="inherit" />
                            ) : (
                              <SortAscendingIcon aria-label="sorted ascending" color="inherit" />
                            )
                          ) : null}
                        </chakra.span>
                      )}
                    </HStack>
                  </Th>
                );
              })}
              {rowIcon && <Th></Th>}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row, index) => (
            <Tr
              key={(row.original as any)?.id ?? row.id}
              {...rowProps}
              {...(conditionalRowProps ? conditionalRowProps(row.original) : {})}
              onClick={() => onRowClick?.(row.original)}
              width="100%"
              position="relative"
            >
              {row.getVisibleCells().map((cell) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = cell.column.columnDef.meta;
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    colSpan={meta?.colSpan}
                    width={cell.column.columnDef.minSize}
                    borderBottom={
                      row.index === table.getRowModel().rows.length - 1 && !bottomRowBorder
                        ? 'none'
                        : '1px'
                    }
                    borderColor="border.decorative"
                    height="48px"
                    fontSize="md"
                    fontWeight="semibold"
                    textTransform="none"
                    padding={cellPadding}
                    style={{
                      width: cell.column.getSize(),
                    }}
                    {...cellProps}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
              {rowIcon && (
                <Td
                  borderBottom={
                    index === table.getRowModel().rows.length - 1 ? 'none' : '1px solid'
                  }
                  borderColor="border.decorative"
                  textAlign="end"
                >
                  {rowIcon}
                </Td>
              )}
            </Tr>
          ))}
          {emptyStateText && !data.length && (
            <Tr {...rowProps}>
              <Td
                textAlign="center"
                padding="14px"
                height="48px"
                colSpan={100}
                bgColor="bg.muted"
                borderRadius=" 0 0 8px 8px"
                borderBottom="none"
              >
                <Typography variant="body" color="text.muted">
                  {emptyStateText}
                </Typography>
              </Td>
            </Tr>
          )}

          {extra}
        </Tbody>
      </ChakraTable>
      {table.getPageCount() > 1 && (
        <HStack justifyContent="flex-start" m={4} width="100%">
          <HStack spacing="6px">
            <Tooltip label={t('common:table.firstPage')}>
              <IconButton
                variant="ghost"
                aria-label={t('common:table.firstPage')}
                onClick={() => table.setPageIndex(0)}
                isDisabled={!table.getCanPreviousPage()}
                icon={<ArrowBarToLeftIcon color="inherit" h={4} w={4} />}
              />
            </Tooltip>
            <Tooltip label={t('common:table.previousPage')}>
              <IconButton
                variant="ghost"
                aria-label={t('common:table.previousPage')}
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
                icon={<ChevronLeftIcon color="inherit" h={4} w={4} />}
              />
            </Tooltip>
          </HStack>
          <HStack spacing="6px">
            <Tooltip label={t('common:table.nextPage')}>
              <IconButton
                variant="ghost"
                aria-label={t('common:table.nextPage')}
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}
                icon={<ChevronRightIcon color="inherit" h={4} w={4} />}
              />
            </Tooltip>
            <Tooltip label={t('common:table.lastPage')}>
              <IconButton
                variant="ghost"
                aria-label={t('common:table.lastPage')}
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                isDisabled={!table.getCanNextPage()}
                icon={<ArrowBarToRightIcon color="inherit" h={4} w={4} />}
              />
            </Tooltip>
          </HStack>
        </HStack>
      )}
    </VStack>
  );
}
