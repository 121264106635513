import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { VStack, Textarea, Menu, MenuButton, MenuList, MenuItem, HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { GetMetricAnswersDocument_, useOptOutMetricMutation } from 'models';
import { Modal } from 'Molecules';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Typography } from 'Tokens';
import { useToast } from 'utils/hooks';

enum OptOutReasonValue {
  NOT_APPLICABLE = 'not-applicable',
  DATA_NOT_AVAILABLE = 'data-not-available',
  WRONG_DATA_FORMAT = 'wrong-data-format',
  OTHER = 'other',
}

type OptOutReason = {
  value: OptOutReasonValue;
  label: string;
  getDescription: (entityType: string) => string;
};

const OPT_OUT_REASON_OPTIONS: OptOutReason[] = [
  {
    value: OptOutReasonValue.NOT_APPLICABLE,
    label: 'Not applicable',
    getDescription: (entityType: string) => `This metric is not applicable for this ${entityType}`,
  },
  {
    value: OptOutReasonValue.DATA_NOT_AVAILABLE,
    label: 'Data not available',
    getDescription: (entityType: string) =>
      `This metric is applicable for this ${entityType} activities, but data is not collected`,
  },
  {
    value: OptOutReasonValue.WRONG_DATA_FORMAT,
    label: 'Wrong format of data',
    getDescription: () =>
      `Data point is available, but not on the right format (e.g level of granularity) to be sufficient for reporting`,
  },
  {
    value: OptOutReasonValue.OTHER,
    label: 'Other reason',
    getDescription: () => `None of above are applicable`,
  },
];

const ReasonSelectorMenu = ({
  reasons,
  selectedReason,
  setSelectedReason,
  entityType,
}: {
  reasons: OptOutReason[];
  selectedReason: string;
  setSelectedReason: Dispatch<SetStateAction<string | undefined>>;
  entityType: string;
}) => {
  return (
    <Menu gutter={2}>
      <MenuButton
        as={Button}
        width="100%"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        color="text.default"
      >
        <Typography
          variant="body"
          noOfLines={1}
          color={selectedReason ? 'text.default' : 'text.hint'}
        >
          {selectedReason
            ? reasons.find((reason) => reason.value === selectedReason)?.label
            : 'Select option'}
        </Typography>
      </MenuButton>
      <MenuList p="8px" w="455px">
        {reasons.map((reason) => {
          return (
            <MenuItem
              p="8px"
              m="0px"
              w="100%"
              onClick={() => {
                setSelectedReason(reason.value);
              }}
            >
              <HStack
                width="100%"
                justifyContent="space-between"
                padding="8px"
                color={selectedReason === reason.value ? 'text.selected' : ''}
              >
                <VStack alignItems="flex-start" spacing="0px">
                  <Typography
                    variant="bodyStrong"
                    color={selectedReason === reason.value ? 'text.selected' : ''}
                  >
                    {reason.label}
                  </Typography>
                  <Typography variant="detail">{reason.getDescription(entityType)}</Typography>
                </VStack>
                {selectedReason === reason.value && <CheckIcon color="inherit" />}
              </HStack>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export const OptOutModal = ({
  isOpen,
  onClose,
  reportingUnitId,
  metricRef,
  isCompany,
  assessmentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  reportingUnitId: string;
  metricRef: string;
  assessmentId: string;
  isCompany: boolean;
}) => {
  const [reasoning, setReasoning] = useState<string>();
  const toast = useToast();
  const [optOut] = useOptOutMetricMutation();

  const [selectedReason, setSelectedReason] = useState<string>();

  const entityType = isCompany ? 'company' : 'business unit';
  useEffect(() => {
    if (selectedReason === OptOutReasonValue.OTHER) setReasoning('');
    else
      setReasoning(OPT_OUT_REASON_OPTIONS.find((reason) => selectedReason === reason.value)?.label);
  }, [selectedReason]);

  const handleSubmit = () => {
    optOut({
      variables: {
        object: {
          metricRef,
          reportingUnitId,
          assessmentId,
          hasOptedOut: true,
          optOutReason: reasoning ?? '',
        },
      },
      refetchQueries: [GetMetricAnswersDocument_],
    })
      .then(() => {
        onClose();
        toast({ text: 'Opted out successfully' });
      })
      .catch(() => {
        toast({ text: 'Failed to opt out', variant: 'danger' });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Opt out from the metric"
      confirmText="Opt out"
      confirmButtonProps={{ disabled: !reasoning }}
      onConfirm={handleSubmit}
    >
      <VStack spacing="16px" alignItems="start">
        <Typography variant="body">
          You can opt out from this metric if you don't have this data available.
        </Typography>
        <VStack spacing="16px" width="100%" alignItems="start">
          <VStack spacing="6px" w="100%" alignItems="start">
            <Typography variant="bodyStrong" as="span">
              Select a reason{' '}
              <Typography variant="bodyStrong" as="span" color="text.critical">
                *
              </Typography>
            </Typography>
            <ReasonSelectorMenu
              selectedReason={selectedReason ?? ''}
              setSelectedReason={setSelectedReason}
              reasons={OPT_OUT_REASON_OPTIONS}
              entityType={entityType}
            />
          </VStack>

          {selectedReason === OptOutReasonValue.OTHER && (
            <VStack spacing="6px" w="100%" alignItems="start">
              <Typography variant="bodyStrong" as="span">
                Provide reasoning{' '}
                <Typography variant="bodyStrong" as="span" color="text.critical">
                  *
                </Typography>
              </Typography>
              <Textarea
                value={reasoning}
                onChange={(e) => setReasoning(e.target.value)}
                borderRadius="8px"
                borderColor="border.default"
                placeholder="Enter your text"
                p="8px"
              />
            </VStack>
          )}
        </VStack>
      </VStack>
    </Modal>
  );
};
