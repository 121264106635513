import { Box, Divider, Input, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Alert, Button, ExternalLink, FormField, Infobox, Tabs } from 'Atoms';
import { UserSessionsTable } from 'Features/UserSessions';
import { ContentLayout } from 'Molecules';
import { Typography } from 'Tokens';
import { CompanyIcon, GroupIcon } from 'Tokens/Icons/Data';
import mixpanel from 'mixpanel-browser';
import {
  CompanyDetails,
  CompanyDetailsDocument_,
  User,
  UserDetailsDocument_,
  useUpdateCompanyMutation,
  useUpdateUserMutation,
} from 'models';
import { Controller, useForm } from 'react-hook-form';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { nhost } from 'utils/nhost';
import { Trans, useTranslation } from 'utils/translation';
import { CurrencyField } from './pieces';
import { LogoUploader } from './pieces/LogoUploader';

type CompanyFields = {
  name: string;
  isPortfolioOwner: boolean;
  currency: string;
  logoUrl: string;
};
const CompanyDetailsForm = ({
  company,
  onSave,
}: {
  company: CompanyDetails;
  onSave?: (Company: CompanyFields) => Promise<any>;
}) => {
  const [updateCompany] = useUpdateCompanyMutation();
  const toast = useToast();
  const { t } = useTranslation('common');
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<CompanyFields>({
    defaultValues: {
      name: company.name,
      isPortfolioOwner: company.isPortfolioOwner,
      currency: company.currency,
      logoUrl: company.logoUrl ?? '',
    },
  });
  const handleSaveCompanySettings = async (values: CompanyFields) => {
    const { name, isPortfolioOwner, currency, logoUrl } = values;

    if (company.name !== name) {
      mixpanel.track(TRACKING_EVENTS.SETTINGS.CHANGE_COMPANY_NAME, {
        companyId: company.id,
        oldName: company.name,
        newName: name,
      });
    }
    if (company.currency !== currency) {
      mixpanel.track(TRACKING_EVENTS.SETTINGS.CHANGE_COMPANY_CURRENCY, {
        companyId: company.id,
        oldCurrency: company.currency,
        newCurrency: currency,
      });
    }

    return updateCompany({
      variables: {
        company: {
          id: company.id,
          name,
          logoUrl: logoUrl === '' ? undefined : logoUrl,
          currency,
          isPortfolioOwner,
        },
      },
      refetchQueries: [CompanyDetailsDocument_],
    })
      .then(() => {
        toast({
          text: `${t('common:toast.success')} ${t('common:toast.companyUpdated.description')}`,
        });
        if (onSave) {
          onSave(values);
        }
      })
      .catch(() =>
        toast({
          text: `${t('common:toast.companyUpdateFailed.title')} 
          ${t('common:toast.companyUpdateFailed.description')}`,
          variant: 'danger',
        })
      );
  };
  return (
    <form onSubmit={handleSubmit(handleSaveCompanySettings)}>
      <Typography variant="h2" mb="8px">
        Company details
      </Typography>
      <VStack maxWidth="42em" spacing="md" alignItems="flex-start" mt="16px">
        <FormField id="name" isRequired label={t('common:fields.companyName.label')}>
          <Input size="md" width="100%" {...register('name')} />
        </FormField>

        <Controller
          name="logoUrl"
          control={control}
          defaultValue={company.logoUrl ?? undefined}
          shouldUnregister
          render={({ field: { onChange, value } }) => (
            <FormField id="logo" isRequired label={t('common:fields.logo.label')}>
              <LogoUploader
                url={value}
                setUrl={(newVal) => {
                  onChange(newVal ?? '');
                }}
              />
            </FormField>
          )}
        />

        <FormField id="type" label={t('common:fields.type.label')}>
          {company.isPortfolioOwner ? (
            <Infobox
              status="neutral"
              closable={false}
              icon={
                <Box pt="3px" color="text.muted">
                  <CompanyIcon boxSize="20px" />
                </Box>
              }
              title={t('common:fields.type.portfolioLabel')}
              description={
                <Trans>
                  <Typography variant="body" color="text.primary">
                    {t('common:fields.type.descriptionSelected')}
                    <span style={{ fontStyle: 'italic' }}>{t('common:fields.type.IFtype')}</span>
                    {t('common:fields.type.IFdescription')}
                    <ExternalLink
                      href="https://www.celsia.io/use-cases/companies"
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize="body"
                      textDecoration="none"
                    >
                      {t('common:fields.type.IFlink')}
                    </ExternalLink>
                    .
                  </Typography>
                </Trans>
              }
            />
          ) : company.isGroupOwner ? (
            <Infobox
              status="neutral"
              closable={false}
              icon={
                <Box pt="3px" color="text.muted">
                  <GroupIcon boxSize="20px" />
                </Box>
              }
              title={t('common:fields.companyType.groupLabel')}
              description={
                <Trans>
                  <Typography variant="body" color="text.primary">
                    {t('common:fields.type.descriptionSelected')}
                    <span style={{ fontStyle: 'italic' }}>
                      {t('common:fields.companyType.groupLabel')}
                    </span>
                    {t('common:fields.type.GPdescription')}
                    <ExternalLink
                      href="https://www.celsia.io/use-cases/companies"
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize="body"
                      textDecoration="none"
                    >
                      {t('common:fields.type.GP1link')}
                    </ExternalLink>
                    {t('words.and')}
                    <ExternalLink
                      href="https://www.celsia.io/use-cases/investment-funds"
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize="body"
                      textDecoration="none"
                    >
                      {t('common:fields.type.GP2link')}
                    </ExternalLink>
                    .
                  </Typography>
                </Trans>
              }
            />
          ) : (
            <Infobox
              status="neutral"
              closable={false}
              icon={
                <Box pt="3px" color="text.muted">
                  <CompanyIcon boxSize="20px" />
                </Box>
              }
              title={t('common:fields.type.regularLabel')}
              description={
                <Trans>
                  <Typography variant="body" color="text.primary">
                    {t('common:fields.type.descriptionSelected')}
                    <span style={{ fontStyle: 'italic' }}>{t('common:fields.type.BEtype')}</span>
                    {t('common:fields.type.BEdescription')}
                    <ExternalLink
                      href="https://www.celsia.io/use-cases/investment-funds"
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize="body"
                      textDecoration="none"
                    >
                      {t('common:fields.type.BElink')}
                    </ExternalLink>
                    .
                  </Typography>
                </Trans>
              }
            />
          )}

          <Typography variant="detail" color="text.muted" mt="8px">
            {t('common:fields.type.changeType')}
          </Typography>
        </FormField>

        <Controller
          name="currency"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CurrencyField value={value} setValue={onChange} />
          )}
        />
        <Button width="auto" type="submit" variant="primary" isDisabled={!isDirty}>
          {t('common:settings.saveDetails')}
        </Button>
      </VStack>
    </form>
  );
};

type UserFields = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  avatarUrl: string;
  displayName: string;
};

const UserAccountForm = ({
  user,
  onSave,
}: {
  user: User;
  onSave?: (user: UserFields) => Promise<any>;
}) => {
  const [updateUser] = useUpdateUserMutation();
  const toast = useToast();
  const { t } = useTranslation(['common', 'settings']);
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<UserFields>({
    defaultValues: {
      displayName: user.displayName,
      avatarUrl: user.avatarUrl ?? '',
    },
  });

  const handleSaveUserSettings = async (values: UserFields) => {
    const { displayName, avatarUrl } = values;

    try {
      await updateUser({
        variables: {
          id: user.id,
          user: {
            displayName,
            avatarUrl,
          },
        },
        refetchQueries: [UserDetailsDocument_],
      });

      await nhost.auth.refreshSession();
      toast({
        text: `${t('common:toast.success')} ${t('common:toast.userUpdated.description')}`,
      });
      if (onSave) {
        onSave(values);
      }
    } catch (e) {
      toast({
        text: `${t('common:toast.userUpdateFailed.title')}
            ${t('common:toast.userUpdateFailed.description')}`,
        variant: 'danger',
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(handleSaveUserSettings)}>
      <VStack alignItems="flex-start" spacing="32px">
        <VStack alignItems="flex-start" spacing="0px">
          <Typography variant="h2" mb="8px">
            {t('settings:account.details')}
          </Typography>
          <Alert
            status="neutral"
            closable={false}
            textColor="text.default"
            title={t('settings:account.notice')}
          />
        </VStack>
      </VStack>
      <Divider mb="24px" background="border.decorative"></Divider>
      <VStack maxWidth="42em" spacing="md" alignItems="flex-start">
        <FormField id="name" label={'Name'}>
          <Input size="md" width="100%" {...register('displayName')} />
        </FormField>

        {/* <Controller
          name="avatarUrl"
          control={control}
          defaultValue={user.avatarUrl ?? undefined}
          shouldUnregister
          render={({ field: { onChange, value } }) => (
            <FormField id="photo" label={'Photo'}>
              <LogoUploader
                url={value}
                setUrl={(newVal) => {
                  onChange(newVal ?? '');
                }}
              />
            </FormField>
          )}
        /> */}
        <FormField id="email" label={'Email'}>
          <Typography variant="body">{user.email}</Typography>
          <Typography variant="detail" color="text.muted" mt="8px">
            {t('settings:account.changeEmail')}
          </Typography>
        </FormField>

        <Button width="auto" type="submit" variant="primary" isDisabled={!isDirty}>
          {t('common:settings.saveDetails')}
        </Button>
      </VStack>
    </form>
  );
};

export function Settings() {
  const { company } = useCurrentCompany();
  const user = useUserData();
  // const session = nhost.auth.getSession()?.refreshTokenId; // Use this to show which sessions is the current
  const { t } = useTranslation('common');

  return (
    <ContentLayout
      variant='narrow'
      header={t('common:menu.settings')}
    >
      <Tabs
        defaultIndex={0}
        items={[
          {
            title: 'Company details',
            id: 'Company',
            content: (
              <VStack 
                alignItems="flex-start"
                mt="32px"
              >
                {company && <CompanyDetailsForm company={company} />}
              </VStack>
            ),
          },
          {
            title: 'My account',
            id: 'User',
            content: (
              <VStack 
                alignItems="flex-start" 
                mt="32px"
              >
                {user && <UserAccountForm user={user} />}
              </VStack>
            ),
          },
          {
            title: 'Sessions',
            id: 'Sessions',
            content: (
              <VStack alignItems="flex-start" m="24px 0px">
                <UserSessionsTable />
              </VStack>
            ),
          },
        ]}
      />
    </ContentLayout>
  );
}
