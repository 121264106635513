import { Box, ChakraProps, HStack, VStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import { Breadcrumbs } from 'Molecules/Breadcrumbs';

export type ContentHeaderProps = {
  title?: string;
  size?: 'md' | 'lg';
  titleExtra?: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  props?: ChakraProps;
  subtitle?: string;
  height?: string;
  style?: React.CSSProperties;
};

export const CONTENT_HEADER_HEIGHT = '44px';

export const ContentHeader = ({
  title,
  size = 'lg',
  titleExtra,
  actions,
  props,
  style,
  subtitle,
}: ContentHeaderProps) => {
  return (
    <VStack alignItems="stretch" gap="32px" style={style}>
      <VStack
        alignItems="start"
        gap="0px"
        {...props}
      >
        {title && (
          <HStack
            width="100%"
            pb="8px"
            justifyContent="space-between"
            {...props}
          >
            <HStack justifyContent="start" height="fit-content">
              <VStack spacing="0px" alignItems="flex-start">
                <HStack width="100%" alignItems="center">
                  <Typography variant={size === 'md' ? 'h2' : 'h1'}>{title}</Typography>
                  {titleExtra}
                </HStack>
                {subtitle && (
                  <Typography variant="body" color="text.muted">
                    {subtitle}
                  </Typography>
                )}
              </VStack>
            </HStack>
            <HStack height="fit-content">{actions}</HStack>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};
