import { Box, useToast as useChakraToast } from '@chakra-ui/react';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { Dispatch, SetStateAction } from 'react';

export const InteractionBlocker = () => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="transparent"
      zIndex={1000}
    />
  );
};

export const AIProgressModal = ({
  isOpen,
  onClose,
  setIsGenerating,
  cancelRequest,
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  cancelRequest: () => void;
}) => {
  const chakraToast = useChakraToast();
  return (
    <Modal
      cancelText="Stay on the page"
      confirmText="Close & stop AI"
      title="AI is writing..."
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {
        cancelRequest();
        setIsGenerating(false);
        chakraToast.closeAll();
        onClose();
      }}
    >
      <Typography variant="body">
        Answers generation is in progress. If you close this window, you will lose the progress. Are
        you sure you want to close this page and open another?
      </Typography>
    </Modal>
  );
};

export const AnswersRegenerationModal = ({
  isOpen,
  onClose,
  extractAndGenerate,
  selectedFileId,
}: {
  isOpen: boolean;
  onClose: () => void;
  extractAndGenerate: (fileId: string) => Promise<any>;
  selectedFileId: string;
}) => {
  return (
    <Modal
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      hasHeader={false}
      confirmText="Write new texts"
      onConfirm={() => {
        extractAndGenerate(selectedFileId);
        onClose();
      }}
    >
      <Typography variant="bodyStrong">
        Do you want AI to generate new answers for all these data points? Current versions of
        answers will be erased and new answers will be generated
      </Typography>
    </Modal>
  );
};
