import { ErrorElement } from 'App';
import { Route, Routes } from 'react-router-dom';
import {
  AddMaterialityAssessment,
  AddTarget,
  DisclosureRequirementGroup,
  EsrsAssessment,
  EsrsAssessmentConfig,
  EsrsAssessmentList,
  MaterialityAssessment,
  MetricChart,
  MetricsDisclosureRequirements,
  Onboarding,
} from './pieces';
import { StandardMetricsDataCollection } from './pieces/DataCollection/StandardMetricsDataCollection';
import { ESRSAssessmentSettings } from './Settings';
import { CreateReport } from './pieces/Report/CreateReport';
import { TestAnswersAI } from './TestAnswersAI';

export const EsrsAssessments = () => {
  return (
    <Routes>
      <Route index element={<EsrsAssessmentList />} errorElement={<ErrorElement />} />
      <Route
        path=":esrsAssessmentId/onboarding"
        element={<Onboarding />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/onboarding/standard/:standardRef"
        element={<Onboarding />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/onboarding/data-collection/:standardRef"
        element={<StandardMetricsDataCollection />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/onboarding/materiality/standard/:standardRef"
        element={<AddMaterialityAssessment />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/report"
        element={<CreateReport />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/settings"
        element={<ESRSAssessmentSettings />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/settings/materiality"
        element={<MaterialityAssessment />}
        errorElement={<ErrorElement />}
      ></Route>
      <Route
        path=":esrsAssessmentId/settings/materiality/standard/:standardRef"
        element={<AddMaterialityAssessment />}
        errorElement={<ErrorElement />}
      ></Route>
      <Route
        path=":esrsAssessmentId/settings/data-collection/:standardRef"
        element={<StandardMetricsDataCollection />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/:childAssessmentId?"
        element={<EsrsAssessment />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/:childAssessmentId/standard/:standardRef/disclosure-requirement/:disclosureRequirementGroupRef?"
        element={<EsrsAssessment />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/standard/:standardRef"
        element={<DisclosureRequirementGroup />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/standard/:standardRef/disclosure-requirement/metric/:disclosureRequirementRef/chart/:metricRef/:materialStandardId/:reportingUnitId?"
        element={<MetricChart />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/standard/:standardRef/disclosure-requirement/:drType/:disclosureRequirementRef/:view?"
        element={<DisclosureRequirementGroup />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/bu-standard/:standardRef/bu/:reportingUnitId/disclosure-requirement/:disclosureRequirementRef/:view?"
        element={<MetricsDisclosureRequirements />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/standard/:standardRef/disclosure-requirement/:drType/:disclosureRequirementRef/target-form"
        element={<AddTarget />}
        errorElement={<ErrorElement />}
      >
        <Route
          path=":targetId/edit"
          element={<AddTarget />}
          errorElement={<ErrorElement />}
        ></Route>
      </Route>
      <Route
        path=":esrsAssessmentId/config"
        element={<EsrsAssessmentConfig />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":esrsAssessmentId/test-answers"
        element={<TestAnswersAI />}
        errorElement={<ErrorElement />}
      />
    </Routes>
  );
};
