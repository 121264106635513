import {
  VStack,
  FormControl,
  FormLabel,
  FormHelperText,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { BusinessUnitFormModal } from 'containers/BusinessUnits/pieces/BusinessUnitForms';
import { useCurrentCompanyId } from 'utils/hooks';
import { BusinessUnit, useBusinessUnitsQuery } from 'models';
import { AvatarWithName, BusinessUnitAvatar } from 'Organisms';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import { Table } from 'Molecules';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox, Button } from 'Atoms';
import { AddIcon } from 'Tokens/Icons/Function';

export const BusinessUnitsSelector = ({
  selected,
  setSelected,
}: {
  selected: string[];
  setSelected: (newSelection: string[]) => void;
}) => {
  const { t } = useTranslation(['bUnits', 'common']);
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { companyId } = useCurrentCompanyId();
  const { data } = useBusinessUnitsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const columns: ColumnDef<BusinessUnit>[] = useMemo(() => {
    return [
      {
        header: () => (
          <Checkbox
            isChecked={selected.length === data?.businessunits.length}
            isIndeterminate={
              selected.length > 0 && selected.length < (data?.businessunits?.length ?? 0)
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelected(data?.businessunits.map((bUnit) => bUnit.id) || []);
              } else {
                setSelected([]);
              }
            }}
          />
        ),
        id: 'isSelected',
        sortDescFirst: true,
        enableSorting: true,
        sortingFn: (rowA, rowB) => rowA.original.name.localeCompare(rowB.original.name),
        meta: {
          colSpan: 1,
        },
        cell: ({ row }) => {
          const bu = row.original;
          return (
            <Checkbox
              isChecked={!!selected.includes(bu.id)}
              onChange={(e) => {
                setSelected(
                  e.target.checked ? [...selected, bu.id] : selected.filter((id) => id !== bu.id)
                );
              }}
            />
          );
        },
      },
      {
        header: t('bUnits:singleTitle'),
        id: 'name',
        sortDescFirst: true,
        accessorKey: 'name',
        enableSorting: true,
        sortingFn: (rowA, rowB) => rowA.original.name.localeCompare(rowB.original.name),
        meta: {
          colSpan: 2,
        },
        cell: ({ row }) => {
          const bu = row.original;
          return (
            <HStack spacing="8px" width="100%">
              <BusinessUnitAvatar name={bu.name} size="sm" />
              <Typography variant="bodyStrong">{bu.name}</Typography>
            </HStack>
          );
        },
      },
      {
        header: t('bUnits:table.assignedTo'),
        sortDescFirst: true,
        enableSorting: true,
        accessorKey: 'contactPerson.displayName',
        sortingFn: (rowA, rowB) =>
          rowA.original.contactPerson.displayName.localeCompare(
            rowB.original.contactPerson.displayName
          ),
        meta: {
          colSpan: 1,
        },
        id: 'assignedTo',
        cell: ({ row }) => (
          <HStack width="100%">
            <AvatarWithName user={row.original?.contactPerson} size="sm" />
          </HStack>
        ),
      },
    ];
  }, [selected, setSelected]);

  return (
    <VStack spacing="16px" alignItems="flex-start">
      <VStack spacing="8px">
        <FormControl isRequired>
          <FormLabel m="0px" color="text.default">{t('common:assessment.assignBusinessUnits')}</FormLabel>
          <FormHelperText mt="2px" color="text.muted">{t('common:assessment.assignBusinessUnitsDescription')}</FormHelperText>
        </FormControl>
      </VStack>
      <VStack alignItems="flex-start" spacing="16px">
        <Table<BusinessUnit> data={data?.businessunits ?? []} columns={columns} pageSize={4} />
        <Button variant="ghost" onClick={onOpen} size="md" leftIcon={<AddIcon/>} alignVertically>
          {t('common:assessment.addNewBusinessUnit')}
        </Button>
      </VStack>

      <BusinessUnitFormModal
        isOpen={isOpen}
        onClose={onClose}
        currentAssessmentId={cAssessmentId}
      />
    </VStack>
  );
};
