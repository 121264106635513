import { Box, Center, HStack, Stack, useDisclosure, VStack } from '@chakra-ui/react';
import { Button, Card } from 'Atoms';
import { useKnowledgeBase } from 'Features';
import {
  DocumentationFile,
  GroupAssessmentFieldsFragment_,
  useGetDocumentationFileByIdQuery,
} from 'models';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { DownloadIcon, UploadIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';
import { ConsolidatedFinancialsUploader } from './ConsolidatedFinancialsUploader';
import { generateSubsidiaryTable } from './ExcelGenerator/consolidated-financials-excel-generator';
import { useMemo } from 'react';
import { File, Loader } from 'Molecules';
import { MenuAction } from 'Molecules/Menu';
import { useDownloadAttachedFile } from './ConsolidatedFinancials.Hooks';

const ConsolidatedFinancialsCard = ({
  title,
  description,
  button,
  upload,
  isUploaded,
}: {
  title: string;
  description: string;
  button: React.ReactNode;
  upload?: boolean;
  isUploaded?: boolean;
}) => {
  return (
    <Card
      width="100%"
      border="1px"
      borderRadius="8px"
      borderColor="bg.unknown"
      bg={upload ? (isUploaded ? 'bg.info' : 'bg.warning') : 'bg.unknown.muted'}
      padding="16px"
      shadow="none"
    >
      <VStack height="100%" justifyContent="space-between" align="start">
        <VStack align="start">
          <Typography variant="h3">{title}</Typography>
          <Typography variant="body">{description}</Typography>
        </VStack>
        {button}
      </VStack>
    </Card>
  );
};

const UploadCsvCard = ({ onOpen }: { onOpen: () => void }) => {
  const { t } = useTranslation('group');

  return (
    <ConsolidatedFinancialsCard
      title={t('financials.upload.title')}
      description={t('financials.upload.description')}
      button={
        <HStack w="100%">
          <Button
            w="30%"
            leftIcon={<UploadIcon color="inherit" />}
            onClick={() => {
              onOpen();
            }}
          >
            {t('financials.upload.title')}
          </Button>
        </HStack>
      }
      upload={true}
    />
  );
};

const UploadedCsvCard = ({
  onOpen,
  assessment,
  file,
}: {
  onOpen: () => void;
  assessment: GroupAssessmentFieldsFragment_;
  file: DocumentationFile;
}) => {
  const { t } = useTranslation('group');
  const downloadAttachedFile = useDownloadAttachedFile();
  const actions: MenuAction[] = useMemo(() => {
    return [
      {
        id: 'reUpload',
        title: t('financials.upload.reupload'),
        leftElement: <UploadIcon color="inherit" />,
        onClick: () => onOpen(),
      },
      {
        id: 'download',
        title: t('common:actions.download'),
        leftElement: <DownloadIcon color="inherit" />,
        onClick: () => downloadAttachedFile(file),
      },
    ];
  }, [file]);

  return (
    <ConsolidatedFinancialsCard
      title={t('financials.upload.uploadedTitle')}
      description={
        t('financials.upload.reuploadDesc1') +
        new Date(assessment.uploadedAt).toLocaleDateString() +
        t('financials.upload.reuploadDesc2')
      }
      button={
        <Box w="65%">
          <File file={file} actions={actions} />
        </Box>
      }
      upload={true}
      isUploaded={true}
    />
  );
};

export const ConsolidatedFinancials = ({
  assessment,
}: {
  assessment: GroupAssessmentFieldsFragment_;
}) => {
  const { gAssessmentId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('group');
  const navigate = useNavigate();
  const { onOpen: onKnowledgeBaseOpen } = useKnowledgeBase();
  const { data: consolidatedCsvData, loading } = useGetDocumentationFileByIdQuery({
    variables: { fileId: assessment.consolidatedCsvId },
    skip: !assessment.consolidatedCsvId,
  });
  const consolidatedCsv: DocumentationFile | undefined | null = useMemo(
    () => consolidatedCsvData?.files,
    [consolidatedCsvData]
  );

  if (loading) return <Loader />;
  return (
    <VStack spacing="0px">
      <Stack direction="row" width="100%" spacing="12px" padding="16px 0">
        <ConsolidatedFinancialsCard
          title={t('financials.download.title')}
          description={t('financials.download.description')}
          button={
            <Button
              leftIcon={<DownloadIcon color="inherit" />}
              onClick={() => (gAssessmentId ? generateSubsidiaryTable({ gAssessmentId }) : null)}
            >
              {t('financials.download.title')}
            </Button>
          }
        />
        <ConsolidatedFinancialsCard
          title={t('financials.consolidate.title')}
          description={t('financials.consolidate.description')}
          button={
            <Button
              leftIcon={<HelpIcon color="inherit" />}
              onClick={() =>
                onKnowledgeBaseOpen(
                  'How to consolidate the taxonomy financials for group companies'
                )
              }
            >
              {t('financials.consolidate.button')}
            </Button>
          }
        />
        {!consolidatedCsv && <UploadCsvCard onOpen={onOpen} />}
        {consolidatedCsv && (
          <UploadedCsvCard assessment={assessment} file={consolidatedCsv} onOpen={onOpen} />
        )}
      </Stack>
      <Center w="400px" flexGrow="1" textAlign="center">
        <Box>
          <Typography variant="body" color="text.muted" as="span">
            {t('financials.comingSoon1')}
          </Typography>
          <Typography
            variant="body"
            cursor="pointer"
            color="text.action"
            as="span"
            onClick={() => navigate('results')}
          >
            {t('financials.comingSoon2')}
          </Typography>
          <Typography variant="body" color="text.muted" as="span">
            {t('financials.comingSoon3')}
          </Typography>
        </Box>
      </Center>
      <ConsolidatedFinancialsUploader onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};
