import { useEsrsAssessmentsQuery } from 'models';
import { useCompanyType, useCurrentCompanyId } from 'utils/hooks';
import { useMemo } from 'react';

export const useGetEsrsAssessmentList = () => {
  const { companyId } = useCurrentCompanyId();
  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const { data, loading } = useEsrsAssessmentsQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const esrsAssessments = useMemo(() => data?.esrsAssessments ?? [], [data]);

  return {
    esrsAssessments,
    isGroup,
    loading: loading,
  };
};

export type GroupMetricMaterialityResult = {
  groupOnly: string[];
  subsidiariesOnly: string[];
};

export type CompanyMetricMaterialityResult = {
  companyOnly: string[];
  parentCompanyOnly: string[];
};
