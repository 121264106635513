import { HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens/Typography';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'utils/translation';
import { GlobalContext, useFeatureFlags, useUserSetting } from 'containers/Navigation';
import { useCurrentCompany } from 'utils/hooks';
import { GetPaiRequestersQuery_, GetReportsQuery_ } from 'models';
import { HomePaiReportCard, HomeTaxonomyReportCard } from '.';

export const TAXONOMY_LEARN_MORE_MODAL = 'taxonomy_learn_more_modal_is_closed';
export const PAI_LEARN_MORE_MODAL = 'pai_learn_more_modal_is_closed';

export const HomeReportsCards = ({
  data,
  companyId,
  paiRequesters,
}: {
  data: GetReportsQuery_ | undefined;
  companyId: string;
  paiRequesters: GetPaiRequestersQuery_ | undefined;
}) => {
  const { t } = useTranslation('home');
  const { company } = useCurrentCompany();
  const { showInfoBoxes, setShowInfoBoxes } = useContext(GlobalContext);
  const { assessments: hasTaxonomyAccess, pai: hasPaiAccess } = useFeatureFlags({ company });
  const isGroup = useMemo(() => company?.isGroupOwner ?? false, [company]);

  const [isTaxonomyLearnMoreClosed, onToggleTaxonomyModal] = useUserSetting(
    TAXONOMY_LEARN_MORE_MODAL,
    false
  );
  const [isPaiLearnMoreClosed, onTogglePaiModal] = useUserSetting(PAI_LEARN_MORE_MODAL, false);

  useEffect(() => {
    if (showInfoBoxes) {
      onToggleTaxonomyModal(false);
      onTogglePaiModal(false);
    }
  }, [showInfoBoxes]);

  useEffect(() => {
    if (isTaxonomyLearnMoreClosed || isPaiLearnMoreClosed) setShowInfoBoxes(false);
  }, [isTaxonomyLearnMoreClosed, isPaiLearnMoreClosed]);

  return (
    <VStack alignItems="stretch" justifyContent="flex-start" spacing="0px">
      <HStack height="100%" mb="8px">
        <Typography variant="bodyStrong" color="text.default">
          {t('home:cards.assessments')}
        </Typography>
      </HStack>
      <HStack spacing="16px" height="100%" alignItems="stretch">
        {hasTaxonomyAccess && (
          <HomeTaxonomyReportCard
            data={data}
            onToggleTaxonomyModal={onToggleTaxonomyModal}
            isTaxonomyLearnMoreClosed={isTaxonomyLearnMoreClosed}
            hasTaxonomyAccess={hasTaxonomyAccess}
            isGroup={isGroup}
          />
        )}
        {hasPaiAccess && (
          <HomePaiReportCard
            data={data}
            companyId={companyId}
            onTogglePaiModal={onTogglePaiModal}
            isPaiLearnMoreClosed={isPaiLearnMoreClosed}
            hasPaiAccess={hasPaiAccess}
            paiRequesters={paiRequesters}
          />
        )}
      </HStack>
    </VStack>
  );
};
