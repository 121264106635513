import { Box, HStack, PopoverAnchor, VStack } from '@chakra-ui/react';
import { Alert, IconButton } from 'Atoms';
import { ContentLayout, Loader } from 'Molecules';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { Breadcrumbs } from 'Molecules/Breadcrumbs';
import { Typography } from 'Tokens';
import { CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { ArrowBarToLeftIcon, ArrowBarToRightIcon, ChevronsRightIcon } from 'Tokens/Icons/Direction';
import { useUserSetting } from 'containers/Navigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { Structure } from '../Assessment/Structure';
import { Actions } from './Actions/Actions';
import { useGetDRGroupData } from './DisclosureRequirements.hooks';
import { SideBarPopover } from './DisclosureRequirementsUtilsComponents';
import { AggregatedMetrics } from './Metrics';
import { Policies } from './Policies/Policies';
import { DREnums, DRTypes, MetricViewEnums, PopoverTypes, SidebarModes } from './Requirement';
import { Targets } from './Targets/Targets';

const DisclosureRequirement = ({ type }: { type: DRTypes }) => {
  if (type === DREnums.policy) {
    return <Policies />;
  }
  if (type === DREnums.target) {
    return <Targets />;
  }
  if (type === DREnums.metric) {
    return <AggregatedMetrics />;
  }
  if (type === DREnums.action) return <Actions />;
  return <AggregatedMetrics />;
};

export const DisclosureRequirementGroup = () => {
  const navigate = useNavigate();
  const [sidebarMode, setSidebarMode] = useLocalStorage('metric-sidebar-mode', SidebarModes.open);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [isOpenedTooltipDismissed, setOpenedTooltipDismissed] = useUserSetting(
    'sidebar-opened-tootlip-dismissed',
    false
  );
  const [isClosedTooltipDismissed, setClosedTooltipDismissed] = useUserSetting(
    'sidebar-closed-tootlip-dismissed',
    false
  );

  useEffect(() => {
    if (!isOpenedTooltipDismissed && !isClosedTooltipDismissed) setSidebarMode(SidebarModes.open);
  }, [isOpenedTooltipDismissed, isClosedTooltipDismissed]);

  const [isSidebarClosed, isSidebarOverlay, isSidebarOpen] = useMemo(
    () => [
      sidebarMode === SidebarModes.closed,
      sidebarMode === SidebarModes.overlay,
      sidebarMode === SidebarModes.open,
    ],
    [sidebarMode]
  );
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    drType,
    disclosureRequirementRef = '',
    view,
  } = useParams();
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>();
  const {
    standard,
    isDataGatheringOnly,
    sortedRequirements,
    targetsCount,
    actionsCount,
    policiesCount,
    loading,
  } = useGetDRGroupData();

  useEffect(() => {
    if (isSidebarOverlay) {
      setSidebarMode(SidebarModes.closed);
    }
  }, []);

  const handleNodeSelect = (nodeKey: string) => {
    const [type, selectedDRRef] = nodeKey?.split('_');
    if (type === DREnums.metric) {
      navigate(
        `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/${type}/${selectedDRRef}/${
          view ?? MetricViewEnums.overview
        }`
      );
    } else
      navigate(
        `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/${type}/${selectedDRRef}`
      );
    setSelectedNodeKey(nodeKey);
  };

  useEffect(() => {
    if (!drType && sortedRequirements[0]?.length > 1) {
      const firstNodeKey = sortedRequirements[0][1].key;
      handleNodeSelect(firstNodeKey);
    }
    const type = selectedNodeKey?.split('_')?.[0];

    const isNodeNotSelected = !!drType && !!disclosureRequirementRef && type !== drType;
    if (isNodeNotSelected) {
      setSelectedNodeKey(`${drType}_${disclosureRequirementRef}`);
    }
  }, [sortedRequirements, drType]);

  const requirements = useMemo(() => {
    return sortedRequirements.map((reqArray) => {
      return reqArray.map((req) => {
        let counter;
        const key = req.key.split('_')[0] as DREnums;
        if (key === DREnums.target) {
          counter = targetsCount;
        } else if (key === DREnums.policy) {
          counter = policiesCount;
        } else {
          counter = actionsCount;
        }
        if (Object.values(DREnums).includes(key) && key !== DREnums.metric) {
          return {
            ...req,
            counter,
          };
        }
        return req;
      });
    });
  }, [targetsCount, actionsCount, policiesCount, sortedRequirements]);

  const handleSidebar = () => {
    if (isSidebarClosed) setSidebarMode(SidebarModes.overlay);
    else if (isSidebarOverlay) setSidebarMode(SidebarModes.open);
    else setSidebarMode(SidebarModes.closed);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box
      flexGrow="1"
      width="100%"
      display="flex"
      alignItems="flex-start"
    >
      <HStack
        borderTopColor="border.decorative"
        height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
        spacing="0px"
        bg="bg.default"
        position="relative"
        w="100%"
      >
        <SideBarPopover
          popoverType={PopoverTypes.closed}
          isSidebarClosed={isSidebarClosed}
          isOpenedTooltipDismissed={isOpenedTooltipDismissed}
          isClosedTooltipDismissed={isClosedTooltipDismissed}
          setClosedTooltipDismissed={setClosedTooltipDismissed}
          setOpenedTooltipDismissed={setOpenedTooltipDismissed}
        >
          <VStack
            height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
            ref={sidebarRef}
            w={isSidebarClosed ? '36px' : '288px'}
            maxW="288px"
            minW={isSidebarOpen ? '288px' : '0px'}
            borderRight="1px solid"
            borderRightColor="border.decorative"
            overflow="hidden"
            bg="bg.default"
            alignItems="start"
            justifyContent={isSidebarClosed ? 'center' : 'unset'}
            position={isSidebarOpen ? 'relative' : 'absolute'}
            gap="0px"
            onMouseEnter={() => {
              const sidebarWidth = sidebarRef?.current?.offsetWidth;
              if (sidebarWidth === 36 && isSidebarClosed) {
                setSidebarMode(SidebarModes.overlay);
                !isClosedTooltipDismissed && setClosedTooltipDismissed(true);
              }
            }}
            onMouseLeave={() => (isSidebarOverlay ? setSidebarMode(SidebarModes.closed) : {})}
            zIndex="5"
            transition="all 0.15s ease-in-out"
          >
            {isSidebarClosed && (
              <PopoverAnchor>
                <IconButton
                  opacity={isSidebarClosed ? 1 : 0}
                  marginInline="auto"
                  variant="ghost"
                  icon={<ChevronsRightIcon color="text.default" />}
                  aria-label="open"
                  transition="all 0.15s"
                  position="absolute"
                  left="0px"
                />
              </PopoverAnchor>
            )}
            <VStack
              opacity={isSidebarClosed ? 0 : 1}
              pointerEvents={isSidebarClosed ? 'none' : 'auto'}
              transition="all 0.15s ease-in-out"
              alignItems="start"
              gap="0px"
              height="100%"
              position={isSidebarClosed ? 'absolute' : 'unset'}
            >
              <HStack justifyContent="space-between" w="100%">
                <Breadcrumbs />
                <SideBarPopover
                  popoverType={PopoverTypes.open}
                  isSidebarClosed={isSidebarClosed}
                  isOpenedTooltipDismissed={isOpenedTooltipDismissed}
                  isClosedTooltipDismissed={isClosedTooltipDismissed}
                  setClosedTooltipDismissed={setClosedTooltipDismissed}
                  setOpenedTooltipDismissed={setOpenedTooltipDismissed}
                >
                  <PopoverAnchor>
                    <IconButton
                      variant="ghost"
                      size="sm"
                      icon={isSidebarOpen ? <ArrowBarToLeftIcon /> : <ArrowBarToRightIcon />}
                      aria-label="hide"
                      mt="8px"
                      mr="8px"
                      onClick={(e) => {
                        handleSidebar();
                        !isOpenedTooltipDismissed && setOpenedTooltipDismissed(true);
                        e.stopPropagation();
                      }}
                    />
                  </PopoverAnchor>
                </SideBarPopover>
              </HStack>
              <Structure
                overflow="auto"
                width="100%"
                minW="288px"
                maxWidth="288px"
                mt="24px"
                header={
                  <VStack p="0px  16px" spacing="16px" alignItems="start">
                    <VStack spacing="16px" alignItems="stretch" w="100%">
                      <VStack spacing="4px" alignItems="start">
                        <Typography variant="h2">{standard?.title}</Typography>
                        <Typography variant="body" color="text.muted">
                          {standard?.reference}
                        </Typography>
                      </VStack>
                      <Alert
                        status="neutral"
                        title={
                          isDataGatheringOnly ? 'Not material but gathering data' : 'Material topic'
                        }
                        textColor="text.default"
                        closable={false}
                        customIcon={<CompanyTopDownIcon />}
                        width="100%"
                      />
                    </VStack>
                    <Typography
                      variant="overline"
                      color="text.hint"
                      textTransform="uppercase"
                      p="8px 0px"
                    >
                      {'Disclosure requirements'}
                    </Typography>
                  </VStack>
                }
                nodes={requirements}
                selectedNodeKey={selectedNodeKey ?? ''}
                onSelectNode={handleNodeSelect}
                size="sm"
              />
            </VStack>
          </VStack>
        </SideBarPopover>
        <VStack
          height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
          width={isSidebarOpen ? 'calc(100% - 288px)' : '100%'}
          overflow="hidden auto"
          transition="width 0.2s"
          ml={!isSidebarOpen ? '36px' : '0px'}
        >
          <ContentLayout 
            header={false} 
            variant='inline'
          >
            <DisclosureRequirement type={drType as DRTypes} />
          </ContentLayout>
        </VStack>
      </HStack>
    </Box>
  );
};
