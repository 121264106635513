import { HStack, Skeleton, Spinner, VStack } from '@chakra-ui/react';
import { TimePeriodsEnums } from '../Requirement';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MetricsTableData } from './MetricAnswers.hooks';
import { useGetDatapointValues } from './InputTable/QuarterInput';
import { AutoResizeTextarea } from 'Atoms';
import { Typography } from 'Tokens';
import { WarningIcon } from 'Tokens/Icons/Status';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

type TextMetricProps = {
  metric: MetricsTableData['metric'];
  companyReportingUnit?: string;
  autoExpandHeight?: boolean;
  isGeneratingAnswers?: boolean;
  setRowData: (param: MetricsTableData | undefined) => void;
};

const AIInfoWarning = () => {
  return (
    <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
      <WarningIcon color="text.warning" boxSize="18px"></WarningIcon>
      <Typography variant="body" color="text.warning">
        AI couldn’t generate answer.
      </Typography>
    </HStack>
  );
};

export const LongTextMetricInput: FC<TextMetricProps> = React.memo(
  ({ metric, companyReportingUnit, isGeneratingAnswers = false, setRowData }) => {
    const [searchParams] = useSearchParams();
    const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
    const openDrawer = new URLSearchParams(location.search).get('openDrawer');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);
    const { onDatapointChange, dataPointPerYear, answer, loading } = useGetDatapointValues(
      {
        metric,
      },
      companyReportingUnit
    );

    const isIrrelevantResponse = useMemo(() => {
      return dataPointPerYear?.isAIGenerated && dataPointPerYear.value === '-';
    }, [dataPointPerYear]);

    // Use a local state for the input value to ensure it's controlled.
    const [inputValue, setInputValue] = useState(dataPointPerYear?.value ?? '');

    // Update the local input value whenever the external value changes.
    useEffect(() => {
      if (!hasStartedTyping) setInputValue(dataPointPerYear?.value ?? '');
    }, [dataPointPerYear?.value]);

    // Restored to rerender narrative answers when population finishes
    useEffect(() => {
      if (dataPointPerYear?.value !== inputValue) {
        setHasStartedTyping(false);
      }
    }, [dataPointPerYear?.value, inputValue]);

    useEffect(() => {
      if (dataPointPerYear?.id === urlDatapointId && openDrawer) {
        setRowData?.({ metric: metric });
      }
    }, [urlDatapointId, dataPointPerYear, openDrawer]);
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.minHeight = 'auto';
        const tdHeight = textareaRef.current.parentElement?.parentElement?.clientHeight;
        textareaRef.current.style.height = tdHeight ? `${tdHeight - 12}px` : 'unset';
        textareaRef.current.style.minHeight = tdHeight ? `${tdHeight - 12}px` : 'unset';
        if (isIrrelevantResponse) {
          textareaRef.current.style.height = '44px';
          textareaRef.current.style.minHeight = '44px';
        }
      }
    }, [textareaRef.current, loading, isIrrelevantResponse, inputValue]);

    // Debounce function to delay state update and minimize re-renders.
    const debounceSave = useCallback(
      debounce((newValue) => {
        onDatapointChange(
          newValue,
          answer?.hasOptedOut ?? false,
          answer?.optOutReason ?? '',
          dataPointPerYear ?? { timeframe: TimePeriodsEnums.year }
        );
      }, 1000),
      [onDatapointChange, answer, dataPointPerYear]
    );

    // Handle input changes with a debounced call.
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      debounceSave(newValue);
      setHasStartedTyping(true);
    };

    if (loading) {
      return <Skeleton height="20px" width="100%" />;
    }

    if (isGeneratingAnswers) {
      return (
        <HStack my="10px">
          <Spinner boxSize="16px" color="bg.brand.accent" />
          <Typography variant="body">AI is writing...</Typography>
        </HStack>
      );
    }

    return (
      <VStack w="100%" alignItems="start" spacing="8px" h="100%%">
        <AutoResizeTextarea
          variant="ghost"
          ref={textareaRef}
          borderRadius="6px"
          lineHeight="20px"
          size="md"
          placeholder="Write your answer"
          value={inputValue}
          opacity={answer?.hasOptedOut ? 0.4 : 1}
          onChange={handleChange}
          onClick={(e) => e.stopPropagation()}
        />
        {isIrrelevantResponse && <AIInfoWarning />}
      </VStack>
    );
  }
);

export const MultipleLongTextInput = ({
  metric,
  companyReportingUnit,
  setRowData,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnit?: string;
  setRowData: (param: MetricsTableData | undefined) => void;
}) => {
  const childrenMetrics = useMemo(
    () => metric.childrenMetrics.filter((m) => m.childMetric?.metricType === 'LONG_TEXT'),
    [metric]
  );
  return (
    <VStack mt="14px" mb="24px" spacing="16px" alignItems="stretch">
      {childrenMetrics.map((qualMetric) => {
        if (!qualMetric.childMetric) return <Typography>--</Typography>;
        return (
          <VStack w="100%" spacing={0} key={qualMetric.childMetric.reference} alignItems="start">
            <LongTextMetricInput
              metric={qualMetric.childMetric as MetricsTableData['metric']}
              companyReportingUnit={companyReportingUnit}
              autoExpandHeight={false}
              setRowData={setRowData}
            />
          </VStack>
        );
      })}
    </VStack>
  );
};
