import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  HStack,
  ModalBodyProps,
  ModalHeaderProps,
} from '@chakra-ui/react';

export function Drawer({
  isOpen,
  onClose,
  children,
  header,
  footer,
  isClosable = true,
  hasOverlay = true,
  placement = 'right',
  name,
  headerProps,
  bodyProps,
  isEmpty = false,
  hideHeader = false,
  width,
  ...props
}: {
  header?: JSX.Element | string;
  footer?: JSX.Element | string;
  isClosable?: boolean | 'no-button';
  hasOverlay?: boolean;
  name?: string;
  headerProps?: ModalHeaderProps;
  bodyProps?: ModalBodyProps;
  isEmpty?: boolean;
  hideHeader?: boolean;
  width?: string;
} & DrawerProps) {
  const paddingForChatIcon = '80px';
  return (
    <ChakraDrawer
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!!isClosable}
      closeOnOverlayClick={!!isClosable}
      placement={placement}
      {...props}
    >
      {hasOverlay && <DrawerOverlay />}
      <DrawerContent width={width} maxWidth={width} bg="bg.elevated.overlay">
        {!hideHeader && (
          <DrawerHeader maxWidth="100%" {...headerProps} justifyContent="space-between">
            <HStack> {header}</HStack>
            <HStack> {isClosable && isClosable !== 'no-button' && <DrawerCloseButton />}</HStack>
          </DrawerHeader>
        )}
        <DrawerBody 
          paddingBottom="16px"
          display="flex" 
          {...bodyProps}
        >
          {children}
        </DrawerBody>
        {footer && (
          <DrawerFooter borderTop="1px solid" borderTopColor="border.decorative">{footer}</DrawerFooter>
        )}
      </DrawerContent>
    </ChakraDrawer>
  );
}
