import { Box, Table as ChakraTable, Thead, Td, Tr, HStack } from '@chakra-ui/react';
import {
  MaterialMetricMapping,
  MaterialityState,
  Metric,
  ParentMaterialMetrics,
  MappedDisclosureRequirements,
} from './MaterialityAssessment.d';
import { Typography } from 'Tokens';
import { HelpIcon } from 'Tokens/Icons/Status';
import { IconButton, TruncatableText } from 'Atoms';
import { MaterialityStatus } from './MaterialityRadioGroup';
import { HelpTooltip, Select } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { Dispatch, MutableRefObject, SetStateAction, useMemo, useRef } from 'react';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { AdditionalTypesGroup } from 'Molecules/MetricTagsList';
import { MetricNameWithTag } from '../DataCollection/DataCollectionUtils';
import { getMetricRefNumber } from '../DisclosureRequirements/Metrics/Metrics.hooks';
import { DataCollectionLevel } from '../DataCollection';
import { QuestionType_Enum_ } from 'models';

export const MetricsMaterialityTable = ({
  parentMetrics,
  setSelectedMetric,
  onDrawerOpen,
  disclosureRequirement,
  isGroupOwner,
  metricMapping,
  setMetricMapping,
  hasParent,
  isDRAssessed,
  readOnly = false,
  isCollectOnly,
}: {
  parentMetrics: ParentMaterialMetrics[] | undefined;
  setSelectedMetric: (metric: Metric) => void;
  onDrawerOpen: () => void;
  disclosureRequirement: MappedDisclosureRequirements[number] | undefined;
  isGroupOwner: boolean | undefined;
  metricMapping: MaterialMetricMapping | undefined;
  setMetricMapping: Dispatch<SetStateAction<MaterialMetricMapping | undefined>>;
  hasParent: boolean;
  isDRAssessed: boolean;
  readOnly?: boolean;
  isCollectOnly?: boolean;
}) => {
  const { t } = useTranslation('esrs');
  const metricNameRef = useRef<HTMLDivElement>(null);
  const isParentMaterial = (ref: string) => {
    if (disclosureRequirement?.parentMateriality === MaterialityState.notMaterial) return false;
    return parentMetrics?.some((metric) => {
      return metric.metricRef === ref && metric.isMaterial === true;
    });
  };

  const defaultDataCollection = useMemo(
    () => (isGroupOwner ? DataCollectionLevel.subsidiaries : DataCollectionLevel.company),
    [isGroupOwner]
  );

  const isParentMaterialForSub = (ref: string) => {
    if (disclosureRequirement?.parentMateriality === MaterialityState.notMaterial) return false;
    return parentMetrics?.some((metric) => {
      return (
        metric.metricRef === ref &&
        metric.isMaterial === true &&
        metric.dataCollection === DataCollectionLevel.subsidiaries
      );
    });
  };

  const updateMateriality = (
    mapping: MaterialMetricMapping,
    isMaterial: boolean | null,
    isDataGatheringOnly: boolean,
    metric?: Metric
  ) => {
    mapping = {
      ...mapping,
      [metric?.reference ?? '']: {
        ...(mapping[metric?.reference ?? ''] || {}),
        isMaterial: isMaterial,
        isDataGatheringOnly,
        dataCollection: mapping[metric?.reference ?? '']?.dataCollection ?? defaultDataCollection,
      },
    };

    if (metric?.childrenMetrics && metric.childrenMetrics.length > 0) {
      metric.childrenMetrics.forEach((child) => {
        mapping = updateMateriality(
          mapping,
          isMaterial,
          isDataGatheringOnly,
          (child?.childMetric as Metric) ?? undefined
        );
      });
    }

    return mapping;
  };

  const someMetricsHaveAdditionalTypes = useMemo(
    () => disclosureRequirement?.metrics.some((metric) => !!metric.additionalTypes.length),
    [disclosureRequirement]
  );

  const showGroup = useMemo(
    () => !readOnly && !isGroupOwner && hasParent,
    [readOnly, isGroupOwner, hasParent]
  );

  return (
    <Box width="100%" border="1px solid" borderColor="border.decorative" borderRadius="8px">
      <ChakraTable width="100%" sx={{ tableLayout: 'fixed' }} variant="unstyled">
        <Thead borderBottom="1px solid" borderColor="border.decorative" borderRadius="8px">
          <Td alignSelf="flex-start" p="12px">
            <Typography variant="bodyStrong">Metric</Typography>
          </Td>

          {someMetricsHaveAdditionalTypes && (
            <Td w="184px" alignSelf="flex-start" p="12px">
              <Typography variant="bodyStrong">Information</Typography>
            </Td>
          )}

          {showGroup && (
            <Td p="12px" w="128px">
              <HStack spacing="2px">
                <Typography variant="bodyStrong">Group</Typography>
                <HelpTooltip label={t('assessment.materialityAssessment.table.tooltip.group')} />
              </HStack>
            </Td>
          )}
          <Td p="5px 8px" w="70px">
            <Typography variant="bodyStrong">Ref.</Typography>
          </Td>
          {!readOnly && (
            <Td p="12px" w="184px">
              <HStack spacing="2px">
                <Typography variant="bodyStrong">
                  {isGroupOwner ? 'Group materiality' : 'Your company'}
                </Typography>
                {!readOnly && (
                  <HelpTooltip
                    label={
                      isGroupOwner || !hasParent
                        ? t('assessment.materialityAssessment.table.tooltip.company')
                        : t('assessment.materialityAssessment.table.tooltip.subsidiary')
                    }
                  />
                )}
              </HStack>
            </Td>
          )}
          <Td w="24px"></Td>
        </Thead>
        {disclosureRequirement?.metrics
          ?.filter((metric) => metric.isAssessable === true)
          .map((metric, index) => {
            const additionalTypes = metric.additionalTypes.map((type) => type.additionalType);
            const metricInfo = metricMapping?.[metric.reference];
            let valueLabel = 'Select';
            let value: MaterialityState | null = null;

            const metricRefNumber = getMetricRefNumber(metric);

            if (metricInfo && isDRAssessed) {
              if (isCollectOnly) {
                if (metricInfo.isMaterial === true) {
                  valueLabel = 'Collect data';
                  value = MaterialityState.collectData;
                } else if (metricInfo.isMaterial === false) {
                  valueLabel = 'Do not collect';
                  value = MaterialityState.doNotCollect;
                }
              } else if (metricInfo.isDataGatheringOnly) {
                valueLabel = MaterialityStatus.gatherData;
                value = MaterialityState.gatherData;
              } else if (metricInfo.isMaterial === true) {
                valueLabel = MaterialityStatus.material;
                value = MaterialityState.material;
              } else if (metricInfo.isMaterial === false) {
                valueLabel = MaterialityStatus.notMaterial;
                value = MaterialityState.notMaterial;
              } else value = null;
            }
            const handleDeselect = () => {
              setMetricMapping((prev) => updateMateriality({ ...prev }, null, false, metric));
            };

            return (
              <Tr
                key={index}
                borderBottom={
                  index + 1 <
                  disclosureRequirement?.metrics.filter((m) => m.isAssessable === true).length
                    ? '1px solid'
                    : 'none'
                }
                borderColor="border.decorative"
              >
                <Td p="12px">
                  <HStack spacing="2px" justifyContent={'left'}>
                    <HStack flex={1}>
                      <MetricTypeIcon
                        type={
                          metric.metricType === QuestionType_Enum_.Decimal_
                            ? MetricTypes.number
                            : MetricTypes.text
                        }
                      />
                      <Box ref={metricNameRef} width="100%">
                        <MetricNameWithTag
                          name={metric.shortTitle ?? metric.title}
                          tags={metric.tags.map((tag) => tag.type)}
                          rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
                        />
                      </Box>
                    </HStack>
                  </HStack>
                </Td>

                {someMetricsHaveAdditionalTypes && (
                  <Td p="12px" w="128px">
                    <HStack spacing="2px">
                      <AdditionalTypesGroup
                        tagsWithHelp={
                          additionalTypes.map((type) => ({
                            reference: type.reference,
                            title: type.title ?? '',
                            helpText: type.description ?? '',
                          })) ?? []
                        }
                      />
                    </HStack>
                  </Td>
                )}

                {showGroup && (
                  <Td p="12px" w="128px">
                    <HStack spacing="2px">
                      <Typography variant="body">
                        {isParentMaterial(metric.reference)
                          ? MaterialityStatus.material
                          : MaterialityStatus.notMaterial}
                      </Typography>
                    </HStack>
                  </Td>
                )}
                <Td p="5px 8px">
                  <TruncatableText variant="body" text={metricRefNumber} />
                </Td>
                {!readOnly && (
                  <Td p="5px 8px">
                    <Box w="100%">
                      {isCollectOnly ? (
                        <Select
                          size="sm"
                          isDisabled={
                            !isDRAssessed ||
                            (value === MaterialityState.collectData &&
                              isParentMaterialForSub(metric.reference))
                          }
                          isClearable={true}
                          options={[
                            {
                              label: 'Collect data',
                              value: MaterialityState.collectData,
                            },
                            {
                              label: 'Do not collect',
                              value: MaterialityState.doNotCollect,
                            },
                          ]}
                          value={{
                            label: valueLabel,
                            value: value,
                          }}
                          onChange={(selection) => {
                            let isMaterial: boolean | null;
                            if (selection?.value === MaterialityState.collectData) {
                              isMaterial = true;
                            } else if (selection?.value === MaterialityState.doNotCollect) {
                              isMaterial = false;
                            }
                            if (value !== selection?.value) {
                              setMetricMapping((prev) => {
                                const updatedMapping = updateMateriality(
                                  { ...prev },
                                  isMaterial,
                                  false,
                                  metric
                                );
                                return updatedMapping;
                              });
                            }
                          }}
                          deselect={handleDeselect}
                        />
                      ) : (
                        <Select
                          size="sm"
                          isDisabled={!isDRAssessed}
                          isClearable={true}
                          options={[
                            {
                              label: MaterialityStatus.material,
                              value: MaterialityState.material,
                            },
                            {
                              label: MaterialityStatus.notMaterial,
                              value: MaterialityState.notMaterial,
                            },
                            {
                              label: MaterialityStatus.gatherData,
                              value: MaterialityState.gatherData,
                            },
                          ]}
                          value={{
                            label: valueLabel,
                            value: value,
                          }}
                          isOptionDisabled={(option) =>
                            option.value === MaterialityState.material &&
                            disclosureRequirement.materialityStatus === MaterialityState.gatherData
                          }
                          onChange={(selection) => {
                            let isMaterial: boolean | null;
                            if (
                              selection?.value === MaterialityState.material ||
                              selection?.value === MaterialityState.gatherData
                            ) {
                              isMaterial = true;
                            } else if (selection?.value === MaterialityState.notMaterial) {
                              isMaterial = false;
                            } else {
                              isMaterial = null;
                            }
                            if (value !== selection?.value) {
                              setMetricMapping((prev) => {
                                const updatedMapping = updateMateriality(
                                  { ...prev },
                                  isMaterial,
                                  selection?.value === MaterialityState.gatherData,
                                  metric
                                );
                                return updatedMapping;
                              });
                            }
                          }}
                          deselect={handleDeselect}
                        />
                      )}
                    </Box>
                  </Td>
                )}
                <Td w="12px" p="10px 4px">
                  <IconButton
                    aria-label="learn more"
                    variant="ghost"
                    icon={<HelpIcon />}
                    size="md"
                    onClick={() => {
                      setSelectedMetric(metric);
                      onDrawerOpen();
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
      </ChakraTable>
    </Box>
  );
};
