import { Box, HStack, VStack } from '@chakra-ui/react';
import { Avatar, Button, IconButton, TruncatableText } from 'Atoms';
import { HelpTooltip } from 'Molecules';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { CompanyIcon, SumIcon } from 'Tokens/Icons/Data';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { HelpIcon } from 'Tokens/Icons/Status';
import { getBUDisclosureRequirementLink, getDisclosureRequirementLink } from 'utils/links';
import { MetricsTableData } from '..';
import { AggregatedQualitativeAnswers, SubsidiaryAssessmentsType } from '../AggregatedMetrics';
import { MetricsDocumentation } from '../MetricsDocumentation';

const DetailsCard = ({
  isChild = true,
  title,
  author,
  answer,
  subsidiary,
  metricRef,
  reportingUnitId,
  isGroup = true,
}: {
  isChild?: boolean;
  title: string;
  author: string;
  answer?: string | null;
  subsidiary?: SubsidiaryAssessmentsType;
  metricRef?: string;
  reportingUnitId?: string;
  isGroup?: boolean;
}) => {
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef,
    disclosureRequirementRef = '',
    drType = '',
  } = useParams();
  const [showMore, setShowMore] = useState(false);
  const numberOfLines = useMemo(() => Math.ceil((answer?.length ?? 1) / 48), [answer]);
  return (
    <VStack alignItems="start" spacing={isChild ? '4px' : '6px'} width="100%">
      <HStack spacing="6px" width="100%">
        {isChild ? <CompanyIcon /> : <SumIcon />}
        <TruncatableText variant="body" text={title} />
        <HStack spacing="4px" width="100%" style={{ flex: '1', marginLeft: 'auto' }}>
          <Avatar size="xs" name={author} />
          {isChild ? (
            <IconButton
              aria-label="click"
              size="sm"
              variant="ghost"
              icon={<ArrowUpRightIcon />}
              onClick={() =>
                window.open(
                  isGroup
                    ? `${getDisclosureRequirementLink({
                        companyId: subsidiary?.company?.id,
                        assessmentId: subsidiary?.id,
                        standardRef,
                        disclosureRequirement: {
                          reference: disclosureRequirementRef,
                          type: drType,
                        },
                      })}/overview?metricRef=${metricRef}`
                    : `${getBUDisclosureRequirementLink({
                        companyId,
                        assessmentId: esrsAssessmentId,
                        businessUnitId: reportingUnitId ?? '',
                        standardRef,
                        disclosureRequirement: {
                          reference: disclosureRequirementRef,
                          type: drType,
                        },
                      })}/data-input?metricRef=${metricRef}`
                )
              }
            />
          ) : (
            <HelpTooltip label="Use answers aggregated from subsidiaries to write your summary for this data point" />
          )}
        </HStack>
      </HStack>
      {!!answer ? (
        <>
          <Typography variant="body" noOfLines={showMore ? undefined : 5} width="305px">
            {answer}
          </Typography>
          {numberOfLines > 5 && (
            <Button
              variant="ghost"
              size="sm"
              alignVertically={true}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Show less' : 'Show more'}
            </Button>
          )}
        </>
      ) : isChild ? (
        <Typography variant="body" color="text.hint">
          N/A
        </Typography>
      ) : (
        <Typography variant="body" color="text.muted">
          Use subsidiaries' answers to write an answer for this data point.
        </Typography>
      )}
    </VStack>
  );
};

export const MetricSource = ({
  answerId,
  metric,
  hasOptedOut,
  companyReportingUnit,
  answerData,
  isGroup,
  username,
}: {
  answerId: string;
  metric: MetricsTableData['metric'];
  hasOptedOut?: boolean;
  companyReportingUnit?: string;
  answerData?: AggregatedQualitativeAnswers[number];
  isGroup: boolean;
  username: string;
}) => {
  return (
    <Box h="100%" display="flex" flexGrow="1" flexDirection="column">
      <VStack alignItems="start" width="100%" spacing="12px" padding="24px 20px" flexGrow="1">
        <Typography variant="h3">Documentation</Typography>
        <MetricsDocumentation
          answerId={answerId}
          metricRef={metric.reference}
          isDisabled={hasOptedOut}
          companyReportingUnit={companyReportingUnit}
        />
      </VStack>
      {answerData && !metric.childrenMetrics.length && (
        <VStack
          alignItems="start"
          spacing="12px"
          padding="20px 24px"
          width="100%"
          borderTop="1px solid"
          borderColor="border.decorative"
          flexGrow="1"
        >
          <Typography variant="h3">
            {isGroup ? `Subsidiaries' answers` : `Business Units' answers`}
          </Typography>
          <Box pb="16px" borderBottom="1px solid" borderColor="border.decorative" width="100%">
            <DetailsCard
              title={'Summary'}
              author={answerData?.answer?.datapoints?.[0]?.author?.displayName ?? username}
              answer={answerData?.answer?.datapoints?.[0]?.value}
              isChild={false}
            />
          </Box>
          <VStack alignItems="start" width="100%" spacing="16px">
            {isGroup
              ? answerData?.subsidiaries?.map((sub) => (
                  <DetailsCard
                    title={sub.subsidiary.company.name}
                    author={sub.answer?.datapoints?.[0]?.author?.displayName ?? ''}
                    answer={sub.answer?.datapoints?.[0]?.value}
                    subsidiary={sub.subsidiary}
                    metricRef={answerData.metricRef}
                    isGroup={isGroup}
                  />
                ))
              : answerData?.reportingUnits?.map((ru) => (
                  <DetailsCard
                    title={ru.reportingUnit.name}
                    author={ru.answer?.datapoints?.[0]?.author?.displayName ?? ''}
                    answer={ru.answer?.datapoints?.[0]?.value}
                    metricRef={answerData.metricRef}
                    reportingUnitId={ru.reportingUnit.id}
                    isGroup={isGroup}
                  />
                ))}
          </VStack>
        </VStack>
      )}
    </Box>
  );
};
