import { useTranslation } from 'utils/translation';
import { AlertDialog, ContentLayout, SearchInput, Table } from 'Molecules';
import { Button, EmptyState, Tag } from 'Atoms';
import { useMemo, useState } from 'react';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { AssessmentsSearchEmptyState } from 'containers/Assessments/pieces';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { GroupAssessmentFieldsFragment_, useGroupAssessmentsQuery } from 'models';
import { EUTaxonomyIllustration, Typography } from 'Tokens';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'Molecules/Menu';
import { DeleteIcon, EditIcon } from 'Tokens/Icons/Function';
import { GroupAssessmentModal } from './GroupAssessmentModal';
import { useDeleteGroupAssessment } from 'containers/GroupAssessment';
import { stringToYear } from 'utils/date';

const AssessmentsEmptyState = () => {
  const { t } = useTranslation('common');
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('report.noReport')}
        description={t('report.noGroupReportDescription')}
        callToAction={{
          text: t('assessment.createAssessment'),
          variant: 'primary',
          onClick: () => {
            onOpen();
          },
        }}
        icon={<EUTaxonomyIllustration boxSize="120px" color="border.default" />}
      />
      <GroupAssessmentModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export type GroupAssessmentEditFields = {
  id: string;
  name: string;
  period: Date;
};

export const GroupAssessmentList = () => {
  const { t } = useTranslation(['common', 'group', 'assessment']);
  const { companyId } = useCurrentCompanyId();
  const [search, setSearch] = useState('');
  const [toDelete, setToDelete] = useState<string>();
  const [toEdit, setToEdit] = useState<GroupAssessmentEditFields>();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const deleteGroupAssessment = useDeleteGroupAssessment();
  const toast = useToast();

  const { data, loading } = useGroupAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const groupAssessments = useMemo(() => {
    return data?.assessments ?? [];
  }, [data]);

  const filteredAssessments = useMemo(() => {
    if (!search) return groupAssessments;
    return groupAssessments.filter((assessment) =>
      assessment.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, groupAssessments]);

  if (!groupAssessments.length) {
    return (
      <ContentLayout header={t('group:assessment.header')}>
        <AssessmentsEmptyState />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout header={t('group:assessment.header')} isLoading={loading}>
      <HStack width="100%" justifyContent="space-between" paddingTop="6px" paddingBottom="8px">
        <SearchInput
          placeholder={t('group:assessment.filter')}
          search={search}
          setSearch={setSearch}
        />
        <Button
          onClick={() => {
            setToEdit(undefined);
            onOpen();
          }}
          minWidth="none"
          paddingX="16px"
          variant="primary"
          size="md"
        >
          {t('common:assessment.createAssessment')}
        </Button>
      </HStack>
      {filteredAssessments.length ? (
        <Table<GroupAssessmentFieldsFragment_>
          onRowClick={(original: any) => navigate(`${original.id}`)}
          rowProps={{
            _hover: {
              cursor: 'pointer',
              bg: 'bg.hover',
            },
          }}
          cellPadding="14px 8px"
          headerPadding="14px 8px"
          columns={[
            {
              header: t('group:assessment.form.name'),
              accessorKey: 'name',
              meta: {
                colSpan: 1,
              },
              cell: ({ row }) => (
                <HStack>
                  <Typography variant="bodyStrong">{row.original.name}</Typography>
                </HStack>
              ),
            },
            {
              header: t('group:assessment.form.period'),
              accessorKey: 'period',
              meta: {
                colSpan: 1,
              },
              cell: ({ row }) => (
                <HStack>
                  <Typography variant="body">{stringToYear(row.original.period)}</Typography>
                </HStack>
              ),
            },
            {
              header: t('group:assessment.status.name'),
              accessorKey: 'status',
              meta: {
                colSpan: 1,
              },
              cell: ({ row }) => (
                <Tag
                  variant={row.original.consolidatedCompanyAssessment?.isLocked ? 'success' : 'info'}
                >
                  {row.original.consolidatedCompanyAssessment?.isLocked
                    ? t('group:assessment.status.complete')
                    : t('group:assessment.status.progress')}
                </Tag>
              ),
            },
            {
              header: '',
              accessorKey: 'actions',
              meta: {
                colSpan: 1,
              },
              cell: ({ row }) => (
                <Box textAlign="right">
                  <Menu
                    sections={[
                      {
                        actions: [
                          {
                            id: 'edit',
                            title: t('common:actions.edit'),
                            onClick: () => {
                              setToEdit({
                                id: row.original.id,
                                name: row.original.name,
                                period: row.original.period,
                              });
                              onOpen();
                            },
                            leftElement: <EditIcon color="inherit" />,
                          },
                        ],
                      },
                      {
                        actions: [
                          {
                            id: 'delete',
                            title: t('common:actions.delete'),
                            variant: 'destructive',
                            onClick: () => {
                              setToDelete(row.original.id);
                            },
                            leftElement: <DeleteIcon color="inherit" />,
                          },
                        ],
                      },
                    ]}
                  />
                </Box>
              ),
            },
          ]}
          data={filteredAssessments}
        />
      ) : (
        <AssessmentsSearchEmptyState setSearch={setSearch} />
      )}
      <GroupAssessmentModal editFields={toEdit} isOpen={isOpen} onClose={onClose} />
      <AlertDialog
        isOpen={!!toDelete}
        onClose={() => setToDelete(undefined)}
        onConfirm={() => {
          if (toDelete)
            deleteGroupAssessment(toDelete).then(() => {
              toast({
                text: t('assessment:list.delete.success'),
              });
              setToDelete(undefined);
            });
        }}
        title={t('assessment:list.title')}
        confirmLabel={t('assessment:list.confirm')}
      >
        <Typography variant="body">{t('assessment:list.alert')}</Typography>
      </AlertDialog>
    </ContentLayout>
  );
};
