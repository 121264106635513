import { AttachmentBox_Constraint_, NoteHistory_Constraint_ } from 'models';
import {
  AdaptationSectionsEnum,
  AssessmentFlags,
  BareFinancials,
  CalculatedActivityResult,
  Financials,
  ScoreSection,
  ScoreSectionsEnum,
} from '../models/types';

const defaultFinancials: BareFinancials = {
  revenue: 0,
  capex: 0,
  opex: 0,
  adaptationCapex: 0,
  adaptationOpex: 0,
  isEstimate: false,
};

export const createFinancials = (
  capex = 0,
  opex = 0,
  revenue = 0,
  adaptationCapex = 0,
  adaptationOpex = 0,
  isEstimate = true,
  noteHistory = {
    data: {},
    on_conflict: {
      constraint: NoteHistory_Constraint_.NoteHistoryFinancialsIdKey_,
      update_columns: [],
    },
  },
  attachmentBox = {
    data: {},
    on_conflict: {
      constraint: AttachmentBox_Constraint_.AttachmentBoxFinancialsIdKey_,
      update_columns: [],
    },
  }
) => ({
  capex,
  opex,
  revenue,
  adaptationCapex,
  adaptationOpex,
  isEstimate,
  noteHistory,
  attachmentBox,
});

export const createBareFinancials = (
  capex = 0,
  opex = 0,
  revenue = 0,
  adaptationCapex = 0,
  adaptationOpex = 0,
  isEstimate = true
) => ({
  capex,
  opex,
  revenue,
  adaptationCapex,
  adaptationOpex,
  isEstimate,
});

export const SCORE_SECTIONS = [
  {
    key: ScoreSectionsEnum.revenue,
    isRequired: true,
    parent: null,
    tooltip: null,
  },
  {
    key: ScoreSectionsEnum.capex,
    isRequired: true,
    parent: null,
    tooltip: null,
  },
  {
    key: ScoreSectionsEnum.opex,
    isRequired: true,
    parent: null,
    tooltip: null,
  },
] as const;

export const ADAPTATION_SECTIONS = [
  {
    key: AdaptationSectionsEnum.adaptationCapex,
    isRequired: false,
    parent: ScoreSectionsEnum.capex,
    tooltip: true,
  },
  {
    key: AdaptationSectionsEnum.adaptationOpex,
    isRequired: false,
    parent: ScoreSectionsEnum.opex,
    tooltip: true,
  },
] as const;

export const FINANCIAL_SECTIONS = [...SCORE_SECTIONS, ...ADAPTATION_SECTIONS];

export const allFinancialSections = FINANCIAL_SECTIONS.map(({ key }) => key);
export const scoreSections = SCORE_SECTIONS.map(({ key }) => key);

export function getPositiveOrZero(number: number) {
  if (number >= 0) {
    return number;
  } else {
    return 0;
  }
}

export const numberOrZero = (n: number | null) => (Number.isNaN(n) || n == null ? 0 : n);

const sumWithUndefined = (...addends: any[]) =>
  addends.reduce((sum, addend) => sum + numberOrZero(addend), 0);

const aggregateFinancialsReducer = (
  aggregatedFinancials: BareFinancials,
  currentFinancials: Partial<Financials>
): BareFinancials =>
  ({
    ...allFinancialSections.reduce(
      (agg, section) => ({
        ...agg,
        [section]: sumWithUndefined(aggregatedFinancials?.[section], currentFinancials?.[section]),
      }),
      defaultFinancials
    ),
    isEstimate: !!aggregatedFinancials?.isEstimate || !!currentFinancials?.isEstimate,
  } as BareFinancials);

export const aggregateFinancials = (financials: Partial<Financials>[]) =>
  financials.reduce(aggregateFinancialsReducer, defaultFinancials);

export const getOtherFinancials = (
  children: Array<Financials | undefined>,
  parent?: Financials
) => {
  const activitiesTotal = aggregateFinancials(children.filter(Boolean) as Financials[]);
  const notEligible = Object.fromEntries(
    allFinancialSections.map((section) => [section, parent?.[section] - activitiesTotal[section]])
  );
  return notEligible as unknown as BareFinancials;
};

export const getEligible = (total?: BareFinancials, notEligible?: BareFinancials) => {
  return FINANCIAL_SECTIONS.reduce((acc, cur) => {
    const { key } = cur;
    const value = total?.[key] ?? 0 - notEligible?.[key] ?? 0;
    return {
      ...acc,
      [key]: value,
    };
  }, {}) as BareFinancials;
};

// Score

const getAdaptationFinancials = (section: ScoreSection, financials: BareFinancials): number => {
  if (section === ScoreSectionsEnum.capex) return financials.adaptationCapex;
  if (section === ScoreSectionsEnum.opex) return financials.adaptationOpex;
  return 0;
};

export const getEligibleFinancials = ({
  section,
  financials,
  isAdaptationOnlyActivity,
  isEnabling,
  hasClimateRiskAssessment,
  isAlwaysEnablingActivity,
}: {
  section: ScoreSection;
  financials: BareFinancials;
} & AssessmentFlags) => {
  if (isAdaptationOnlyActivity && !isEnabling && !isAlwaysEnablingActivity) {
    if (!hasClimateRiskAssessment) return 0;
    return getAdaptationFinancials(section, financials);
  }
  return financials[section];
};

export const getAlignedFinancials = ({
  section,
  financials,
  isAdaptationOnlySC,
  isEnabling,
  isCompleted,
  isAligned,
}: {
  section: ScoreSection;
  financials: BareFinancials;
} & AssessmentFlags) => {
  if (!isAligned || !isCompleted) return 0;

  if (isAdaptationOnlySC && !isEnabling) {
    return getAdaptationFinancials(section, financials);
  }
  return financials[section];
};

export const getInProgressFinancials = ({
  section,
  financials,
  isAdaptationOnlySC,
  isEnabling,
  isCompleted,
  isAligned,
  isAlwaysEnablingActivity,
}: {
  section: ScoreSection;
  financials: BareFinancials;
} & AssessmentFlags) => {
  if (!isAligned || isCompleted) return 0;

  if (isAdaptationOnlySC && !isEnabling && !isAlwaysEnablingActivity) {
    return getAdaptationFinancials(section, financials);
  }
  return financials[section];
};

export const getScreenedActivityFinancials = (
  financials: BareFinancials,
  assessmentFlags: AssessmentFlags
) => {
  return scoreSections.reduce((financialsBySection, section) => {
    const total = financials[section];
    const eligible = getEligibleFinancials({ financials, section, ...assessmentFlags });
    const aligned = getAlignedFinancials({ financials, section, ...assessmentFlags });
    const inProgress = getInProgressFinancials({ financials, section, ...assessmentFlags });
    // If turnover is negative, we still want adaptation financials to be 0, not negative. This helps Math.min() to pick the right one.
    // TODO: How do we want this to behave if there's negative capex/opex and 0 or positive adaptation?
    const positiveAligned = aligned > 0 ? aligned : 0;
    const rawAdaptation = getAdaptationFinancials(section, financials);
    const alignedAdaptation = Math.min(rawAdaptation, positiveAligned);

    const enabling = assessmentFlags.isEnabling ? aligned : 0;
    const transitional = assessmentFlags.isTransitional ? aligned : 0;

    return {
      ...financialsBySection,
      [section]: {
        total,
        eligible,
        aligned,
        inProgress,
        notEligible: total - eligible,
        notAligned: eligible - aligned - inProgress,
        adaptation: alignedAdaptation,
        rawAdaptation,
        enabling,
        transitional,
      },
    };
  }, {} as CalculatedActivityResult['financials']);
};
