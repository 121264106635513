import { Box, VStack } from '@chakra-ui/react';
import { useCurrentCompanyId } from 'utils/hooks';
import mixpanel from 'mixpanel-browser';
import { DocumentationFile } from 'models';
import { FileUploader } from 'Molecules';
import React, { useState } from 'react';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { FileEditorContextProvider } from '../FileEditor';
import { FileListHeader } from './FileListHeader';
import { FileListTable } from './FileListTable';

export type FileIds = DocumentationFile['id'][];

export const FileList = ({
  files,
  selectedFiles,
  setSelectedFiles,
  drive = false,
  singleFileSelect,
}: {
  files: DocumentationFile[];
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
  drive?: boolean;
  singleFileSelect: boolean;
}) => {
  const [search, setSearch] = useState('');
  const { companyId } = useCurrentCompanyId();

  function toggleFileSelected(file: DocumentationFile) {
    if (selectedFiles.find((val) => val.id === file.id) !== undefined) {
      return setSelectedFiles(selectedFiles.filter((val) => val.id !== file.id));
    } else {
      return setSelectedFiles(selectedFiles.concat(file));
    }
  }

  if (files.length > 0) {
    return (
      <FileEditorContextProvider>
        <VStack width="100%">
          <FileListHeader
            search={search}
            setSearch={(val) => {
              setSearch(val);
              mixpanel.track(TRACKING_EVENTS.DRIVE.SEARCH, {
                companyId,
                query: val,
              });
            }}
            drive={drive}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
          <FileListTable
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            files={files}
            search={search}
            setSearch={(val) => {
              setSearch(val);
              mixpanel.track(TRACKING_EVENTS.DRIVE.SEARCH, {
                companyId,
                query: val,
              });
            }}
            toggleFileSelected={toggleFileSelected}
            drive={drive}
            singleFileSelect={singleFileSelect}
          />
        </VStack>
      </FileEditorContextProvider>
    );
  }
  return (
    <Box w="100%" flexGrow="1">
      <FileUploader multiple={true} displayToast />
    </Box>
  );
};
