import { Divider, HStack, MenuButton, VStack } from '@chakra-ui/react';
import { Button, Tag, TruncatableText } from 'Atoms';
import { keyBy } from 'lodash';
import { Modal, ModalProps } from 'Molecules';
import { useEffect, useState, Fragment } from 'react';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { PaiCompanyReportWithMetrics } from '../CompanyPai.hooks';
import { PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { Menu } from 'Molecules/Menu';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { SquareIconWrapper } from 'Tokens/Icons/SquareIconWrapper';
import { PortfoliosWithAccessToReportQuery_ } from 'models';

export enum PaiReportKey {
  year = 'sharedPaiCompanyReportId',
  q1 = 'sharedPaiCompanyReportQ1Id',
  q2 = 'sharedPaiCompanyReportQ2Id',
  q3 = 'sharedPaiCompanyReportQ3Id',
  q4 = 'sharedPaiCompanyReportQ4Id',
}

export type PaiAccessType = Record<
  string,
  {
    portfolioId: string;
    sharedPaiCompanyReportId: boolean;
    sharedPaiCompanyReportQ1Id: boolean;
    sharedPaiCompanyReportQ2Id: boolean;
    sharedPaiCompanyReportQ3Id: boolean;
    sharedPaiCompanyReportQ4Id: boolean;
  }
>;

export const SharePaiReportModal = ({
  isOpen,
  onClose,
  report,
  onConfirm,
  portfoliosWithAccess,
  idToCheck,
}: {
  report: PaiCompanyReportWithMetrics | undefined;
  onConfirm: (param: PaiAccessType) => void;
  portfoliosWithAccess?: PortfoliosWithAccessToReportQuery_;
  idToCheck: PaiReportKey;
} & Pick<ModalProps, 'isOpen' | 'onClose'>) => {
  const [status, setStatus] = useState<PaiAccessType>({});
  const { t } = useTranslation(['assessment', 'pai']);

  useEffect(() => {
    setStatus(
      keyBy(
        report?.portfoliosRequesting.map((requestingPortfolio) => ({
          portfolioId: requestingPortfolio.id,
          sharedPaiCompanyReportId:
            portfoliosWithAccess?.Portfolio.some((portfolio) => {
              return (
                portfolio.id === requestingPortfolio.id &&
                portfolio.companies[0]?.sharedPaiCompanyReportId !== null
              );
            }) ?? false,
          sharedPaiCompanyReportQ1Id:
            portfoliosWithAccess?.Portfolio.some((portfolio) => {
              return (
                portfolio.id === requestingPortfolio.id &&
                portfolio.companies[0]?.sharedPaiCompanyReportQ1Id !== null
              );
            }) ?? false,
          sharedPaiCompanyReportQ2Id:
            portfoliosWithAccess?.Portfolio.some((portfolio) => {
              return (
                portfolio.id === requestingPortfolio.id &&
                portfolio.companies[0]?.sharedPaiCompanyReportQ2Id !== null
              );
            }) ?? false,
          sharedPaiCompanyReportQ3Id:
            portfoliosWithAccess?.Portfolio.some((portfolio) => {
              return (
                portfolio.id === requestingPortfolio.id &&
                portfolio.companies[0]?.sharedPaiCompanyReportQ3Id !== null
              );
            }) ?? false,
          sharedPaiCompanyReportQ4Id:
            portfoliosWithAccess?.Portfolio.some((portfolio) => {
              return (
                portfolio.id === requestingPortfolio.id &&
                portfolio.companies[0]?.sharedPaiCompanyReportQ4Id !== null
              );
            }) ?? false,
        })),
        'portfolioId'
      )
    );
  }, [report]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      title={t('pai:share.access')}
      onConfirm={() => onConfirm(status)}
    >
      <VStack>
        {report?.portfoliosRequesting.map((portfolio, index) => (
          <Fragment key={portfolio.id}>
            <HStack justifyContent="space-between" width="100%" pr="4px">
              <HStack width="60%" justifyContent="space-between">
                <HStack>
                  <SquareIconWrapper icon={CompanyIcon} size="22px" color="white" />
                  <Typography variant="bodyStrong">{portfolio.ownerCompany.name}</Typography>
                </HStack>
                <HStack width="45%">
                  <Tag>
                    <TruncatableText variant="bodyStrong" text={portfolio?.name ?? 'N/A'} />
                  </Tag>
                </HStack>
              </HStack>
              <Menu
                size="lg"
                menuButton={
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon color="inherit" />}
                    size="sm"
                    variant="ghost"
                    aria-label="share"
                  >
                    <Typography variant="bodyStrong" color="text.muted">
                      {status[portfolio.id]?.[idToCheck]
                        ? t('assessment:sharedModal.access')
                        : t('assessment:sharedModal.noAccess')}
                    </Typography>
                  </MenuButton>
                }
                sections={[
                  {
                    actions: [
                      {
                        id: 'no access',
                        variant: status[portfolio.id]?.[idToCheck] ? 'ghost' : 'selected',
                        title: t('assessment:sharedModal.noAccess'),
                        leftElement: <PrivateIcon color="inherit" />,
                        description: t('assessment:sharedModal.noAccessDescription'),
                        rightElement: status[portfolio.id]?.[idToCheck] ? (
                          <></>
                        ) : (
                          <CheckIcon color="text.selected" />
                        ),
                        onClick: () => {
                          setStatus({
                            ...status,
                            [portfolio.id]: {
                              portfolioId: portfolio.id,
                              [idToCheck]: false,
                            },
                          });
                        },
                      },
                      {
                        id: 'access',
                        variant: status[portfolio.id]?.[idToCheck] ? 'selected' : 'ghost',
                        title: t('assessment:sharedModal.access'),
                        leftElement: <PublicIcon color="inherit" />,
                        description: t('assessment:sharedModal.accessDescription'),
                        rightElement: status[portfolio.id]?.[idToCheck] ? (
                          <CheckIcon color="text.selected" />
                        ) : (
                          <></>
                        ),
                        onClick: () => {
                          setStatus({
                            ...status,
                            [portfolio.id]: {
                              portfolioId: portfolio.id,
                              [idToCheck]: true,
                            },
                          });
                        },
                      },
                    ],
                  },
                ]}
              />
            </HStack>
            {index !== report?.portfoliosRequesting.length - 1 && (
              <Divider border="1px" borderColor="border.decorative" orientation="horizontal" />
            )}
          </Fragment>
        ))}
        ,
      </VStack>
    </Modal>
  );
};
