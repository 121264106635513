import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Circle, Collapse, HStack, Tooltip, VStack, useColorMode } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { CompletionStatus, ReportStatusIcon, toRomanNumeral } from '../ReportUtils';
import { useStandardProgress } from '../../Assessment/StandardProgress/StandardProgress.hooks';
import { useParams } from 'react-router-dom';
import { useCategoryProgress, useDisclosureProgress } from '../Report.hooks';

type StructureNode = {
  key: string;
  title: string;
  description?: string | null;
  isHidden?: boolean;
};
const ParentNode = ({
  node,
  isOpen,
  isVisible = true,
  setIsOpen,
  index,
  paddingLeft,
  progress,
}: {
  node: StructureNode[];
  isOpen: boolean;
  isVisible?: boolean;
  setIsOpen: (param: boolean) => void;
  index?: number;
  paddingLeft?: string;
  progress: number;
}) => {
  const scrollIntoView = () => {
    const element = document.getElementById(`${node[0].key}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  return (
    <Box width="100%">
      <Collapse in={isVisible} animateOpacity>
        <HStack
          onClick={scrollIntoView}
          cursor="pointer"
          paddingTop="8px"
          userSelect="none"
          paddingBottom="8px"
          width="100%"
          spacing="8px"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={paddingLeft}
        >
          {node[0].isHidden ? (
            <ReportStatusIcon status={CompletionStatus.hidden} />
          ) : progress === 100 ? (
            <ReportStatusIcon status={CompletionStatus.complete} />
          ) : (
            <Tooltip label={progress}>
              <Circle size="16px" alignItems="center">
                <PieChart
                  lineWidth={25}
                  data={[
                    {
                      title: 'Collected',
                      value: progress,
                      color: colors['bg.progress'][color],
                    },
                    {
                      title: 'Missing',
                      value: 100 - progress,
                      color: colors['bg.unknown'][color],
                    },
                  ]}
                />
              </Circle>
            </Tooltip>
          )}
          <VStack alignItems="stretch" gap="2px" w="100%" opacity={node[0].isHidden ? 0.3 : 1}>
            <Typography variant="bodyStrong" noOfLines={1}>
              {index !== undefined && `${toRomanNumeral(index + 1)}. `}
              {node[0].title}
            </Typography>
          </VStack>
          <IconButton
            variant="ghost"
            aria-label="OpenButton"
            size="xs"
            icon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setIsOpen(!isOpen)}
          />
        </HStack>
      </Collapse>
    </Box>
  );
};

const StructureItem = ({
  node,
  isVisible,
  paddingLeft,
  progress = 0,
}: {
  node: StructureNode;
  isVisible?: boolean;
  paddingLeft?: string;
  progress: number;
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const scrollIntoView = () => {
    const element = document.getElementById(`${node.key}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  return (
    <Box width="100%">
      <Collapse in={isVisible} animateOpacity>
        <HStack
          paddingTop="8px"
          cursor="pointer"
          width="100%"
          paddingBottom="8px"
          spacing="8px"
          userSelect="none"
          alignItems="start"
          onClick={scrollIntoView}
          justifyContent="space-between"
          paddingLeft={paddingLeft}
        >
          {node.isHidden ? (
            <ReportStatusIcon status={CompletionStatus.hidden} />
          ) : progress === 100 ? (
            <ReportStatusIcon status={CompletionStatus.complete} />
          ) : (
            <Circle size="16px" alignItems="center">
              <PieChart
                lineWidth={25}
                data={[
                  {
                    title: 'Collected',
                    value: progress,
                    color: colors['bg.progress'][color],
                  },
                  {
                    title: 'Missing',
                    value: 100 - progress,
                    color: colors['bg.unknown'][color],
                  },
                ]}
              />
            </Circle>
          )}
          <VStack alignItems="stretch" gap="2px" w="100%" opacity={node.isHidden ? 0.3 : 1}>
            <Typography variant="bodyStrong" noOfLines={1}>
              {node.title}
            </Typography>
          </VStack>
        </HStack>
      </Collapse>
    </Box>
  );
};

const DRNode = ({
  node,
  isVisible,
  paddingLeft,
}: {
  node: StructureNode;
  isVisible?: boolean;
  paddingLeft?: string;
}) => {
  const { esrsAssessmentId } = useParams();

  const { progress } = useDisclosureProgress(node.key, esrsAssessmentId);

  return (
    <StructureItem
      node={node}
      isVisible={isVisible}
      paddingLeft={paddingLeft}
      progress={Number(progress)}
    />
  );
};
const StandardNode = ({
  node,
  isCategoryOpen,
}: {
  node: StructureNode[];
  isCategoryOpen: boolean;
}) => {
  const [isStandardOpen, setIsStandardOpen] = useState(false);

  const { esrsAssessmentId } = useParams();
  const { progress } = useStandardProgress({
    standardRef: node[0].key,
    assessmentId: esrsAssessmentId ?? '',
  });
  return (
    <>
      <ParentNode
        node={node}
        isVisible={isCategoryOpen}
        isOpen={isStandardOpen}
        setIsOpen={setIsStandardOpen}
        paddingLeft="24px"
        progress={Number(progress)}
      />
      {isStandardOpen
        ? node
            .slice(1)
            .map((drNode) => (
              <DRNode
                key={drNode.key}
                node={drNode}
                isVisible={isStandardOpen && isCategoryOpen}
                paddingLeft="48px"
              />
            ))
        : null}
    </>
  );
};

const CategoryNode = ({ node, index }: { node: StructureNode[][]; index: number }) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);
  const { esrsAssessmentId } = useParams();
  const { progress } = useCategoryProgress(node[0][0].key, esrsAssessmentId ?? '');
  return (
    <>
      <ParentNode
        index={index}
        node={node[0]}
        isOpen={isCategoryOpen}
        setIsOpen={setIsCategoryOpen}
        progress={Number(progress)}
      />
      {isCategoryOpen
        ? node
            .slice(1)
            .map((n, key) => (
              <StandardNode key={`${key}-standard`} node={n} isCategoryOpen={isCategoryOpen} />
            ))
        : null}
    </>
  );
};

export const ReportStructure = ({ nodes }: { nodes: StructureNode[][][] }) => {
  return (
    <VStack alignItems="stretch" display="flex" p="0px" spacing="0px">
      {nodes.map((node, index) => (
        <CategoryNode key={`${index}-category`} index={index} node={node} />
      ))}
    </VStack>
  );
};
