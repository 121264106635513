import { Box, HStack, VStack, Center, Skeleton } from '@chakra-ui/react';
import { Button, IconButton, Tag, TruncatableText } from 'Atoms';
import { AvatarGroup } from 'Molecules';
import React from 'react';
import { Typography } from 'Tokens';
import { ListIcon } from 'Tokens/Icons/Data';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { PlayIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { CheckIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';

export type ReportItem = {
  id: string;
  status: 'todo' | 'done';
  title: string;
  count?: number;
  period?: string;
};

export type ReportsCardProps = {
  title: string;
  description: string;
  subTitle: string;
  type: 'taxonomy' | 'pai';
  buttonText: string;
  onLearnMoreClick: () => void;
  onDismissClick: () => void;
  isContentVisible?: boolean;
  requestedBy?: { name: string; logoUrl?: string }[];
  reportsList?: Array<ReportItem & { onClick: () => void }>;
  emptyState: { title: string; description: string; action?: React.ReactNode };
  onAllReportClick?: () => void;
  isLoaded?: boolean;
};

export const ReportsCard = ({
  title,
  description,
  subTitle,
  type,
  buttonText,
  onLearnMoreClick,
  onDismissClick,
  isContentVisible = false,
  requestedBy,
  reportsList,
  emptyState,
  onAllReportClick,
  isLoaded = true,
}: ReportsCardProps) => {
  const { t } = useTranslation('home');
  return (
    <Skeleton isLoaded={isLoaded} width="100%" alignSelf="stretch">
      <Box
        borderRadius="8px"
        border="1px solid"
        borderColor="border.decorative"
        h="100%"
        p="16px"
        w="100%"
        display="flex"
        flexDirection="column"
      >
        <HStack justifyContent="space-between" mb="10px">
          <Typography variant="h2" color="text.default">
            {title}
          </Typography>
          {requestedBy?.length && (
            <HStack>
              <Typography variant="detail" color="text.default">
                {t('home:requestedBy')}:
              </Typography>
              <AvatarGroup namesOrLogos={requestedBy} size="sm" isRounded={false} />
            </HStack>
          )}
        </HStack>

        {isContentVisible && (
          <Box borderRadius="8px" bg="bg.muted" p="12px 16px" mb="16px">
            <HStack justifyContent="space-between" alignItems="top" spacing="16px">
              <VStack>
                <HStack
                  width="100%"
                  height="100%"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography variant="h4" color="text.default" alignItems="center">
                    {subTitle}
                  </Typography>
                  <IconButton
                    variant="ghost"
                    aria-label="RemoveIcon"
                    size="sm"
                    alignItems="center"
                    icon={<RemoveIcon color="inherit" />}
                    onClick={onDismissClick}
                  />
                </HStack>
                <Typography variant="body" color="text.muted">
                  {description}
                </Typography>
                <HStack
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="100%"
                  height="100%"
                >
                  <Button
                    ml="-8px"
                    variant="ghost"
                    onClick={onLearnMoreClick}
                    size="sm"
                    color="text.selected"
                    leftIcon={type == 'taxonomy' ? <PlayIcon color="inherit" /> : undefined}
                  >
                    {buttonText}
                  </Button>
                </HStack>
              </VStack>
            </HStack>
          </Box>
        )}
        {reportsList?.length ? (
          <>
            <HStack width="100%" justifyContent="space-between">
              <Typography variant="bodyStrong" color="text.default">
                {t('lastUpdated')}
              </Typography>
              <Button
                variant="ghost"
                size="sm"
                onClick={onAllReportClick}
                mr="-8px !important"
                rightIcon={
                  <ArrowNarrowRightIcon boxSize="18px" color="text.muted" paddingTop="2px" />
                }
              >
                {t('showAll')}
              </Button>
            </HStack>
            {reportsList?.slice(0, 3)?.map((report, index) => (
              <HStack
                key={report.id ?? index}
                padding="8px"
                marginTop="8px"
                bg="bg.interactive"
                borderRadius="12px"
                justifyContent="space-between"
                cursor="pointer"
                _hover={{ bg: 'bg.hover', cursor: 'pointer' }}
                _active={{ bg: 'bg.pressed' }}
                onClick={report.onClick}
              >
                <HStack width="100%">
                  {report.status === 'todo' ? (
                    <Center height="36px" minWidth="36px" borderRadius="8px" bg="bg.unknown">
                      <ListIcon boxSize="14px" />
                    </Center>
                  ) : (
                    <Center height="36px" minWidth="36px" borderRadius="8px" bg="bg.success">
                      <CheckIcon color="text.success" boxSize="13px" />
                    </Center>
                  )}
                  <TruncatableText
                    variant="h4"
                    color="text.default"
                    text={`${report.title}${
                      !!report?.period && report?.period !== 'year'
                        ? ` ${report?.period?.toUpperCase()}`
                        : ''
                    }`}
                  />
                </HStack>
                {report?.count && report?.count > 0 && (
                  <Tag minWidth="fit-content" variant="info">
                    {report.count} {t('new')}
                  </Tag>
                )}
                <Tag minWidth="fit-content">{report.status === 'todo' ? t('todo') : t('done')}</Tag>
              </HStack>
            ))}
          </>
        ) : (
          <Center
            padding="24px"
            border="1px dashed"
            borderColor="border.decorative"
            borderRadius="8px"
            flexGrow="1"
          >
            <VStack spacing="0px">
              <Typography variant="h4" color="text.default" marginBottom="2px" textAlign="center">
                {emptyState.title}
              </Typography>
              <Typography
                variant="body"
                color="text.muted"
                marginBottom="16px !important"
                textAlign="center"
              >
                {emptyState.description}
              </Typography>
              {emptyState.action}
            </VStack>
          </Center>
        )}
      </Box>
    </Skeleton>
  );
};
