import { Typography } from 'Tokens';
import { ObjectiveWithQuestions } from 'models';
import { Modal, ModalProps } from 'Molecules';
import { HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';

import { QuestionRow } from 'Features/Screening/QuestionRow';
import { Checkbox } from 'Atoms';
import { useState } from 'react';

export const RemovedQuestionsModal = ({
  isOpen,
  onClose,
  onConfirm,
  removedQuestions,
}: {
  removedQuestions: ObjectiveWithQuestions['questions'];
} & Omit<ModalProps, 'children'>) => {
  const { t } = useTranslation(['assessment', 'common']);
  const [hasReviewed, setHasReviewed] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      size="md"
      title="Removed questions"
      confirmButtonProps={{ isDisabled: !hasReviewed }}
    >
      <VStack alignItems="start" spacing="16px">
        <Typography variant="body">
          These questions were removed because they were outdated
        </Typography>
        <VStack
          p="16px"
          alignItems="start"
          bg="bg.muted"
          borderRadius="8px"
          border="1px solid"
          borderColor="border.decorative"
        >
          <Typography variant="h4">Old questions</Typography>
          <VStack spacing="6px">
            {removedQuestions?.map((question) => (
              <QuestionRow
                key={question.id}
                question={question}
                index={''}
                // activityAssessmentId={activityAssessmentId}
                readOnly={true}
                showVersionAlert={false}
                activityAssessmentId={''}
                openAttachmentDrawer={() => {}}
                isLocked={false}
              />
            ))}
          </VStack>
        </VStack>
        <HStack>
          <Checkbox isChecked={hasReviewed} onChange={(e) => setHasReviewed(e.target.checked)}>
            <Typography variant="body">I have reviewed all deleted questions</Typography>
          </Checkbox>
        </HStack>
      </VStack>
    </Modal>
  );
};
