import { Circle, HStack, VStack, useColorMode } from '@chakra-ui/react';
import { CONTENT_HEADER_HEIGHT, MD_PADDING_HEIGHT } from 'Molecules';
import { BREADCRUMBS_HEIGHT } from 'Molecules/Breadcrumbs';
import { Typography, colors } from 'Tokens';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useESRSReportStructure, useReportProgress } from './Report.hooks';
import { ReportStructure } from './ReportComponents/ReportStructure';
import { useParams } from 'react-router-dom';

const CompletionProgressBox = ({ assessmentId }: { assessmentId: string }) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const { progress } = useReportProgress(assessmentId);
  return (
    <HStack
      gap="16px"
      bgColor="bg.info"
      border="1px solid"
      borderRadius="8px"
      borderColor="bg.info"
      padding="16px"
    >
      <Circle size="32px" alignItems="center">
        <PieChart
          lineWidth={16}
          data={[
            {
              title: 'Collected',
              value: Number(progress),
              color: colors['bg.progress'][color],
            },
            {
              title: 'Missing',
              value: 100 - Number(progress),
              color: colors['bg.unknown'][color],
            },
          ]}
        />
      </Circle>
      <VStack alignItems="stretch" gap="2px">
        <Typography variant="bodyStrong">Completion progress</Typography>
        <Typography variant="detail">{progress}%</Typography>
      </VStack>
    </HStack>
  );
};

export const ReportSideMenu = () => {
  const { esrsAssessmentId } = useParams();
  const { structure } = useESRSReportStructure(esrsAssessmentId);
  return (
    <VStack
      alignItems="stretch"
      w="20%"
      gap="16px"
      paddingBottom="24px"
      borderBottom="1px solid"
      borderBottomColor="border.decorative"
      overflowY="scroll"
      height={`calc(100vh - ${TOP_MENU_HEIGHT} - ${BREADCRUMBS_HEIGHT} - ${MD_PADDING_HEIGHT} - ${CONTENT_HEADER_HEIGHT})`}
      position="sticky"
      top="56px"
      css={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
        scrollbarWidth: 'none',
      }}
    >
      <CompletionProgressBox assessmentId={esrsAssessmentId ?? ''} />
      <Typography variant="overline">CONTENT</Typography>
      <ReportStructure nodes={structure} />
    </VStack>
  );
};
