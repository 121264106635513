import { HStack } from '@chakra-ui/react';
import { useCurrentCompany } from 'utils/hooks';
import { CompanyStructure } from 'Features/CompanyStructure';
import { useReportingGroupsQuery, CompanyAssessment } from 'models';
import { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { NodeType } from 'Features/CompanyStructure/TreeNode';
import { BusinessUnitAssessment } from 'Features/Screening/BusinessUnitAssessment';
import './Screening.css';
import { COMPANY_LEVEL_ASSESSMENT_ID } from 'containers/Assessments/Assessments.hooks';
import { useElementSize } from 'usehooks-ts';
import { BusinessUnitsSortingType } from 'containers/Assessments/pieces/Assessment.hooks';

export const Screening = ({
  cAssessment,
  businessUnitsSorting,
}: {
  cAssessment: CompanyAssessment;
  businessUnitsSorting: BusinessUnitsSortingType;
}) => {
  const { company } = useCurrentCompany();
  const { businessUnitId } = useParams();
  const [pageRef, { height }] = useElementSize();

  const navigate = useNavigate();
  const { data } = useReportingGroupsQuery({
    variables: {
      cAssessmentId: cAssessment?.id,
    },
    skip: !cAssessment?.id,
  });

  const currentBusinessUnitAssessment = useMemo(() => {
    if (businessUnitId === COMPANY_LEVEL_ASSESSMENT_ID) {
      return cAssessment?.generalAssessment[0];
    }
    return cAssessment?.bAssessments.find((bA) => bA?.businessUnit?.id === businessUnitId);
  }, [businessUnitId, cAssessment]);

  const reportingGroups = useMemo(() => {
    return data?.reportingGroups ?? [];
  }, [data]);

  const handleSelection = (selection: { nodeType: NodeType; nodeId: string }) => {
    if (selection.nodeType === 'businessUnit') {
      const bAssessment = cAssessment?.bAssessments.find(
        (bA) => bA?.businessUnit?.id === selection.nodeId
      );
      const firstActivityRef = bAssessment?.activityReports?.[0]?.activity?.reference;
      navigate(`screening/${selection.nodeId}${firstActivityRef ? `/${firstActivityRef}` : ''}`, {
        replace: true,
      });
    } else {
      // navigate to Company level SPECIAL business unit
      navigate(`screening/company/0.0`, { replace: true });
    }
  };

  if (!businessUnitId) {
    return <Navigate replace to={`screening/company/0.0`} />;
  }
  return (
    <HStack spacing="0px" alignItems="stretch" flexGrow="1" ref={pageRef}>
      {company && (
        <CompanyStructure
          reportingGroups={reportingGroups}
          bAssessments={cAssessment?.bAssessments ?? []}
          company={company}
          cAssessment={cAssessment}
          selectedNode={businessUnitId}
          onSelect={handleSelection}
          selectableNodes={[NodeType.businessUnit, NodeType.company]}
          withProgressBar
          businessUnitsSorting={businessUnitsSorting}
        />
      )}
      <BusinessUnitAssessment
        businessUnitAssessment={currentBusinessUnitAssessment}
        isLocked={cAssessment?.isLocked ?? true}
      />
    </HStack>
  );
};
