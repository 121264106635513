import { ColumnDef } from '@tanstack/react-table';
import { Table } from 'Molecules';
import { HStack, MenuButton, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import {
  ActionFieldsFragment_,
  EsrsAssessmentActionsDocument_,
  GetActionsDrDocument_,
  GetDisclosureRequirementGroupsDocument_,
  useDeleteActionMutation,
} from 'models';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Tag, TruncatableText } from 'Atoms';
import { Menu } from 'Molecules/Menu';
import { DeleteIcon, EditIcon } from 'Tokens/Icons/Function';
import { useMemo } from 'react';
import { ViewTabs } from '../Targets/Targets';
import { CombinedActionsData } from './Actions.hooks';

const ActionTitle = ({ row }: { row: CombinedActionsData }) => {
  const latestYear = row.latestYear;
  const earliestYear = row.earliestYear;
  const dates = useMemo(() => {
    if (latestYear || earliestYear) {
      if (latestYear === earliestYear) return latestYear;
      else return earliestYear + ' - ' + latestYear;
    }
    return '';
  }, [latestYear, earliestYear]);
  return (
    <VStack alignItems="start" spacing="2px">
      <TruncatableText variant="bodyStrong" text={row.title} />
      <Typography variant="micro">{dates}</Typography>
    </VStack>
  );
};

export const ActionsTable = ({
  data,
  currentTab,
  isSearch,
  setAction,
  openAction,
  isOverview,
}: {
  data: CombinedActionsData[];
  currentTab: string;
  isSearch: boolean;
  setAction: (action: ActionFieldsFragment_ | undefined) => void;
  openAction: () => void;
  isOverview?: boolean;
}) => {
  const navigate = useNavigate();
  const { esrsAssessmentId = '', standardRef = '', companyId = '' } = useParams();
  const [deleteAction] = useDeleteActionMutation();

  const columns: ColumnDef<CombinedActionsData>[] = useMemo(
    () => [
      {
        header: 'Title',
        accessorKey: 'title',
        meta: {
          width: '55%',
          padding: '0px',
        },
        cell: ({ row }) => <ActionTitle row={row.original} />,
      },
      {
        header: 'Company',
        accessorKey: 'company',
        meta: {
          width: '15%',
          padding: '0px',
        },
        cell: ({ row }) => {
          return (
            <VStack alignItems="start">
              <Typography variant="body">{row.original.companyName}</Typography>
            </VStack>
          );
        },
      },
      {
        header: isOverview ? 'Related targets' : 'Targets',
        accessorKey: 'targets',
        meta: {
          width: '15%',
          padding: '0px',
        },
        cell: ({ row }) => {
          const sections =
            row.original.targets?.map((target, key) => ({
              actions: [
                {
                  id: String(key),
                  title: target.title,
                  isDisabled: row.original?.isSubOrParent,
                  onClick: () => {
                    if (!row.original?.isSubOrParent)
                      navigate(
                        `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/target/${row.original.disclosureRequirementRef}/target-form/${target.id}/edit`
                      );
                  },
                },
              ],
            })) ?? [];
          return (
            <Menu
              customMenuButton={
                <MenuButton
                  onClick={(e) => e.stopPropagation()}
                  _hover={{ cursor: row.original.numberOfTargets ? 'pointer' : 'default' }}
                >
                  <Tag
                    variant={row.original.numberOfTargets ? 'info' : 'default'}
                    size="xs"
                    clickable={row.original.numberOfTargets ? true : false}
                    title={String(row.original.numberOfTargets)}
                  />
                </MenuButton>
              }
              sections={sections}
              size="sm"
            />
          );
        },
      },
      {
        header: isOverview ? 'Owners' : 'Owner',
        accessorKey: 'owner',
        meta: {
          width: '10%',
          padding: '0px',
        },
        cell: ({ row }) => {
          return (
            <VStack alignItems="start">
              <Avatar name={row.original.owner} size="xs" boxSize="28px" />
            </VStack>
          );
        },
      },
      {
        header: '',
        id: 'settings',
        accessorKey: 'settings',
        meta: {
          width: '5%',
          padding: '0px',
        },
        cell: ({ row }) => {
          return (
            !row.original?.isSubOrParent && (
              <HStack justifyContent="flex-end">
                <Menu
                  sections={[
                    {
                      actions: [
                        {
                          id: 'edit',
                          title: 'Edit',
                          onClick: () => {
                            setAction(row.original.action);
                            openAction();
                          },
                          leftElement: <EditIcon color="inherit" />,
                        },
                        {
                          id: 'delete',
                          title: 'Delete',
                          variant: 'destructive',
                          onClick: () => {
                            deleteAction({
                              variables: {
                                actionId: row.original.actionId,
                              },
                              refetchQueries: [
                                GetActionsDrDocument_,
                                GetDisclosureRequirementGroupsDocument_,
                                EsrsAssessmentActionsDocument_,
                              ],
                            });
                          },
                          leftElement: <DeleteIcon color="inherit" />,
                        },
                      ],
                    },
                  ]}
                  size="sm"
                />
              </HStack>
            )
          );
        },
      },
    ],
    []
  );

  const emptyStateText = useMemo(() => {
    if (isSearch) {
      return 'No actions found';
    } else {
      let message = 'No actions added by ';

      if (currentTab === ViewTabs.subsidiaries) {
        message += 'subsidiaries';
      } else if (currentTab === ViewTabs.parentCompany) {
        message += 'parent company';
      } else {
        message += 'your company';
      }

      return message;
    }
  }, [currentTab]);

  return (
    <Table
      columns={columns}
      data={data}
      withBorder={true}
      headerPadding="8px"
      cellPadding="8px"
      bottomRowBorder={false}
      emptyStateText={emptyStateText}
      onRowClick={(row) => {
        if (!row?.isSubOrParent) {
          setAction(row.action);
          openAction();
        }
      }}
      rowProps={{
        _hover: {
          cursor: 'pointer',
          bg: 'bg.hover',
        },
      }}
      conditionalRowProps={(row) => ({
        _hover: {
          cursor: row?.isSubOrParent ? 'default' : 'pointer',
          bg: 'bg.hover',
        },
      })}
    />
  );
};
