import { useMemo } from 'react';
import {
  useCompanyInvitationsQuery,
  useCompanyUsersQuery,
  useGetUserRoleInCompanyQuery,
} from 'models';
import { useCurrentCompanyId } from 'utils/hooks';
import { TeamMember } from 'containers/TeamMembers';
import { useUserData } from '@nhost/react';

export enum TeamMembersFilterOption {
  Active = 'active',
  Pending = 'pending',
  Deactivated = 'deactivated',
  All = 'all',
}

export enum UserStatus {
  Active = 'active',
  Deactivated = 'deactivated',
  Pending = 'pending',
}

export const useTeamMembers = (filterBy: TeamMembersFilterOption) => {
  const { companyId } = useCurrentCompanyId();
  const { data, loading: loadingInvitations } = useCompanyInvitationsQuery({
    variables: {
      id: companyId,
    },
  });
  const { data: usersData, loading: loadingUsers } = useCompanyUsersQuery({
    variables: {
      id: companyId,
    },
  });

  const members = useMemo(() => {
    const invitations = data?.invitations || [];
    const invited = (invitations || [])
      .filter(({ status }) => status !== UserStatus.Active)
      .map(({ userEmail, status, role, id }) => ({
        id,
        email: userEmail ?? '',
        role,
        disabled: status !== UserStatus.Pending,
        type: 'invitation',
      }));

    const active = (usersData?.users || []).map((user) => ({
      id: user.id,
      name: user.displayName,
      email: user.email,
      role: user.companyUsers?.[0]?.role,
      disabled: user.disabled,
      type: 'user',
    }));

    if (filterBy === TeamMembersFilterOption.All) {
      return [...invited, ...active];
    }
    if (filterBy === TeamMembersFilterOption.Active) {
      return active.filter((u) => !u.disabled);
    }
    if (filterBy === TeamMembersFilterOption.Pending) {
      return active.filter((u) => u.disabled);
    }
    return invited;
  }, [filterBy, usersData, data]) as TeamMember[];

  return {
    members,
    loading: loadingInvitations || loadingUsers,
  };
};

export const useUserRole = () => {
  const user = useUserData();
  const { companyId } = useCurrentCompanyId();

  const { data, loading, error } = useGetUserRoleInCompanyQuery({
    variables: {
      companyId,
      userId: user?.id,
    },
    skip: !user || !companyId,
  });

  return {
    role: data?.roleInCompany?.role,
    loading,
    error,
  };
};
