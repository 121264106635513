import { VStack, HStack, Box, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import { Button, SearchEmptyState } from 'Atoms';
import { groupBy, uniq, uniqBy } from 'lodash';
import { Loader, SearchInput, Select } from 'Molecules';
import React, { useMemo, useState } from 'react';
import { Typography } from 'Tokens/Typography';
import { TableViewSwitcher } from '../DisclosureRequirements';
import { useEsrsTargets } from './GroupAssessmentOverview.hooks';
import { useLocalStorage } from 'react-use';
import { TargetsGraphCard } from '../DisclosureRequirements/Targets/TargetsGraphCard';
import { TargetsTable } from '../DisclosureRequirements/Targets/TargetsTable';
import { AddActionModal } from '../DisclosureRequirements/Actions/AddActionModal';
import { useGetTargetsFormData } from '../DisclosureRequirements/Targets/Targets.hooks';
import { useAssessmentReportingUnits } from 'containers/Esrs/EsrsAssessment.hooks';
import { ViewSelector } from './ViewSelector';
import { MaterialTopicFilter } from '../Assessment/EsrsOverview';

export const TargetsOverview = ({ esrsAssessmentId }: { esrsAssessmentId: string }) => {
  const { esrsTargets, loading } = useEsrsTargets(esrsAssessmentId);
  const [searchValue, setSearchValue] = useState('');
  const [isChartsView, setIsChartsView] = useLocalStorage('isChartView', false);
  const [selectedView, setSelectedView] = useState('all');
  const [selectedActionId, setActionId] = useState<number>();
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<string>('All subsidiaries');
  const { reportingUnitAssessments: reportingUnits } = useAssessmentReportingUnits(
    esrsAssessmentId ?? ''
  );
  const [showAll, setShowAll] = useState(false);

  const { assessmentActions } = useGetTargetsFormData();

  const filteredTargets = useMemo(() => {
    const filteredTargs = esrsTargets?.filter((target) => {
      const search = target.title.toLowerCase().includes(searchValue.toLowerCase());
      let subsidiary = true;
      if (selectedSubsidiary !== 'All subsidiaries') {
        subsidiary = target.companyName === selectedSubsidiary;
      }

      let materialView = true;
      if (selectedView === MaterialTopicFilter.NOT_MATERIAL) {
        materialView = target.isSubOrParent;
      } else if (selectedView === MaterialTopicFilter.MATERIAL) {
        materialView = !target.isSubOrParent;
      }

      return search && subsidiary && materialView;
    });
    return filteredTargs;
  }, [esrsTargets, searchValue, selectedSubsidiary, selectedView]);

  const companyOptions = useMemo(() => {
    const companyArray = esrsTargets?.map((target) => ({
      label: target.companyName,
      value: target.companyName,
    }));
    return uniqBy(companyArray, (name) => {
      return name.label;
    });
  }, [esrsTargets]);

  const {
    onOpen: onOpenActionModal,
    isOpen: isOpenActionModal,
    onClose: onCloseActionModal,
  } = useDisclosure();

  const groupedTargets = useMemo(() => {
    return groupBy(filteredTargets, 'category.title');
  }, [filteredTargets]);

  if (loading) {
    return <Loader />;
  }

  return (
    <VStack mt="16px" spacing="24px" w="100%" alignItems="start">
      <VStack spacing="24px" alignItems="start" w="100%">
        <VStack spacing="12px" alignItems="start" w="100%">
          <HStack justifyContent="space-between" w="100%">
            <HStack>
              <SearchInput
                search={searchValue}
                setSearch={setSearchValue}
                placeholder="Filter"
                withLeftIcon={false}
                borderColor="border.default"
                minW="200px"
              />
              <ViewSelector
                width="250px"
                selectedView={selectedView}
                setSelectedView={setSelectedView}
              />
              <Select
                width="200px"
                value={{
                  label: selectedSubsidiary,
                  value: selectedSubsidiary,
                }}
                onChange={(val) => setSelectedSubsidiary(val?.value ?? '')}
                options={
                  [
                    {
                      label: 'All subsidiaries',
                      value: 'All subsidiaries',
                    },
                    ...uniq(companyOptions),
                  ] ?? []
                }
              />
            </HStack>
            <HStack>
              {/* <TableViewSwitcher
                isChartsView={isChartsView ?? false}
                setIsChartsView={setIsChartsView}
              /> */}
            </HStack>
          </HStack>
        </VStack>
        {filteredTargets?.length === 0 ? (
          <Box w="100%" h="100%">
            <SearchEmptyState setSearch={setSearchValue} />
          </Box>
        ) : (
          <VStack spacing="24px" w="100%" alignItems="stretch">
            {/* Category level */}
            {Object.entries(groupedTargets).map((categoryTarget, index) => {
              return (
                <VStack key={index} spacing="10px" alignItems="flex-start">
                  <Typography variant="overline" color="text.muted" textTransform="uppercase">
                    {categoryTarget[0]}
                  </Typography>

                  <VStack alignItems="start" w="100%">
                    {isChartsView ? (
                      <Grid templateColumns="repeat(3, 1fr)" gap="8px" w="100%">
                        {categoryTarget[1]?.map((target, curIndex) => (
                          <GridItem w="100%" key={curIndex}>
                            <TargetsGraphCard
                              target={target}
                              setAction={setActionId}
                              openAction={onOpenActionModal}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    ) : (
                      <>
                        <TargetsTable
                          data={showAll ? categoryTarget[1] : categoryTarget[1].slice(0, 3) ?? []}
                          currentTab={selectedView}
                          isOverview={true}
                          isSearch={!!searchValue}
                          setAction={setActionId}
                          openAction={onOpenActionModal}
                        />
                        {categoryTarget[1].length > 3 && (
                          <Button variant="ghost" onClick={() => setShowAll(!showAll)}>
                            {showAll ? 'Hide' : 'Show all...'}
                          </Button>
                        )}
                      </>
                    )}
                  </VStack>
                </VStack>
              );
            })}
          </VStack>
        )}
        <AddActionModal
          isOpen={isOpenActionModal}
          onClose={onCloseActionModal}
          actionToEdit={assessmentActions?.esrs_Actions.find(
            (action) => action.id === selectedActionId
          )}
          disclosureRequirementRef={
            assessmentActions?.esrs_Actions.find((action) => action.id === selectedActionId)
              ?.disclosureRequirementRef ?? ''
          }
          reportingUnits={reportingUnits}
          isGroup={true}
        />
      </VStack>
    </VStack>
  );
};
