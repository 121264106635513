import { primitives } from './Primitives';

export const colors: { [key: string]: { [key: string]: string } } = {
  'text.default': {
    default: primitives.greyscale['900-solid'],
    _dark: primitives.white['900'],
  },
  'text.muted': {
    default: primitives.greyscale['800-solid'],
    _dark: primitives.white['800'],
  },
  'text.hint': {
    default: primitives.greyscale['700'],
    _dark: primitives.white['700'],
  },
  'text.onAccent': {
    default: primitives.white['1000'],
    _dark: primitives.white['900'],
  },
  'text.selected': {
    default: primitives.primary['800'],
    _dark: primitives['primary-dark']['700'],
  },
  'text.disabled': {
    default: primitives.greyscale['700'],
    _dark: primitives.white['600'],
  },
  'text.onAccent.disabled': {
    default: primitives.white['600'],
    _dark: primitives.white['600'],
  },
  'text.success': {
    default: primitives.green['900'],
    _dark: primitives['green-dark']['900'],
  },
  'text.warning': {
    default: primitives.yellow['900'],
    _dark: primitives['yellow-dark']['900'],
  },
  'text.critical': {
    default: primitives.red['900'],
    _dark: primitives['red-dark']['900'],
  },
  'text.info': {
    default: primitives.primary['1000'],
    _dark: primitives['primary-dark']['700'],
  },
  'text.action': {
    default: primitives.primary['800'],
    _dark: primitives['primary-dark']['900'],
  },
  'text.compliant': {
    default: primitives.green['900'],
    _dark: primitives['green-dark']['900'],
  },
  'text.notCompliant': {
    default: primitives.red['900'],
    _dark: primitives['red-dark']['900'],
  },
  'border.default': {
    default: primitives.greyscale['500'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'border.hover': {
    default: primitives.greyscale['600'],
    _dark: primitives['greyscale-dark']['400'],
  },
  'border.selected.accent': {
    default: primitives.primary['800'],
    _dark: primitives['primary-dark']['600'],
  },
  'border.critical.accent': {
    default: primitives.red['900'],
    _dark: primitives['red-dark']['700'],
  },
  'border.decorative': {
    default: primitives.greyscale['400'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'border.onAccent': {
    default: primitives.white['300'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'border.disabled': {
    default: primitives.greyscale['400'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'border.drop': {
    default: primitives.primary['600'],
    _dark: primitives['primary-dark']['300'],
  },
  'border.success': {
    default: primitives.green['200'],
    _dark: primitives['green-dark']['100'],
  },
  'border.warning': {
    default: primitives.yellow['200'],
    _dark: primitives['yellow-dark']['100'],
  },
  'border.critical': {
    default: primitives.red['200'],
    _dark: primitives['red-dark']['100'],
  },
  'border.unknown': {
    default: primitives.greyscale['200'],
    _dark: primitives['greyscale-dark']['100'],
  },
  'border.info': {
    default: primitives.primary['100'],
    _dark: primitives['primary-dark']['100'],
  },
  'border.info.accent': {
    default: primitives.primary['700'],
    _dark: primitives['primary-dark']['600'],
  },
  'border.illustration': {
    default: primitives.greyscale['500-solid'],
    _dark: primitives['greyscale-dark']['400-solid'],
  },
  'bg.default': {
    default: primitives.white['1000'],
    _dark: primitives['greyscale-dark']['0-solid'],
  },
  'bg.muted': {
    default: primitives.greyscale['100-solid'],
    _dark: primitives['greyscale-dark']['100-solid'],
  },
  'bg.decorative': { // decorative bg
    default: primitives.gradient['01'],
    _dark: primitives['gradient-dark']['01'],
  },
  'bg.defaultToDecorative': { // transition from default bg to decorative bg
    default: primitives.gradient['defaultTo01'],
    _dark: primitives['gradient-dark']['defaultTo01'],
  },
  'bg.defaultToTransparent': { // transition from default bg to transparent bg
    default: primitives.gradient['defaultToTransparent'],
    _dark: primitives['gradient-dark']['defaultToTransparent'],
  },
  'bg.interactive': {
    default: primitives.greyscale['200'],
    _dark: primitives['greyscale-dark']['100'],
  },
  'bg.interactive.accent': {
    default: primitives.greyscale['300'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'bg.hover': {
    default: primitives.greyscale['200'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'bg.pressed': {
    default: primitives.greyscale['300'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'bg.disabled': {
    default: primitives.greyscale['100'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'bg.disabled.accent': {
    default: primitives.greyscale['400'],
    _dark: primitives['greyscale-dark']['400'],
  },
  'bg.accent': {
    default: primitives.greyscale['1000'],
    _dark: primitives['greyscale-dark']['200-solid'],
  },
  'bg.backdrop': {
    default: primitives.greyscale['700'],
    _dark: primitives.black['400'],
  },
  'bg.drop': {
    default: primitives.primary['300'],
    _dark: primitives['primary-dark']['300'],
  },
  'bg.progress': {
    default: primitives.greyscale['800'],
    _dark: primitives['primary-dark']['300'],
  },
  'bg.onAccent.hover': {
    default: primitives.white['200'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'bg.onAccent.pressed': {
    default: primitives.white['500'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'bg.transparent': {
    default: primitives.white['0'],
    _dark: primitives.white['0'],
  },
  'bg.selected': {
    default: primitives.primary['100'],
    _dark: primitives['primary-dark']['100'],
  },
  'bg.selected.hover': {
    default: primitives.primary['200'],
    _dark: primitives['primary-dark']['200'],
  },
  'bg.selected.pressed': {
    default: primitives.primary['300'],
    _dark: primitives['primary-dark']['300'],
  },
  'bg.selected.accent': {
    default: primitives.primary['800'],
    _dark: primitives['primary-dark']['700'],
  },
  'bg.selected.accent.hover': {
    default: primitives.primary['900'],
    _dark: primitives['primary-dark']['700'],
  },
  'bg.selected.muted': {
    default: primitives.greyscale['100'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'bg.onAccent.selected': {
    default: primitives.white['300'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'bg.action.accent': {
    default: primitives.primary['800'],
    _dark: primitives['primary-dark']['700'],
  },
  'bg.action.accent.hover': {
    default: primitives.primary['900'],
    _dark: primitives['primary-dark']['900'],
  },
  'bg.action.accent.pressed': {
    default: primitives.primary['1000'],
    _dark: primitives['primary-dark']['900'],
  },
  'bg.elevated.interactive': {
    default: primitives.white['1000'],
    _dark: primitives['greyscale-dark']['100-solid'],
  },
  'bg.elevated.interactive.hover': {
    default: primitives.white['1000'],
    _dark: primitives['greyscale-dark']['200-solid'],
  },
  'bg.elevated.overlay': {
    default: primitives.white['1000'],
    _dark: primitives['greyscale-dark']['300-solid'],
  },
  'bg.success': {
    default: primitives.green['100'],
    _dark: primitives['green-dark']['100'],
  },
  'bg.success.hover': {
    default: primitives.green['200'],
    _dark: primitives['green-dark']['200'],
  },
  'bg.success.pressed': {
    default: primitives.green['300'],
    _dark: primitives['green-dark']['300'],
  },
  'bg.success.accent': {
    default: primitives.green['700'],
    _dark: primitives['green-dark']['700'],
  },
  'bg.warning': {
    default: primitives.yellow['100'],
    _dark: primitives['yellow-dark']['100'],
  },
  'bg.warning.hover': {
    default: primitives.yellow['200'],
    _dark: primitives['yellow-dark']['200'],
  },
  'bg.warning.pressed': {
    default: primitives.yellow['300'],
    _dark: primitives['yellow-dark']['300'],
  },
  'bg.warning.accent': {
    default: primitives.yellow['700'],
    _dark: primitives['yellow-dark']['700'],
  },
  'bg.critical': {
    default: primitives.red['100'],
    _dark: primitives['red-dark']['100'],
  },
  'bg.critical.hover': {
    default: primitives.red['200'],
    _dark: primitives['red-dark']['200'],
  },
  'bg.critical.pressed': {
    default: primitives.red['300'],
    _dark: primitives['red-dark']['300'],
  },
  'bg.critical.accent': {
    default: primitives.red['700'],
    _dark: primitives['red-dark']['700'],
  },
  'bg.unknown': {
    default: primitives.greyscale['300'],
    _dark: primitives['greyscale-dark']['100'],
  },
  'bg.unknown.hover': {
    default: primitives.greyscale['300'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'bg.unknown.pressed': {
    default: primitives.greyscale['400'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'bg.unknown.accent': {
    default: primitives.greyscale['600'],
    _dark: primitives['greyscale-dark']['600'],
  },
  'bg.unknown.muted': {
    default: primitives.greyscale['100'],
    _dark: primitives['greyscale-dark']['100'],
  },
  'bg.info': {
    default: primitives.primary['100'],
    _dark: primitives['primary-dark']['100'],
  },
  'bg.info.hover': {
    default: primitives.primary['200'],
    _dark: primitives['primary-dark']['200'],
  },
  'bg.info.pressed': {
    default: primitives.primary['300'],
    _dark: primitives['primary-dark']['300'],
  },
  'bg.info.accent': {
    default: primitives.primary['700'],
    _dark: primitives['primary-dark']['300'],
  },
  'bg.info.muted': {
    default: primitives.primary['100'],
    _dark: primitives['primary-dark']['100'],
  },
  'bg.compliant': {
    default: primitives.green['100'],
    _dark: primitives['green-dark']['100'],
  },
  'bg.compliant.hover': {
    default: primitives.green['200'],
    _dark: primitives['green-dark']['200'],
  },
  'bg.compliant.pressed': {
    default: primitives.green['200'],
    _dark: primitives['green-dark']['300'],
  },
  'bg.compliant.accent': {
    default: primitives.green['700'],
    _dark: primitives['green-dark']['700'],
  },
  'bg.notCompliant': {
    default: primitives.red['100'],
    _dark: primitives['red-dark']['100'],
  },
  'bg.notCompliant.hover': {
    default: primitives.red['200'],
    _dark: primitives['red-dark']['200'],
  },
  'bg.notCompliant.pressed': {
    default: primitives.red['300'],
    _dark: primitives['red-dark']['300'],
  },
  'bg.notCompliant.accent': {
    default: primitives.red['700'],
    _dark: primitives['red-dark']['700'],
  },
  'bg.notEligible.accent': {
    default: primitives.greyscale['800'],
    _dark: primitives['greyscale-dark']['600'],
  },
  'bg.notEligibleAligned.accent': {
    default: primitives.greyscale['600'],
    _dark: primitives['greyscale-dark']['300'],
  },
  'bg.promo': {
    default: primitives.random['08'],
    _dark: primitives.random['08'],
  },
  'bg.decor.01': {
    default: primitives.random['01'],
    _dark: primitives.random['01'],
  },
  'bg.decor.02': {
    default: primitives.random['02'],
    _dark: primitives.random['02'],
  },
  'bg.decor.03': {
    default: primitives.random['03'],
    _dark: primitives.random['03'],
  },
  'bg.decor.04': {
    default: primitives.random['04'],
    _dark: primitives.random['04'],
  },
  'bg.decor.05': {
    default: primitives.random['05'],
    _dark: primitives.random['05'],
  },
  'bg.decor.06': {
    default: primitives.random['06'],
    _dark: primitives.random['09'],
  },
  'bg.decor.07': {
    default: primitives.random['07'],
    _dark: primitives.random['07'],
  },
  'bg.decor.08': {
    default: primitives.random['08'],
    _dark: primitives.random['08'],
  },
  'misc.shadow.default': {
    default: primitives.greyscale['300'],
    _dark: primitives.black['200'],
  },
  'misc.shadow.accent': {
    default: primitives.greyscale['400'],
    _dark: primitives.black['200'],
  },
  'misc.shadow.border': {
    default: primitives.greyscale['400'],
    _dark: primitives['greyscale-dark']['200'],
  },
  'misc.shadow.hover': {
    default: primitives.greyscale['500'],
    _dark: primitives.black['200'],
  },
  'misc.focus': {
    default: primitives.primary['600'],
    _dark: primitives['primary-dark']['600'],
  },
};
