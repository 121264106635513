import { HStack, VStack } from '@chakra-ui/react';
import { Alert, Button, Card, ExternalLink, Infobox, Input, TruncatableText } from 'Atoms';
import { ContentHeader, ContentLayout, Logo, SearchInput } from 'Molecules';
import { Typography } from 'Tokens';
import { useMemo, useState } from 'react';
import { INTEGRATIONS_CATEGORIES, INTEGRATIONS_LIST } from './IntegrationsList';

type integration = {
  title: string;
  src: string;
};

export const IntegrationCard = ({
  integration,
  category,
}: {
  integration: integration;
  category: string;
}) => {
  return (
    <Card
      border="1px solid "
      borderColor="border.decorative"
      boxShadow="none"
      padding="12px"
      borderRadius="8px"
      w="calc(25% - 8px)"
      cursor="pointer"
      bg="bg.default"
    >
      <HStack spacing="16px" alignItems="center" w="100%">
        <img src={integration.src} width="40px" />
        <VStack alignItems="flex-start" spacing="4px" w="100%">
          <TruncatableText variant="h4" text={integration.title}></TruncatableText>
          <Typography variant="detail" color="text.hint">
            {category}
          </Typography>
        </VStack>
      </HStack>
    </Card>
  );
};

export function Integrations() {
  const [searchValue, setSearchValue] = useState<string>('');
  const searchArray = useMemo(() => {
    const lowerCaseValue = searchValue.toLowerCase();

    const filteredArray = Object.keys(INTEGRATIONS_LIST).reduce((result, category) => {
      const categoryResults = INTEGRATIONS_LIST[category].filter((integration) =>
        integration.title.toLowerCase().includes(lowerCaseValue)
      );

      if (categoryResults.length > 0) {
        result[category] = categoryResults;
      }
      return result;
    }, {} as Record<string, any[]>);

    return filteredArray;
  }, [searchValue]);

  const [showMoreStates, setShowMoreStates] = useState<Record<string, string | boolean>>({});

  const toggleShowMore = (category: string) => {
    setShowMoreStates((prevStates) => ({
      ...prevStates,
      [category]: !prevStates[category],
    }));
  };

  return (
    <ContentLayout header={<ContentHeader title="Integrations" />}>
      <VStack alignItems="flex-start" spacing="24px">
        <Alert status="info" withIcon={true} closable={false}>
          <HStack w="100%">
            <Typography variant="body">
              Missing an integration?{' '}
              <ExternalLink
                textColor="text.info"
                textDecoration="none"
                fontSize="14px"
                fontWeight="semibold"
              >
                Let us know through Celsia ESG Portal
              </ExternalLink>
            </Typography>
          </HStack>
        </Alert>
        <SearchInput
          withLeftIcon={false}
          placeholder="Filter by name"
          search={searchValue}
          setSearch={setSearchValue}
        />

        {searchValue.length === 0 &&
          INTEGRATIONS_CATEGORIES.map((category) => (
            <VStack spacing="12px" alignItems="flex-start" w="100%" key={category}>
              <Typography variant="h3">{category}</Typography>
              <HStack wrap="wrap" spacing="8px" w="100%">
                {showMoreStates[category]
                  ? INTEGRATIONS_LIST[category].map((integration, key) => (
                      <IntegrationCard integration={integration} category={category} key={key} />
                    ))
                  : INTEGRATIONS_LIST[category]
                      .slice(0, 8)
                      .map((integration) => (
                        <IntegrationCard
                          integration={integration}
                          category={category}
                          key={integration.title}
                        />
                      ))}
              </HStack>
              {INTEGRATIONS_LIST[category].length > 8 && (
                <Button variant="ghost" onClick={() => toggleShowMore(category)}>
                  {showMoreStates[category] ? 'Show less...' : 'Show more...'}
                </Button>
              )}
            </VStack>
          ))}
        {searchValue.length > 0 &&
          Object.keys(searchArray).map((category) => (
            <VStack spacing="12px" alignItems="flex-start" w="100%">
              <Typography variant="h3">{category}</Typography>
              <HStack wrap="wrap" spacing="8px" w="100%">
                {searchArray[category].map((integration, key) => (
                  <IntegrationCard integration={integration} category={category} key={key} />
                ))}
              </HStack>
            </VStack>
          ))}
      </VStack>
    </ContentLayout>
  );
}
