export const primitives = {
  greyscale: {
    '100': 'rgba(15, 15, 46, 0.0300)',
    '200': 'rgba(15, 15, 46, 0.0400)',
    '300': 'rgba(15, 15, 46, 0.0600)',
    '400': 'rgba(15, 15, 46, 0.1200)',
    '500': 'rgba(15, 15, 46, 0.2000)',
    '600': 'rgba(15, 15, 46, 0.3000)',
    '700': 'rgba(15, 15, 46, 0.4600)',
    '800': 'rgba(15, 15, 46, 0.7500)',
    '900': 'rgba(15, 15, 46, 0.8500)',
    '1000': '#1a1a32',
    '900-solid': '#3e3e58',
    '800-solid': '#4b4b63',
    '700-solid': '#91919f',
    '600-solid': '#b6b6c0',
    '500-solid': '#cfcfd5',
    '400-solid': '#e2e2e6',
    '300-solid': '#f1f1f3',
    '200-solid': '#f6f6f7',
    '100-solid': '#f7f7f8',
  },
  primary: {
    '100': 'rgba(36, 36, 255, 0.0600)',
    '200': 'rgba(36, 36, 255, 0.1200)',
    '300': 'rgba(36, 36, 255, 0.2000)',
    '600': 'rgba(36, 36, 255, 0.5000)',
    '700': 'rgba(36, 36, 255, 0.7500)',
    '800': '#2424ff',
    '900': '#1e1ed1',
    '1000': '#1515ab',
    '700-solid': '#5b5bff',
    '600-solid': '#9191ff',
    '300-solid': '#d3d3ff',
    '200-solid': '#e4e4ff',
    '100-solid': '#f2f2ff',
  },
  red: {
    '100': 'rgba(255, 71, 71, 0.1000)',
    '200': 'rgba(255, 71, 71, 0.1600)',
    '300': 'rgba(255, 71, 71, 0.2400)',
    '700': '#ff4747',
    '900': '#d12b1f',
    '200-solid': '#ffe4e4',
  },
  green: {
    '100': 'rgba(66, 191, 150, 0.1000)',
    '200': 'rgba(66, 191, 150, 0.1600)',
    '300': 'rgba(66, 191, 150, 0.2400)',
    '700': '#42bf96',
    '900': '#27725a',
    '200-solid': '#e1f5ee',
  },
  yellow: {
    '100': 'rgba(255, 194, 77, 0.1400)',
    '200': 'rgba(255, 194, 77, 0.2000)',
    '300': 'rgba(255, 194, 77, 0.2800)',
    '700': '#ffc24d',
    '900': '#bc4710',
    '200-solid': '#fff3db',
  },
  white: {
    '0': 'rgba(255, 255, 255, 0.0001)',
    '100': 'rgba(255, 255, 255, 0.0500)',
    '200': 'rgba(255, 255, 255, 0.0800)',
    '300': 'rgba(255, 255, 255, 0.1200)',
    '500': 'rgba(255, 255, 255, 0.2000)',
    '600': 'rgba(255, 255, 255, 0.3000)',
    '700': 'rgba(255, 255, 255, 0.5500)',
    '800': 'rgba(255, 255, 255, 0.7500)',
    '900': 'rgba(255, 255, 255, 0.8500)',
    '1000': '#ffffff',
  },
  black: {
    '200': 'rgba(0, 0, 0, 0.2000)',
    '400': 'rgba(0, 0, 0, 0.4000)',
  },
  random: {
    '01': '#0171da',
    '02': '#007ba2',
    '03': '#348119',
    '04': '#dd5d00',
    '05': '#c11855',
    '06': '#0e0e2f',
    '07': '#1e1ed1',
    '08': '#3D3DCD',
    '09': '#424269',
  },
  gradient: {
    '01': 'radial-gradient(104.31% 73.04% at 23.66% 83.48%, rgba(36, 36, 255, 0.05) 0%, rgba(36, 36, 255, 0.00) 100%), #fbfbff;',
    'defaultToTransparent': 'linear-gradient(0deg, transparent, var(--chakra-colors-bg-default))',
    'defaultTo01': 'linear-gradient(0deg, rgba(36, 36, 255, 0.05) 50%, var(--chakra-colors-bg-default))', 
  },
  'gradient-dark': {
    '01': 'radial-gradient(104.31% 73.04% at 23.66% 83.48%, rgba(109, 109, 255, 0.14) 0%, rgba(109, 109, 255, 0.00) 100%), #17171D;',
    'defaultToTransparent': 'linear-gradient(0deg, transparent, var(--chakra-colors-bg-default))', 
    'defaultTo01': 'linear-gradient(0deg, rgba(109, 109, 255, 0.05) 50%, var(--chakra-colors-bg-default))',
  },
  'greyscale-dark': {
    '100': 'rgba(204, 204, 244, 0.0300)',
    '200': 'rgba(204, 204, 244, 0.0600)',
    '300': 'rgba(204, 204, 244, 0.1000)',
    '400': 'rgba(204, 204, 244, 0.2000)',
    '500': 'rgba(204, 204, 244, 0.4000)',
    '600': 'rgba(204, 204, 244, 0.6000)',
    '400-solid': '#3e3e4c',
    '300-solid': '#2d2d37',
    '200-solid': '#26262f',
    '100-solid': '#202028',
    '0-solid': '#1b1b22',
  },
  'primary-dark': {
    '100': 'rgba(109, 109, 255, 0.2000)',
    '200': 'rgba(109, 109, 255, 0.3000)',
    '300': 'rgba(109, 109, 255, 0.4000)',
    '600': 'rgba(109, 109, 255, 0.6000)',
    '700': '#6d6dff',
    '900': '#a0a0ff',
  },
  'red-dark': {
    '100': 'rgba(255, 71, 71, 0.2000)',
    '200': 'rgba(255, 71, 71, 0.3000)',
    '300': 'rgba(255, 71, 71, 0.4000)',
    '700': '#ff4747',
    '900': '#ff7575',
  },
  'green-dark': {
    '100': 'rgba(45, 171, 130, 0.2000)',
    '200': 'rgba(45, 171, 130, 0.3000)',
    '300': 'rgba(45, 171, 130, 0.4000)',
    '700': '#2dab82',
    '900': '#42bf96',
  },
  'yellow-dark': {
    '100': 'rgba(255, 177, 29, 0.2000)',
    '200': 'rgba(255, 177, 29, 0.3000)',
    '300': 'rgba(255, 177, 29, 0.4000)',
    '700': '#ffb11d',
    '900': '#ffc24d',
  },
};
