import { useTranslation } from 'utils/translation';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { FormField, IconButton, Infobox, Input, Link } from 'Atoms';
import { Modal } from 'Molecules';
import { useUpsertCompanyInvitation } from 'Features/CompanyInvitations';
import { useCompanyInvitationsQuery, CompanyDetails, UserRole_Enum_ } from 'models';
import { Suspense, useEffect, useState } from 'react';
import { Typography } from 'Tokens';
import { useParams } from 'react-router-dom';
import { RemoveIcon } from 'Tokens/Icons/Function';
import { IconPlus } from '@tabler/icons-react';
import { RoleSelector } from './RoleSelector';

const MAX_INVITE_LENGTH = 5;

const defaultInvite = { email: '', role: UserRole_Enum_.Editor_ };

export const InviteMembersForm = ({
  invitations,
  setInvitations,
  allValid,
  setAllValid,
}: {
  company: CompanyDetails;
  invitations: Array<Invite>;
  setInvitations: (invitations: Array<Invite>) => void;
  inviteToChildCompanies: boolean;
  setInviteToChildCompanies: (inviteToChildCompanies: boolean) => void;
  allValid: boolean[];
  setAllValid: (allValid: boolean[]) => void;
}) => {
  const { t } = useTranslation(['login', 'common']);

  useEffect(() => {
    setAllValid(
      invitations.map(({ email }) =>
        email !== '' ? email.includes('@') && email.includes('.') : true
      )
    );
  }, [invitations]);

  const remove = (index: number) => {
    setInvitations(invitations.filter((_, i) => i !== index));
  };
  const append = () => {
    setInvitations([...invitations, defaultInvite]);
  };

  return (
    <VStack spacing="8px" alignItems="flex-start" width="100%">
      {invitations.map((field, index) => (
        <FormField
          key={index}
          id=""
          label=""
          isInvalid={!!allValid && !allValid[index]}
          error={t('login:inputs.email.validateError')}
        >
          <HStack key={index} width="100%" spacing="4px">
            <Input
              key={index}
              isInvalid={!!allValid && !allValid[index]}
              placeholder={t('login:fields.email.label')}
              type="email"
              isRequired
              width="100%"
              value={field.email}
              onChange={(e) => {
                setInvitations(
                  invitations.map((val, i) =>
                    i === index ? { ...val, email: e.target.value } : val
                  )
                );
              }}
            />
            <RoleSelector
              value={field.role}
              onChange={(newRole) => {
                setInvitations(
                  invitations.map((val, i) => (i === index ? { ...val, role: newRole } : val))
                );
              }}
            />
            <IconButton
              aria-label="remove"
              icon={<RemoveIcon />}
              variant="ghost"
              onClick={() => remove(index)}
              size="md"
            />
          </HStack>
        </FormField>
      ))}
      <Button
        onClick={() => append()}
        isDisabled={invitations.length >= MAX_INVITE_LENGTH}
        leftIcon={<IconPlus fontWeight="500" size="16px" />}
        iconSpacing="4px"
        color="text.muted"
        size="sm"
        fontWeight="500"
        variant="ghost"
      >
        Add
      </Button>
      {/* {company?.isPortfolioOwner && (
        <HStack justifyContent="flex-start" spacing="sm">
          <Checkbox
            checked={inviteToChildCompanies}
            onChange={(e) => setInviteToChildCompanies(e.target.checked)}
          />
          <BodyText fontSize="md">{t('common:invitations.inviteToChildCompanies')}</BodyText>{' '}
        </HStack>
      )} */}
    </VStack>
  );
};

type Invite = { email: string; role: UserRole_Enum_ };

export const InviteMembersModal = ({
  isOpen = false,
  onClose,
  onInvitationsSent = () => {},
  withSubsidiaries = false,
}: {
  isOpen: boolean;
  onClose: () => void;
  onInvitationsSent?: (invitations: Invite[]) => void;
  withSubsidiaries?: boolean;
}) => {
  const { companyId = '' } = useParams();
  const [allValid, setAllValid] = useState<boolean[]>([]);
  const [newInvitations, setNewInvitations] = useState<Invite[]>([]);
  const [inviteToChildCompanies, setInviteToChildCompanies] = useState(false);

  const { company } = useCurrentCompany();
  const [inviteUserToCompany] = useUpsertCompanyInvitation();
  const toast = useToast();
  const { t } = useTranslation('common');
  const { data, loading } = useCompanyInvitationsQuery({
    variables: {
      id: company?.id,
    },
    skip: !company,
  });
  const existingInvitations = data?.invitations || [];

  const onSubmit = async () => {
    try {
      await Promise.allSettled(
        newInvitations.map(({ email, role }) => {
          if (email) {
            const previsoulyInvited = existingInvitations.find(
              (invitation) => invitation.userEmail === email
            );
            return inviteUserToCompany({
              ...previsoulyInvited,
              userEmail: email,
              companyId: company?.id,
              status: 'pending',
              inviteToChildCompanies: inviteToChildCompanies ?? false,
              shouldAddToSubs: withSubsidiaries ?? false,
              role,
            });
          }
        })
      );
      onInvitationsSent(newInvitations);
      onClose();
      toast({ text: t('common:toast.invitationSent') });
    } catch (err: any) {
      toast({
        text: `${t('common:toast.invitationFailed.title')} - 
        ${err.response?.data?.message ?? t('common:toast.invitationFailed.tryAgain')}`,
        variant: 'danger',
      });
    }
  };
  useEffect(() => {
    setNewInvitations([{ email: '', role: UserRole_Enum_.Editor_ }]);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('common:invitations.inviteMember')}
      subtitle={`Invite users to ${company?.name ?? 'Group company'} ${
        withSubsidiaries ? 'and subsidiaries' : ''
      }`}
      confirmText={t('common:invitations.invite')}
      onConfirm={() => {
        onSubmit();
      }}
      confirmButtonProps={{
        isDisabled: newInvitations[0]?.email === '' || !allValid.every((a) => a),
      }}
    >
      <VStack alignItems="start" w="100%" spacing="16px">
        <Box width="100%">
          <Infobox
            status="neutral"
            withIcon={false}
            closable={false}
            p="16px"
            pr="24px"
            minHeight="unset"
            description={
              withSubsidiaries ? (
                <Typography variant="body">
                  People added here will be invited to {company?.name ?? 'Group company'} and all
                  subsidiaries. If you don’t want to invite new users to subsidiaries, add them
                  through the{' '}
                  <Link
                    to={`/${companyId}/team-members`}
                    _hover={{ textDecoration: 'none' }}
                    color="text.action"
                    target="_blank"
                  >
                    team members page
                  </Link>
                  .
                </Typography>
              ) : (
                <Typography variant="body">
                  People invited here will be invited only to {company?.name ?? 'current company'}
                </Typography>
              )
            }
          />
        </Box>
        <Suspense>
          {company && !loading && (
            <InviteMembersForm
              company={company}
              invitations={newInvitations}
              inviteToChildCompanies={inviteToChildCompanies}
              setInviteToChildCompanies={setInviteToChildCompanies}
              allValid={allValid}
              setAllValid={setAllValid}
              setInvitations={setNewInvitations}
            />
          )}
        </Suspense>
      </VStack>
    </Modal>
  );
};
