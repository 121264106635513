import { useGetSubsidiaryType } from 'containers/Esrs';
import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetric_Constraint_,
  Esrs_MaterialMetric_Update_Column_,
  GetMaterialStandardDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useEsrsAssessmentQuery,
  useGetEsrsCategoriesQuery,
  useGetParentMaterialityAssessmentQuery,
  useUpsertMaterialStandardMutation,
  useUpsertPolicyMutation,
  MaterialStandardMaterialRequirementsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetBuDisclosureRequirementMetricsDocument_,
  CompanyLevelMetricsPerDisclosureDocument_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  GetTargetsDrDocument_,
  GetActionsDrDocument_,
  GetPoliciesDrDocument_,
  GetMetricsDrDocument_,
  GetEsrsStandardQuery_,
  useUpsertMultipleMaterialStandardsMutation,
  GroupLevelMetricsPerDisclosureDocument_,
  GetParentMaterialityDocument_,
  useGetEsrsStandardQuery,
  useGetMaterialStandardQuery,
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  useGetStandardMetricsQuery,
  GetParentMaterialityAssessmentQuery_,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyType, useToast } from 'utils/hooks';
import { DataCollectionLevel } from '../DataCollection';
import {
  DisclosureRequirementGroups,
  MaterialityState,
  MaterialMetric,
  Requirement,
  MaterialMap,
  MaterialityFields,
  Metric,
  ParentMaterialMetricsType,
} from './MaterialityAssessment.d';

export const useGetStandardMaterialityData = () => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const { companyType } = useCompanyType();

  const { data: standardData, loading: standardDataLoading } = useGetEsrsStandardQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });

  const { data: materialStandardData, loading: materialStandardLoading } =
    useGetMaterialStandardQuery({
      variables: { standardRef: standardRef, assessmentId: esrsAssessmentId },
    });

  const { data: parentData, loading: parentDataLoading } = useGetParentMaterialityAssessmentQuery({
    variables: {
      childAssessmentId: esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const { data: standardMetrics, loading: standardsLoading } = useGetStandardMetricsQuery({
    variables: { standard: standardRef ?? '' },
    skip: !standardRef,
  });

  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);
  const materialStandard = useMemo(
    () => materialStandardData?.materialityAssessment[0],
    [materialStandardData]
  );
  const parentStandardMaterialityData = useMemo(
    () =>
      parentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
        (mA) => mA.standardRef === standardRef
      ),
    [parentData, standardRef]
  );

  const { isCollectOnly, subsidiaryTypeLoading } = useGetSubsidiaryType(esrsAssessmentId);

  const hasParent = useMemo(
    () => parentData?.EsrsAssessment_by_pk?.parentAssessment !== null,
    [parentData]
  );
  const isGroupOwner = useMemo(() => companyType === 'group-company', [companyType]);

  return {
    standard,
    materialStandard,
    standardMetrics,
    parentStandardMaterialityData,
    hasParent,
    isGroupOwner,
    standardDataLoading,
    materialStandardLoading,
    parentData,
    isCollectOnly,
    loading:
      standardDataLoading ||
      materialStandardLoading ||
      parentDataLoading ||
      subsidiaryTypeLoading ||
      standardsLoading,
  };
};

export const useGetMaterialityAssessment = (esrsAssessmentId?: string) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: parentMaterialityAssessmentData, loading: loadingParentMaterialityAssessment } =
    useGetParentMaterialityAssessmentQuery({
      variables: { childAssessmentId: esrsAssessmentId },
      skip: !esrsAssessmentId,
    });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return esrsCategoriesData?.EsrsCategory ?? [];
  }, [esrsCategoriesData]);

  const materialityAssessments = useMemo(() => {
    const hasParentMaterialityAssessment =
      !!parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment
        ?.materialStandards &&
      parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards
        ?.length > 0;
    if (data?.esrsAssessment?.materialStandards) {
      const mappedCategories: MaterialMap[] = categories.map((category) => {
        return {
          categoryName: category.title,
          categoryRef: category.reference,
          materialStandards:
            category.standards
              ?.map((standard) => {
                const materialStandard = data?.esrsAssessment?.materialStandards?.find((ms) => {
                  return ms.standardRef === standard.reference;
                });

                return {
                  id: materialStandard?.id,
                  standardRef: standard.reference,
                  standardName: standard.title,
                  description: standard.description,
                  isTopical: standard.isTopical,
                  disclosureRequirementGroups: standard.disclosureRequirementGroups,
                  isMaterial: materialStandard?.isMaterial,
                  isDataGatheringOnly: materialStandard?.isDataGatheringOnly,
                  isCopiedFromParent: materialStandard?.isCopiedFromParent,
                  isParentMaterial:
                    parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                      (ms) => {
                        return ms.standardRef === standard.reference;
                      }
                    )?.isMaterial,
                  metrics: materialStandard?.materialMetrics ?? [],
                  parentCompanyMetrics:
                    parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                      (ms) => {
                        return ms.standardRef === standard.reference;
                      }
                    )?.materialMetrics ?? [],
                  isDataCollected: materialStandard?.isDataCollected ?? false,
                };
              })
              ?.sort((a, b) => a.standardRef.localeCompare(b.standardRef)) ?? [],
          hasParentMaterialityAssessment,
        };
      });
      return mappedCategories;
    } else return [];
  }, [data, parentMaterialityAssessmentData, esrsCategoriesData]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingParentMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingParentMaterialityAssessment]);

  return {
    materialityAssessments,
    loading,
  };
};

export const useAddMaterialityAssessment = () => {
  const { standardRef, esrsAssessmentId } = useParams();
  const [addAssessment] = useUpsertMaterialStandardMutation();
  const [upsertPolicy] = useUpsertPolicyMutation();
  const toast = useToast();

  return useCallback(
    (
      values: MaterialityFields,
      material: boolean,
      materialMetrics: MaterialMetric[],
      parentMetrics: {
        metricRef: string;
        isMaterial?: boolean | null;
        dataCollection?: string | null | undefined;
      }[],
      isGatheringOnly?: boolean
    ) => {
      const defaultSubsidiariesMetrics = parentMetrics
        .filter((metric) => metric.isMaterial)
        .map((metric) => ({
          isMaterial: false,
          metricRef: metric.metricRef,
        }));

      const filteredMaterialMetrics = materialMetrics.filter((m) => m.metricRef !== '');

      const mapMaterialTags = (metric: MaterialMetric['metric']) => {
        const hasTags = metric?.tags?.length;
        const parentHasTags = metric?.parentMetrics.some((met) =>
          met.parentMetric.tags.some((tag) => tag.tagType.alwaysAll)
        );
        const metricTags = metric?.tags.filter((tag) => tag.tagType.alwaysAll) ?? [];
        return hasTags
          ? metricTags.map((tag) => ({
              tagType: tag.tagType.type,
              materialTagValues: {
                data: tag.tagType.tagValues.map((val) => ({
                  tagType: tag.tagType.type,
                  tagValue: val.value,
                })),
                on_conflict: {
                  constraint:
                    Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                  update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                },
              },
            }))
          : parentHasTags
          ? metric?.parentMetrics.flatMap((met) =>
              met.parentMetric.tags
                .filter((tag) => tag.tagType.alwaysAll)
                .map((tag) => ({
                  tagType: tag.tagType.type,
                  materialTagValues: {
                    data: tag.tagType.tagValues.map((val) => ({
                      tagType: tag.tagType.type,
                      tagValue: val.value,
                    })),
                    on_conflict: {
                      constraint:
                        Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                      update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                    },
                  },
                }))
            ) ?? []
          : [];
      };

      addAssessment({
        variables: {
          materialStandard: {
            assessmentId: esrsAssessmentId,
            standardRef,
            isDataGatheringOnly: isGatheringOnly ?? values.material === MaterialityState.gatherData,
            isMaterial:
              values.material === null
                ? null
                : values.material === MaterialityState.material ||
                  values.material === MaterialityState.gatherData,
            materialMetrics: {
              data: filteredMaterialMetrics.length
                ? filteredMaterialMetrics.map(
                    ({ isMaterial, isDataGatheringOnly, metricRef, dataCollection, metric }) => {
                      return {
                        isMaterial,
                        isDataGatheringOnly,
                        metricRef,
                        dataCollection,
                        materialMetricTags: {
                          data: mapMaterialTags(metric),
                          on_conflict: {
                            constraint:
                              Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
                            update_columns: [Esrs_MaterialMetricTag_Update_Column_.TagType_],
                          },
                        },
                      };
                    }
                  )
                : defaultSubsidiariesMetrics,
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.Metadata_,
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                  Esrs_MaterialMetric_Update_Column_.DataCollection_,
                  Esrs_MaterialMetric_Update_Column_.IsDataGatheringOnly_,
                ],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          },
        },
        refetchQueries: [
          EsrsAssessmentDocument_,
          GetMaterialStandardDocument_,
          GetParentMaterialityDocument_,
          MaterialStandardMaterialRequirementsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          GetTargetsDrDocument_,
          GetActionsDrDocument_,
          GetPoliciesDrDocument_,
          GetMetricsDrDocument_,
          GetBuDisclosureRequirementMetricsDocument_,
          CompanyLevelMetricsPerDisclosureDocument_,
          GroupLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
        ],
      })
        .then((res) => {
          toast({
            text: material ? 'Materiality assessment updated!' : 'Materiality assessment added!',
          });
          const result = res.data?.insert_esrs_MaterialStandard_one;

          if (values.material === MaterialityState.material) {
            const requirementsWithPolicies = result?.standard.disclosureRequirementGroups.filter(
              (g) => g.requirements.filter((r) => r.type === 'policy').length
            );
            upsertPolicy({
              variables: {
                input:
                  requirementsWithPolicies?.map((group) => {
                    return {
                      assessmentId: result?.id,
                      disclosureRequirementRef: group.requirements[0].reference,
                      noteHistory: {
                        data: {},
                        on_conflict: {
                          constraint: NoteHistory_Constraint_.NoteHistoryPolicyIdKey_,
                          update_columns: [],
                        },
                      },
                      attachmentBox: {
                        data: {},
                        on_conflict: {
                          constraint: AttachmentBox_Constraint_.AttachmentBoxPoliciesIdKey_,
                          update_columns: [],
                        },
                      },
                    };
                  }) ?? [],
              },
            });
          }
        })
        .catch(() => {
          toast({ text: 'Failed to add materiality assessment', variant: 'danger' });
        });
    },
    [addAssessment, upsertPolicy]
  );
};

export const useAddMandatoryMaterialityAssessment = () => {
  const { esrsAssessmentId } = useParams();
  const [addAssessment] = useUpsertMultipleMaterialStandardsMutation();
  const { companyType } = useCompanyType();
  const isGroupOwner = useMemo(() => companyType === 'group-company', [companyType]);

  return useCallback(
    (
      mandatoryStandards: {
        standardRef: string;
        metrics: string[];
      }[]
    ) => {
      addAssessment({
        variables: {
          materialStandards: mandatoryStandards.map((standard) => ({
            assessmentId: esrsAssessmentId,
            standardRef: standard.standardRef,
            isMaterial: true,
            materialMetrics: {
              data:
                standard.metrics
                  ?.filter((m) => m !== '')
                  .map((metricRef) => ({
                    isMaterial: true,
                    metricRef,
                    dataCollection: isGroupOwner
                      ? DataCollectionLevel.group
                      : DataCollectionLevel.company,
                  })) ?? [],
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.Metadata_,
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                  Esrs_MaterialMetric_Update_Column_.DataCollection_,
                  Esrs_MaterialMetric_Update_Column_.IsDataGatheringOnly_,
                ],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          })),
        },
        refetchQueries: [
          EsrsAssessmentDocument_,
          GetMaterialStandardDocument_,
          MaterialStandardMaterialRequirementsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          GetMetricsDrDocument_,
          GetBuDisclosureRequirementMetricsDocument_,
          CompanyLevelMetricsPerDisclosureDocument_,
          GroupLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
        ],
      });
    },
    [addAssessment]
  );
};

const useGetDRState = () => {
  return useCallback(
    (
      requirement: Requirement,
      materialMetrics: MaterialMetric[],
      isStandardMaterial: boolean,
      isCollectOnly: boolean
    ): MaterialityState => {
      const metrics = materialMetrics.filter(
        (metric) => metric.metric?.disclosureRequirementRef === requirement.reference
      );

      if (isCollectOnly) {
        if (metrics?.some((metric) => metric.isMaterial && metric?.isCopiedFromParent)) {
          return MaterialityState.collectDataMandatory;
        }
        if (metrics?.some((metric) => metric.isMaterial)) {
          return MaterialityState.collectData;
        } else {
          return MaterialityState.doNotCollect;
        }
      }
      if (isStandardMaterial) {
        return MaterialityState.mandatory;
      }
      if (requirement.isMandatory) {
        return MaterialityState.materialMandatory;
      }

      const isDRAssessed = !!metrics.length && metrics.some((m) => m.isMaterial !== null);
      if (!isDRAssessed) return MaterialityState.toAssess;

      const isDataGathering = metrics.some((m) => m.isDataGatheringOnly && m.isMaterial);
      if (isDataGathering) {
        return MaterialityState.gatherData;
      }

      const isMaterial = metrics.some((m) => m.isMaterial);
      return isMaterial ? MaterialityState.material : MaterialityState.notMaterial;
    },
    []
  );
};

const useGetParentDRstate = () => {
  return useCallback(
    (
      requirement: Requirement,
      parentMaterialMetrics: MaterialMetric[],
      isStandardMaterial: boolean
    ): MaterialityState => {
      const metrics = parentMaterialMetrics.filter(
        (m) => m.metric?.disclosureRequirementRef === requirement.reference
      );
      if (!isStandardMaterial) {
        return MaterialityState.notMaterial;
      }
      if (requirement.isMandatory) {
        return MaterialityState.mandatory;
      }
      const isDRAssessed = !!metrics.length && metrics.some((m) => m.isMaterial !== null);

      if (!isDRAssessed) return MaterialityState.toAssess;

      const isDataGathering = metrics.some((m) => m.isDataGatheringOnly && m.isMaterial);

      if (isDataGathering) return MaterialityState.gatherData;

      const isMaterial = metrics.some((m) => m.isMaterial);
      return isMaterial ? MaterialityState.material : MaterialityState.notMaterial;
    },
    []
  );
};

const getMetricTags = (metric?: MaterialMetric) => {
  const tags = !!metric?.materialMetricTags?.length
    ? metric?.materialMetricTags?.map((tag) => ({ type: tag.tagType }))
    : metric?.metric?.tags;

  return tags;
};

export const useMapDisclosureRequirements = () => {
  const getDrState = useGetDRState();
  const getParentDRState = useGetParentDRstate();
  return useCallback(
    ({
      materialMetrics,
      parentMaterialMetrics,
      isGroupOwner,
      isStandardMaterial,
      isCollectOnly,
      requirementGroups,
      isParentStandardMaterial,
    }: {
      materialMetrics: MaterialMetric[];
      parentMaterialMetrics: ParentMaterialMetricsType;
      isGroupOwner: boolean;
      isStandardMaterial: boolean;
      isCollectOnly: boolean;
      requirementGroups?: DisclosureRequirementGroups;
      isParentStandardMaterial?: boolean | null;
    }) => {
      const allRequirements = Object.values(requirementGroups ?? {}).flatMap(
        (group) => group.requirements
      );

      const requirements = allRequirements.slice().sort((a, b) => {
        return a.order - b.order || a.reference.localeCompare(b.reference);
      });

      const mappedRequirements = requirements.map((req) => ({
        title: req.title,
        drRef: req.reference,
        isMandatory: req.isMandatory,
        materialityStatus: getDrState(req, materialMetrics, isStandardMaterial, isCollectOnly),
        order: req.order,
        parentMateriality: !isGroupOwner
          ? getParentDRState(req, parentMaterialMetrics, isParentStandardMaterial ?? false)
          : MaterialityState.toAssess,
        metrics: req.metrics.map((metric) => ({
          ...metric,
          tags:
            getMetricTags((materialMetrics ?? []).find((m) => m.metricRef === metric.reference)) ??
            metric.tags,
        })),
        description: req.description,
        additionalTypes: req.additionalTypes.map((at) => at.additionalType),
      }));
      return mappedRequirements;
    },
    []
  );
};

const getDataCollectionLevel = (
  materialMetrics: MaterialMetric[],
  metric: Metric,
  isGroup: boolean
) => {
  const existingLevel = materialMetrics.find(
    (mm) => mm.metricRef === metric.reference
  )?.dataCollection;
  const defaultValue = isGroup ? DataCollectionLevel.subsidiaries : DataCollectionLevel.company;
  if (
    !existingLevel ||
    ((existingLevel === DataCollectionLevel.subsidiaries ||
      existingLevel === DataCollectionLevel.group) &&
      !isGroup)
  ) {
    return defaultValue;
  }
  return existingLevel;
};

export const addMandatoryMetrics = (
  standard: GetEsrsStandardQuery_['esrsStandard'],
  materialMetrics: MaterialMetric[],
  isGroup: boolean,
  material: string | null
) => {
  const mandatoryMaterialMetrics =
    standard?.disclosureRequirementGroups
      .flatMap((disclosure) =>
        disclosure.requirements
          .filter((requirement) => requirement.isMandatory)
          ?.flatMap((requirement) =>
            requirement.metrics?.map((m) => ({
              metricRef: m.reference,
              dataCollection: getDataCollectionLevel(materialMetrics, m, isGroup),
              isMaterial:
                material === null
                  ? null
                  : material === MaterialityState.material ||
                    material === MaterialityState.gatherData,
              isDataGatheringOnly: material === MaterialityState.gatherData,
            }))
          )
      )
      ?.filter((m) => m !== undefined) ?? [];

  const filteredMaterialMetrics = materialMetrics.filter(
    (m) => !mandatoryMaterialMetrics.some((mm) => mm.metricRef === m.metricRef)
  );

  const allMaterialMetrics = [...filteredMaterialMetrics, ...mandatoryMaterialMetrics];
  return allMaterialMetrics;
};

export const getUnassessedMandatoryStandardData = (materialityAssessments: MaterialMap[]) => {
  const mandatoryStandards =
    materialityAssessments?.flatMap((assessment) =>
      assessment.materialStandards?.filter((standard) => !standard.isTopical)
    ) ?? [];
  const standardDisclosures = mandatoryStandards?.map((standard) => {
    const disclosureRequirements = standard?.disclosureRequirementGroups?.flatMap(
      (drg) => drg?.requirements
    );

    const currentMaterialMetrics = materialityAssessments
      .flatMap((assessment) => assessment.materialStandards)
      .find((s) => s.standardRef === standard.standardRef)?.metrics;
    const unassessedMetrics =
      disclosureRequirements
        ?.flatMap((req) => req.metrics?.map((metric) => metric.reference))
        ?.filter(
          (reference) => !currentMaterialMetrics?.find((mm) => mm.metricRef === reference)
        ) ?? [];

    return {
      standardRef: standard.standardRef,
      metrics: unassessedMetrics,
    };
  });
  return standardDisclosures;
};
