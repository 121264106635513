import {
  Esrs_ActionTarget_Constraint_,
  Esrs_Baseline_Constraint_,
  Esrs_Baseline_Update_Column_,
  Esrs_MilestoneResult_Constraint_,
  Esrs_MilestoneResult_Update_Column_,
  Esrs_Milestone_Constraint_,
  Esrs_Milestone_Update_Column_,
  Esrs_Target_Constraint_,
  Esrs_Target_Update_Column_,
  GetActionsDocument_,
  GetReportingUnitsQuery_,
  GetDisclosureRequirementGroupsDocument_,
  GetTargetsDocument_,
  TargetsWithSubsidiariesFragment_,
  useDeleteActionTargetMutation,
  useGetActionsQuery,
  useGetMaterialStandardQuery,
  useGetDisclosureRequirementRefQuery,
  useGetMaterialQuantitativeMetricsQuery,
  useGetSubsidiariesWithAssessmentsQuery,
  useGetTargetQuery,
  useGetTargetsQuery,
  useUpsertMultipleMaterialStandardsMutation,
  useUpsertTargetMutation,
  GetMaterialStandardDocument_,
  GetTargetsSubsidiariesDocument_,
  GetParentTargetsDrDocument_,
  GetTargetsDrQuery_,
  GetParentTargetsDrQuery_,
  GetTargetsSubsidiariesQuery_,
  useGetTargetsDrQuery,
  useGetTargetsSubsidiariesQuery,
  useGetParentTargetsDrQuery,
  GetTargetsDrDocument_,
  EsrsAssessmentTargetsDocument_,
  useEsrsAssessmentActionsQuery,
} from 'models';
import { useCallback, useMemo } from 'react';
import { TargetFields, TargetUnit, KeyResultEnums } from '../Requirement';
import { useParams } from 'react-router-dom';
import { useMaterialStandardId } from 'containers/Esrs';
import { useAssessmentReportingUnits } from 'containers/Esrs/EsrsAssessment.hooks';
import { useCurrentCompany } from 'utils/hooks';

export type SubsidiariesType = {
  id: any;
  company: {
    id: any;
    name: string;
  };
  materialStandards: {
    id: any;
  }[];
}[];

type Target =
  | GetParentTargetsDrQuery_['esrs_Target'][number]
  | NonNullable<
      GetTargetsSubsidiariesQuery_['esrs']
    >['subsidiaries'][number]['materialStandards'][number]['targets'][number];

export type CombinedTargetsData = {
  title: string;
  isSubOrParent: boolean;
  disclosureRequirementRef: string;
  earliestMilestone?: number;
  latestMilestone?: number;
  targetValue?: number;
  companyName?: string;
  baseYear: number;
  baseline: number;
  milestones: {
    year: number;
    value: number;
  }[];
  actions: {
    title: string;
    id: number;
  }[];
  numberOfActions: number;
  owner: string;
  targetId: number;
  standard?: {
    reference: string;
    title: string;
  };
  category?: {
    reference: string;
    title: string;
  };
};

export const useGetTargetData = (
  disclosureRequirementRef: string,
  esrsAssessmentId: string,
  standardRef: string,
  targetId: string | undefined,
  isGroup: boolean
) => {
  const { data: action, loading: actionLoading } = useGetDisclosureRequirementRefQuery({
    variables: {
      reference: disclosureRequirementRef,
      type: 'action',
    },
  });
  const actionRef = useMemo(
    () => action?.disclosureRequirement?.group.requirements[0]?.reference,
    [action]
  );

  const { data: materialityAssessment, loading: materialLoading } = useGetMaterialStandardQuery({
    variables: { standardRef: standardRef, assessmentId: esrsAssessmentId },
    skip: !standardRef || !esrsAssessmentId,
  });

  const { companyAssessmentId, parentAssessmentId } = useMemo(() => {
    const companyId = materialityAssessment?.materialityAssessment[0].id;
    const parentId =
      materialityAssessment?.materialityAssessment[0].esrsAssessment.parentData
        ?.materialStandards?.[0]?.id || companyId;

    return { companyAssessmentId: companyId, parentAssessmentId: parentId };
  }, [materialityAssessment]);

  const { data: metrics, loading } = useGetMaterialQuantitativeMetricsQuery({
    variables: {
      standardRef: standardRef,
      companyAssessmentId: companyAssessmentId,
      parentAssessmentId: parentAssessmentId,
    },
    skip: !standardRef || !companyAssessmentId,
  });

  const { data: tData, loading: targetLoading } = useGetTargetQuery({
    variables: { targetId: targetId },
    skip: !targetId,
  });
  const targetData = useMemo(() => tData?.target, [tData]);

  const { reportingUnitAssessments: reportingUnits, loading: ruLoading } =
    useAssessmentReportingUnits(esrsAssessmentId);

  const { data: subsidiariesData, loading: subLoading } = useGetSubsidiariesWithAssessmentsQuery({
    variables: {
      esrsAssessmentId: isGroup
        ? esrsAssessmentId
        : materialityAssessment?.materialityAssessment[0].esrsAssessment.parentData?.id,
      standardRef,
    },
    skip: !esrsAssessmentId,
  });

  const subsidiaries = useMemo(
    () => (isGroup ? subsidiariesData?.esrs?.subsidiaries ?? [] : []),
    [subsidiariesData]
  );
  const allSubsidiariesIds = useMemo(
    () => subsidiariesData?.esrs?.subsidiaries?.map((s) => s.id) ?? [],
    [subsidiariesData]
  );

  const { data: actData, loading: actionsLoading } = useGetActionsQuery({
    variables: {
      disclosureRequirementRef,
      assessmentId: materialityAssessment?.materialityAssessment[0]?.id,
    },
    skip: !materialityAssessment || !disclosureRequirementRef,
  });
  const actionsData = useMemo(() => actData?.actions, [actData]);

  const parentActionsData = useMemo(
    () =>
      targetData?.parentTargetId
        ? targetData.parentTarget?.actionTargets.map((at) => at.action)
        : [],
    [targetData]
  );

  const { data: allTData, loading: allTloading } = useGetTargetsQuery({
    variables: {
      disclosureRequirementRef,
      assessmentId: materialityAssessment?.materialityAssessment[0]?.id,
    },
    skip: !disclosureRequirementRef || !materialityAssessment,
  });
  const allTargets = useMemo(() => allTData?.targets, [allTData]);

  const initialLocalAnswers = useMemo(() => {
    const buBaselinesArray =
      targetData?.keyResults
        .filter((res) => res.reportingUnitId !== null)
        .map((k) => ({
          [`${KeyResultEnums.baseYear}_${k.reportingUnitId}`]: k.baseYear,
          [`${KeyResultEnums.baseline}_${k.reportingUnitId}`]: k.baseline,
        })) ?? [];
    const baselinesObject = Object.assign({}, ...buBaselinesArray);

    const subsidiariesArray =
      targetData?.subsidiariesTargets.map((s) => {
        const keyResult = s?.keyResults?.find((k) => k.reportingUnitId === null);
        return {
          [`${KeyResultEnums.baseYear}_${s.materialStandard.assessmentId}`]: keyResult?.baseYear,
          [`${KeyResultEnums.baseline}_${s.materialStandard.assessmentId}`]: keyResult?.baseline,
        };
      }) ?? [];
    const subObject = Object.assign({}, ...subsidiariesArray);

    const milestonesArray =
      targetData?.milestones.map((milestone) => ({
        id: milestone.id,
        year: milestone.year,
        isBottomUp: milestone.isBottomUp,
        value: milestone.milestoneResults.find((mr) => mr.reportingUnitId === null)?.value,
        subsidiaries: Object.assign(
          {},
          ...targetData?.subsidiariesTargets.map((subsidiary) => {
            const subMileStone = subsidiary?.milestones.find((m) => m.year === milestone.year);
            return {
              [`${subsidiary.materialStandard.assessmentId}`]: subMileStone?.milestoneResults.find(
                (mr) => mr.reportingUnitId === null
              )?.value,
            };
          })
        ),
        reportingUnits: Object.assign(
          {},
          ...(milestone.milestoneResults
            .filter((mr) => mr.reportingUnitId !== null)
            .map((mr) => ({
              [`${mr.reportingUnitId}`]: mr.value,
            })) ?? [])
        ),
      })) ?? [];

    return {
      id: targetData?.id,
      direction: targetData?.direction ?? null,
      isRelative: true,
      metricRef: targetData?.metricRef ?? null,
      description: targetData?.description ?? null,
      ownerId: targetData?.ownerId ?? null,
      baseline: targetData?.keyResults.find((k) => k.reportingUnitId === null)?.baseline ?? 0,
      baseYear:
        targetData?.keyResults.find((k) => k.reportingUnitId === null)?.baseYear ?? new Date(),
      actions: targetData?.actionTargets.flatMap(({ actionId }) => actionId) ?? [],
      milestones: milestonesArray,
      ...baselinesObject,
      ...subObject,
    };
  }, [targetData]);

  return {
    metrics,
    materialityAssessment,
    targetData,
    reportingUnits,
    subsidiaries,
    actionsData,
    parentActionsData,
    allTargets,
    actionRef,
    initialLocalAnswers,
    allSubsidiariesIds,
    loading:
      loading ||
      materialLoading ||
      targetLoading ||
      ruLoading ||
      subLoading ||
      actionsLoading ||
      actionLoading ||
      allTloading,
  };
};

export const useCombineTargets = (
  {
    targetsData,
    parent,
    subsidiaries,
  }: {
    targetsData?: GetTargetsDrQuery_['DisclosureRequirement_by_pk'];
    parent?: GetParentTargetsDrQuery_['esrs_Target'];
    subsidiaries?: NonNullable<GetTargetsSubsidiariesQuery_['esrs']>['subsidiaries'];
  },
  companyName?: string
) => {
  const getTargetTitle = (target: Target) => {
    if (target.direction) {
      return (
        target.direction.charAt(0).toUpperCase() +
        target.direction.slice(1) +
        ' ' +
        (target.metric?.title || '').toLowerCase()
      );
    }
    return target?.metric?.title || '';
  };
  const getTargetValue = (target: Target, latestYear?: number) => {
    return target?.milestones.find((ml: any) => ml.year === latestYear)?.milestoneResults[0].value;
  };
  const mapMilestones = (target: Target) => {
    return target?.milestones.map((ml: any) => ({
      year: ml.year,
      value: ml.milestoneResults[0].value,
    }));
  };
  const mapActions = (target: Target) => {
    return target?.actionTargets.map((action: any) => ({
      title: action.action.title,
      id: action.actionId,
    }));
  };

  const mapTargets = (isSubOrParent: boolean, company?: string, targets?: Target[]) => {
    return targets?.map((target) => {
      const milestoneYears = target?.milestones.map((milestone: any) => milestone.year);
      const earliestYear = milestoneYears?.length ? Math.min(...milestoneYears) : undefined;
      const latestYear = milestoneYears?.length ? Math.max(...milestoneYears) : undefined;
      return {
        title: getTargetTitle(target),
        isSubOrParent,
        earliestMilestone: earliestYear,
        baseYear: target?.keyResults?.[0].baseYear,
        baseline: Number(target?.keyResults?.[0].baseline),
        latestMilestone: latestYear,
        disclosureRequirementRef: targetsData?.reference ?? '',
        targetValue: getTargetValue(target, latestYear),
        milestones: mapMilestones(target),
        companyName: company,
        actions: mapActions(target),
        numberOfActions: target?.actionTargets.length,
        owner: target?.owner?.displayName || '',
        targetId: target?.id,
      };
    });
  };

  const companyTargets = useMemo(
    () => (targetsData ? mapTargets(false, companyName, targetsData.targets) : []),
    [targetsData]
  );

  const subsidiariesTargets = useMemo(
    () =>
      subsidiaries?.flatMap(
        (s) => mapTargets(true, s?.company?.name ?? 'N/A', s?.materialStandards[0]?.targets) ?? []
      ) ?? [],
    [subsidiaries]
  );

  const parentTargets = useMemo(
    () =>
      mapTargets(true, parent?.[0]?.materialStandard.esrsAssessment.company?.name, parent) ?? [],
    [parent]
  );

  const combinedData: CombinedTargetsData[] = useMemo(
    () => companyTargets?.concat(subsidiariesTargets).concat(parentTargets) ?? [],
    [companyTargets, parentTargets, subsidiariesTargets]
  );

  return combinedData;
};

export const useGetTargetsFormData = () => {
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { company } = useCurrentCompany();

  const {
    companyAssessmentId,
    parentAssessmentId,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: targetsData, loading: targetsLoading } = useGetTargetsDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      esrsAssessmentId,
      standardRef,
    },
    skip: !disclosureRequirementRef || !esrsAssessmentId || !standardRef,
  });

  const [targetDR, targets] = useMemo(
    () => [
      targetsData?.DisclosureRequirement_by_pk,
      targetsData?.DisclosureRequirement_by_pk?.targets,
    ],
    [targetsData]
  );

  const { data: subData, loading: subLoading } = useGetTargetsSubsidiariesQuery({
    variables: {
      esrsAssessmentId,
      standardRef,
    },
    skip: !esrsAssessmentId || !standardRef || !company?.isGroupOwner,
  });

  const subsidiaries = useMemo(() => subData?.esrs?.subsidiaries, [subData]);

  const { data: pData, loading: parentLoading } = useGetParentTargetsDrQuery({
    variables: {
      parentStandardId: parentAssessmentId,
    },
    skip: !parentAssessmentId,
  });

  const parentTargets = useMemo(() => pData?.esrs_Target, [pData]);

  const { data: actionData, loading: actionsLoading } = useGetActionsQuery({
    variables: {
      disclosureRequirementRef: targetDR?.reference ?? '',
      assessmentId: companyAssessmentId,
    },
    skip: !companyAssessmentId || !targetDR?.reference,
  });

  const { data: assessmentActions } = useEsrsAssessmentActionsQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const actions = useMemo(() => actionData?.actions, [actionData]);

  const combinedData = useCombineTargets(
    { targetsData: targetDR, parent: parentTargets, subsidiaries },
    company?.name
  );

  return {
    targetDR,
    targets,
    actions,
    assessmentActions,
    combinedData,
    loading: targetsLoading || parentLoading || subLoading || actionsLoading || materialLoading,
  };
};

export const useGetFilteredMetrics = (disclosureRequirementRef: string, assessmentId: string) => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();

  const { companyAssessmentId, parentAssessmentId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  const { data: metrics } = useGetMaterialQuantitativeMetricsQuery({
    variables: {
      standardRef: standardRef,
      companyAssessmentId: companyAssessmentId,
      parentAssessmentId: parentAssessmentId || companyAssessmentId,
    },
    skip: !standardRef || !companyAssessmentId,
  });

  const { data: allTData } = useGetTargetsQuery({
    variables: {
      disclosureRequirementRef,
      assessmentId,
    },
    skip: !disclosureRequirementRef || !assessmentId,
  });
  const allTargets = useMemo(() => allTData?.targets, [allTData]);

  const filteredMetrics = useMemo(
    () =>
      metrics?.metrics.filter(
        (m) => !allTargets?.find((target) => target.metricRef === m?.reference),
        [allTargets, metrics]
      ),
    [allTargets, metrics]
  );

  if (!disclosureRequirementRef || !assessmentId) {
    return ['show'];
  }

  return filteredMetrics;
};

const mapToAction = (a: string) => ({
  actionId: a,
});

const getChosenActions = (
  targetFields: TargetFields,
  targetData: TargetsWithSubsidiariesFragment_ | null | undefined
) => {
  if (targetFields?.actions?.length || targetFields.actions[0] === undefined) {
    if (!targetData) {
      return targetFields.actions.filter((a) => !!a).map(mapToAction);
    }
    return (
      targetFields.actions
        .filter((a) => !!a)
        .filter(
          (action) =>
            !targetData?.actionTargets.find((actionTarget) => actionTarget.actionId === action)
        )
        .map(mapToAction) ?? []
    );
  }

  return [];
};

export const useAddTarget = () => {
  const [upsertTarget] = useUpsertTargetMutation();
  const [deleteActionTarget] = useDeleteActionTargetMutation();
  const [addAssessments] = useUpsertMultipleMaterialStandardsMutation();

  return useCallback(
    (
      values: TargetFields,
      targetData: TargetsWithSubsidiariesFragment_ | null | undefined,
      disclosureRequirement: string,
      materialityAssessmentId: string,
      reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined,
      subsidiaries: SubsidiariesType,
      standardRef: string,
      targetId: string | undefined,
      setTargetId: (param: string) => void,
      setIsSaving: (param: boolean) => void,
      milestoneId: string,
      setMilestoneId: (param: string) => void
    ) => {
      const missingAssessments = subsidiaries.filter((s) => !s.materialStandards.length) ?? [];
      const toDelete: string[] =
        targetData?.actionTargets
          ?.filter(
            (actionTarget) => !values.actions?.find((action) => action === actionTarget.actionId)
          )
          .map((actionTarget) => actionTarget.id) ?? [];

      const chosenActions = getChosenActions(values, targetData);

      addAssessments({
        variables: {
          materialStandards: missingAssessments.map((assessment) => ({
            assessmentId: assessment.id,
            standardRef,
            isMaterial: true,
          })),
        },
      }).then((res) => {
        upsertTarget({
          variables: {
            target: {
              id: targetId,
              disclosureRequirementRef: disclosureRequirement,
              assessmentId: materialityAssessmentId,
              direction: values.direction,
              ownerId: values.ownerId,
              isRelative: values.relative === TargetUnit.relative ? true : false,
              metricRef: values.metricRef,
              description: values.description,
              actionTargets: {
                data: chosenActions,
                on_conflict: {
                  constraint: Esrs_ActionTarget_Constraint_.ActionTargetActionIdTargetIdKey_,
                  update_columns: [
                    // Esrs_ActionTarget_Update_Column_.Estimate_
                  ],
                },
              },
              keyResults: {
                data: [
                  {
                    id: targetData?.keyResults.find((k) => k.reportingUnitId === null)?.id,
                    baseYear: values.baseYear,
                    baseline: `${values.baseline}`,
                    reportingUnitId: null,
                  },
                  ...(reportingUnits?.map((ru) => ({
                    id: targetData?.keyResults.find((k) => k.reportingUnitId === ru.id)?.id,
                    reportingUnitId: ru.id,
                    baseYear: values.baseYear,
                    baseline: `${values[`${KeyResultEnums.baseline}_${ru.id}`] ?? 0}`,
                  })) ?? []),
                ],
                on_conflict: {
                  constraint: Esrs_Baseline_Constraint_.KeyResultPkey_,
                  update_columns: [
                    Esrs_Baseline_Update_Column_.BaseYear_,
                    Esrs_Baseline_Update_Column_.Baseline_,
                    Esrs_Baseline_Update_Column_.ReportingUnitId_,
                  ],
                },
              },
              milestones: {
                data:
                  values.milestones?.map((mileStone) => ({
                    id: mileStone?.id,
                    year: Number(mileStone.year),
                    isBottomUp: mileStone?.isBottomUp,
                    milestoneResults: {
                      data: [
                        {
                          id: targetData?.milestones
                            ?.find((m) => m.id === mileStone?.id)
                            ?.milestoneResults?.find((m) => m.reportingUnitId === null)?.id,
                          value: mileStone?.value,
                          reportingUnitId: null,
                        },
                        ...(reportingUnits?.map((ru) => ({
                          id: targetData?.milestones
                            .find((m) => m.id === mileStone.id)
                            ?.milestoneResults?.find((m) => m.reportingUnitId === ru.id)?.id,
                          reportingUnitId: ru.id,
                          value: mileStone?.reportingUnits[`${ru.id}`] ?? 0,
                        })) ?? []),
                      ],
                      on_conflict: {
                        constraint: Esrs_MilestoneResult_Constraint_.MilestoneResultPkey_,
                        update_columns: [Esrs_MilestoneResult_Update_Column_.Value_],
                      },
                    },
                  })) ?? [],
                on_conflict: {
                  constraint: Esrs_Milestone_Constraint_.MilestoneIdKey_,
                  update_columns: [
                    Esrs_Milestone_Update_Column_.Year_,
                    Esrs_Milestone_Update_Column_.IsBottomUp_,
                  ],
                },
              },
              subsidiariesTargets: {
                data: [
                  ...subsidiaries.map((s) => ({
                    id: targetData?.subsidiariesTargets.find(
                      (target) => target?.materialStandard.assessmentId === s?.id
                    )?.id,
                    disclosureRequirementRef: disclosureRequirement,
                    assessmentId: s.materialStandards.length
                      ? s.materialStandards[0].id
                      : res.data?.insert_esrs_MaterialStandard?.returning.filter(
                          (r) => r.assessmentId === s.id
                        )[0].id,
                    direction: values.direction,
                    ownerId: values.ownerId,
                    isRelative: values.relative === TargetUnit.relative ? true : false,
                    metricRef: values.metricRef,
                    description: values.description,
                    keyResults: {
                      data: [
                        {
                          id: targetData?.subsidiariesTargets
                            ?.find((target) => target?.materialStandard.assessmentId === s?.id)
                            ?.keyResults.filter((k) => k.reportingUnitId === null)[0].id,
                          baseYear: values.baseYear,
                          baseline: `${values[`${KeyResultEnums.baseline}_${s?.id}`] ?? 0}`,
                          reportingUnitId: null,
                        },
                      ],
                      on_conflict: {
                        constraint: Esrs_Baseline_Constraint_.KeyResultPkey_,
                        update_columns: [
                          Esrs_Baseline_Update_Column_.BaseYear_,
                          Esrs_Baseline_Update_Column_.Baseline_,
                          Esrs_Baseline_Update_Column_.ReportingUnitId_,
                        ],
                      },
                    },
                    milestones: {
                      data:
                        values.milestones?.map((milestone) => ({
                          id:
                            targetData?.subsidiariesTargets
                              ?.find((target) => target?.materialStandard.assessmentId === s?.id)
                              ?.milestones.find((m) => m?.year === Number(milestone?.year))?.id ??
                            targetData?.subsidiariesTargets
                              ?.find((target) => target?.materialStandard.assessmentId === s?.id)
                              ?.milestones.find((m) => m?.year === Number(milestone?.prevYear))?.id,
                          year: milestone.year,
                          isBottomUp: false,
                          milestoneResults: {
                            data: [
                              {
                                id:
                                  targetData?.subsidiariesTargets
                                    ?.find(
                                      (target) => target?.materialStandard.assessmentId === s?.id
                                    )
                                    ?.milestones.find((m) => m.year === milestone.year)
                                    ?.milestoneResults.find((m) => m.reportingUnitId === null)
                                    ?.id ??
                                  targetData?.subsidiariesTargets
                                    ?.find(
                                      (target) => target?.materialStandard.assessmentId === s?.id
                                    )
                                    ?.milestones.find((m) => m.year === milestone?.prevYear)
                                    ?.milestoneResults.find((m) => m.reportingUnitId === null)?.id,
                                value: milestone?.subsidiaries[`${s?.id}`],
                                reportingUnitId: null,
                              },
                            ],
                            on_conflict: {
                              constraint: Esrs_MilestoneResult_Constraint_.MilestoneResultPkey_,
                              update_columns: [Esrs_MilestoneResult_Update_Column_.Value_],
                            },
                          },
                        })) ?? [],
                      on_conflict: {
                        constraint: Esrs_Milestone_Constraint_.MilestoneIdKey_,
                        update_columns: [Esrs_Milestone_Update_Column_.Year_],
                      },
                    },
                  })),
                ],
                on_conflict: {
                  constraint: Esrs_Target_Constraint_.TargetPkey_,
                  update_columns: [
                    Esrs_Target_Update_Column_.Description_,
                    Esrs_Target_Update_Column_.Direction_,
                    Esrs_Target_Update_Column_.IsRelative_,
                    Esrs_Target_Update_Column_.MetricRef_,
                  ],
                },
              },
            },
          },
          refetchQueries: [
            GetDisclosureRequirementGroupsDocument_,
            GetTargetsDrDocument_,
            GetTargetsDocument_,
            GetTargetsSubsidiariesDocument_,
            GetParentTargetsDrDocument_,
            GetMaterialStandardDocument_,
            EsrsAssessmentTargetsDocument_,
          ],
          awaitRefetchQueries: true,
        }).then((r) => {
          setIsSaving(false);
          if (!targetData?.id && !targetId)
            setTargetId(r.data?.insert_esrs_Target?.returning[0].id);
          if (milestoneId.length === 4) {
            setMilestoneId(
              r.data?.insert_esrs_Target?.returning[0].milestones.find(
                (m) => m.year === Number(milestoneId)
              )?.id
            );
          }
          deleteActionTarget({
            variables: {
              id: toDelete,
            },
            refetchQueries: [
              GetDisclosureRequirementGroupsDocument_,
              GetTargetsDocument_,
              GetActionsDocument_,
              GetTargetsSubsidiariesDocument_,
              GetParentTargetsDrDocument_,
              GetMaterialStandardDocument_,
            ],
            awaitRefetchQueries: true,
          });
        });
      });
    },
    [upsertTarget]
  );
};

export const getBaselineTotal = (
  fields: {
    id: any;
    [key: string]: any;
  }[],
  values: any
) => {
  const baselineValues = fields?.map((field) => Number(values[`baseline_${field.id}`] ?? 0)) ?? [];
  const total = baselineValues?.reduce((acc, cur) => {
    return acc + cur;
  }, 0);
  return total;
};
